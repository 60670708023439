<template>
  <div class="pb-16">
    <!-- 본문 -->
    <v-sheet class="pt-4 mx-auto">
      <v-img src="@/assets/deco/card-top-deco.png" max-width="240px" class="mx-auto" />
      <v-sheet width="80%" class="mx-auto">
        <div class="primary--text text-h6 text-center">{{ $t("page.mypage.shot-list.title") }}</div>

        <p class="warning--text text-center my-2">{{ $t("page.mypage.shot-list.warning-guide") }}</p>
        <p class="text-center text-body-1 px-md-8" style="color: #535353" v-html="$t('page.mypage.shot-list.description')"></p>
      </v-sheet>

      <!-- 촬영 내역 없을 시 -->
      <v-sheet v-if="!list.length" class="rounded-10 pt-8 text-center text-subtitle-1 primary--text">
        <v-icon size="40" color="primary" class="mb-2">mdi-cancel</v-icon><br />
        {{ $t("page.mypage.shot-list.empty") }}
      </v-sheet>

      <!-- 촬영 내역 있을 시 -->
      <v-sheet v-if="list.length" class="rounded-5 px-2 text-subtitle-1 primary--text">
        <ShotCard v-for="item in list" :key="item.id" :item="item" />
      </v-sheet>
    </v-sheet>
  </div>
</template>
<script>
import ShotCard from "./ShotCard"

export default {
  components: {
    ShotCard,
  },
  data: () => ({
    list: [],

    selected_list: [],

    dialog: {
      download: false,
    },
  }),

  mounted() {
    this.load()
  },

  methods: {
    load() {
      this.$http
        .post("/api/qrcode/select/byUser", {
          params: {
            user_id: this.$store.state.lafia_user.user_id,
          },
        })
        .then((res) => {
          console.log(res)
          this.list = res.data.reverse()
        })
    },
  },
}
</script>
