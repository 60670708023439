<template>
  <div>
    <!-- 제목 -->
    <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">쿠폰 관리</font>
    </v-sheet>

    <!-- 본문 -->
    <v-sheet outlined class="pa-2 ma-2">
      <!-- 검색 -->
      <v-sheet outlined class="pa-2 mb-2 d-flex">
        <v-sheet class="d-flex align-center" width="800">
          <!-- 유형 -->
          <v-sheet width="160" class="mr-3">
            <v-select outlined dense hide-details :items="select_list.search_type" v-model="search_type"></v-select>
          </v-sheet>

          <!-- 키워드 -->
          <v-text-field placeholder="검색어를 입력해주세요" outlined dense hide-details v-model="keyword" @keyup.enter="search()"></v-text-field>

          <!-- 제출 버튼 -->
          <v-btn height="40" class="px-6 ml-3" color="primary" dark depressed @click="search()">
            <v-icon small left>mdi-magnify</v-icon>
            검색하기
          </v-btn>

          <!-- 활성화 여부 체크 박스-->
          <v-checkbox v-model="isEnabled" class="shrink mt-0 ml-4" label="활성화만" color="primary" hide-details dense> 활성화 상태만</v-checkbox>
        </v-sheet>
      </v-sheet>

      <!-- 목록 -->
      <v-radio-group v-model="selected" hide-details>
        <v-data-table
          hide-default-header
          :items="list"
          :footer-props="{
            'items-per-page-options': [10, 20, 100, -1],
            itemsPerPageText: '페이지당 아이템수',
          }"
          no-data-text=""
        >
          <template v-slot:header>
            <tr>
              <th style="width: 52px"></th>
              <th style="width: 150px">종류</th>
              <th style="width: 150px">이름</th>
              <th style="width: 100px">할인가격(KRW)</th>
              <!-- <th style="width: 100px">할인가격(USD)</th> -->
              <th style="width: 50px">만료여부</th>
              <th style="width: 100px">발급자</th>
              <th style="width: 150px">시작일</th>
              <th style="width: 150px">종료일</th>
              <th style="width: 150px">생성일</th>
            </tr>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <v-radio class="ml-1" hide-details dense :value="item" @change="selectedItem = item"></v-radio>
              </td>
              <td class="text-truncate">
                {{ item.type }}
              </td>
              <td class="text-truncate">
                {{ item.title }}
              </td>
              <td class="text-truncate">{{ $toComma(item.discount_KRW) }} 원</td>
              <!-- <td class="text-truncate">{{ $toComma(item.discount_USD) }} USD</td> -->
              <td>{{ item.isExpired ? "비활성화" : "사용중" }}</td>
              <td class="text-truncate">
                {{ item.admin_id }}
              </td>

              <td class="text-truncate text-caption">
                {{ item.startAt ? dayjs(new Date(item.startAt)).format("YYYY-MM-DD") : "-" }}
              </td>
              <td class="text-truncate text-caption">
                {{ dayjs(new Date(item.expireAt)).format("YYYY-MM-DD") }}
              </td>
              <td class="text-truncate text-caption">
                {{ new Date(item.created).toLocaleString() }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-radio-group>

      <!-- 버튼 -->
      <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
        <v-btn class="px-6 ml-3" color="green" large dark depressed @click="updateOpen()">
          <v-icon small left>mdi-database-edit-outline</v-icon>
          수정하기
        </v-btn>

        <v-btn class="px-6 ml-3" color="blue" large dark depressed @click="createNew()">
          <v-icon small left>mdi-database-plus-outline</v-icon>
          추가하기
        </v-btn>
        <v-btn class="px-6 ml-3" color="red" large dark depressed @click="deleteItem()">
          <v-icon small left>mdi-database-remove-outline</v-icon>
          삭제하기
        </v-btn>
      </v-card>
    </v-sheet>
    <!-- 쿠폰 추가하기 -->
    <v-dialog v-model="dialog.create" width="660" content-class="rounded-xl">
      <InsertCoupons @close="close_handler" :key="componentCreateKey" />
    </v-dialog>

    <!-- 쿠폰 수정하기 -->
    <v-dialog v-model="dialog.update" width="660" content-class="rounded-xl">
      <UpdateCoupons :updateItem_import="selectedItem" @close="close_handler" :key="componentKey" />
    </v-dialog>
  </div>
</template>
<script>
import InsertCoupons from "./InsertCoupons"
import UpdateCoupons from "./UpdateCoupons"

export default {
  components: {
    InsertCoupons,
    UpdateCoupons,
  },
  data: () => ({
    select_list: {
      search_type: ["이름"],
    },

    search_type: "이름",
    keyword: "",
    isEnabled: true,

    list: [],

    selected: false,
    selectedItem: {},

    componentKey: 0,
    componentCreateKey: 0,

    dialog: {
      create: false,
      update: false,
    },
  }),
  mounted() {
    // 목록
    this.search()
  },
  watch: {
    selectedItem() {
      console.log(this.selectedItem)
      this.componentKey++
    },

    isEnabled() {
      this.search()
    },
  },
  methods: {
    // 목록
    search() {
      console.log("search", this.search_type, this.keyword)
      this.$http
        .get("/api/coupon/getCouponList/search", {
          params: {
            search_type: this.search_type,
            keyword: this.keyword,
            enabled: this.isEnabled,
          },
        })
        .then((res) => {
          this.list = res.data
        })
        .catch((e) => {
          alert(this.$t("alert.error_page"))
        })
    },
    // 닫기 핸들러
    close_handler() {
      this.dialog.create = false
      this.dialog.update = false
      this.selected = false
      this.selectedItem = {}
      this.search()
    },

    createNew() {
      this.dialog.create = true
      this.componentCreateKey++
    },

    // 삭제하기
    deleteItem() {
      if (!Object.keys(this.selectedItem).length) {
        alert("선택한 항목이 없습니다")
      } else {
        if (confirm("정말 해당 아이템을 삭제하겠습니까?")) {
          if (!this.selectedItem.isExpired) {
            alert("아이템을 비활성화 후에 삭제를 해주세요.")
            return
          }
          this.$http
            .post("/api/coupon/deleteCoupon", {
              coupon_id: this.selectedItem.id,
            })
            .then((res) => {
              if (res.data.affectedRows) {
                this.search()
                this.selected = false
                this.selectedItem = {}
              } else {
                this.search()
                this.selected = false
                this.selectedItem = {}
                alert("정상적으로 삭제가 되지 않았습니다.")
              }
            })
        }
      }
    },

    // update Open
    updateOpen() {
      if (!Object.keys(this.selectedItem).length) {
        alert("선택한 아이템이 없습니다")
      } else {
        this.dialog.update = true
      }
    },
  },
}
</script>
<style></style>
