<template>
  <div>
    <v-img src="@/assets/deco/card-top-deco.png" max-width="240px" class="mx-auto" />

    <div class="text-center primary--text text-h6 font-weight-medium">{{ $t("page.reservation.makeup-title") }}</div>

    <div class="text-center text-body-2">{{ $t("page.reservation.makeup-subtitle") }}</div>

    <MakerComponent name="민지" address="https://open.kakao.com/o/s9hdL8jg">
      <template v-slot:profile>
        <v-img class="ma-1 rounded-0" src="@/assets/maker/maker_minji.png" aspect-ratio="0.61" />
      </template>
      <template v-slot:title>
        <div :class="$vuetify.breakpoint.mobile ? 'small' : 'normal'" class="font-weight-medium">* 메이크업 & 헤어 스타일링 *</div>
        <ul :class="$vuetify.breakpoint.mobile ? 'xsmall' : 'medium'" class="mt-1" style="color: #595755">
          <li>
            (전) 아모레퍼시픽 뷰티 교육강사<br />
            서울패션위크 루키즈 페스티벌 패션쇼 메이크업 총괄 진행
          </li>
          <li>연극 행복을 찾아서 , 뮤지컬 급식왕, 오페라 마술피리 외 다수 공연 헤어&메이크업 진행</li>
          <li>삼성 갤럭시, 부산은행 외 다수 광고 촬영 헤어&메이크업 진행</li>
          <li>타이거 JK X 태권크리 MV 촬영 헤어&메이크업 진행</li>
          <li>김포공항 행사 퍼스널 컬러 클래스 진행</li>
          <li>개인화보, 프로필, 야외스냅 등 룩북 촬영 헤어&메이크업 다수 진행</li>
        </ul>
      </template>
    </MakerComponent>
  </div>
</template>
<script>
import MakerComponent from "@/views/makeup/components/MakerComponent"
import { mapState } from "vuex"

export default {
  components: {
    MakerComponent,
  },
  computed: {
    ...mapState(["lang"]),
  },
}
</script>
<style></style>
