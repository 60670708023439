<template>
  <v-app>
    <v-container>
      <!-- 결제 수단 : 카드 -->
      <v-card v-if="method == '카드'" outlined class="ma-5 pa-10">
        <h3 class="mb-4">{{ $t("page.pay.pay-card-method") }}</h3>
        <p v-html="$t('page.pay.pay-progress-text')"></p>
      </v-card>

      <!-- 결제 오류 -->
      <v-card v-if="error" outlined class="ma-5 pa-10">
        <h3 class="mb-4">{{ $t("page.pay.pay-error-title") }}</h3>
        <p>
          {{ $t("page.pay.pay-error-code") }} : {{ code }}<br />
          {{ $t("page.pay.pay-error-message") }}: {{ message }}
        </p>
      </v-card>
    </v-container>
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from "vuex"
import { PayOrderTypes } from "@/constant"
import { kStorageUrl } from "@/models/PSDLayer"

export default {
  data: () => ({
    error: false,
    method: "",
    code: "",
    message: "",
  }),

  computed: {
    ...mapGetters(["isKRW"]),
  },

  created() {
    /*
    // 중복 체크
    this.$http
      .post("/api/order/select/check_duplicate", {
        params: {
          toss_id: this.$route.query.orderId,
        },
      })
      .then((res) => {
        // 키오스크 연동 온라인 결제
        if (res.data[0].type == PayOrderTypes.OnlineConceptBuy || res.data[0].type == PayOrderTypes.OnlineAddShot) {
          this.onlinePayment(res.data[0])
        }
        // 무인촬영기
        else if (res.data[0].type == PayOrderTypes.OnliePhotoTouch) {
          // 정상 결제
          this.paymentAuto(res.data[0])
        } else if (res.data[0].type === PayOrderTypes.OnlinePrint) {
          this.onlinePaymentForPrint(res.data[0])
        } else {
          // 라피아캐슬
          this.$http
            .post("/api/reservation/select/specific/time/castle/check", {
              params: {
                year: res.data[0].year,
                month: res.data[0].month,
                day: res.data[0].day,
                time: res.data[0].time,
              },
            })
            .then((res2) => {
              if (res2.data.length) {
                if (res.data[0].cloth + res.data[0].uncloth + (res2.data[0].total_cloth + res2.data[0].total_uncloth) > 15) {
                  alert(this.$t("alert.error_max_reservation_error"))
                  this.$router.push("/")
                } else {
                  // 정상 결제
                  this.payment(res.data[0])
                }
              } else {
                // 정상 결제
                this.payment(res.data[0])
              }
            })
        }
      }) */
  },

  mounted() {
    let amount = this.$route.query.amount
    let orderId = this.$route.query.orderId
    let paymentKey = this.$route.query.paymentKey

    this.doPayCompleted({
      orderNo: orderId,
      paymentKey,
      amount: Number(amount),
    })
      .then((res) => {
        console.log("결제 완료후 처리 성공")
      })
      .catch((e) => {
        console.log(e)
        alert(this.$t("alert.error_doing_pay_unknown_error"))
      })
  },

  methods: {
    ...mapActions(["doPayCompleted", "reqPicturePrint", "updateUsedCoupons"]),

    // 정상 결제
    payment({ order_list_id, user_id, type }) {
      let tossPayment = () => {
        return this.$http.post("/api/payments/toss/confirm", {
          orderListId: order_list_id,
          paymentKey: this.$route.query.paymentKey,
          orderId: this.$route.query.orderId,
          amount: Number(this.$route.query.amount),
        })
      }
      /*
      if (this.$route.query.paymentKey === "free") {
        tossPayment = () => {
          return this.$http
            .post("/api/order/update/status", {
              params: {
                id: order_list_id,
                status: "결제완료",
              },
            })
            .then((res) => ({ data: { status: "DONE" } }))
        }
      } */

      // let tossPayment = () => {
      //   return this.$http.get("/api/toss/success", {
      //     params: {
      //       paymentKey: this.$route.query.paymentKey,
      //       orderId: this.$route.query.orderId,
      //       amount: this.$route.query.amount,
      //     },
      //   })
      // }

      // 토스 결제
      tossPayment().then((res) => {
        if (res.data.status != "DONE") {
          alert(this.$t("alert.error_doing_pay_unknown_error"))
        } else {
          this.updateUsedCoupons({ orderId: this.$route.query.orderId, user_id })

          this.$router.push("/reservation/complete?id=" + this.$route.query.orderId)

          this.$gtag.purchase({
            transaction_id: this.$route.query.orderId,
            value: Number(this.$route.query.amount),
            currency: "KRW",
            items: [
              {
                item_name: type,
                price: Number(this.$route.query.amount),
              },
            ],
          })
        }
      })
    },

    // 정상 결제 (무인촬영기)
    paymentAuto({ order_list_id, user_id, type }) {
      let tossPayment = () => {
        return this.$http.post("/api/payments/toss/confirm", {
          orderListId: order_list_id,
          paymentKey: this.$route.query.paymentKey,
          orderId: this.$route.query.orderId,
          amount: Number(this.$route.query.amount),
        })
      }

      if (this.$route.query.paymentKey === "test") {
        tossPayment = () => {
          return new Promise((resolve, reject) => {
            resolve({ data: { status: "DONE" } })
          })
        }
      }
      /*
      else if (this.$route.query.paymentKey === "free") {
        tossPayment = () => {
          return this.$http
            .post("/api/order/update/status", {
              params: {
                id: order_list_id,
                status: "결제완료",
              },
            })
            .then((res) => ({ data: { status: "DONE" } }))
        }
      } */

      tossPayment().then((res) => {
        if (res.data.status != "DONE") {
          alert(this.$t("alert.error_doing_pay_unknown_error"))
        } else {
          this.$http
            .post("/api/qrcode/update/status/byOrderId", {
              params: {
                order_id: order_list_id,
                paymentKey: this.$route.query.paymentKey,
                status: "선택완료",
              },
            })
            .then((res) => {
              this.$toast.success(this.$t("toast.succes-photo-effect-request"))
              this.updateUsedCoupons({ orderId: this.$route.query.orderId, user_id })

              this.$router.push("/mypage/home?type=picture")

              this.$gtag.purchase({
                transaction_id: this.$route.query.orderId,
                value: Number(this.$route.query.amount),
                currency: "KRW",
                items: [
                  {
                    item_name: type,
                    price: Number(this.$route.query.amount),
                  },
                ],
              })
            })
        }
      })
    },

    // 키오스크 온라인 결제 연동
    onlinePayment({ order_list_id, user_id, type }) {
      let tossPayment = () => {
        return this.$http.post("/api/payments/toss/confirm", {
          orderListId: order_list_id,
          paymentKey: this.$route.query.paymentKey,
          orderId: this.$route.query.orderId,
          amount: Number(this.$route.query.amount),
        })
      }

      if (this.$route.query.paymentKey === "test") {
        tossPayment = () => {
          return new Promise((resolve, reject) => {
            resolve({ data: { status: "DONE" } })
          })
        }
      } /* else if (this.$route.query.paymentKey === "free") {
        tossPayment = () => {
          return this.$http
            .post("/api/order/update/status", {
              params: {
                id: order_list_id,
                status: "결제완료",
              },
            })
            .then((res) => ({ data: { status: "DONE" } }))
        }
      } */

      tossPayment().then(async (res) => {
        if (res.data.status != "DONE") {
          alert(this.$t("alert.error_doing_pay_unknown_error"))
        } else {
          const result = await this.$http.post("/api/qrcode/pay/complete", {
            pay_amount: Number(this.$route.query.amount),
            orderListId: order_list_id,
            currency: "KRW",
          })
          if (result.data.affectedRows) {
            this.updateUsedCoupons({ orderId: this.$route.query.orderId, user_id })

            // 업데이트 완료
            // 결제가 완료되었습니다. 안내 페이지로 이동
            this.$router.push("/onlinePaySuccess?id=" + this.$route.query.orderId)
            //결제완료
            this.$gtag.purchase({
              transaction_id: this.$route.query.orderId,
              value: Number(this.$route.query.amount),
              currency: "KRW",
              items: [
                {
                  item_name: type,
                  price: Number(this.$route.query.amount),
                },
              ],
            })
          } else {
            alert(this.$t("alert.error_doing_pay_unknown_error_2"))
          }
        }
      })
    },

    onlinePaymentForPrint({ order_list_id, user_id, memo }) {
      let tossPayment = () => {
        return this.$http.post("/api/payments/toss/confirm", {
          orderListId: order_list_id,
          paymentKey: this.$route.query.paymentKey,
          orderId: this.$route.query.orderId,
          amount: Number(this.$route.query.amount),
        })
      }

      if (this.$route.query.paymentKey === "test") {
        tossPayment = () => {
          return new Promise((resolve, reject) => {
            resolve({ data: { status: "DONE" } })
          })
        }
      } /* else if (this.$route.query.paymentKey === "free") {
        tossPayment = () => {
          return this.$http
            .post("/api/order/update/status", {
              params: {
                id: order_list_id,
                status: "결제완료",
              },
            })
            .then((res) => ({ data: { status: "DONE" } }))
        }
      } */

      tossPayment().then(async (res) => {
        if (res.data.status != "DONE") {
          alert(this.$t("alert.error_doing_pay_unknown_error"))
        } else {
          try {
            let info = JSON.parse(memo)
            info = {
              ...info,
              imageUrl: this.$imageUrl(`${kStorageUrl}/complete/${info.fileName}`, info.updated),
              orderId: this.$route.query.orderId,
            }
            const res = await this.reqPicturePrint(info)
          } catch (e) {
            alert(this.$t("alert.error_failed_print_request_after_paid"))
          }
        }
      })
    },
  },
}
</script>
