<template>
  <v-card class="d-flex flex-column justify-center align-center">
    <v-card-title> 현장 방문 추가 </v-card-title>
    <v-divider></v-divider>
    <v-card-subtitle class="my-2 pa-0"> 아래 인원 수 및 시간 정보를 입력해 주세요. </v-card-subtitle>
    <v-card-text>
      <table class="table mt-4">
        <tr>
          <th>아이디</th>
          <th style="min-width: 60px">이름</th>
          <th>인원수</th>
        </tr>
        <tr>
          <td class="font-weight-medium black--text text-body-1">{{ info.user_id }}</td>
          <td class="font-weight-medium black--text text-body-1">{{ info.name }}</td>
          <td>
            <div class="d-flex justify-center align-center">
              <v-select
                style="width: 72px; margin: 0 6px; z-index: 5"
                class="rounded-10 shrink my-0"
                hide-details
                outlined
                dense
                append-icon="mdi-chevron-down"
                :items="select_person_amount"
                v-model.number="persons"
              >
                <template v-slot:selection="{ item }">
                  <span class="pl-1 pb-2px">{{ item }}</span>
                </template>
                <template v-slot:append>
                  <v-icon style="width: 12px">mdi-chevron-down</v-icon>
                </template>
              </v-select>
              명
            </div>
          </td>
        </tr>
      </table>
      <v-sheet class="text-center mt-6">
        <div>
          결제금액 : <span class="font-danjunghae mx-2" style="font-size: 24px">{{ $toComma(totalPrice) }}</span
          >원
        </div>
        <div>(평일: 29,000원, 주말 39,000원)</div>

        <div class="d-flex justify-center mt-5 flex-wrap mx-auto" style="width: 350px">
          <v-btn
            v-for="item in time_option_list"
            :key="item"
            height="36"
            depressed
            class="timeSelectBtn rounded-10 px-2 mb-1 my-1 mx-2"
            :class="{ enable: selected_time == item }"
            @click="selected_time = item"
          >
            {{ item }}
          </v-btn>
        </div>
      </v-sheet>
      <v-sheet class="mt-3">
        <v-textarea lass="rounded-15 flex-grow-1" outlined v-model="memo" counter="512" maxlength="512" height="150" label="메모" />
      </v-sheet>
    </v-card-text>
    <v-card-actions class="mb-4">
      <v-btn width="140px" color="warning" large class="px-8" depressed @click="$emit('cancel')">취소</v-btn>
      <v-btn width="140px" color="success" large class="px-4" depressed @click="submit" :disabled="!selected_time" :loading="saving">결제 확인 및 저장</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { RESERV_CAN_TIME_LIST, Holidays } from "@/constant"
export default {
  props: ["info", "saving", "date"],
  data: () => ({
    select_person_amount: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
    selected_time: "",
    persons: 1,
    time_option_list: RESERV_CAN_TIME_LIST,
    memo: "",
  }),
  mounted() {
    let hourIdx = Math.min(Math.max(this.dayjs().hour() - 12, 0), 7)
    this.selected_time = this.time_option_list[hourIdx]
  },
  methods: {
    isWeekendOrHoliday() {
      const date = this.date
      return date.day() === 6 || date.day() === 0 || Holidays.includes(date.format("YYYY-MM-DD"))
    },

    submit: _.debounce(async function () {
      this.$emit("save", { count: this.persons, time: this.selected_time, price: this.totalPrice, memo: this.memo })
    }, 500),
  },

  computed: {
    totalPrice() {
      return this.persons * (this.isWeekendOrHoliday() ? 39000 : 29000)
    },
  },
}
</script>
<style scoped>
.table {
  width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;
  text-align: center;
}
tr th {
  padding: 4px 8px;
  height: 64px;
  border: 1px solid #ccc;
  border-collapse: collapse;
  background: rgba(195, 126, 120, 0.15);
}
tr td {
  height: 64px;
  vertical-align: middle;
  margin-top: auto;
  margin-bottom: auto;
  border-collapse: collapse;
  border: 1px solid #ccc;
}
.timeSelectBtn {
  border: 1px solid lightgray;
  color: #515151;
  font-size: 16px;
  font-weight: normal;
  margin: 0 3px;
  background-color: white !important;
}
.enable {
  background: #8d75cb !important;
  color: white !important;
}
</style>
