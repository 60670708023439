<template>
  <div class="pb-10">
    <v-sheet outlined class="ma-2 pa-2 mb-2 d-flex align-center">
      <v-btn depressed @click="$emit('prev')" color="white"><v-icon> mdi-arrow-left </v-icon></v-btn>
      &nbsp;&nbsp;세부 내역서 ({{ month + 1 }} 월)
    </v-sheet>
    <!-- 본문 -->
    <v-sheet outlined class="pa-2 ma-2">
      <v-sheet v-if="isLoading">
        <span>Loading... Please wait</span>
      </v-sheet>
      <v-sheet v-else>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>{{ month + 1 }} 월</th>
                <th>드레스 예약</th>
                <th>컨셉 결제</th>
                <th>촬영 추가</th>
                <th>보정 요청</th>
                <th>인쇄 요청</th>
                <!-- <th>현장 결제</th> -->
                <th>결제 취소</th>
                <th>매출 합계</th>
              </tr>
            </thead>
            <tbody>
              <tr class="accentBg">
                <td class="text-center">계</td>
                <td>{{ $toComma(totalStats.dress) }}</td>
                <td>{{ $toComma(totalStats.concept) }}</td>
                <td>{{ $toComma(totalStats.addShot) }}</td>
                <td>{{ $toComma(totalStats.photoTouch) }}</td>
                <td>{{ $toComma(totalStats.print) }}</td>
                <!-- <td>{{ $toComma(totalStats.offline) }}</td> -->
                <td>{{ $toComma(totalStats.cancel_amount) }}</td>
                <td>{{ $toComma(totalStats.total_amount) }}</td>
              </tr>
              <tr v-for="(item, index) in items" :key="index" :class="{ greyBg: item.date.day() === 1 }">
                <td :class="{ 'red--text': item.date.day() === 0 || item.date.day() === 6 }">{{ item.date.format("D") }}일</td>
                <td>{{ $toComma(item.dress) }}</td>
                <td>{{ $toComma(item.concept) }}</td>
                <td>{{ $toComma(item.addShot) }}</td>
                <td>{{ $toComma(item.photoTouch) }}</td>
                <td>{{ $toComma(item.print) }}</td>
                <!-- <td>{{ $toComma(item.offline) }}</td> -->
                <td>{{ $toComma(item.cancel_amount) }}</td>
                <td>{{ $toComma(item.total_amount) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-sheet>
    </v-sheet>
  </div>
</template>
<script>
import statusService from "@/api/stats-service"
export default {
  props: {
    month: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    items: [],
    totalStats: {
      dress: 0,
      concept: 0,
      addShot: 0,
      photoTouch: 0,
      cancel_amount: 0,
      //offline: 0,
    },
  }),
  mounted() {
    this.load()
  },

  computed: {
    totalDress() {
      this.items.reduce((sum, e) => sum + e.dress, 0)
    },
    totalConcept() {
      this.items.reduce((sum, e) => sum + e.dress, 0)
    },
  },

  methods: {
    async load() {
      try {
        this.isLoading = true
        let fromDayjs = this.dayjs().month(this.month).startOf("month")
        let toDayjs = fromDayjs.endOf("month")

        const from = fromDayjs.format("YYYY-MM-DD")
        const to = toDayjs.format("YYYY-MM-DD")
        console.log("load ", from, to)

        const result = await statusService.getSalesStatsDetail(from, to)

        const totalSalesResult = await statusService.getSaleStatsV2(false, from, to)

        let items = this._getStructItems(fromDayjs, toDayjs, result, totalSalesResult)
        this.totalStats = {
          dress: items.reduce((sum, e) => sum + e.dress, 0),
          concept: items.reduce((sum, e) => sum + e.concept, 0),
          addShot: items.reduce((sum, e) => sum + e.addShot, 0),
          photoTouch: items.reduce((sum, e) => sum + e.photoTouch, 0),
          print: items.reduce((sum, e) => sum + e.print, 0),
          //offline: items.reduce((sum, e) => sum + e.offline, 0),
          cancel_amount: items.reduce((sum, e) => sum + Number(e.cancel_amount), 0),
          total_amount: items.reduce((sum, e) => sum + Number(e.total_amount), 0),
        }

        items.sort((a, b) => a.date - b.date)
        this.items = items
      } catch (e) {
        console.log(e)
        alert("데이터를 가져오지 못했습니다.")
      } finally {
        this.isLoading = false
      }
    },

    _getStructItems(from, to, infos, cancelInfos) {
      const diffDay = to.diff(from, "day")

      return [...Array(diffDay + 1).keys()].map((e) => {
        const date = from.add(e, "day")
        let cancelInfo = cancelInfos.find((element) => element.date === date.format("YYYY-MM-DD")) || {
          cancel_amount: 0,
          pay_amount: 0,
        }
        cancelInfo = {
          ...cancelInfo,
          total_amount: cancelInfo.pay_amount - cancelInfo.cancel_amount,
        }

        const infoValue = infos[date.format("YYYY-MM-DD")]
        let eachItem = {
          dress: 0,
          concept: 0,
          addShot: 0,
          photoTouch: 0,
          print: 0,
          //offline: 0,
        }
        if (infoValue) {
          eachItem.dress = infoValue
            .filter((p) => p.type && (p.type.includes("예약") || p.type.includes("이용권")))
            .filter((p) => p.type && !p.type.includes("현장"))
            .reduce((sum, e) => sum + Number(e.pay_amount), 0)
          eachItem.concept = infoValue.filter((p) => p.type && p.type.includes("컨셉구매")).reduce((sum, e) => sum + Number(e.pay_amount), 0)
          eachItem.addShot = infoValue.filter((p) => p.type && p.type.includes("추가촬영")).reduce((sum, e) => sum + Number(e.pay_amount), 0)
          eachItem.photoTouch = infoValue
            .filter((p) => p.type && p.type.includes("보정"))
            .filter((p) => p.type && !p.type.includes("현장"))
            .reduce((sum, e) => sum + Number(e.pay_amount), 0)
          eachItem.print = infoValue
            .filter((p) => p.type && p.type.includes("인쇄"))
            .filter((p) => p.type && !p.type.includes("현장"))
            .reduce((sum, e) => sum + Number(e.pay_amount), 0)
          //eachItem.offline = infoValue.filter((p) => p.type && p.type.includes("현장")).reduce((sum, e) => sum + Number(e.pay_amount), 0)
        }

        return {
          ...cancelInfo,
          ...eachItem,
          dateStr: date.format("YYYY-MM-DD"),
          date,
        }
      })
    },
  },
}
</script>
<style scoped>
.greyBg {
  background-color: rgb(220, 220, 220);
}

.accentBg {
  background-color: rgb(220, 182, 220);
}
</style>
