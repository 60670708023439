<template>
  <div>
    <!-- LIST -->
    <v-sheet v-show="view_type == 'list'" max-width="1000" class="mx-auto mt-8 mb-16 px-4">
      <p class="text-h5 font-weight-bold" style="letter-spacing: 1px !important">후기/팁</p>
      <v-divider></v-divider>

      <div class="py-4">
        <v-sheet class="py-4 rounded-15 customBoard">
          <v-data-table v-show="!$vuetify.breakpoint.mobile" hide-default-header hide-default-footer :items="list" no-data-text="">
            <template v-slot:item="{ item }">
              <tr class="pa-0 ma-0" style="cursor: pointer; height: 64px" @click="read(item)">
                <td class="px-2 font-weight-medium">{{ item.title }}</td>
              </tr>
            </template>
          </v-data-table>

          <v-data-table v-show="$vuetify.breakpoint.mobile" hide-default-header hide-default-footer :items="list" no-data-text="">
            <template v-slot:item="{ item }">
              <tr class="pa-0 ma-0" style="cursor: pointer; height: 54px; width: 100%; border-bottom: 1px solid #dedede" @click="read(item)">
                <td class="px-3 font-weight-medium" style="border: none; padding: 0">{{ item.title }}</td>
              </tr>
            </template>
          </v-data-table>
          <v-divider></v-divider>

          <!-- 버튼 & 페이지네이션 -->
          <v-sheet class="mt-3 mx-3 d-flex align-center">
            <v-sheet width="140" height="40"></v-sheet>
            <v-spacer></v-spacer>

            <!-- 페이징 -->
            <v-pagination class="my-2" color="primary" v-model="page" :length="pageLength" :total-visible="7"></v-pagination>
            <v-spacer></v-spacer>

            <v-sheet width="140" height="40"></v-sheet>
          </v-sheet>

          <v-sheet class="d-flex justify-center mt-10">
            <!-- TEXT -->
            <v-text-field
              height="40"
              style="font-size: 18px; max-width: 340px"
              class="rounded-10 font-weight-bold"
              outlined
              hide-details
              dense
              persistent-placeholder
              autofocus
              v-model="keyword"
              append-icon="mdi-magnify"
              @keyup.enter="search()"
            >
              <template v-slot:append>
                <v-icon size="24" class="mr-1" color="#424361">mdi-magnify</v-icon>
              </template>
            </v-text-field>
            <v-btn height="40" class="ml-4 rounded-10" color="primary" depressed @click="view_type = 'write'"> 작성하기 </v-btn>
          </v-sheet>
        </v-sheet>
      </div>
    </v-sheet>

    <!-- READ -->
    <v-sheet v-show="view_type == 'read'" max-width="1000" class="mx-auto mt-8 mb-16 px-4">
      <p class="text-h5 font-weight-bold" style="letter-spacing: 1px !important">후기/팁</p>
      <v-divider></v-divider>

      <!-- 제목 -->
      <p style="font-size: 18px" class="ma-0 pa-0 pt-4 text-truncate font-weight-medium">
        {{ title }}
      </p>

      <v-sheet class="d-flex align-center justify-space-between mt-1 mb-2">
        <!-- 실명 -->
        <span class="text-body-2 font-weight-medium">
          <font class="primary--text">{{ writer }}</font>
        </span>

        <!-- 작성일 -->
        <span class="text-caption grey--text">
          {{ new Date(created).toLocaleDateString() }}
        </span>
      </v-sheet>

      <v-divider></v-divider>
      <v-sheet class="mt-4 mb-2">
        <TipTapReader v-if="options.content" style="min-height: 300px" class="tiptap_style" :options="options" />
      </v-sheet>
      <v-divider class="my-4"></v-divider>
      <v-sheet class="d-flex justify-end">
        <v-btn height="40" color="primary" class="rounded-10 px-8" depressed @click="view_type = 'list'"> 목록 </v-btn>
      </v-sheet>
    </v-sheet>

    <!-- WRITE -->
    <div v-show="view_type == 'write'" max-width="1000" class="mx-auto mt-8 mb-16 px-4">
      <p class="text-h5 font-weight-bold" style="letter-spacing: 1px !important">후기/팁</p>

      <!-- 제목 -->
      <v-sheet class="pa-1 mt-2 rounded-10" outlined>
        <v-text-field hide-details dense placeholder="제목을 입력해주세요" solo flat v-model="title"></v-text-field>
      </v-sheet>

      <!-- 본문 -->
      <TipTapWriter class="tiptap_style mt-2" :options="options" />

      <!-- 버튼 -->
      <v-sheet class="d-flex justify-center pa-1 mt-2">
        <v-btn class="mx-2 px-6 rounded-10" color="#ADAFCA" dark depressed @click="view_type = 'list'"> 취소하기 </v-btn>
        <v-btn class="mx-2 px-6 rounded-10" color="primary" dark depressed @click="submit()"> 등록하기 </v-btn>
      </v-sheet>
    </div>
  </div>
</template>
<script>
import TipTapReader from "@/components/tiptap/Reader"
import TipTapWriter from "@/components/tiptap/Writer"

export default {
  components: {
    TipTapReader,
    TipTapWriter,
  },

  data: () => ({
    // 목록
    list: [],

    // 페이징
    page: 1,
    pageLength: 0,
    itemsPerPage: 6,

    // 검색 키워드
    keyword: "",

    //TipTap
    options: {
      content: "",
      editable: true,
      supportImage: true,
      supportVideo: true,
    },

    view_type: "list",
    selected_id: "",

    writer: "",
    title: "",
    created: "",
  }),

  watch: {
    // 페이징
    page() {
      this.currentPageList = this.list.slice((this.page - 1) * 6, this.page * 6)
    },
  },

  mounted() {
    this.load()
  },

  methods: {
    load() {
      this.$http.post("/api/board/select").then((res) => {
        if (res.data.length) {
          this.list = res.data
          this.currentPageList = res.data.slice(0, 6)
          this.pageLength = Math.ceil(res.data.length / 6)
        }
      })
    },

    search() {
      // 카드 검색 결과
      this.$http
        .post("/api/board/select/search", {
          params: {
            keyword: this.keyword,
          },
        })
        .then((res) => {
          if (res.data.length) {
            this.list = res.data
            this.currentPageList = res.data.slice(0, 6)
            this.pageLength = Math.ceil(res.data.length / 6)
          }
        })
    },

    read(item) {
      this.selected_id = item.id
      this.view_type = "read"

      if (this.view_type == "read") {
        this.$http
          .post("/api/board/select/specific", {
            params: {
              id: this.selected_id,
            },
          })
          .then((res) => {
            this.writer = res.data[0].writer
            this.title = res.data[0].title
            this.options.content = res.data[0].content
            this.created = res.data[0].created
          })
      }
    },

    // DB 저장
    insert: _.debounce(async function () {
      this.$http
        .post("/api/board/insert", {
          params: {
            writer: this.$store.state.lafia_user.user_id,
            title: this.title,
            content: this.options.content,
          },
        })
        .then((res) => {
          this.view_type = "list"
          this.title = ""
          this.options.content = ""
          this.load()
        })
    }, 500),

    // 취소하기
    cancel() {
      if (confirm("지금 작성하고 계신 글이 저장되지 않습니다.\n페이지를 이동하시겠습니까?")) {
        this.$router.go(-1)
      }
    },

    // 등록하기
    async submit() {
      if (!this.$store.state.lafia_user.is_logined) {
        alert("로그인 후 이용바랍니다")
      } else if (!this.title.length) {
        alert("제목을 입력해주세요")
      } else if (!this.options.content.length) {
        alert("내용을 입력해주세요")
      } else {
        // DB 저장
        await this.insert()
      }
    },
  },
}
</script>
