<template>
    <v-img :src="filteredImageUrl">
        <div v-if="loading" class="d-flex align-center justify-center fill-height">
            <v-progress-circular color="white" indeterminate></v-progress-circular>
        </div>
    </v-img>
</template>

<script>

import lutFilter from "lut-filter/webgl";
import { loadImage } from "@/util/utils"

export default {

    props: {
        srcImage: {
            type: [String, HTMLImageElement],
            required: true,
        },
        lutUrl: {
            type: String,
            required: true,
        },
    },

    data: () => ({
        filteredImageUrl: "",
        loading: true,
        appliedLutUrl: "",
    }),

    async mounted() {
        await this.applyFilter();
    },

    watch: {
        /*
        srcImage: async function () {
            this.loading = true;
            await this.applyFilter();
        },
        */
        lutUrl: async function () {
            await this.applyFilter();
        }
    },

    methods: {
        applyFilter: async function () {
            if (this.appliedLutUrl === this.lutUrl) {
                return;
            }
            this.appliedLutUrl = this.lutUrl;
            this.loading = true;
            const srcImage = typeof this.srcImage == 'string' ? await loadImage(this.srcImage) : this.srcImage;
            const lutImage = await loadImage(this.lutUrl);
            const canvas = document.createElement("canvas");
            canvas.width = srcImage.width;
            canvas.height = srcImage.height;
            lutFilter({
                canvas: canvas,
                image: srcImage,
                filterImage: lutImage,
            });

            this.filteredImageUrl = canvas.toDataURL('image/jpg', 1.0);
            this.loading = false;
        },

        src: function () {
            return this.filteredImageUrl;
        }
    }
}

</script>