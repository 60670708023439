export async function fetchConceptByTitle(vue, title) {
  const result = await vue.$http.post("/api/concept/select/search", {
    params: { keyword: title },
  })

  if (result.data && result.data.length > 0) {
    const concept = result.data[0]
    return {
      ...concept,
      imageUrl: vue.$imageUrl("/upload/concept/thumbnail_image/" + concept.pattern_1_image),
    }
  }
  return null
}
