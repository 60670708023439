<template>
  <div class="pb-10 px-4">
    <TitleLogo title="트래블러 랭킹" />
    <v-divider></v-divider>
    <v-sheet v-if="isLoading" class="text-center mt-10">
      <div class="font-weight-medium text-body-1 pb-4">Loading...</div>
      <v-progress-circular size="48" :width="2" color="primary" indeterminate></v-progress-circular>
    </v-sheet>
    <v-sheet v-else>
      <v-list>
        <v-list-item-content>
          <v-row> <v-col cols="2">순위</v-col> <v-col>닉네임</v-col><v-col>실적</v-col><v-col>이벤트수</v-col><v-col>활동일</v-col> </v-row>
        </v-list-item-content>
        <v-list-item v-for="(traveler, index) in items" :key="traveler.code">
          <v-list-item-content>
            <v-row>
              <v-col cols="2">{{ index + 1 }}</v-col> <v-col>{{ traveler.name }}</v-col
              ><v-col>{{ $toComma(traveler.total_amount) }}</v-col>
              <v-col>{{ traveler.pageViews || 0 }}</v-col>
              <v-col>{{ dayjs(traveler.startAt).format("YYYY.MM.DD") }}</v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-sheet>
  </div>
</template>
<script>
import TitleLogo from "@/views/components/TitleLogo"
import { mapState } from "vuex"
const kMaxLength = 256
export default {
  components: {
    TitleLogo,
  },
  data: () => ({
    isLoading: false,
    items: [],
  }),
  computed: {
    ...mapState(["lafia_user"]),
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      this.isLoading = true
      try {
        const promotionsRes = await this.$http.get("/api/promotion/select/travelers")

        const statsRes = await this.$http.get("/api/stats/getPromotionCodeSaleStats", {
          params: {
            from: "2024-04-01",
            to: this.dayjs().format("YYYY-MM-DD"),
          },
        })

        const analyticsRes = await this.$http.get("/api/analytics")

        let items = promotionsRes.data.map((promotion) => {
          const statsItem = statsRes.data.find((e) => e.promotionCode === promotion.code)
          const pageViews = this.getPageViewCount(promotion.code, analyticsRes.data.rows)
          return statsItem
            ? {
                ...statsItem,
                startAt: promotion.startAt,
                name: promotion.name,
                pageViews,
              }
            : {
                total_amount: 0,
                startAt: promotion.startAt,
                name: promotion.name,
                pageViews,
              }
        })

        items = items.filter((e) => e)
        items = items.sort((a, b) => b.total_amount - a.total_amount)
        console.log(items)
        this.items = items
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },

    getPageViewCount(event, rows) {
      let eventName = `/reservation/buy?event=${event}`
      let packageEventName = `/reservation/package?packageEvent=${event}`
      const filtered = rows.filter((row) => {
        return row.dimensionValues[0].value.startsWith(eventName) || row.dimensionValues[0].value.startsWith(packageEventName)
      })
      const sum = filtered.reduce((acc, row) => {
        return acc + Number(row.metricValues[0].value)
      }, 0)
      return sum
    },
  },
}
</script>
<style scoped>
.col {
  font-size: 12px;
}
</style>
