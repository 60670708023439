<template>
  <div>
    <!-- 제목 -->
    <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">트래블러 관리</font>
    </v-sheet>

    <!-- 본문 -->
    <v-sheet outlined class="pa-2 ma-2">
      <!-- 목록 -->
      <v-radio-group v-model="selected" hide-details>
        <v-data-table
          hide-default-header
          :items="list"
          :footer-props="{
            'items-per-page-options': [10, 20, 100, -1],
            itemsPerPageText: '페이지당 아이템수',
          }"
          no-data-text=""
        >
          <template v-slot:header>
            <tr>
              <th style="width: 52px"></th>
              <th style="width: 100px">이름</th>
              <th style="width: 150px">코드</th>
              <th style="width: 50px">활성화여부</th>
              <th style="width: 150px">예약 날짜 시작일</th>
              <th style="width: 150px">예약 날짜 종료일</th>
              <th style="width: 150px">생성일</th>
            </tr>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <v-radio class="ml-1" hide-details dense :value="item" @change="selectedItem = item"></v-radio>
              </td>
              <td class="text-truncate">
                {{ item.name }}
              </td>
              <td class="text-truncate">
                {{ item.code }}
              </td>
              <td class="text-truncate">
                {{ item.enabled }}
              </td>
              <td class="text-truncate text-caption">
                {{ item.startAt ? dayjs(new Date(item.startAt)).format("YYYY-MM-DD") : "-" }}
              </td>
              <td class="text-truncate text-caption">
                {{ dayjs(new Date(item.endAt)).format("YYYY-MM-DD") }}
              </td>
              <td class="text-truncate text-caption">
                {{ dayjs(new Date(item.created)).format("YYYY-MM-DD") }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-radio-group>

      <!-- 버튼 -->
      <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
        <v-sheet width="160" class="mr-3">
          <v-select outlined dense hide-details :items="packageProducts" v-model="selectedProduct" item-text="name" item-value="code"></v-select>
        </v-sheet>
        <v-btn class="px-6 ml-3" color="primary" large dark depressed @click="openPackageUrl()" outlined>
          <v-icon small left>mdi-open-in-new</v-icon>
          패키지 구매 LINK
        </v-btn>
        <v-btn class="px-6 ml-3" color="primary" large dark depressed @click="openUrl()" outlined>
          <v-icon small left>mdi-open-in-new</v-icon>
          드레스 예앾 LINK
        </v-btn>
        <v-btn class="px-6 ml-3" color="green" large dark depressed @click="updateOpen()">
          <v-icon small left>mdi-database-edit-outline</v-icon>
          수정하기
        </v-btn>

        <v-btn class="px-6 ml-3" color="blue" large dark depressed @click="createNewConcept()">
          <v-icon small left>mdi-database-plus-outline</v-icon>
          추가하기
        </v-btn>
        <v-btn class="px-6 ml-3" color="red" large dark depressed @click="deleteItem()">
          <v-icon small left>mdi-database-remove-outline</v-icon>
          삭제하기
        </v-btn>
      </v-card>
    </v-sheet>

    <!-- 프로모션 수정하기 -->
    <v-dialog v-model="dialog.update" width="660" content-class="rounded-xl">
      <UpdateTraveler :updateItem_import="selectedItem" @close="close_handler" :key="componentKey" />
    </v-dialog>

    <!-- 프로모션 추가하기 -->
    <v-dialog v-model="dialog.create" width="660" content-class="rounded-xl">
      <InsertTraveler @close="close_handler" :key="componentCreateKey" />
    </v-dialog>
  </div>
</template>
<script>
import InsertTraveler from "./InsertTraveler"
import UpdateTraveler from "./UpdateTraveler"

export default {
  components: {
    InsertTraveler,
    UpdateTraveler,
  },
  data: () => ({
    list: [],
    packageProducts: [],

    selected: false,
    selectedItem: {},
    selectedProduct: null,

    componentKey: 0,
    componentCreateKey: 0,

    dialog: {
      create: false,
      update: false,
    },

    visibleCouponSelect: false,
  }),

  mounted() {
    // 목록
    this.search()

    this.$http.get("/api/promotion/select/packages").then((res) => {
      this.packageProducts = res.data
      if (res.data.length > 0) {
        this.selectedProduct = res.data[0].code
      }
    })
  },

  watch: {
    selectedItem() {
      console.log(this.selectedItem)
      this.componentKey++
    },
  },

  methods: {
    // 목록
    search() {
      this.$http
        .get("/api/promotion/select/travelers/all")
        .then((res) => {
          this.list = res.data
        })
        .catch((e) => {
          alert(this.$t("alert.error_page"))
        })
    },
    // 닫기 핸들러
    close_handler() {
      this.dialog.create = false
      this.dialog.update = false
      this.selected = false
      this.selectedItem = {}
      this.search()
    },

    createNewConcept() {
      this.dialog.create = true
      this.componentCreateKey++
    },

    // 삭제하기
    deleteItem() {
      if (!this.selectedItem || !this.selectedItem.id) {
        alert("선택한 항목이 없습니다")
      } else {
        if (confirm("정말 해당 아이템을 삭제하겠습니까?")) {
          this.$http
            .post("/api/promotion/delete/id", {
              params: {
                id: this.selectedItem.id,
              },
            })
            .then((res) => {
              if (res.data.affectedRows) {
                this.search()
                this.selected = false
                this.selectedItem = {}
              } else {
                this.search()
                this.selected = false
                this.selectedItem = {}
                alert("정상적으로 삭제가 되지 않았습니다.")
              }
            })
        }
      }
    },

    // update Open
    updateOpen() {
      if (!this.selectedItem || !this.selectedItem.id) {
        alert("선택한 아이템이 없습니다")
      } else {
        this.componentKey++
        this.dialog.update = true
      }
    },

    openUrl() {
      if (!this.selectedItem || !this.selectedItem.id) {
        alert("선택한 아이템이 없습니다")
      } else {
        let url = window.location.origin + "/reservation/buy?event=" + this.selectedItem.code
        window.open(url, "_blank")
      }
    },
    openPackageUrl() {
      if (!this.selectedItem || !this.selectedItem.id) {
        alert("선택한 아이템이 없습니다")
      } else {
        let url = window.location.origin + "/reservation/package?packageEvent=" + this.selectedItem.code
        if (this.selectedProduct) {
          url += `&productId=${this.selectedProduct}`
        }
        window.open(url, "_blank")
      }
    },

    showCouponSelct() {
      this.visibleCouponSelect = true
    },
  },
}
</script>
