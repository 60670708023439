<template>
  <div>
    <!-- 본문 -->
    <div class="py-8">
      <v-sheet class="py-4 px-4 mx-auto rounded-lg text-center" width="80%" max-width="400" outlined>
        <!-- 로고 & 문구 -->
        <div class="d-flex mb-2 mx-auto">
          <v-img height="120" width="150" contain src="@/assets/logo/menu-logo.png"></v-img>
        </div>
        <p class="text-subtitle-1 font-weight-medium mt-2 grey--text text--darken-2">{{ $t("page.register.title") }}</p>
        <p class="text-caption grey--text text--darken-1">{{ $t("page.register.agree-need") }}</p>

        <!-- 약관 동의 -->
        <v-sheet width="94%" class="mx-auto">
          <!-- 이용약관 -->
          <v-sheet class="mt-10 d-flex">
            <!-- 체크박스 -->
            <v-checkbox
              style="padding-top: 3px !important"
              class="ma-0 pa-0"
              color="primary"
              v-model="rule"
              :error-messages="rule_Errors"
              :label="$t('bottom.use_terms')"
              required
              @change="$v.rule.$touch()"
              @blur="$v.rule.$touch()"
            ></v-checkbox>
            <v-spacer></v-spacer>

            <!-- 약관보기 -->
            <router-link to="/policy/rule?type=read" style="cursor: pointer; text-decoration: none !important">
              <p class="text-subtitle-2 font-weight-bold mt-3px mr-3 primary--text">
                <span v-if="!$vuetify.breakpoint.mobile">{{ $t("page.register.view-terms") }}</span>
                <v-icon size="20" class="ml-1 mb-2px" color="primary"> mdi-open-in-new </v-icon>
              </p>
            </router-link>
          </v-sheet>

          <!-- 개인정보처리방침 -->
          <v-sheet class="d-flex">
            <!-- 체크박스 -->
            <v-checkbox
              style="padding-top: 3px !important"
              class="ma-0 pa-0"
              color="primary"
              v-model="privacy"
              :error-messages="privacy_Errors"
              :label="$t('bottom.private_terms')"
              required
              @change="$v.privacy.$touch()"
              @blur="$v.privacy.$touch()"
            ></v-checkbox>
            <v-spacer></v-spacer>

            <!-- 약관보기 -->
            <router-link to="/policy/privacy?type=read" style="cursor: pointer; text-decoration: none !important">
              <p class="text-subtitle-2 font-weight-bold mt-3px mr-3 primary--text">
                <span v-if="!$vuetify.breakpoint.mobile">{{ $t("page.register.view-terms") }}</span>
                <v-icon size="20" class="ml-1 mb-2px" color="primary"> mdi-open-in-new </v-icon>
              </p>
            </router-link>
          </v-sheet>

          <!-- 버튼 -->
          <v-sheet class="mt-6">
            <v-btn color="primary" width="100%" large dark depressed @click="submit()"> {{ $t("page.register.go-next-btn-title") }} </v-btn>
          </v-sheet>
        </v-sheet>
      </v-sheet>
    </div>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate"
import { mapState } from "vuex"

export default {
  mixins: [validationMixin],

  validations: {
    rule: {
      checked(val) {
        return val
      },
    },

    privacy: {
      checked(val) {
        return val
      },
    },
  },

  data: () => ({
    rule: false,
    privacy: false,
    submitStatus: null,
  }),

  computed: {
    ...mapState(["temp_sns_user"]),

    rule_Errors() {
      const errors = []
      if (!this.$v.rule.$dirty) return errors
      !this.$v.rule.checked && errors.push(this.$t("page.register.warning-check-use-terms"))
      return errors
    },
    privacy_Errors() {
      const errors = []
      if (!this.$v.privacy.$dirty) return errors
      !this.$v.privacy.checked && errors.push(this.$t("page.register.warning-check-privacy-terms"))
      return errors
    },
  },

  methods: {
    // 진행하기
    submit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        let path = null
        if (this.temp_sns_user.user_id) {
          path = "/auth/join/snsJoinForm"
        } else {
          path = "/auth/join/form"
        }
        if (this.$route.query.qrcode) {
          path = path + "?qrcode=" + this.$route.query.qrcode
        } else if (this.$route.query.visitQrcode) {
          path = path + "?visitQrcode=" + this.$route.query.visitQrcode
        } else if (this.$route.query.pay_qrcode) {
          path = path + "?pay_qrcode=" + this.$route.query.pay_qrcode
        }

        if (this.$route.query.event) {
          const delim = path.includes("?") ? "&" : "?"
          path = path + `${delim}event=${this.$route.query.event}`
        }

        if (this.$route.query.packageEvent) {
          let delim = path.includes("?") ? "&" : "?"
          if (this.$route.query.productId) {
            path = path + `${delim}productId=${this.$route.query.productId}`
          }
          delim = path.includes("?") ? "&" : "?"
          path = path + `${delim}packageEvent=${this.$route.query.packageEvent}`
        }

        if (this.$route.query.callbackUrl) {
          const delim = path.includes("?") ? "&" : "?"
          path = path + `${delim}callbackUrl=${this.$route.query.callbackUrl}`
        }

        this.$router.push(path)
      }
    },
  },
}
</script>
<style>
.v-input--checkbox .v-label {
  font-size: 15px;
  font-weight: bold;
}
</style>
