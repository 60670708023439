<template>
  <div>
    <v-img width="120" height="56" contain class="mx-auto mt-2" src="@/assets/deco/line_castle.png"></v-img>
    <p :class="[warning ? 'warning--text' : 'accent--text']" class="text-center mb-2 font-danjunghae" style="font-size: 30px" :lang="lang">
      {{ title }}
    </p>
  </div>
</template>
<script>
import { mapState } from "vuex"
export default {
  props: {
    title: { type: String, required: true },
    warning: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["lang"]),
  },
}
</script>
<style></style>
