<template>
  <div>
    <!-- 본문 -->
    <div class="py-8">
      <v-sheet class="py-4 px-4 mx-auto rounded-lg text-center" width="80%" outlined>
        <!-- 로고 & 문구 -->
        <div class="d-flex mb-2 mx-auto">
          <v-img height="120" width="150" contain src="@/assets/logo/menu-logo.png"></v-img>
        </div>
        <p class="text-center text-subtitle-1 font-weight-medium mt-2 grey--text text--darken-2">{{ $t("page.register.title") }}</p>

        <!-- 입력 & 버튼 -->
        <v-sheet width="94%" class="mt-8 mx-auto">
          <!-- 아이디 -->
          <v-text-field :class="$vuetify.breakpoint.mobile ? '' : 'mx-16'" outlined dense v-model="temp_sns_user.user_id" :label="$t('page.login.id')" readonly></v-text-field>

          <!-- 실명 -->
          <v-text-field
            :class="$vuetify.breakpoint.mobile ? '' : 'mx-16'"
            class="mt-2"
            outlined
            dense
            v-model.trim="name"
            :label="$t('page.mypage.info.name')"
            persistent-placeholder
            @change="$v.name.$touch()"
            @blur="$v.name.$touch()"
            :error-messages="name_Errors"
          ></v-text-field>

          <!-- 연락처 -->
          <v-text-field
            :class="$vuetify.breakpoint.mobile ? '' : 'mx-16'"
            class="mt-2"
            outlined
            dense
            v-model.trim="phone"
            :label="$t('page.mypage.info.phone')"
            type="number"
            hide-spin-buttons
            persistent-placeholder
            @change="$v.phone.$touch()"
            @blur="$v.phone.$touch()"
            :error-messages="phone_Errors"
          ></v-text-field>

          <!-- 가입하기 -->
          <div class="mt-3" :class="$vuetify.breakpoint.mobile ? '' : 'mx-16'">
            <v-btn color="primary" width="100%" dark large depressed @click="submit()"> {{ $t("page.register.register-btn-title") }} </v-btn>
          </div>
        </v-sheet>
      </v-sheet>
    </div>
  </div>
</template>

<script>
// 검증
import { validationMixin } from "vuelidate"
import { required, sameAs, minLength, email } from "vuelidate/lib/validators"
import authService from "@/api/auth-service"
import { mapMutations, mapState } from "vuex"

export default {
  mixins: [validationMixin],

  validations: {
    name: {
      required,
    },

    phone: {
      required,
    },
  },

  data: () => ({
    // 입력값
    user_id: "",
    name: "",
    phone: "",
  }),

  computed: {
    ...mapState(["temp_sns_user"]),
    // 실명 Error 문구
    name_Errors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.required && errors.push("실명을 입력해주세요")
      return errors
    },

    // 연락처 Error 문구
    phone_Errors() {
      const errors = []
      if (!this.$v.phone.$dirty) return errors
      !this.$v.phone.required && errors.push("연락처를 입력해주세요")
      return errors
    },
  },

  methods: {
    ...mapMutations(["login", "DELETE_TEMP_SNS_USER"]),

    // 가입하기
    submit: _.debounce(async function () {
      console.log("submit")
      this.user_id_dup_error = false
      // 입력값 기본 검증
      this.$v.$touch()

      // 입력값 기본 검증 통과 시
      if (!this.$v.$invalid) {
        let snsUser = this.temp_sns_user
        console.log("저장된 sns user 정보")
        console.log(snsUser)
        try {
          snsUser = {
            ...snsUser,
            name: this.name,
            phone: this.phone,
            password: snsUser.sns_id,
            email: snsUser.user_id,
            type: "SNS",
          }

          // db 에 저장
          await authService.doUserInsert(snsUser)
          this.DELETE_TEMP_SNS_USER()

          try {
            if (this.$route.query.qrcode) {
              await authService.updateQrCode(snsUser.user_id, this.$route.query.qrcode)
            } else if (this.$route.query.visitQrcode) {
              await authService.updatePayQrcode(snsUser.user_id, this.$route.query.visitQrcode)
            } else if (this.$route.query.pay_qrcode) {
              await authService.updatePayQrcode(snsUser.user_id, this.$route.query.pay_qrcode)
            }
          } catch (e) {
            console.log(e)
            alert(this.$t("alert.error_failed_qrocde_authentication"))
            this.$router.replace("/auth/login")
            return
          }

          this.login(snsUser)
          if (this.$route.query.callbackUrl) {
            this.$router.replace(this.$route.query.callbackUrl)
          } else if (this.$route.query.qrcode) {
            this.$router.replace("/?qrlogin=true")
          } else if (this.$route.query.event) {
            this.$router.replace(`/reservation/buy?event=${this.$route.query.event}`)
          } else if (this.$route.query.packageEvent) {
            let pathUrl = `/reservation/package?packageEvent=${this.$route.query.packageEvent}`
            if (this.$route.query.productId) {
              pathUrl += `&productId=${this.$route.query.productId}`
            }
            this.$router.replace(pathUrl)
          } else if (this.$route.query.pay_qrcode) {
            this.$router.replace(`/onlinePayConnect?pay_qrcode=${this.$route.query.pay_qrcode}`)
          } else {
            this.$router.replace("/")
          }
        } catch (e) {
          console.log(e)
          alert(this.$t("alert.error_page"))
        }
      }
    }, 500),
  },
}
</script>
<style scoped>
/* v-text-field, v-select */
.v-text-field--outlined >>> fieldset {
  border-color: #e0e0eb;
  border-radius: 8px;
}

.v-text-field--outlined >>> label {
  color: #8e73a2;
  font-size: 16px;
  font-weight: 400;
}

::v-deep .v-text-field input {
  margin-left: 8px;
  font-size: 16px;
}

::v-deep .v-select input {
  margin-left: 8px;
  font-size: 16px;
}
</style>
