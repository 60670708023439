import { render, staticRenderFns } from "./ReservationTitleLogo.vue?vue&type=template&id=5cb05d81&scoped=true"
import script from "./ReservationTitleLogo.vue?vue&type=script&lang=js"
export * from "./ReservationTitleLogo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cb05d81",
  null
  
)

export default component.exports