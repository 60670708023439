<template>
  <div class="ma-2">
    <!-- 제목 -->
    <v-sheet outlined class="d-flex align-center px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">컨셉부스 운영 관리</font>
    </v-sheet>

    <v-sheet outlined class="pa-2 mt-2">
      <v-card>
        <v-card-title>설정</v-card-title>
        <v-row>
          <v-col>
            <v-card-subtitle>동기화</v-card-subtitle>
            <v-card-text>
              <v-text-field v-model="info.verCode" label="컨셉 ver" filled :readonly="isReadOnly" type="number"></v-text-field>
              <v-text-field v-model="info.shotPrice" label="추가샷 가격 (원화)" filled :readonly="isReadOnly" type="number"></v-text-field>
              <!-- <v-text-field label="추가샷 가격 (달러)" filled></v-text-field> -->
            </v-card-text>
          </v-col>
          <v-col>
            <v-card-subtitle>카메라</v-card-subtitle>
            <v-card-text>
              <v-text-field v-model="info.takePictureWaitingTime" label="촬영 대기시간(sec)" filled :readonly="isReadOnly" type="number"></v-text-field>
              <v-text-field v-model="info.takePictureCnt" label="촬영 카운트(sec)" filled :readonly="isReadOnly" type="number"></v-text-field>
              <v-text-field v-model="info.takePictureCntVoice" label="촬영 카운트 음성(sec)" filled :readonly="isReadOnly" type="number"></v-text-field>
              <v-text-field v-model="info.reTakePictureAvailableCnt" label="재촬영 허용횟수" filled :readonly="isReadOnly" type="number"></v-text-field>
            </v-card-text>
          </v-col>
          <v-col>
            <v-card-subtitle>키오스크</v-card-subtitle>
            <v-card-text>
              <v-text-field v-model="info.availablePageTime" label="페이지 허용시간(sec)" filled :readonly="isReadOnly" type="number"></v-text-field>
              <v-text-field v-model="info.qrAuthTime" label="QR 인증 허용시간(sec)" filled :readonly="isReadOnly" type="number"></v-text-field>
              <v-text-field v-model="info.dispAvailableTime" label="허용시간 표시(sec)" filled :readonly="isReadOnly" type="number"></v-text-field>
              <v-text-field v-model="info.lastPageAvailableTime" label="마지막 페이지 허용시간 (sec)" filled :readonly="isReadOnly" type="number"></v-text-field>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-sheet>

    <!-- 버튼 -->
    <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
      <v-btn class="px-6 ml-3" color="blue" large dark depressed @click="submit()">
        <v-icon small left>mdi-database-edit-outline</v-icon>
        {{ isReadOnly ? "편집" : "저장" }}
      </v-btn>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    info: {},
    isReadOnly: true,
  }),

  mounted() {
    this.$http.get("/api/kiosk/config").then((res) => {
      this.info = res.data
    })
  },

  methods: {
    submit: _.debounce(async function () {
      if (this.isReadOnly) {
        this.isReadOnly = false
        return
      }

      try {
        const result = await this.$http.post("/api/kiosk/config/update", {
          ...this.info,
        })
        if (result.data.affectedRows) {
          this.isReadOnly = true
          alert("정상적으로 수정하였습니다.")
        } else {
          alert("업데이트된 데이터가 존재하지 않습니다.")
        }
      } catch (e) {
        console.log(e)
        alert(this.$t("alert.error_page"))
      }
    }, 500),
  },
}
</script>
<style></style>
