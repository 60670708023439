<template>
    <div v-if="layer.children" style="position: absolute; pointer-events: none; user-select: none;" ref="image"
        :id="layer.name">
        <PSDRenderLayer v-for="(child, index) in layer.children" :key="child.index" :layer="child" :onload="onload" />
    </div>
    <img v-else :key="layer.id" :src="layer.src" style="position: absolute; pointer-events: none; user-select: none;"
        ref="image" />
</template>



<script>
export default {
    name: "PSDRenderLayer",
    props: {
        layer: {
            type: Object,
            required: true,
        },
        onload: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            refresh: 0,
        };
    },
    created() {
        if (this.layer.refreshSubject) {
            this.layer.refreshSubject.subscribe(() => {
                this.refresh++;
                this.updateLayerStyle();
            });
        }
    },
    destroyed() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    },

    mounted() {

        this.updateLayerStyle();
        if (this.$refs.image) {
            this.$refs.image.onerror = () => {
                console.log("error", this.layer.src);
                const element = this.$refs.image;
                element.style.visibility = "hidden";
                this.onload();
            }
            this.$refs.image.onload = () => {
                this.onload();
            }
        }

    },


    methods: {
        updateLayerStyle() {
            const layer = this.layer;
            const element = this.$refs.image;
            if (!element) {
                return;
            }

            element.style.left = layer.left + "px";
            element.style.top = layer.top + "px";

            if (layer.width !== undefined) {
                element.style.width = layer.width + "px";
            }

            if (layer.height !== undefined) {
                element.style.height = layer.height + "px";
            }

            element.style.visibility = layer.hidden ? "hidden" : "visible";
            element.style.opacity = layer.opacity;
            element.style.mixBlendMode = layer.blendMode;
            element.style.overflow = layer.isRoot === true ? "hidden" : "visible";

            if (layer.name === '인물' || layer.name == '그림자') {
                // NOTE: 그외 레이어에서 transform 을 사용하면 mixBlendMode 가 적용되지 않는 문제가 있어서
                element.style.transform = (layer.flipX ? "scaleX(-1)" : "scaleX(1)");
            }
        },
    }
};
</script>
