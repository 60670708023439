<template>
  <div>
    <v-sheet>
      <v-sheet min-height="200">
        <v-card flat class="px-0 py-4" style="min-height: 200px">
          <TipTapReader class="tiptap_style" :options="options" v-if="options.content" :key="componentKey" />
          <div v-else class="text-center font-weight-medium">입력 정보가 없습니다.</div>
        </v-card>
      </v-sheet>

      <v-sheet>
        <!-- title -->
        <div style="position: relative">
          <p class="text-center text-subtitle-1 text-md-h6 font-weight-medium title">{{ $t("page.conceptbooth.review-title") }}</p>
          <v-btn class="reviewBtn" style="position: absolute; bottom: 10px; right: 10px" small depressed @click="show_warning_dialog = true">{{ $t("button.review-write") }}</v-btn>
        </div>
        <div class="mt-1">
          <ReviewList :conceptId="$route.params.conceptId" />
        </div>
      </v-sheet>

      <!-- <v-tabs v-model="tabs" color="primary" fixed-tabs class="mt-1">
        <v-tab v-for="item in tabItems" :key="item" class="text-subtitle-1 font-weight-medium">
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <ReviewList :conceptId="$route.params.conceptId" />
        </v-tab-item>
        <v-tab-item>
          <HtmlReader id="castle-concept-qna" />
        </v-tab-item>
      </v-tabs-items> -->
    </v-sheet>
    <ReviewWriteMoveGuideDialog @input="(e) => (show_warning_dialog = e)" @close="show_warning_dialog = false" @confirm="albumMove" :visible="show_warning_dialog" />
  </div>
</template>
<script>
import HtmlReader from "./components/HtmlReader"
import TipTapReader from "@/components/tiptap/Reader"
import ReviewList from "@/views/review/ReviewList"
import ReviewWriteMoveGuideDialog from "@/views/components/ReviewWriteMoveGuideDialog"
import { mapState } from "vuex"

export default {
  components: {
    HtmlReader,
    TipTapReader,
    ReviewList,
    ReviewWriteMoveGuideDialog,
  },

  data: () => ({
    show_warning_dialog: false,
    tabs: null,
    tabItems: ["공주들의 촬영" /*, "컨셉부스 QNA" */],

    concept: {},

    //TipTap
    options: {
      content: "",
      readonly: true,
      editable: true,
      supportImage: true,
      supportVideo: true,
    },
    componentKey: 0,
  }),

  computed: {
    ...mapState(["lang"]),
  },

  watch: {
    lang: function (val) {
      this.options.content = this.getContent()
      this.componentKey += 1
    },
  },

  mounted() {
    console.log(this.$route.params.conceptId)
    this.load()
  },
  methods: {
    load() {
      console.log("conceptId: " + this.$route.params.conceptId)
      this.$http
        .post("/api/concept/select/specific", {
          params: {
            id: this.$route.params.conceptId,
          },
        })
        .then((res) => {
          this.concept = res.data[0]
          this.options.content = this.getContent()
          this.componentKey += 1
          //this.options.content = res.data[0].content
        })
    },

    albumMove() {
      this.show_warning_dialog = false
      this.$router.push("/mypage/home?type=picture")
    },

    getContent() {
      if (!this.concept) {
        return ""
      }

      switch (this.lang) {
        case "jp":
          return this.concept.content_jp || this.concept.content
        case "en":
          return this.concept.content_en || this.concept.content
        default:
          return this.concept.content
      }
    },
  },
}
</script>
<style scoped>
.v-tab--active {
  color: black;
}
.title {
  border-bottom: 3px solid #8d75cb;
  height: 40px;
}

.reviewBtn {
  background-color: #9a80d1 !important;
  color: white !important;
  font-weight: 500;
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 17px !important;
  border: 1px solid grey;
  border-radius: 6px;
}
</style>
