<template>
  <div>
    <v-sheet class="text-center py-16">
      <v-progress-circular indeterminate color="primary" size="48"></v-progress-circular>
      <p class="mt-4 text-caption">Google 로그인중</p>
    </v-sheet>

    <!-- SNS 로그인 신규 가입 dialog -->
    <v-dialog max-width="440" v-model="show_confirm_dialog" content-class="rounded-10" style="z-index: 999">
      <v-sheet class="rounded-10 pa-4">
        <p class="text-center mb-0">
          <v-icon size="80" color="#f2b8bf" class="">mdi-alert-circle-outline</v-icon>
        </p>

        <p class="text-center text-body mt-3 px-1" style="letter-spacing: 0px !important" v-html="$t('page.login.alert-info-new-member-guide')"></p>

        <div class="d-flex justify-space-between mt-6">
          <v-btn color="#ddd" class="rounded-10" dark width="48%" depressed @click="loginCancle()"> {{ $t("button.no") }} </v-btn>

          <v-btn color="#f2b8bf" class="rounded-10" dark width="48%" depressed @click="next_register()"> {{ $t("button.yes") }} </v-btn>
        </div>
      </v-sheet>
    </v-dialog>
    <v-dialog max-width="440" v-model="show_warn_dialog" content-class="rounded-10" style="z-index: 999">
      <v-sheet class="rounded-10 pa-4">
        <p class="text-center mb-0">
          <v-icon size="80" color="#f2b8bf" class="">mdi-alert-circle-outline</v-icon>
        </p>

        <p class="text-center text-body mt-3 px-1" style="letter-spacing: 0px !important">
          {{ warn_error_message }}
        </p>

        <div class="d-flex justify-center mt-6">
          <v-btn color="#f2b8bf" class="rounded-10" dark width="48%" depressed @click="loginCancle()"> {{ $t("button.ok") }} </v-btn>
        </div>
      </v-sheet>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapMutations } from "vuex"
import authService from "@/api/auth-service"

export default {
  data() {
    return {
      show_confirm_dialog: false,
      warn_error_message: "",
    }
  },

  computed: {
    show_warn_dialog: {
      get() {
        return !!this.warn_error_message
      },
      set(value) {
        if (!value) {
          this.warn_error_message = ""
        }
      },
    },
  },
  mounted() {
    this.googleLogin()
  },

  methods: {
    ...mapMutations(["login", "ADD_TEMP_SNS_USER"]),

    async googleLogin() {
      const formData = new URLSearchParams()
      const redirectUri = window.location.origin + process.env.VUE_APP_GOOGLE_REDIRECT_URI
      formData.append("code", this.$route.query.code)
      formData.append("client_id", process.env.VUE_APP_GOOGLE_CLIENT_ID)
      formData.append("client_secret", process.env.VUE_APP_GOOGLE_SECRET)
      formData.append("redirect_uri", window.location.origin + process.env.VUE_APP_GOOGLE_REDIRECT_URI)
      formData.append("grant_type", "authorization_code")

      try {
        const tokenRes = await this.$http.post("https://oauth2.googleapis.com/token", formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        const userResp = await this.$http.get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: {
            Authorization: `Bearer ${tokenRes.data.access_token}`,
          },
        })
        if (userResp.data) {
          const email = userResp.data.email
          let state = null
          if (this.$route.query.state) {
            state = JSON.parse(decodeURIComponent(atob(this.$route.query.state)))
          }
          // console.log(userResp)
          // 만약 기존에 구글로그인한 계정이 있다면
          let user = await authService.checkDuplicateEmail(email)
          if (!user) {
            // 회원정보가 없음.
            console.log("회원정보가 없음.")
            user = {
              user_id: userResp.data.email,
              //password: userResp.data.sub,
              name: userResp.data.name,
              profile_image: userResp.data.picture,
              //phone: "SNS 로그인",
              //email: userResp.data.email,
              sns_id: userResp.data.sub,
              provider_type: "google",
            }
            this.ADD_TEMP_SNS_USER(user)
            // show popup
            this.show_confirm_dialog = true
          } else {
            // 회원정보가 있음. 있다면, 내꺼인지 확인을 해야함.
            if (/* user.sns_id == userResp.data.sub && */ user.provider_type === "google") {
              try {
                await authService.updateLastLoginAt(user.user_id)
              } catch (e) {
                console.log(e)
              }
            } else if (user.phone === "SNS 로그인") {
              //DB 이전의 호환을 위해서
              try {
                user = {
                  ...user,
                  name: userResp.data.name,
                  sns_id: userResp.data.sub,
                  provider_type: "google",
                }
                await authService.updateSnsUserInfo(user)
              } catch (e) {
                console.log(e)
              }
            } else {
              // 이미 가입된 계정안내
              // show POPUP
              let prefix = "이메일 회원으로 가입된 계정입니다. "
              switch (user.provider_type.toLowerCase()) {
                case "kakao":
                  prefix = "카카오 계정으로 가입된 계정입니다. "
                  break
                case "naver":
                  prefix = "네이버 계정으로 가입된 계정입니다. "
                  break
                default:
                  break
              }
              this.warn_error_message = prefix + "계정 정보를 확인하여 다시 로그인 해주세요.(" + user.user_id + ")"
              return
            }
            try {
              // rediretion uri 에서 state 에 qrcode를 저장함.
              if (state && state.qrcode) {
                await authService.updateQrCode(user.user_id, state.qrcode)
              }
            } catch (e) {
              console.log(e)
              alert(this.$t("alert.error_failed_qrocde_authentication"))
              this.$router.replace("/auth/login")
              return
            }
            const profile_image = user.profile_image !== "default.png" ? "/upload/user/profile_image/" + user.profile_image : userResp.data.picture

            this.login({ ...user, profile_image, type: "SNS" })
            this.$gtag.event("login", { method: "Google" })
            if (state && state.callbackUrl) {
              this.$router.replace(state.callbackUrl)
            } else if (state && state.qrcode) {
              this.$router.replace("/?qrlogin=true")
            } else if (state && state.event) {
              this.$router.replace(`/reservation/buy?event=${state.event}`)
            } else if (state && state.packageEvent) {
              let pathUrl = `/reservation/package?packageEvent=${state.packageEvent}`
              if (state.productId) {
                pathUrl += `&productId=${state.productId}`
              }
              this.$router.replace(pathUrl)
            } else if (state && state.pay_qrcode) {
              this.$router.replace(`/onlinePayConnect?pay_qrcode=${state.pay_qrcode}`)
            } else {
              this.$router.replace("/")
            }
          }
        } else {
          alert("구글 로그인 정보를 가져오지 못해 인증에 실패하였습니다.")
          this.$router.replace("/auth/login")
        }
      } catch (e) {
        console.log(e)
        alert("인증 및 가입에 실패하였습니다.")
        this.$router.replace("/auth/login")
      }
    }, // end googleLogin

    // sns 인증 다음 화면으로 이동
    next_register: _.debounce(async function () {
      let state = null
      if (this.$route.query.state) {
        state = JSON.parse(decodeURIComponent(atob(this.$route.query.state)))
      }
      let path = "/auth/join/agree"
      if (state && state.qrcode) {
        path = path + `?qrcode=${state.qrcode}`
      }
      if (state && state.event) {
        const delim = path.includes("?") ? "&" : "?"
        path = path + `${delim}event=${state.event}`
      }

      if (state && state.packageEvent) {
        let delim = path.includes("?") ? "&" : "?"
        path = path + `${delim}packageEvent=${state.packageEvent}`
        if (state.productId) {
          delim = path.includes("?") ? "&" : "?"
          path = path + `${delim}productId=${state.productId}`
        }
      }
      this.$router.push(path)
    }, 500),

    loginCancle() {
      this.$router.back()
      //this.show_warn_dialog = false
      //this.show_confirm_dialog = false
    },
  },
}
</script>
