<template>
  <v-sheet class="py-6 px-8">
    <p class="text-h6 font-weight-bold grey--text text--darken-3 text-center mb-6">쿠폰 추가하기</p>

    <v-sheet class="mt-2 px-4">
      <v-select outlined dense :items="typeList" v-model="create.type" label="종류"></v-select>
      <v-select v-if="create.type === '특정컨셉할인'" outlined dense :items="concepts" item-text="title" item-value="id" v-model="create.conceptId" label="컨셉"></v-select>
      <v-text-field class="rounded-10" label="이름(한글)" dense outlined v-model.trim="create.title"></v-text-field>
      <v-text-field class="rounded-10" label="이름(영어)" dense outlined v-model.trim="create.title_en"></v-text-field>
      <v-text-field class="rounded-10" label="이름(일어)" dense outlined v-model.trim="create.title_jp"></v-text-field>
      <v-text-field class="rounded-10" label="할인금액(KRW)" dense outlined type="number" hide-spin-buttons v-model="create.discount_KRW"></v-text-field>
      <!-- <v-text-field class="rounded-10" label="할인금액(USD)" dense outlined type="number" hide-spin-buttons v-model="create.discount_USD"></v-text-field> -->
      <v-textarea class="rounded-10" style="min-height: 100px" maxlength="256" counter="256" clear-icon outlined label="설명" v-model="create.description"></v-textarea>
      <v-row>
        <v-menu v-model="startAtMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="create.startAtDate" label="시작일자" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="green"></v-text-field>
          </template>
          <v-date-picker v-model="create.startAtDate" no-title scrollable color="green" @input="startAtMenu = false"></v-date-picker>
        </v-menu>
        <v-menu v-model="endAtMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="create.endAtDate" label="종료일자" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="warning"></v-text-field>
          </template>
          <v-date-picker v-model="create.endAtDate" no-title scrollable color="warning" @input="endAtMenu = false"> </v-date-picker>
        </v-menu>
      </v-row>
    </v-sheet>
    <v-sheet class="d-flex justify-center mt-5">
      <v-btn class="rounded-10 px-6 mr-4" color="grey" large dark depressed @click="$emit('close')">
        <v-icon small left>mdi-cancel</v-icon>
        취소하기
      </v-btn>
      <v-btn class="rounded-10 px-6" color="primary" large dark depressed @click="createSubmit()">
        <v-icon small left>mdi-database-plus-outline</v-icon>
        추가하기
      </v-btn>
    </v-sheet>
  </v-sheet>
</template>
<script>
import dayjs from "dayjs"
export default {
  data: () => ({
    typeList: ["모든컨셉할인", "특정컨셉할인", "드레스금액할인", "이용권할인", "인쇄권할인"],
    create: {
      title: "",
      title_en: "",
      title_jp: "",
      type: "모든컨셉할인",
      conceptId: -1,
      description: "",
      discount_KRW: 0,
      discount_USD: 0,
      isEnabled: true,
      startAtDate: dayjs().format("YYYY-MM-DD"),
      endAtDate: dayjs().add(1, "month").format("YYYY-MM-DD"),
    },
    startAtMenu: false,
    endAtMenu: false,
    concepts: [],
  }),

  mounted() {
    this.$http
      .post("/api/concept/select")
      .then((res) => {
        this.concepts = res.data
      })
      .catch((e) => {
        this.concepts = []
      })
  },

  computed: {
    conceptNames() {
      return this.concepts.map((e) => e.title)
    },
  },

  methods: {
    // 목록
    createSubmit: _.debounce(async function () {
      // 제목 검증
      if (this.create.title == "") {
        alert("이름을 입력해주세요")
        return
      }

      // 컨셉소개 내용 검증
      if (this.create.type == "") {
        alert("쿠폰 종류를 선택해주세요")
        return
      }

      if (!this.create.startAtDate) {
        alert("시작일자를 입력해주세요")
        return
      }

      if (!this.create.endAtDate) {
        alert("종료일자를 입력해주세요")
        return
      }

      if (this.create.type === "특정컨셉할인" && this.create.conceptId < 0) {
        alert("컨셉을 선택해 주세요.")
        return
      }

      console.log(this.create.startAtDate)
      console.log(this.create)

      this.$http
        .post("/api/coupon/createCoupon", {
          type: this.create.type,
          title: this.create.title,
          title_en: this.create.title_en,
          title_jp: this.create.title_jp,
          conceptId: this.create.conceptId,
          description: this.create.description,
          admin_id: this.$store.state.lafia_admin.user_id,
          discount_KRW: Number(this.create.discount_KRW),
          discount_USD: Number(this.create.discount_USD),
          expireAt: false,
          startAt: this.create.startAtDate ? this.dayjs(this.create.startAtDate).format("YYYY-MM-DD HH:mm:ss") : null,
          expireAt: this.create.endAtDate ? this.dayjs(this.create.endAtDate).format("YYYY-MM-DD HH:mm:ss") : null,
        })
        .then((res) => {
          if (res) {
            alert("정상적으로 등록되었습니다.")
            this.$emit("close")
          } else {
            alert("등록되지 않았습니다")
          }
        })
        .catch((e) => {
          console.log(e)
          alert(this.$t("alert.error_page"))
        })
    }, 500),
  },
}
</script>
<style scoped>
.my-clipper {
  width: 100%;
}

.placeholder {
  width: 100%;
  height: 150px;
}
</style>
