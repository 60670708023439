<template>
  <div>
    <!-- 제목 -->
    <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">컨셉 관리</font>
    </v-sheet>

    <!-- 본문 -->
    <v-sheet outlined class="pa-2 ma-2">
      <!-- 검색 -->
      <v-sheet outlined class="pa-2 mb-2 d-flex">
        <v-sheet class="d-flex" width="660">
          <!-- 유형 -->
          <v-sheet width="160" class="mr-3">
            <v-select outlined dense hide-details :items="select_list.search_type" v-model="search_type"></v-select>
          </v-sheet>

          <!-- 키워드 -->
          <v-text-field placeholder="검색어를 입력해주세요" outlined dense hide-details v-model="keyword" @keyup.enter="search()"></v-text-field>

          <!-- 제출 버튼 -->
          <v-btn height="40" class="px-6 ml-3" color="primary" dark depressed @click="search()">
            <v-icon small left>mdi-magnify</v-icon>
            검색하기
          </v-btn>
        </v-sheet>

        <v-spacer></v-spacer>

        <!-- 제출 버튼 -->
        <v-btn height="40" class="px-3 ml-2" color="primary" dark depressed @click="search()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-sheet>

      <!-- 목록 -->
      <v-radio-group v-model="selected" hide-details>
        <v-data-table
          hide-default-header
          :items="list"
          :footer-props="{
            'items-per-page-options': [5, 20, 100, -1],
            itemsPerPageText: '페이지당 컨셉 수',
          }"
          no-data-text=""
        >
          <template v-slot:header>
            <tr>
              <th style="width: 52px"></th>
              <th style="width: 100px">썸네일</th>
              <th style="width: 100px">제목</th>
              <th style="width: 100px">가격</th>
              <!-- <th style="width: 100px">뉴컨셉 달성치</th>
              <th style="width: 100px">뉴컨셉 목표치</th> -->
              <th style="width: 100px">태그</th>
              <th style="width: 100px">상태</th>
              <th style="width: 100px">셔터스피드</th>
              <th style="width: 100px">조리개</th>
              <th style="width: 100px">ISO</th>
              <th style="width: 100px">카메라</th>
              <th style="width: 100px">조명(좌)</th>
              <th style="width: 100px">조명(우)</th>
              <th style="width: 100px">스튜디오</th>
              <th style="width: 100px">포즈 설명</th>
              <th style="width: 100px">위치 설명</th>
              <th style="width: 100px">새도우</th>
              <th style="width: 100px">컨셉 생성일</th>
              <th style="width: 100px">순서</th>
            </tr>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <v-radio class="ml-1" hide-details dense :value="item" @change="selectedItem = item"></v-radio>
              </td>
              <td>
                <v-img height="80" :src="'/upload/concept/thumbnail_image/' + item.thumbnail_image"></v-img>
              </td>
              <td class="text-truncate">
                {{ item.title }}
              </td>
              <td class="text-truncate">{{ $toComma(item.price) }}원</td>
              <!-- <td class="text-truncate">
                <span v-if="item.is_new == '뉴컨셉'">{{ $toComma(item.new_amount) }}원</span>
                <span v-if="item.is_new == '일반컨셉'" class="grey--text text--lighten-1">일반컨셉</span>
              </td>
              <td class="text-truncate">
                <span v-if="item.is_new == '뉴컨셉'">{{ $toComma(item.new_max) }}원</span>
                <span v-if="item.is_new == '일반컨셉'" class="grey--text text--lighten-1">일반컨셉</span>
              </td> -->

              <td class="text-truncate">
                {{ item.tag.toString() }}
              </td>
              <td class="text-truncate">
                {{ item.status }}
              </td>
              <td class="text-truncate">
                {{ item.shutter_speed }}
              </td>
              <td class="text-truncate">
                {{ item.aperture }}
              </td>
              <td class="text-truncate">
                {{ item.iso }}
              </td>
              <td class="text-truncate">
                {{ item.camera }}
              </td>
              <td class="text-truncate">
                {{ item.light_left }}
              </td>
              <td class="text-truncate">
                {{ item.light_right }}
              </td>
              <td class="text-truncate">
                {{ item.studio_type }}
              </td>
              <td class="text-truncate">
                {{ item.pose_type }}
              </td>
              <td class="text-truncate">
                {{ item.standing_type }}
              </td>
              <td class="text-truncate">
                {{ item.shadow_json }}
              </td>
              <td class="text-truncate">
                {{ new Date(item.created).toLocaleDateString() }}
              </td>
              <td class="text-truncate">
                {{ item.order }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-radio-group>

      <!-- 버튼 -->
      <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
        <v-btn class="px-6 ml-3" color="green" large dark depressed @click="updateOpen()">
          <v-icon small left>mdi-database-edit-outline</v-icon>
          수정하기
        </v-btn>

        <v-btn class="px-6 ml-3" color="blue" large dark depressed @click="createNewConcept()">
          <v-icon small left>mdi-database-plus-outline</v-icon>
          추가하기
        </v-btn>

        <v-btn class="px-6 ml-3" color="red" large dark depressed @click="deleteItem()">
          <v-icon small left>mdi-database-remove-outline</v-icon>
          삭제하기
        </v-btn>
      </v-card>
    </v-sheet>

    <!-- 컨셉 수정하기 -->
    <v-dialog v-model="dialog.update" width="660" content-class="rounded-xl" persistent>
      <UpdateConcept :updateItem_import="selectedItem" @close="close_handler" :key="'update' + componentKey" />
    </v-dialog>

    <!-- 컨셉 추가하기 -->
    <v-dialog v-model="dialog.create" width="660" content-class="rounded-xl" persistent>
      <InsertConcept @close="close_handler" :key="'insert' + componentCreateKey" :src="selectedItem" />
    </v-dialog>
  </div>
</template>
<script>
import UpdateConcept from "./concept/UpdateConcept"
import InsertConcept from "./concept/InsertConcept"

export default {
  components: {
    UpdateConcept,
    InsertConcept,
  },

  data: () => ({
    select_list: {
      search_type: ["제목"],
      status: ["노출", "숨김"],
    },

    search_type: "제목",
    keyword: "",

    //TipTap
    options: {
      content: "",
      editable: true,
      supportImage: true,
      supportVideo: true,
    },

    selected: false,
    selectedItem: {},
    list: [],

    componentKey: 0,
    componentCreateKey: 0,

    dialog: {
      create: false,
      update: false,
    },
  }),

  mounted() {
    // 목록
    this.search()
  },

  watch: {
    selectedItem() {
      console.log(this.selectedItem)
      this.componentKey++
    },

    only() {
      this.search()
    },
  },

  methods: {
    // 닫기 핸들러
    close_handler() {
      this.dialog.create = false
      this.dialog.update = false
      this.selected = false
      this.selectedItem = {}
      this.search()
    },

    createNewConcept() {
      this.dialog.create = true
      this.componentCreateKey++
    },

    // 목록
    search() {
      this.$http
        .post("/api/concept/select/search", {
          params: {
            keyword: this.keyword,
          },
        })
        .then((res) => {
          if (res.data.length) {
            this.list = res.data
          }
        })
    },

    // 삭제하기
    deleteItem() {
      if (!Object.keys(this.selectedItem).length) {
        alert("선택한 컨셉이 없습니다")
      } else {
        if (confirm("정말 해당 컨셉을 삭제하겠습니까?")) {
          this.$http
            .post("/api/concept/delete", {
              params: {
                id: this.selectedItem.id,
              },
            })
            .then((res) => {
              if (res.data.affectedRows) {
                this.search()
                this.selected = false
                this.selectedItem = {}
              }
            })
        }
      }
    },

    // 수정 열기
    updateOpen() {
      if (!Object.keys(this.selectedItem).length) {
        alert("선택한 컨셉이 없습니다")
      } else {
        this.dialog.update = true
      }
    },
  },
}
</script>
<style scoped>
.my-clipper {
  width: 100%;
}

.placeholder {
  width: 100%;
  height: 150px;
}
</style>
