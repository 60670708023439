<template>
  <div>
    <v-img width="180" height="32" contain class="mx-auto mt-2" src="@/assets/deco/line_0.png"></v-img>
    <p class="font-danjunghae text-center mb-2" style="color: #b67a7a; font-size: 30px" :lang="lang">라피아캐슬</p>

    <p class="text-center mb-4" style="color: #333; font-size: 11px">
      화려한 드레스와 악세사리를 하고 라피아의 성을 거닐며<br />
      여러가지 자유롭게 셀프포토를 즐길 수 있어요.<br />
      또한 무인부스에서 다양한 컨셉사진도 촬영해 보세요!
    </p>

    <v-sheet class="px-3">
      <iframe width="100%" style="aspect-ratio: 16/9" src="https://www.youtube.com/embed/fytw0YX0etU?si=cLV5afKqiLV-rWDw" frameborder="0" allowfullscreen></iframe>
    </v-sheet>

    <v-divider class="mt-3 mb-2"></v-divider>

    <v-sheet class="d-flex justify-center align-center py-2">
      <v-btn
        depressed
        class="font-weight-medium rounded-10 mr-2"
        width="140"
        :color="$route.path == '/concept/lafia_castle' ? '#ffaabe' : 'grey lighten-2 grey--text'"
        :dark="$route.path == '/concept/lafia_castle'"
        @click="$router.push('/concept/lafia_castle')"
      >
        라피아캐슬
      </v-btn>

      <v-btn
        depressed
        class="font-weight-medium rounded-10"
        width="140"
        :color="$route.path == '/concept/solo_premium' ? '#ffaabe' : 'grey lighten-2 grey--text'"
        :dark="$route.path == '/concept/solo_premium'"
        @click="$router.push('/concept/solo_premium')"
      >
        솔로프리미엄
      </v-btn>
    </v-sheet>

    <div class="d-flex justify-center mt-2 mb-4">
      <v-btn :color="tag == '전체' ? '#ffc0c0' : 'grey'" :outlined="tag != '전체'" depressed small class="mr-2 rounded-lg" style="padding-bottom: 2px" @click="tag = '전체'"> 전체 </v-btn>
      <v-btn :color="tag == '러블리' ? '#ffc0c0' : 'grey'" :outlined="tag != '러블리'" depressed small class="mr-2 rounded-lg" style="padding-bottom: 2px" @click="tag = '러블리'"> 러블리 </v-btn>
      <v-btn :color="tag == '몽환' ? '#ffc0c0' : 'grey'" :outlined="tag != '몽환'" depressed small class="mr-2 rounded-lg" style="padding-bottom: 2px" @click="tag = '몽환'"> 몽환 </v-btn>
      <v-btn :color="tag == '큐티' ? '#ffc0c0' : 'grey'" :outlined="tag != '큐티'" depressed small class="mr-2 rounded-lg" style="padding-bottom: 2px" @click="tag = '큐티'"> 큐티 </v-btn>
      <v-btn :color="tag == '판타지' ? '#ffc0c0' : 'grey'" :outlined="tag != '판타지'" depressed small class="rounded-lg" style="padding-bottom: 2px" @click="tag = '판타지'"> 판타지 </v-btn>
    </div>

    <v-row v-if="list.length" no-gutters>
      <v-col v-for="item in list" :key="item.id" cols="6" class="pa-2">
        <!-- 이미지 -->
        <v-img :src="'/upload/concept/thumbnail_image/' + item.pattern_1_image" position="top center" class="rounded-lg"></v-img>

        <!-- 텍스트 -->
        <div class="text-end">
          <!-- 가격 -->
          <p class="text-h6 font-weight-bold mt-1 mb-0" style="line-height: 20px">
            {{ $toComma(item.price) }}
          </p>

          <!-- 제목 -->
          <p class="text-subtitle-1 mb-1 mt-0">
            {{ item.title }}
          </p>

          <!-- 1대1 / 임의 -->
          <!-- <v-btn
                        dark
                        depressed
                        small
                        color="pink lighten-3"
                        class="rounded-lg pa-1 mb-8"
                    >
                        1대1보정
                    </v-btn> -->
        </div>
      </v-col>
    </v-row>

    <!-- <div
            v-if="!list.length"
            class="mt-10"
        >
            <v-icon size="40" color="primary lighten-1" class="d-flex justify-center mb-4">mdi-close-circle-outline</v-icon>
            <p
                class="text-center font-weight-bold primary--text text-subtitle-1"
            >
                해당 조건에 맞는 컨셉사진이 없습니다
            </p>
        </div> -->
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex"
export default {
  data: () => ({
    list: [],

    category: "무인촬영",
    tag: "전체",
  }),

  mounted() {
    // 목록 불러오기
    this.load()
  },

  computed: {
    ...mapState(["lang"]),
  },

  watch: {
    async category() {
      await this.load()
    },

    async tag() {
      await this.load()
    },
  },

  methods: {
    ...mapActions(["loadConcepts"]),
    // 목록 불러오기
    load() {
      this.loadConcepts().then((concepts) => {
        if (concepts.length) {
          this.list = concepts
          this.list = this.list.filter((e) => e.category.includes(this.category))

          if (this.tag != "전체") {
            this.list = this.list.filter((e) => e.tag.includes(this.tag))
          }
        }
      })
      /*
      this.$http.post("/api/concept/select").then((res) => {
        console.log(res)
        if (res.data.length) {
          this.list = res.data

          this.list = this.list.filter((e) => e.category.includes(this.category))

          if (this.tag != "전체") {
            this.list = this.list.filter((e) => e.tag.includes(this.tag))
          }
        }
      })*/
    },
  },
}
</script>
