<template>
  <v-sheet width="440" class="px-16 pt-8 pb-6">
    <!-- 제목 -->
    <p class="mb-6 font-weight-bold text-center text-h5">직원 추가</p>

    <!-- 입력란 -->
    <v-sheet class="mt-2 px-4">
      <!-- 유형 -->
      <v-select class="rounded-lg mb-4" label="유형" dense outlined hide-details :items="select_list.type" v-model="type"></v-select>

      <!-- 이름 -->
      <v-text-field class="rounded-lg mb-4" label="이름" dense outlined hide-details v-model="name"></v-text-field>

      <!-- 아이디 -->
      <v-text-field class="rounded-lg mb-4" label="아이디" dense outlined hide-details v-model="user_id"></v-text-field>

      <!-- 비밀번호 -->
      <v-text-field class="rounded-lg mb-4" label="비밀번호" type="password" dense outlined hide-details v-model="password"></v-text-field>

      <v-text-field class="rounded-lg mb-4" label="휴대폰번호" dense outlined hide-details v-model="phoneNumber" type="number" hide-spin-buttons></v-text-field>

      <v-select :items="select_list.receiveTalk" class="rounded-10" label="알림톡" dense outlined v-model="receiveTalk"></v-select>
    </v-sheet>

    <!-- 버튼 -->
    <div class="d-flex justify-center mt-8">
      <!-- 취소 -->
      <v-btn width="100" color="grey lighten-3" depressed class="font-weight-bold rounded-10 mr-3" @click="close()"> 닫기 </v-btn>

      <!-- 입력 -->
      <v-btn width="100" dark depressed class="font-weight-bold rounded-10" @click="submit()"> 입력 </v-btn>
    </div>
  </v-sheet>
</template>
<script>
export default {
  data: () => ({
    select_list: {
      type: ["관리자", "메이커"],
      receiveTalk: ["Y", "N"],
    },

    type: "",
    name: "",
    user_id: "",
    password: "",
    phoneNumber: "",
    receiveTalk: "N",
  }),

  methods: {
    // 취소
    close() {
      // 값 초기화
      this.type = ""
      this.name = ""
      this.user_id = ""
      this.password = ""

      this.$emit("close")
    },

    // 입력
    submit() {
      if (this.type == "") {
        alert("직원 유형을 선택해주세요.")
      } else if (this.name == "") {
        alert("이름을 입력해주세요.")
      } else if (this.user_id == "") {
        alert("아이디를 입력해주세요.")
      } else if (this.password == "") {
        alert("패스워드를 입력해주세요.")
      } else {
        this.$http
          .post("/api/staff/insert", {
            params: {
              type: this.type,
              name: this.name,
              user_id: this.user_id,
              password: this.password,
              phoneNumber: this.phoneNumber ? this.phoneNumber : null,
              notification: this.receiveTalk === "Y" ? 1 : null,
            },
          })
          .then((res) => {
            if (res.data.affectedRows) {
              alert("직원이 정상적으로 입력되었습니다.")
              this.$emit("close")
              this.$emit("inserted")
            } else {
              alert("직원을 입력하는 도중 오류가 발생하였습니다.")
            }
          })
      }
    },
  },
}
</script>
<style scoped>
.test {
  width: 100%;
  border: 1px solid red;
}

.test tr td,
.test tr th {
  border: 1px solid red;
}
</style>
<style scoped>
.v-data-table >>> th,
.v-data-table >>> td {
  min-width: 200px !important;
}
</style>
