export function sleep(ms) {
  return new Promise((r) => setTimeout(r, ms))
}

export function isMobile() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  // iOS detection
  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return true
  }

  // Android detection
  if (/Android/i.test(userAgent)) {
    return true
  }

  // Other mobile device detection
  if (/webOS|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
    return true
  }

  // If none of the above, assume desktop
  return false
}

export const loadImage = async (url) => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.setAttribute("crossorigin", "anonymous")
    image.src = url
    image.onload = () => {
      resolve(image)
    }
    image.onerror = (e) => {
      reject(e)
    }
  })
}

export const resizeImage = async (image, width, height) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext("2d")
    canvas.width = width
    canvas.height = height
    ctx.drawImage(image, 0, 0, width, height)
    resolve(canvas.toDataURL())
  })
}

export const scaleImage = async (image, maxWidth) => {
  const aspectRatio = image.width / image.height
  const maxHeight = maxWidth / aspectRatio
  return resizeImage(image, maxWidth, maxHeight)
}
