<template>
  <div>
    <v-sheet outlined class="pa-2 ma-2 d-flex align-center">
      <div v-if="!isDetail">
        <!-- title -->
        <span class="primary--text font-weight-bold"> {{ dayjs().year() }}년</span>
        <span class="font-weight-bold mx-2">총 컨셉 촬영</span>
        <span class="text-h6 mx-4">{{ $toComma(totalAmount || 0) }} 건</span>
      </div>
      <div v-else>
        <v-btn depressed @click="prev" color="white"><v-icon> mdi-arrow-left </v-icon></v-btn> {{ selectedMonth + 1 }}월
      </div>
    </v-sheet>

    <!-- 본문 -->
    <v-sheet outlined class="pa-2 ma-2" width="800">
      <v-data-table v-show="!isDetail" :headers="listTableHeaders" :items="list_items" hide-default-footer :items-per-page="-1" :loading="isLoading" loading-text="Loading... Please wait">
        <template v-slot:[`item.date`]="{ item }">
          <span>{{ Number(item.date.split("-")[1]) }}월</span>
        </template>
        <template v-slot:[`item.conceptPhotoCount`]="{ item }">
          <span>{{ $toComma(Number(item.conceptPhotoCount)) }}</span>
        </template>
        <template v-slot:[`item.extraPhotoCount`]="{ item }">
          <span>{{ $toComma(Number(item.extraPhotoCount)) }}</span>
        </template>
        <template v-slot:[`item.extraPhotoRequestCount`]="{ item }">
          <span>{{ $toComma(Number(item.extraPhotoRequestCount)) }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn small depressed color="primary" outlined @click="openDetail(item)">내역보기</v-btn>
        </template>
      </v-data-table>

      <v-sheet v-if="isDetail">
        <v-sheet v-if="isLoading">
          <span>Loading... Please wait</span>
        </v-sheet>
        <v-simple-table v-else>
          <template v-slot:default>
            <thead>
              <tr>
                <th>컨셉명</th>
                <th>컨셉촬영 수</th>
                <th>추가 촬영 수</th>
                <th>추가 인화</th>
              </tr>
            </thead>
            <tbody>
              <tr class="accentBg">
                <td class="text-center">계</td>
                <td>{{ selectedItem.conceptPhotoCount }}</td>
                <td>{{ selectedItem.extraPhotoCount }}</td>
                <td>{{ selectedItem.extraPhotoRequestCount }}</td>
              </tr>
              <tr v-for="item in detail_items" :key="item.title">
                <td>{{ item.title }}</td>
                <td>{{ $toComma(item.conceptPhotoCount) }}</td>
                <td>{{ $toComma(item.extraPhotoCount) }}</td>
                <td>{{ $toComma(item.extraPhotoRequestCount) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-sheet>
    </v-sheet>
  </div>
</template>
<script>
import statusService from "@/api/stats-service"
export default {
  data: () => ({
    isLoading: false,
    isDetail: false,
    selectedMonth: 1,
    selectedItem: null,
    list_items: [],
    detail_items: [],
    totalAmount: 0,
    listTableHeaders: [
      { value: "date", text: "날짜", width: "80px" },
      { value: "conceptPhotoCount", text: "컨셉 촬영 수", align: "right" },
      { value: "extraPhotoCount", text: "추가 촬영 수", align: "right" },
      { value: "extraPhotoRequestCount", text: "추가 인화", align: "right" },
      { value: "actions", text: "세부내역", width: "200px", sortable: false },
    ],
  }),
  created() {
    this.selectedMonth = this.dayjs().month()
  },
  mounted() {
    this.loadList()
  },
  methods: {
    async loadList() {
      try {
        this.isLoading = true

        let from = this.dayjs().startOf("year").format("YYYY-MM-DD")
        let to = this.dayjs().format("YYYY-MM-DD")

        let result = await statusService.getPhotoShootStats(true, from, to)
        result.sort((a, b) => Number(a.date.split("-")[1]) - Number(b.date.split("-")[1]))
        this.list_items = result

        this.totalAmount = result.reduce((sum, e) => sum + Number(e.conceptPhotoCount), 0)
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },

    async loadDetail(month) {
      this.detail_items = []
      try {
        this.isLoading = true

        let fromDayjs = this.dayjs().month(month).startOf("month")
        let toDayjs = fromDayjs.endOf("month")

        const from = fromDayjs.format("YYYY-MM-DD")
        const to = toDayjs.format("YYYY-MM-DD")

        let result = await statusService.getPhotoShootContentsStats(false, from, to)

        let groupedRes = Object.groupBy(result, ({ title }) => title)
        result = Object.entries(groupedRes).map(([key, value]) => {
          return {
            date: key,
            title: value[0].title,
            conceptPhotoCount: value.reduce((sum, e) => sum + Number(e.conceptPhotoCount), 0),
            extraPhotoCount: value.reduce((sum, e) => sum + Number(e.extraPhotoCount), 0),
            extraPhotoRequestCount: value.reduce((sum, e) => sum + Number(e.extraPhotoRequestCount), 0),
          }
        })
        result.sort((a, b) => b.conceptPhotoCount - a.conceptPhotoCount)

        this.detail_items = result
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },

    openDetail(item) {
      this.selectedMonth = Number(item.date.split("-")[1]) - 1
      this.selectedItem = item
      this.isDetail = true
      this.loadDetail(this.selectedMonth)
    },
    prev() {
      this.isDetail = false
      this.selectedItem = null
    },
  },
}
</script>
<style scoped>
.greyBg {
  background-color: rgb(220, 220, 220);
}

.accentBg {
  background-color: rgb(220, 182, 220);
}
</style>
