<template>
  <v-sheet class="py-6 px-8">
    <p class="text-h6 font-weight-bold grey--text text--darken-3 text-center mb-6">컨셉 무인촬영설정 추가하기</p>

    <v-sheet class="mt-2 px-4">
      <v-text-field class="rounded-10" label="제목" dense outlined v-model="updateItem.title"></v-text-field>
      <v-text-field class="rounded-10" label="셔터스피드" dense outlined type="number" v-model="updateItem.shutter_speed"></v-text-field>
      <v-text-field class="rounded-10" label="조리개" dense outlined type="number" v-model="updateItem.aperture"></v-text-field>
      <v-text-field class="rounded-10" label="ISO" dense outlined type="number" v-model="updateItem.iso"></v-text-field>
      <v-text-field class="rounded-10" label="카메라" dense outlined type="number" v-model="updateItem.camera"></v-text-field>
      <v-text-field class="rounded-10" label="조명(좌)" dense outlined v-model="updateItem.light_left"></v-text-field>
      <v-text-field class="rounded-10" label="조명(우)" dense outlined v-model="updateItem.light_right"></v-text-field>
      <v-text-field class="rounded-10" label="가격" dense outlined type="number" v-model="updateItem.price"></v-text-field>
      <v-text-field class="rounded-10" label="카테고리" dense outlined v-model="updateItem.category"></v-text-field>
      <v-text-field class="rounded-10" label="스튜디오" dense outlined v-model="updateItem.studio_type"></v-text-field>
      <v-text-field class="rounded-10" label="포즈 설명" dense outlined v-model="updateItem.pose_type"></v-text-field>
      <v-text-field class="rounded-10" label="위치 설명" dense outlined type="number" v-model="updateItem.standing_type"></v-text-field>
    </v-sheet>
    <v-sheet class="d-flex justify-center mt-5">
      <v-btn class="rounded-10 px-6 mr-4" color="grey" large dark depressed @click="dialog.updateItem = false">
        <v-icon small left>mdi-cancel</v-icon>
        취소하기
      </v-btn>
      <v-btn class="rounded-10 px-6" color="primary" large dark depressed @click="updateItemSubmit()">
        <v-icon small left>mdi-database-edit-outline</v-icon>
        추가하기
      </v-btn>
    </v-sheet>
  </v-sheet>
</template>
<script>
export default {
  props: ["updateItem_import"],

  data: () => ({
    updateItem: {},
  }),

  mounted() {
    this.updateItem = this.updateItem_import
  },

  watch: {
    updateItem_import() {
      this.updateItem = this.updateItem_import
    },
  },

  methods: {
    // 목록
    updateItemSubmit() {
      // 제목 검증
      if (this.updateItem.title == "") {
        alert("제목을 입력해주세요")
        return
      }

      // 셔터스피드 검증
      else if (this.updateItem.shutter_speed == "") {
        alert("셔터스피드를 입력해주세요")
        return
      }

      // 조리개 검증
      else if (this.updateItem.aperture == "") {
        alert("조리개을 입력해주세요")
        return
      }

      // ISO 검증
      else if (this.updateItem.iso == "") {
        alert("ISO을 입력해주세요")
        return
      }

      // 카메라 검증
      else if (this.updateItem.camera == "") {
        alert("카메라를 입력해주세요")
        return
      }

      // 조명(좌) 검증
      else if (this.updateItem.light_left == "") {
        alert("조명(좌)을 입력해주세요")
        return
      }

      // 조명(우) 검증
      else if (this.updateItem.light_right == "") {
        alert("조명(우)을 입력해주세요")
        return
      }

      // 가격 검증
      else if (this.updateItem.price == "") {
        alert("가격을 입력해주세요")
        return
      }

      // 카테고리 검증
      else if (this.updateItem.category == "") {
        alert("카테고리를 입력해주세요")
        return
      }

      // 스튜디오 검증
      else if (this.updateItem.studio_type == "") {
        alert("스튜디오를 입력해주세요")
        return
      }

      // 포즈 설명 검증
      else if (this.updateItem.pose_type == "") {
        alert("포즈 설명을 입력해주세요")
        return
      }

      // 위치 설명 검증
      else if (this.updateItem.standing_type == "") {
        alert("위치 설명을 입력해주세요")
        return
      }

      // DB 입력
      this.$http
        .post("/api/concept_auto/update", {
          params: {
            title: this.updateItem.title,
            shutter_speed: this.updateItem.shutter_speed,
            aperture: this.updateItem.aperture,
            iso: this.updateItem.iso,
            camera: this.updateItem.camera,
            light_left: this.updateItem.light_left,
            light_right: this.updateItem.light_right,
            price: this.updateItem.price,
            category: this.updateItem.category,
            studio_type: this.updateItem.studio_type,
            pose_type: this.updateItem.pose_type,
            standing_type: this.updateItem.standing_type,
            id: this.updateItem.id,
          },
        })
        .then((res) => {
          if (res.data.affectedRows) {
            alert("컨셉 무인촬영설정이 정상적으로 수정되었습니다")
            this.$emit("close")
          }
        })
    },
  },
}
</script>
<style scoped>
.my-clipper {
  width: 100%;
}

.placeholder {
  width: 100%;
  height: 150px;
}
</style>
