<template>
  <div>
    <!-- 제목 -->
    <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">컨셉부스 QNA 관리</font>
    </v-sheet>

    <!-- 본문 -->
    <div class="d-flex">
      <v-sheet width="740" class="pa-2 ma-2" outlined>
        <!-- 본문 -->
        <TipTapWriter :key="writeComponentKey" style="min-height: 400px" class="tiptap_style mt-2" :options="options" @contentUpdated="contentUpdate" />

        <!-- 버튼 -->
        <v-sheet class="d-flex justify-center pa-1 mt-2">
          <v-btn class="mx-2 px-10 rounded-10" color="#ADAFCA" dark large depressed @click="cancel()"> 취소하기 </v-btn>
          <v-btn class="mx-2 px-10 rounded-10" color="primary" dark large depressed @click="submit()"> 등록하기 </v-btn>
        </v-sheet>
      </v-sheet>
      <v-sheet class="pa-2 ma-2" outlined>
        <p class="mb-0 text-center text-subtitle-1 font-weight-medium">작은 모바일</p>
        <p class="text-center text-body-2 primary--text">(320)</p>
        <div style="width: 320px">
          <v-sheet class="test">
            <!-- 대문 -->
          </v-sheet>

          <TipTapReader v-if="options.content" class="tiptap_style pa-4" :options="options" :key="componentKey + '320'" />
        </div>
      </v-sheet>

      <v-sheet class="pa-2 ma-2" outlined>
        <p class="mb-0 text-center text-subtitle-1 font-weight-medium">큰 모바일</p>
        <p class="text-center text-body-2 primary--text">(412)</p>
        <div style="width: 412px">
          <v-sheet class="test"> </v-sheet>

          <TipTapReader v-if="options.content" class="tiptap_style pa-4" :options="options" :key="componentKey + '412'" />
        </div>
      </v-sheet>
    </div>
  </div>
</template>
<script>
import TipTapWriter from "@/components/tiptap/Writer"
import TipTapReader from "@/components/tiptap/Reader"
import { CONCEPT_BOOT_QNA } from "@/constant"
import htmlServce from "@/api/html-service"

export default {
  components: {
    TipTapWriter,
    TipTapReader,
  },

  data: () => ({
    //TipTap
    options: {
      content: "",
      editable: true,
      supportImage: true,
      supportVideo: true,
    },

    writeComponentKey: 0,
    componentKey: 0,
  }),

  mounted() {
    this.load()
  },

  methods: {
    // 내용 Count 값 import
    contentUpdate() {
      this.componentKey++
    },

    // 취소하기
    cancel() {
      if (confirm("지금 작성하고 계신 글이 저장되지 않습니다.\n페이지를 이동하시겠습니까?")) {
        this.$router.go(-1)
      }
    },

    // 등록하기
    async submit() {
      if (!this.options.content.length) {
        alert("내용을 입력해주세요")
      } else {
        try {
          const isSuccess = await htmlServce.updateHtmlBlobData(CONCEPT_BOOT_QNA, this.options.content)
          if (isSuccess) {
            alert("컨셉부스 QNA가 정상적으로 등록되었습니다.")
          } else {
            await htmlServce.insertHtmlBlocData(CONCEPT_BOOT_QNA, this.options.content)
            alert("컨셉부스 QNA가 정상적으로 등록되었습니다.")
          }
        } catch (e) {
          console.log(e)
          alert("컨셉부스 QNA 등록에 실패하였습니다.")
        }
      }
    },

    // 내용 load
    load() {
      htmlServce
        .getHtmlBlobData(CONCEPT_BOOT_QNA)
        .then((res) => {
          if (res) {
            this.options.content = res.content
            this.writeComponentKey += 1
          }
        })
        .catch((e) => {
          console.log(e)
          alert("정보를 가져오지 못했습니다.")
        })
    },
  },
}
</script>
<style scoped>
.table_style {
  width: 100%;
  background: white;
  border: 1px solid #ddd;
  border-collapse: collapse;
}

.table_style tr td {
  border: 1px solid #ddd;
  padding: 4px;
  text-align: center;
}

.filled {
  background: #ddd;
  color: #999;
  font-weight: 300;
}

.reservation {
  background: #bba8ca;
  color: #84679a;
  font-weight: 300;
}
</style>

<style scoped>
.my-clipper {
  width: 100%;
}

.placeholder {
  width: 100%;
  height: 150px;
}
</style>
<style scoped>
.test {
  /* background: url('~@/assets/list_main.svg'); */
  background-size: 100%;
}
</style>
