<template>
  <div>
    <!-- 제목 -->
    <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">이벤트 페이지 관리</font>
    </v-sheet>

    <!-- 본문 -->
    <v-sheet outlined class="pa-2 ma-2">
      <!-- 검색 -->
      <v-sheet outlined class="pa-2 mb-2 d-flex">
        <v-sheet class="d-flex align-center" width="800">
          <!-- 유형 -->
          <v-sheet width="160" class="mr-3">
            <v-select outlined dense hide-details :items="select_list.search_type" v-model="search_type"></v-select>
          </v-sheet>

          <!-- 키워드 -->
          <v-text-field placeholder="검색어를 입력해주세요" outlined dense hide-details v-model="keyword" @keyup.enter="search()"></v-text-field>

          <!-- 제출 버튼 -->
          <v-btn height="40" class="px-6 ml-3" color="primary" dark depressed @click="search()">
            <v-icon small left>mdi-magnify</v-icon>
            검색하기
          </v-btn>
        </v-sheet>
      </v-sheet>

      <v-radio-group v-model="selected" hide-details>
        <v-data-table
          hide-default-header
          :items="list"
          :footer-props="{
            'items-per-page-options': [10, 20, 100, -1],
            itemsPerPageText: '페이지당 아이템수',
          }"
          no-data-text=""
        >
          <template v-slot:header>
            <tr>
              <th style="width: 52px"></th>
              <th style="width: 100px">이름</th>
              <th style="width: 150px">코드</th>
              <th style="width: 100px">프로모션</th>
              <th style="width: 150px">생성일</th>
            </tr>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <v-radio class="ml-1" hide-details dense :value="item" @change="selectedItem = item"></v-radio>
              </td>
              <td class="text-truncate">
                {{ item.name }}
              </td>
              <td class="text-truncate">
                {{ item.code }}
              </td>
              <td class="text-truncate">{{ getPromotionTitle(item.promotionCode) }}</td>
              <td class="text-truncate text-caption">
                {{ dayjs(new Date(item.created)).format("YYYY-MM-DD") }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-radio-group>

      <!-- 버튼 -->
      <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
        <v-btn class="px-6 ml-3" color="primary" large dark depressed @click="openUrl()" outlined>
          <v-icon small left>mdi-open-in-new</v-icon>
          OPEN LINK
        </v-btn>
        <v-btn class="px-6 ml-3" color="green" large dark depressed @click="updateOpen()">
          <v-icon small left>mdi-database-edit-outline</v-icon>
          수정하기
        </v-btn>

        <v-btn class="px-6 ml-3" color="blue" large dark depressed @click="createNew()">
          <v-icon small left>mdi-database-plus-outline</v-icon>
          추가하기
        </v-btn>
        <v-btn class="px-6 ml-3" color="red" large dark depressed @click="deleteItem()">
          <v-icon small left>mdi-database-remove-outline</v-icon>
          삭제하기
        </v-btn>
      </v-card>
    </v-sheet>

    <!-- 프로모션 수정하기 -->
    <v-dialog v-model="dialog.update" width="660" content-class="rounded-xl" persistent>
      <UpdateEvent :updateItem_import="selectedItem" @close="close_handler" :key="componentKey" />
    </v-dialog>

    <!-- 프로모션 추가하기 -->
    <v-dialog v-model="dialog.create" width="660" content-class="rounded-xl" persistent>
      <InsertEvent @close="close_handler" :key="componentCreateKey" />
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex"
import InsertEvent from "./InsertEvent"
import UpdateEvent from "./UpdateEvent"
import { computed } from "vue"

export default {
  components: {
    InsertEvent,
    UpdateEvent,
  },

  provide() {
    return {
      concepts: computed(() => this.concepts),
      promotions: computed(() => this.promotions),
    }
  },

  data: () => ({
    select_list: {
      search_type: ["이름", "코드"],
    },

    search_type: "이름",
    keyword: "",

    list: [],
    concepts: [],
    promotions: [],

    selected: false,
    selectedItem: {},

    componentKey: 0,
    componentCreateKey: 0,

    dialog: {
      create: false,
      update: false,
    },
  }),

  async mounted() {
    try {
      this.concepts = await this.loadConceptsAll()
      this.promotions = (await this.$http.get("/api/promotion/select")).data
    } catch (e) {}

    // 목록
    this.search()
  },

  methods: {
    ...mapActions(["loadConceptsAll"]),
    // 목록
    async search() {
      console.log("search", this.search_type, this.keyword)

      this.$http
        .post("/api/event/select/admin", {
          params: {
            type: this.search_type,
            keyword: this.keyword,
          },
        })
        .then((res) => {
          this.list = res.data
        })
        .catch((e) => {
          alert(this.$t("alert.error_page"))
        })
    },
    // 닫기 핸들러
    close_handler() {
      this.dialog.create = false
      this.dialog.update = false
      this.selected = false
      this.selectedItem = {}
      this.search()
    },

    createNew() {
      this.dialog.create = true
      this.componentCreateKey++
    },

    // 삭제하기
    deleteItem() {
      if (!this.selectedItem || !this.selectedItem.id) {
        alert("선택한 항목이 없습니다")
      } else {
        if (confirm("정말 해당 아이템을 삭제하겠습니까?")) {
          this.$http
            .post("/api/event/delete/id", {
              params: {
                id: this.selectedItem.id,
              },
            })
            .then(async (res) => {
              this.search()
              this.selected = false
              this.selectedItem = {}
            })
        }
      }
    },

    // update Open
    updateOpen() {
      if (!this.selectedItem || !this.selectedItem.id) {
        alert("선택한 아이템이 없습니다")
      } else {
        this.componentKey++
        this.dialog.update = true
      }
    },

    openUrl() {
      if (!this.selectedItem || !this.selectedItem.id) {
        alert("선택한 아이템이 없습니다")
      } else {
        let url = window.location.origin + "/eventPage/" + this.selectedItem.code
        window.open(url, "_blank")
      }
    },

    getPromotionTitle(promotionCode) {
      if (!promotionCode) {
        return ""
      }
      const promotion = this.promotions.find((e) => e.code === promotionCode)
      return promotion?.name || promotionCode
    },
  },
}
</script>
<style></style>
