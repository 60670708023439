<template>
  <div class="pb-14">
    <!-- 본문 -->
    <v-sheet class="pt-4 mx-auto d-flex justify-space-between">
      <!-- 내용 -->
      <v-sheet class="rounded-10 pt-8 mx-auto">
        <!-- 프로필 이미지 & 이미지 수정 -->
        <v-sheet class="d-flex justify-center mb-2">
          <!-- 뱃지 -->
          <v-badge color="primary" offset-x="20" offset-y="20">
            <!-- 프로필 이미지 -->
            <v-img class="rounded-circle" style="width: 80px; height: 80px; cursor: pointer" :src="lafiaUser_profile_img_path" @click="dialog.updateImage = true"></v-img>

            <!-- 뱃지 아이콘 -->
            <template v-slot:badge>
              <v-icon>mdi-cog</v-icon>
            </template>
          </v-badge>

          <!-- 프로필 이미지 수정 -->
          <v-dialog v-model="dialog.updateImage" width="auto">
            <ProfileImage
              @imageUpdated="
                load()
                dialog.updateImage = false
              "
            />
          </v-dialog>
        </v-sheet>

        <table class="custom_table mx-auto px-2 mt-10">
          <tr>
            <td class="pb-7">{{ $t("page.mypage.info.ID") }}</td>
            <td>
              <v-text-field class="ml-2 mb-8 rounded-10" style="background: #f5f5f5" outlined dense readonly hide-details :placeholder="getId"></v-text-field>
            </td>
          </tr>
          <tr>
            <td class="pb-7">{{ $t("page.mypage.info.name") }}</td>
            <td>
              <v-text-field class="ml-2 mb-8 rounded-10" style="background: #f5f5f5" outlined dense readonly hide-details :placeholder="$store.state.lafia_user.name"></v-text-field>
            </td>
          </tr>
          <tr>
            <td class="pb-12">{{ $t("page.mypage.info.phone") }}</td>
            <td>
              <v-text-field
                class="ml-2 mb-8 rounded-10"
                outlined
                dense
                :placeholder="$store.state.lafia_user.phone"
                v-model="phone"
                type="number"
                hide-spin-buttons
                @change="$v.phone.$touch()"
                @blur="$v.phone.$touch()"
                :error-messages="phone_Errors"
              ></v-text-field>
            </td>
          </tr>

          <tr v-if="!isSnsUser">
            <td class="pb-7">{{ $t("page.mypage.info.curr-password") }}<font class="ml-1 red--text">*</font></td>
            <td>
              <v-text-field
                class="ml-2 rounded-10"
                outlined
                dense
                persistent-placeholder
                type="password"
                v-model="password_prev"
                @change="$v.password_prev.$touch()"
                @blur="$v.password_prev.$touch()"
                :error-messages="password_prev_Errors"
              ></v-text-field>
            </td>
          </tr>
          <tr v-if="!isSnsUser">
            <td class="pb-7">{{ $t("page.mypage.info.new-password") }}</td>
            <td>
              <v-text-field
                class="ml-2 rounded-10"
                outlined
                dense
                persistent-placeholder
                type="password"
                v-model="password_next"
                @change="$v.password_next.$touch()"
                @blur="$v.password_next.$touch()"
                :error-messages="password_next_Errors"
              ></v-text-field>
            </td>
          </tr>
          <tr v-if="!isSnsUser">
            <td class="pb-7">{{ $t("page.mypage.info.new-password-confirm") }}</td>
            <td>
              <v-text-field
                class="ml-2 rounded-10"
                outlined
                dense
                persistent-placeholder
                type="password"
                v-model="password_check"
                @change="$v.password_check.$touch()"
                @blur="$v.password_check.$touch()"
                :error-messages="password_check_Errors"
              ></v-text-field>
            </td>
          </tr>
          <tr v-if="!isSnsUser">
            <td>{{ $t("page.mypage.info.email") }}</td>
            <td>
              <v-text-field class="ml-2 rounded-10" style="background: #f5f5f5" outlined dense readonly hide-details :placeholder="$store.state.lafia_user.email"></v-text-field>
            </td>
          </tr>
          <tr v-if="isSnsUser">
            <td colspan="2" class="text-center primary--text text-subtitle-1">{{ getSnsLoginTypeStr }}</td>
          </tr>
        </table>

        <!-- 제출 -->
        <v-sheet class="mt-12 mb-6 d-flex justify-center">
          <v-btn @click="submit()" class="rounded-15 px-8" dark depressed color="#f1b2ba">{{ $t("button.do-edit") }}</v-btn>
          <v-btn class="rounded-15 px-8 ml-3" dark depressed color="#f1b2ba" to="/auth/logout"> {{ $t("button.logout") }} </v-btn>
        </v-sheet>

        <v-btn class="rounded-10 px-6 d-flex mx-auto mt-8 mb-6" outlined color="grey lighten-1" @click="dialog.alert = true">{{ $t("button.withdraw-membership") }} </v-btn>
      </v-sheet>
    </v-sheet>

    <!-- 경고 -->
    <v-dialog v-model="dialog.alert" width="auto" content-class="rounded-10" style="z-index: 999">
      <v-sheet class="rounded-10 pa-4">
        <p class="text-center mb-0">
          <v-icon size="80" color="#f2b8bf" class="">mdi-alert-circle-outline</v-icon>
        </p>

        <p class="text-center text-caption mt-3 px-1" style="letter-spacing: 0px !important" v-html="$t('page.mypage.info.user_withdraw_guide_text')"></p>

        <div class="d-flex justify-center">
          <v-checkbox color="#f2b8bf" dense v-model="agree" :label="$t('page.mypage.info.confirm_check_withdraw_label')"></v-checkbox>
        </div>

        <div class="d-flex justify-space-between mt-2">
          <v-btn color="#ddd" class="rounded-10" dark width="48%" depressed @click="dialog.alert = false"> {{ $t("button.return-title") }} </v-btn>

          <v-btn :disabled="!agree" color="#f2b8bf" style="color: white !important" class="rounded-10" width="48%" depressed @click="leave()"> {{ $t("button.withdraw") }} </v-btn>
        </div>
      </v-sheet>
    </v-dialog>
  </div>
</template>
<script>
import ProfileImage from "./ProfileImage"

// 검증
import { validationMixin } from "vuelidate"
import { required, sameAs, minLength } from "vuelidate/lib/validators"
import { mapState, mapMutations, mapGetters } from "vuex"
import authService from "@/api/auth-service"
import { requiredIf } from "vuelidate/lib/validators"

function phoneNumberValidation(number) {
  var regex = /^[0-9]*$/
  console.log("phoneNumberValidation", number)
  if (regex.test(number) && number > 6) {
    console.log("phoneNumberValidation true")
    return true
  } else {
    console.log("phoneNumberValidation false")
    return false
  }
}

export default {
  components: {
    ProfileImage,
  },

  mixins: [validationMixin],

  validations: {
    phone: {
      required,
      phoneNumberValidation,
    },

    password_prev: {
      required: requiredIf(function () {
        return !this.isSnsUser
      }),
      minLength: minLength(6),
    },

    password_next: {
      minLength: minLength(6),
    },

    password_check: {
      sameAsPassword: sameAs("password_next"),
    },
  },

  data: () => ({
    tabs: null,

    name_list: [],

    password_prev: "",
    password_next: "",
    password_check: "",
    phone: "",
    agree: false,

    user: {},

    dialog: {
      updateImage: false,
      alert: false,
    },
  }),

  computed: {
    ...mapState(["lafia_user"]),
    ...mapGetters(["lafiaUser_profile_img_path", "isKRW"]),

    getId() {
      if (this.user.phone === "SNS 로그인") {
        return this.user.email
      } else {
        return this.user.user_id
      }
    },
    getSnsLoginType() {
      if (this.user.provider_type) {
        switch (this.user.provider_type.toLowerCase()) {
          case "kakao":
            return this.isKRW ? "카카오" : "Kakao"
          case "google":
            return this.isKRW ? "구글" : "Google"
          case "naver":
            return this.isKRW ? "네이버" : "Naver"
          default:
            break
        }
        return this.user.provider_type.charAt(0).toUpperCase() + this.user.provider_type.slice(1)
      } else {
        if (this.user.phone === "SNS 로그인") {
          return "-"
        }
      }
      return ""
    },
    getSnsLoginTypeStr() {
      let suffix = this.isKRW ? " 계정 로그인" : " Account Login"
      return this.getSnsLoginType ? this.getSnsLoginType + suffix : ""
    },

    isSnsUser() {
      if (this.user.provider_type && this.user.provider_type !== "normal") {
        return true
      }
      // 이전 버전 호환을 위해서
      if (this.user.phone === "SNS 로그인") {
        return true
      }
      return false
    },

    // 현재 비밀번호 Error 문구
    password_prev_Errors() {
      console.log("password_prev_Errors")
      const errors = []
      if (!this.$v.password_prev.$dirty) return errors
      !this.$v.password_prev.required && errors.push(this.$t("page.mypage.info.input-current-password-hint"))
      !this.$v.password_prev.minLength && errors.push(this.$t("page.mypage.info.password-min-length-rule"))
      return errors
    },

    // 신규 비밀번호 Error 문구
    password_next_Errors() {
      console.log("password_next_Errors")
      const errors = []
      if (!this.$v.password_next.$dirty) return errors
      !this.$v.password_next.minLength && errors.push(this.$t("page.mypage.info.password-min-length-rule"))
      return errors
    },

    // 신규 비밀번호 확인 Error 문구
    password_check_Errors() {
      console.log("password_check_Errors")
      const errors = []
      if (!this.$v.password_check.$dirty) return errors
      !this.$v.password_check.sameAsPassword && errors.push(this.$t("page.mypage.info.password-mismatch-newpassword"))
      return errors
    },

    // 연락처 Error 문구
    phone_Errors() {
      console.log("phone_Errors")
      const errors = []
      if (!this.$v.phone.$dirty) return errors
      if (!this.$v.phone.required || !this.$v.phone.phoneNumberValidation) {
        errors.push(this.$t("page.findId.warning-input-phone"))
      }
      return errors
    },
  },

  mounted() {
    // 유저정보 불러오기
    this.load()
  },

  methods: {
    ...mapMutations(["logout"]),
    // 유저정보 불러오기
    async load() {
      console.log()
      const user_id = this.lafia_user.user_id
      if (!user_id) {
        this.$router.replace("/auth/login")
        return
      }
      try {
        const res = await authService.getUser(user_id)
        if (!res) {
          // 사용자 정보가 없음.
          alert("사용자 정보가 없습니다. 다시 로그인해 주세요")
          this.logout()
          this.$router.replace("/auth/login")
          return
        }
        this.user = res
        this.phone = this.user.phone
      } catch (e) {
        alert("사용자 정보를 가져오지 못했습니다.")
        this.$router.replace("/")
      }

      // this.$http
      //   .post("/api/user/select/specific/user_id", {
      //     params: {
      //       user_id: this.$store.state.lafia_user.user_id,
      //     },
      //   })
      //   .then((res) => {
      //     this.user.type = res.data[0].type || undefined
      //     this.user.profile_image = res.data[0].profile_image
      //     this.name = res.data[0].name
      //   })
    },

    // 수정하기
    submit: _.debounce(async function () {
      // 입력값 기본 검증
      this.$v.$touch()

      // 입력값 기본 검증 통과 시
      if (!this.$v.$invalid) {
        console.log("validation success")
        try {
          if (this.isSnsUser) {
            const result = await authService.updateUserPhoneNumber({
              user_id: this.user.user_id,
              phone: this.phone,
            })
            if (result) {
              alert(this.$t("alert.success_to_update_my_info"))
            } else {
              alert(this.$t("alert.error_fail_to_update_my_info"))
            }
          } else {
            const checkPwdResult = await authService.checkCurrentPassword(this.user.user_id, this.password_prev)
            if (checkPwdResult) {
              const pwdChgResult = authService.updatePassword(this.user.user_id, this.password_next)
              if (pwdChgResult) {
                this.password_prev = ""
                this.password_next = ""
                this.password_check = ""
                this.load()
                alert(this.$t("alert.success_to_update_my_info"))
              }
            } else {
              alert(this.$t("alert.error_mismatch_password"))
              return
            }
          }
        } catch (e) {
          console.log(e)
          alert(this.$t("alert.error_fail_to_update_my_info"))
        }
      } else {
        console.log("validation error")
      }
    }, 500),

    // 회원탈퇴하기
    leave() {
      if (confirm(this.$t("alert.confirm_user_withdraw"))) {
        this.$http
          .post("/api/user/delete", {
            params: {
              user_id: this.$store.state.lafia_user.user_id,
            },
          })
          .then((res) => {
            alert(this.$t("alert.confirm_user_withdraw"))
            this.$router.push("/auth/logout")
            this.$gtag.event("delete_account", { method: this.user.provider_type || "" })
          })
      }
    },
  },
}
</script>
<style scoped>
/* 리스트 기본 이펙트 제거 */
::v-deep .v-list-item:before,
::v-deep .v-list-item:hover:before,
::v-deep .v-list-item--active:before,
::v-deep .v-list-item--active:hover:before,
::v-deep .v-list-item:focus:before {
  opacity: 0;
}
</style>
<style scoped>
/* 검색 keyword */
::v-deep .v-text-field input {
  margin-left: 8px;
  font-size: 16px;
}

/* 검색 type */
::v-deep .v-select input {
  margin-left: 8px;
  font-size: 16px;
}

/* 입력란 공통 (fieldset) */
.v-text-field--outlined >>> fieldset {
  border-color: #e0e0eb;
}

/* 입력란 공통 (label) */
.v-text-field--outlined >>> label {
  color: #c3bdd1;
  font-size: 13px;
  font-weight: 400;
}

/* Custom Truncate */
.custom-truncate-mobile {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.custom_table tr td {
  padding: 0px;
}

.custom_table tr td:first-child {
  width: 120px;
  font-weight: bold;
}
</style>
<style scoped>
.test {
  /* background: url('~@/assets/list_main.svg'); */
  background-size: cover;
}

::v-deep .v-label {
  font-size: 12px;
  padding-bottom: 2px;
  margin-left: -4px;
}
</style>
