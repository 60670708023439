<template>
  <div v-if="isLoading" class="text-center mt-10">
    <div class="font-weight-medium text-body-1 pb-4">Loading...</div>
    <v-progress-circular size="48" :width="2" color="primary" indeterminate></v-progress-circular>
  </div>
  <div v-else class="pb-10">
    <TitleLogo :title="title" />
    <v-divider></v-divider>
    <div>
      <div class="d-flex flex-column align-center my-4">
        <div class="font-weight-medium text-h5">{{ selectedConcept.title || "" }}</div>
        <v-img
          :lazy-src="require('@/assets/placeholder.jpg')"
          :src="'/upload/concept/thumbnail_image/' + selectedConcept.pattern_1_image"
          position="top center"
          aspect-ratio="0.83"
          max-width="60%"
          class="mt-2"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0 align-center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div>
    </div>
    <p v-if="type === '키오스크컨셉구매'" class="text-body-2 text-md-body-1 text-center mt-2 px-4" style="color: #545454">{{ $t("page.onlinePayConn.guide-1") }}</p>
    <p v-else-if="type === '키오스크추가촬영'" class="text-body-2 text-md-body-1 text-center mt-2 px-4" style="color: #545454" v-html="$t('page.onlinePayConn.shotadd-guide')"></p>

    <v-divider class="my-4 my-md-6"></v-divider>

    <v-sheet>
      <v-row class="px-6 mt-2">
        <v-col cols="6" class="py-0">
          <!-- 최종가격 -->
          <div class="text-end font-danjunghae" style="font-size: 40px; color: #5b4a62">
            {{ $toComma(computed_total_price) }}
            <span class="text-body-1 ml-1 grey--text text--darken-2">원</span>
          </div>
        </v-col>
        <v-col cols="6" class="py-0">
          <v-btn
            large
            color="primary"
            width="100%"
            max-width="180px"
            dark
            style="font-size: 20px"
            class="rounded-10"
            @click="onSubmitWithApplicableCouponCheck(false)"
            :loading="pay_loading"
            depressed
          >
            결제하기
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="px-6 ma-0">
        <v-col cols="6" class="text-end pa-0">
          <span v-if="selected_coupon" class="text-body-2 warning--text font-weight-light">{{ $t("page.pay.coupon-apply-label", { amount: getDiscountPriceStr }) }}</span>
        </v-col>
      </v-row>

      <v-row class="px-6">
        <v-col cols="6" class="py-0 text-end">
          <v-btn large width="100%" max-width="180px" color="#f1b2ba" style="font-size: 20px; color: #693838" class="flex-grow-1 rounded-10 pb-1" depressed outlined @click="showCouponSelectDialog()">
            쿠폰<span v-show="available_coupon_cnt">({{ available_coupon_cnt }})</span>
          </v-btn>
        </v-col>
        <v-col cols="6" class="py-0">
          <v-btn
            large
            width="100%"
            max-width="180px"
            style="font-size: 20px"
            class="flex-grow-1 rounded-10 pb-1 darken-1 font-weight-medium"
            depressed
            outlined
            @click="onSubmitWithApplicableCouponCheck(true)"
            :disabled="!computed_total_price"
          >
            현장결제요청
          </v-btn>
        </v-col>
      </v-row>
    </v-sheet>
    <MyCouponSelectList
      :visible="show_coupon_select"
      :type="type"
      :conceptId="selectedConcept.id"
      @close="show_coupon_select = false"
      @input="(e) => (show_coupon_select = e)"
      :key="'coupon' + show_coupon_select_componentKey"
      @apply="(item) => applyCoupon(item)"
      :showNoCoupon="showNoCoupon"
    />
    <TwoButtonDialogVue
      :msg="twobutton_dialog_messsage"
      @input="twoButtonDialogInputEvent"
      positiveText="button.pay"
      negativeText="button.coupon-select"
      @positive="positiveDialogEvent"
      @negative="negativeDialogEvent"
      :visible="show_twobutton_dialog"
      :key="show_twobutton_dialog_componentKey"
    />
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex"
import TitleLogo from "@/views/components/TitleLogo"
import { PayOrderTypes } from "@/constant"
import authService from "@/api/auth-service"
import MyCouponSelectList from "@/views/components/MyCouponSelectList"
import TwoButtonDialogVue from "@/views/components/TwoButtonDialog"

export default {
  components: {
    TitleLogo,
    MyCouponSelectList,
    TwoButtonDialogVue,
  },
  data: () => ({
    error: null,
    isLoading: true,

    //기본 가격 테이블 정보
    defaultPriceInfo: null,

    //결제 진행중
    pay_loading: false,

    type: "",
    roomNo: "",
    //conceptList: [],
    //selectedIdx: 0,

    selectedConcept: {
      title: "",
      pattern_1_image: "",
    },
    componentSlideKey: 0,

    show_coupon_select_componentKey: 0,
    show_coupon_select: false,
    selected_coupon: null,
    available_coupon_cnt: 0,

    show_twobutton_dialog: false,
    twobutton_dialog_messsage: "",
    show_twobutton_dialog_componentKey: 0,
    showNoCoupon: true,

    isOffline: false,
  }),
  created() {
    //비 로그인 시
    if (!this.lafia_user.is_logined) {
      alert("로그인 후 이용가능합니다.")
      let loginPath = "/auth/login"
      if (this.$route.query.pay_qrcode) {
        loginPath = loginPath + `?pay_qrcode=${this.$route.query.pay_qrcode}`
      }
      this.$router.push(loginPath)
    } else {
      this.autoQrLogin(this.lafia_user.user_id)
    }
  },
  async mounted() {
    // 토스 기본설정
    await this.tossInit()
    // this.loadPriceInfo()
    this.initQrInfo()
  },

  computed: {
    ...mapState(["lafia_user"]),

    couponType() {
      switch (this.type) {
        case "키오스크컨셉구매":
          return "컨셉 촬영"
        case "키오스크추가촬영":
          return "샷 추가"
        default:
          return "결제 연동"
      }
    },

    title() {
      switch (this.type) {
        case "키오스크컨셉구매":
          return "컨셉 촬영"
        case "키오스크추가촬영":
          return "샷 추가"
        default:
          return "결제 연동"
      }
    },

    productName() {
      switch (this.type) {
        case "키오스크컨셉구매":
          return "온라인키오스크컨셉구매"
        case "키오스크추가촬영":
          return "온라인키오스크추가촬영"
        default:
          return "기타온라인결제"
      }
    },
    computed_price() {
      switch (this.type) {
        case "키오스크컨셉구매":
          return (this.selectedConcept && this.selectedConcept.price) || 9900
        case "키오스크추가촬영":
          return 6900
        default:
          return 0
      }
    },

    // 최종 가격 계산
    computed_total_price() {
      switch (this.type) {
        case "키오스크컨셉구매":
          return Math.max((this.selectedConcept && this.selectedConcept.price) - this.getDiscountPrice, 0)
        case "키오스크추가촬영":
          return 6900
        default:
          return 0
      }
    },

    getDiscountPrice() {
      if (!this.selected_coupon) {
        return 0
      }
      return this.selected_coupon.discount_KRW || 0
    },
    getDiscountPriceStr() {
      const price = this.getDiscountPrice
      return `${this.$toComma(Math.min(price || 0, this.computed_price))}${this.$t("common.krw")}`
    },
  },

  methods: {
    // 토스 기본설정
    tossInit() {
      let recaptchaScript = document.createElement("script")
      recaptchaScript.setAttribute("src", "https://js.tosspayments.com/v1")
      document.head.appendChild(recaptchaScript)
    },
    async initQrInfo() {
      this.error = null
      this.isLoading = true
      console.log("initQrInfo")
      try {
        const result = await this.$http.get("/api/qrcode/pay/info", {
          params: {
            qrcode: this.$route.query.pay_qrcode,
          },
        })
        const { pay_qrcode, order_list_id, type, concept_id, roomNo, site, status } = result.data
        //접수,로그인,결제완료,취소요청,취소완료
        if (!["접수", "로그인"].includes(status)) {
          alert("이미 취소 또는 완료된 QR 코드 입니다.")
          this.$router.replace("/")
          return
        }
        this.type = type
        this.roomNo = roomNo

        if (!concept_id) {
          alert("잘못된 정보입니다. 관리자에게 문의해 주세요.")
          return
        }
        const conceptResult = await this.$http.post("/api/concept/select/specific", {
          params: { id: concept_id },
        })
        this.selectedConcept = conceptResult.data[0]

        this.$http
          .get("/api/coupon/getUserCoupons", {
            params: {
              user_id: this.lafia_user.user_id,
              type: this.type,
            },
          })
          .then((res) => {
            let coupons = []
            if (this.type === "키오스크컨셉구매") {
              coupons = res.data.filter((e) => {
                return e.type === "모든컨셉할인" || (e.type === "특정컨셉할인" && e.conceptId === this.selectedConcept.id)
              })
            } else {
              coupons = res.data.filter((e) => e.type === this.type)
            }
            this.available_coupon_cnt = coupons.length
          })
          .catch((err) => {
            alert("쿠폰목록을 가져오지 못했습니다.")
          })
      } catch (e) {
        console.log(e)
        if (e.response && e.response.data && e.response.data.errorCode === 1002) {
          alert("일치하는 QR 정보가 없습니다. 다시 시도해 주세요.")
        } else {
          this.error = "QR정보를 가져오는데 실패하였습니다. 네트워크 환경을 확인 후 다시 시도해 주세요."
        }
      } finally {
        this.isLoading = false
      }
    },

    async getConceptList(roomNo) {
      const result = await this.$http.post("/api/kiosk/auto_photo/concept_list/new", {
        studio_type: roomNo,
      })
      return result.data
    },

    onSubmitWithApplicableCouponCheck: _.debounce(async function (isOffline = false) {
      if (this.available_coupon_cnt > 0 && !this.selected_coupon) {
        this.showTwoButtonDialog("dialog.confirm_applicable_coupons", isOffline)
      } else {
        this.onSubmit(isOffline)
      }
    }, 500),

    onSubmit: _.debounce(async function (isOffline = false) {
      // if (!this.defaultPriceInfo) {
      //   this.$router.go(0)
      //   alert("페이지에 오류가 있습니다. 다시 시도해 주세요")
      //   return
      // }

      let orderId = Math.floor(Math.random() * 1000000000)

      let payKioskProduct = {
        productName: this.productName,
        memo: this.getMemo(),
      }

      try {
        this.pay_loading = true

        const result = await this.$http.get("/api/qrcode/pay/info", {
          params: {
            qrcode: this.$route.query.pay_qrcode,
          },
        })
        const { pay_qrcode, order_list_id, type, concept_id, roomNo, site, status } = result.data

        //접수,로그인,결제완료,취소요청,취소완료
        if (status === "취소완료") {
          alert("이미 취소된 QR 코드 입니다.")
          this.$router.replace("/")
          return
        } else if (!["접수", "로그인"].includes(status)) {
          alert("이미 취소 또는 완료된 QR 코드 입니다.")
          this.$router.replace("/")
          return
        }

        // 현장 결제
        if (isOffline) {
          console.log("현장 결제를 요청합니다.")
          try {
            await this.$http.post("/api/payments/offline/insert", {
              productName: payKioskProduct.productName,
              pay_qrcode: this.$route.query.pay_qrcode,
              price: this.computed_total_price,
              pay_amount: this.computed_total_price,
              type: this.type,
              user_id: this.lafia_user.user_id,
              user_name: this.lafia_user.name,
              roomNo: this.roomNo,
              couponId: this.selected_coupon && this.selected_coupon.issuedId,
              couponName: this.selected_coupon && this.selected_coupon.title,
            })
            this.$router.replace("/offlinePayReqCompl")
          } catch (e) {
            alert("현장 결제 요청에 실패하였습니다.")
          }
          return
        }

        const orderRes = await this.$http.post("/api/order/v2/order", {
          orderId: orderId.toString(),
          userId: this.lafia_user.user_id,
          orderDetailList: [
            {
              productName: payKioskProduct.productName,
              price: this.computed_price,
              totalPrice: this.computed_price,
              finalPrice: this.computed_total_price,
              couponId: this.selected_coupon && this.selected_coupon.issuedId,
            },
          ],
          type: payKioskProduct.productName,
          memo: payKioskProduct.memo,
        })

        // 주문 정보를 저장
        await this.$http.post("/api/qrcode/pay/setOrderId", {
          pay_qrcode: this.$route.query.pay_qrcode,
          orderListId: orderRes.data.id,
        })

        if (window.location.host.startsWith("localhost") && window.confirm("테스트 결제 하기")) {
          this.$router.replace("/toss/success?orderId=" + orderId + "&paymentKey=test&amount=" + this.computed_total_price)
        } else if (this.computed_price > 0 && this.computed_total_price === 0) {
          this.$router.replace("/toss/success?orderId=" + orderId + "&paymentKey=free&amount=" + this.computed_total_price)
        } else {
          let orderName = this.type + " (" + this.selectedConcept.title || "" + ")"

          var tossPayments = TossPayments(process.env.VUE_APP_TOSS_PAYMENT_CLIENT_KEY)
          tossPayments
            .requestPayment("카드", {
              amount: this.computed_total_price,
              orderId: orderId,
              orderName: orderName,
              customerName: this.lafia_user.name,
              customerEmail: this.lafia_user.email,
              successUrl: window.location.origin + "/toss/success",
              failUrl: window.location.origin + "/fail",
              useEscrow: false,
            })
            .catch((error) => {
              console.error("토스페이먼츠 결제", error)
              this.pay_loading = false // 결제 진행 중 취소
            })
        }
      } catch (e) {
        console.log(e)
        alert(this.$t("alert.error_page"))
      } finally {
        this.pay_loading = false
      }
    }, 500),

    getMemo() {
      switch (this.type) {
        case "키오스크컨셉구매":
        case "키오스크추가촬영":
          return `컨셉-${this.selectedConcept.title}`
        default:
          return "키오스크기타"
      }
    },

    async autoQrLogin(user_id) {
      try {
        await authService.updateLastLoginAt(user_id)
        if (this.$route.query.pay_qrcode) {
          await authService.updatePayQrcode(user_id, this.$route.query.pay_qrcode)
        }
      } catch (e) {
        // update error ignore
        console.log(e)
      }
    },

    showCouponSelectDialog(showNoCoupon = true) {
      this.showNoCoupon = showNoCoupon
      this.show_coupon_select_componentKey++
      this.show_coupon_select = true
    },
    applyCoupon(coupon) {
      console.log("applyCoupon", coupon)
      this.selected_coupon = null

      if (!coupon) {
        return
      }
      const { discount_KRW, discount_USD, startAt, expireAt, type, isExpired, conceptId } = coupon
      if (isExpired) {
        return
      } else if (!this.dayjs().isBetween(this.dayjs(startAt), this.dayjs(expireAt), "day", "[]")) {
        return
      } else if (type === "모든컨셉할인" && type === "특정컨셉할인") {
        return
      } else if (type === "특정컨셉할인" && conceptId !== this.selectedConcept.id) {
        return
      }
      this.selected_coupon = coupon
    },

    async loadPriceInfo() {
      try {
        const result = await this.$http.get("/api/promotion/select")
        this.defaultPriceInfo = result.data.find((e) => e.code == "default")
        if (!this.defaultPriceInfo) {
          alert(this.$t("alert.error_page"))
          if (window.history.length > 1) {
            this.$router.go(-1)
          } else {
            window.close()
          }
          //가격 테이블이 없는 경우
        }
      } catch (e) {
        //if (e.status === 404) {
        this.$router.go(-1)
        alert(this.$t("alert.error_page"))
        //가격 테이블이 없는 경우
        //}
      }
    },

    showTwoButtonDialog(message, isOffline) {
      this.twobutton_dialog_messsage = message
      this.show_twobutton_dialog = true
      this.show_twobutton_dialog_componentKey++
      this.isOffline = isOffline
    },

    closeTwoButtonDialog() {
      this.show_twobutton_dialog = false
      this.twobutton_dialog_messsage = ""
      this.show_twobutton_dialog_componentKey++
    },

    positiveDialogEvent() {
      this.closeTwoButtonDialog()
      this.onSubmit(this.isOffline)
    },

    negativeDialogEvent() {
      this.closeTwoButtonDialog()
      this.showCouponSelectDialog(false)
    },

    twoButtonDialogInputEvent(e) {
      if (!e) {
        this.closeTwoButtonDialog()
      }
    },
  },
}
</script>
<style scoped>
::v-deep .VueCarousel-navigation-prev {
  left: 40px;
}

::v-deep .VueCarousel-navigation-next {
  right: 40px;
}

.VueCarousel-slide {
  visibility: visible;
  flex-basis: 100%;
  width: 100%;
}
</style>
