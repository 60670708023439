<template>
  <div>
    <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">통계</font>
    </v-sheet>
    <!-- 본문 -->
    <v-sheet outlined class="pa-2 ma-2">
      <v-sheet outlined class="pa-2 mb-2 d-flex">
        <v-sheet class="d-flex align-center">
          <!-- 유형 -->
          <v-sheet width="160" class="mr-3">
            <v-select outlined dense hide-details :items="statsTypes" v-model="selectedType" style="min-width: 200px"></v-select>
          </v-sheet>

          <div class="ml-10 d-flex align-center">
            <v-menu v-model="startAtMenu" :close-on-content-click="false" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="startAtDate" label="시작일자" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="green" clearable :rules="[rules.required]"></v-text-field>
              </template>
              <v-date-picker v-model="startAtDate" no-title scrollable color="green" @input="startAtMenu = false"></v-date-picker>
            </v-menu>

            <span class="px-4"> ~ </span>

            <v-menu v-model="endAtMenu" :close-on-content-click="false" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="endAtDate" label="종료일자" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="warning" clearable :rules="[rules.required]"></v-text-field>
              </template>
              <v-date-picker v-model="endAtDate" no-title scrollable color="warning" @input="endAtMenu = false"></v-date-picker>
            </v-menu>
          </div>

          <!-- 월간 -->
          <v-checkbox v-model="isMonth" class="shrink mt-0 ml-4" label="월간" color="primary" hide-details dense></v-checkbox>

          <v-btn height="40" class="px-6 ml-8" color="primary" dark depressed @click="load()">
            <v-icon small left>mdi-magnify</v-icon>
            검색하기
          </v-btn>
        </v-sheet>
      </v-sheet>
      <v-data-table :headers="tableHeaders" :items="items" :loading="isLoading" loading-text="Loading... Please wait"></v-data-table>
    </v-sheet>
  </div>
</template>
<script>
import statusService from "@/api/stats-service"

export default {
  data: () => ({
    rules: {
      required: (value) => !!value || "Required.",
    },
    selectedType: "판매금액",
    stats: [
      {
        type: "판매금액",
        func: async ({ isMonth, from, to }) => statusService.getSaleStats(isMonth, from, to),
        fields: [
          { field: "date", text: "날짜" },
          { field: "total_amount", text: "총수익금액" },
          { field: "pay_amount", text: "총매출금액" },
          { field: "cancel_amount", text: "총취소금액" },
        ],
      },

      {
        type: "사용자",
        func: async ({ isMonth, from, to }) => statusService.getUserStats(isMonth, from, to),
        fields: [
          { field: "date", text: "날짜" },
          { field: "new_user", text: "신규사용자 수" },
          { field: "not_sns_user", text: "계정사용자(Not SNS) 수" },
        ],
      },
      {
        type: "방문자",
        func: async ({ isMonth, from, to }) => statusService.getVisitorStats(isMonth, from, to),
        fields: [
          { field: "date", text: "날짜" },
          { field: "payed_people_count", text: "방문자 수" },
          { field: "canceled_people_count", text: "취소한 사용자 수" },
          { field: "canceled_people_ratio", text: "취소 비율" },
        ],
      },
      {
        type: "사진촬영",
        func: async ({ isMonth, from, to }) => statusService.getPhotoShootStats(isMonth, from, to),
        fields: [
          { field: "date", text: "날짜" },
          { field: "conceptPhotoCount", text: "컨셉 촬영 수" },
          { field: "extraPhotoCount", text: "추가 촬영 수" },
          { field: "extraPhotoRequestCount", text: "추가 인화 요청 수" },
        ],
      },
      {
        type: "컨텐츠",
        func: async ({ isMonth, from, to }) => statusService.getContentsStats(isMonth, from, to),
        fields: [
          { field: "date", text: "날짜" },
          { field: "title", text: "컨셉명칭" },
          { field: "count", text: "구매횟수" },
        ],
      },
      {
        type: "리뷰",
        func: async ({ isMonth, from, to }) => statusService.getReviewStats(isMonth, from, to),
        fields: [
          { field: "date", text: "날짜" },
          { field: "review_count", text: "리뷰등록횟수" },
          { field: "like_count", text: "좋아요등록횟수" },
        ],
      },
      {
        type: "방문 시간",
        func: async ({ isMonth, from, to }) => statusService.getVisitTimeStats(isMonth, from, to),
        fields: [
          { field: "date", text: "날짜" },
          { field: "time", text: "시각" },
          { field: "payed_people_count", text: "결제횟수" },
        ],
      },
      {
        type: "방문회당객단가",
        func: async ({ isMonth, from, to }) => statusService.getSalesPerVisitorStats(isMonth, from, to),
        fields: [
          { field: "date", text: "날짜" },
          { field: "pay_amount", text: "방문결제총금액" },
          { field: "payed_people_count", text: "방문결제횟수" },
          { field: "average_pay_amount_per_person", text: "방문평균결제금액" },
        ],
      },
      {
        type: "재방문율",
        func: async ({ isMonth, from, to }) => statusService.getRevisitStats(isMonth, from, to),
        fields: [
          { field: "date", text: "날짜" },
          { field: "duplication_rate", text: "재방문율" },
        ],
      },
    ],

    startAtMenu: false,
    endAtMenu: false,
    startAtDate: null,
    endAtDate: null,

    isLoading: false,
    items: [],

    isMonth: false,
  }),

  mounted() {
    this.endAtDate = this.dayjs().format("YYYY-MM-DD")
    this.startAtDate = this.dayjs().subtract(60, "day").format("YYYY-MM-DD")
    this.load()
  },

  computed: {
    statsTypes() {
      return this.stats.map((e) => e.type)
    },
    getSelctedStatObj() {
      return this.stats.find((e) => e.type == this.selectedType)
    },

    tableHeaders() {
      return this.getSelctedStatObj.fields.map((e) => ({ text: e.text, value: e.field }))
    },
  },

  watch: {
    selectedType() {
      let obj = this.getSelctedStatObj
      if (obj) {
        this.load()
      }
    },
  },

  methods: {
    async load() {
      let obj = this.getSelctedStatObj
      if (obj) {
        try {
          this.isLoading = true
          const result = await obj.func({ isMonth: this.isMonth, from: this.startAtDate, to: this.endAtDate })
          this.items = result
        } catch (e) {
          console.log(e)
          alert("데이터를 가져오는데 실패하였습니다.")
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>
<style></style>
