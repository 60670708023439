<template>
  <div class="pb-16">
    <!-- 본문 -->
    <v-sheet class="pt-4 mx-auto">
      <!-- 쿠폰 내역 없을 시 -->
      <v-sheet v-if="!myCoupons.length" class="rounded-10 pt-8 text-center text-subtitle-1 primary--text">
        <v-icon size="40" color="primary" class="mb-2">mdi-cancel</v-icon><br />
        {{ $t("page.mypage.coupons.coupons-empty") }}
      </v-sheet>
      <v-sheet v-else class="px-2 text-subtitle-1 primary--text mt-6">
        <v-sheet v-for="item in myCoupons" :key="item.issuedId" class="mb-8 pa-3 pt-4" style="cursor: pointer; background: #e9e5ff; position: relative; border: 5px solid #dec5e1; border-radius: 16px">
          <img src="@/assets/deco/card-top-deco.png" width="240px" style="position: absolute; margin-top: -40px; left: 50%; margin-left: -120px" />
          <div class="d-flex flex-column">
            <span class="font-danjunghae accent--text" style="font-size: 28px" :lang="lang">{{ getCouponTitle(item) }}</span>
            <div class="font-weight-light" style="color: rgb(84, 84, 84); font-size: 18px">
              {{ $t("page.mypage.coupons.usable-date", { startAt: dayjs(item.startAt).format("YYYY.MM.DD"), expireAt: dayjs(item.expireAt).format("YYYY.MM.DD") }) }}
            </div>
          </div>

          <div class="mt-2" style="color: rgb(84, 84, 84); font-size: 16px; line-height: 1.1">{{ $t("page.mypage.coupons.discount-amount") }} : {{ getDiscountPriceStr(item) }}</div>
        </v-sheet>
      </v-sheet>
    </v-sheet>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex"
export default {
  data: () => ({
    myCoupons: [],
  }),

  computed: {
    ...mapGetters(["isKRW"]),
    ...mapState(["lafia_user", "lang"]),
  },

  mounted() {
    this.load()
  },

  methods: {
    load() {
      this.$http
        .get("/api/coupon/getUserCoupons", {
          params: {
            user_id: this.lafia_user.user_id,
            isIncludeFuture: true,
          },
        })
        .then((res) => {
          this.myCoupons = res.data
        })
        .catch((err) => {
          alert(this.$t("toast.error-get-user-coupons"))
        })
    },
    getDiscountPriceStr(item) {
      return `${this.$toComma(item.discount_KRW)}${this.$t("common.krw")}`
    },

    getCouponTitle(coupon) {
      switch (this.lang) {
        case "en":
          return coupon.title_en || coupon.title
        case "jp":
          return coupon.title_jp || coupon.title
        default:
          return coupon.title
      }
    },
  },
}
</script>
