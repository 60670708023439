<template>
  <div>
    <v-sheet class="py-6 px-8">
      <p class="text-h6 font-weight-bold grey--text text--darken-3 text-center mb-6">미션 추가하기</p>

      <v-sheet class="mt-2 px-4">
        <v-text-field class="rounded-10" label="제목" dense outlined v-model="create.title"></v-text-field>
        <v-select :items="status" class="rounded-10" label="활성화여부" dense outlined v-model="create.status" style="max-width: 140px"></v-select>
        <!-- 본문 -->
        <TipTapWriter class="tiptap_style" :options="options" />
      </v-sheet>

      <v-sheet class="d-flex justify-center mt-5">
        <v-btn class="rounded-10 px-6 mr-4" color="grey" large dark depressed @click="$emit('close')">
          <v-icon small left>mdi-cancel</v-icon>
          취소하기
        </v-btn>
        <v-btn class="rounded-10 px-6" color="primary" large dark depressed @click="createSubmit()">
          <v-icon small left>mdi-database-edit-outline</v-icon>
          추가하기
        </v-btn>
      </v-sheet>
    </v-sheet>
  </div>
</template>
<script>
import TipTapWriter from "@/components/tiptap/Writer"
import TipTapReader from "@/components/tiptap/Reader"
import { mapState } from "vuex"

export default {
  components: {
    TipTapWriter,
    TipTapReader,
  },
  computed: {
    ...mapState(["lafia_admin"]),
  },

  data: () => ({
    status: ["Y", "N"],
    create: {
      title: "",
      status: "Y",
    },
    //TipTap
    options: {
      content: "",
      editable: true,
      supportImage: true,
      supportVideo: true,
    },
  }),
  methods: {
    createSubmit: _.debounce(async function () {
      // 제목 검증
      if (this.create.title == "") {
        alert("제목을 입력해주세요")
        return
      }

      // 컨셉소개 내용 검증
      if (this.options.content == "") {
        alert("미션 내용을 입력해주세요")
        return
      }

      this.$http
        .post("/api/missions/insert", {
          title: this.create.title,
          content: this.options.content,
          admin_id: this.lafia_admin.user_id,
          enabled: this.create.status === "Y" ? 1 : 0,
        })
        .then((res) => {
          if (res.data.affectedRows) {
            alert("미션이 정상적으로 등록되었습니다")
            this.$emit("close")
          }
        })
    }, 500),
  },
}
</script>
<style></style>
