<template>
  <v-sheet class="py-6 px-8">
    <p class="text-h6 font-weight-bold grey--text text--darken-3 text-center mb-6">쿠폰 수정하기</p>

    <v-sheet class="mt-2 px-4">
      <v-text-field class="rounded-10" label="종류" dense outlined :value="updateItem.type" readonly></v-text-field>
      <v-select v-if="updateItem.type === '특정컨셉할인'" outlined dense :items="concepts" item-text="title" item-value="id" v-model="updateItem.conceptId" label="컨셉"></v-select>
      <v-select :items="status" class="rounded-10" label="사용중" dense outlined v-model="updateItem.isExpired" style="max-width: 140px"></v-select>
      <v-text-field class="rounded-10" label="이름(한글)" dense outlined v-model.trim="updateItem.title"></v-text-field>
      <v-text-field class="rounded-10" label="이름(영어)" dense outlined v-model.trim="updateItem.title_en"></v-text-field>
      <v-text-field class="rounded-10" label="이름(일어)" dense outlined v-model.trim="updateItem.title_jp"></v-text-field>

      <v-text-field class="rounded-10" label="할인금액(KRW)" dense outlined type="number" hide-spin-buttons v-model="updateItem.discount_KRW"></v-text-field>
      <!-- <v-text-field class="rounded-10" label="할인금액(USD)" dense outlined type="number" hide-spin-buttons v-model="updateItem.discount_USD"></v-text-field> -->
      <v-textarea class="rounded-10" style="min-height: 100px" maxlength="256" counter="256" clear-icon outlined label="설명" v-model="updateItem.description"></v-textarea>
      <v-row>
        <v-menu v-model="startAtMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="updateItem.startAtDate" label="시작일자" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="green"></v-text-field>
          </template>
          <v-date-picker v-model="updateItem.startAtDate" no-title scrollable color="green" @input="startAtMenu = false"></v-date-picker>
        </v-menu>
        <v-menu v-model="endAtMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="updateItem.endAtDate" label="종료일자" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="warning"></v-text-field>
          </template>
          <v-date-picker v-model="updateItem.endAtDate" no-title scrollable color="warning" @input="endAtMenu = false"> </v-date-picker>
        </v-menu>
      </v-row>
    </v-sheet>
    <v-sheet class="d-flex justify-center mt-5">
      <v-btn class="rounded-10 px-6 mr-4" color="grey" large dark depressed @click="$emit('close')">
        <v-icon small left>mdi-cancel</v-icon>
        취소하기
      </v-btn>
      <v-btn class="rounded-10 px-6" color="primary" large dark depressed @click="createSubmit()">
        <v-icon small left>mdi-database-edit-outline</v-icon>
        수정하기
      </v-btn>
    </v-sheet>
  </v-sheet>
</template>
<script>
import dayjs from "dayjs"
export default {
  props: ["updateItem_import"],

  data: () => ({
    status: [
      { text: "Y", value: 0 },
      { text: "N", value: 1 },
    ],
    updateItem: {},
    startAtMenu: false,
    endAtMenu: false,

    concepts: [],
  }),
  mounted() {
    this.$http
      .post("/api/concept/select")
      .then((res) => {
        this.concepts = res.data
      })
      .catch((e) => {
        this.concepts = []
      })

    this.updateItem = {
      ...this.updateItem_import,
      startAtDate: this.dayjs(this.updateItem_import.startAt).format("YYYY-MM-DD"),
      endAtDate: this.dayjs(this.updateItem_import.expireAt).format("YYYY-MM-DD"),
    }
    console.log(this.updateItem)
    // this.load()
  },

  methods: {
    // 목록
    createSubmit: _.debounce(async function () {
      // 제목 검증
      if (this.updateItem.name == "") {
        alert("이름을 입력해주세요")
        return
      }

      // 컨셉소개 내용 검증
      if (this.updateItem.type == "") {
        alert("쿠폰 종류를 선택해주세요")
        return
      }

      if (!this.updateItem.startAtDate) {
        alert("시작일자를 입력해주세요")
        return
      }

      if (this.updateItem.type === "특정컨셉할인" && this.updateItem.conceptId < 0) {
        alert("컨셉을 선택해 주세요.")
        return
      }

      if (!this.updateItem.endAtDate) {
        alert("종료일자를 입력해주세요")
        return
      }
      console.log(this.updateItem.startAtDate)
      console.log(this.updateItem.endAtDate)

      this.$http
        .post("/api/coupon/updateCoupon", {
          coupon_id: this.updateItem.id,
          type: this.updateItem.type,
          conceptId: this.updateItem.conceptId,
          title: this.updateItem.title,
          title_en: this.updateItem.title_en,
          title_jp: this.updateItem.title_jp,
          description: this.updateItem.description,
          admin_id: this.$store.state.lafia_admin.user_id,
          discount_KRW: Number(this.updateItem.discount_KRW),
          discount_USD: Number(this.updateItem.discount_USD),
          isExpired: this.updateItem.isExpired,
          startAt: this.updateItem.startAtDate ? this.dayjs(this.updateItem.startAtDate).format("YYYY-MM-DD HH:mm:ss") : null,
          expireAt: this.updateItem.endAtDate ? this.dayjs(this.updateItem.endAtDate).format("YYYY-MM-DD HH:mm:ss") : null,
        })
        .then((res) => {
          if (res) {
            alert("정상적으로 등록되었습니다.")
            this.$emit("close")
          } else {
            alert("등록되지 않았습니다")
          }
        })
        .catch((e) => {
          console.log(e)
          alert(this.$t("alert.error_page"))
        })
    }, 500),
  },
}
</script>
<style scoped>
.my-clipper {
  width: 100%;
}

.placeholder {
  width: 100%;
  height: 150px;
}
</style>
