<template>
  <div>
    <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">세라캐슬 매출 관리</font>
    </v-sheet>

    <SaledEachDate v-if="!isDetail" @open="openDetail" />
    <SalesEachTypes v-else :month="selectedMonth" @prev="openList" />
  </div>
</template>
<script>
import statusService from "@/api/stats-service"
import SalesEachTypes from "./components/selaSalesEachTypes"
import SaledEachDate from "./components/selaSelasEachDate"
export default {
  components: {
    SalesEachTypes,
    SaledEachDate,
  },
  data: () => ({
    isDetail: false,
    selectedMonth: 1,
  }),
  created() {
    this.selectedMonth = this.dayjs().month()
  },

  mounted() {},

  methods: {
    openDetail(item) {
      console.log(`openDetail`, item)
      //this.selectedMonth = Number(item.date.split("-")[1]) - 1
      this.selectedMonth = this.dayjs(item.date).month()
      this.isDetail = true
    },
    openList() {
      console.log("open list")
      this.isDetail = false
    },
  },
}
</script>
<style></style>
