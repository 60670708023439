<template>
  <div>
    <!-- 제목 -->
    <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">인쇄 요청 목록</font>
    </v-sheet>

    <!-- 본문 -->
    <v-sheet outlined class="pa-2 ma-2">
      <v-data-table
        :headers="tableHeaders"
        item-key="id"
        :items="items"
        :loading="loading"
        loading-text="Loading... Please wait"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50],
          'show-current-page': true,
          'show-first-last-page': true,
        }"
        :options.sync="options"
        :page.sync="pageNum"
      >
        <template v-slot:[`item.created`]="{ item }">
          <span>{{ dayjs(item.created).format("YYYY.MM.DD HH:mm") }}</span>
        </template>

        <template v-slot:[`item.image`]="{ item }">
          <v-btn outlined color="primary" small @click="openImage(item.imageUrl)"> 이미지보기 </v-btn>
        </template>
      </v-data-table>
    </v-sheet>
  </div>
</template>
<script>
export default {
  data: () => ({
    tableHeaders: [
      { text: "아이디", value: "user_id", sortable: false },
      { text: "컨셉", value: "conceptName", sortable: false },
      { text: "상태", value: "status", sortable: false },
      { text: "이미지보기", value: "image", sortable: false },
      { text: "요청일", value: "created", sortable: false },
      { text: "프린터ID", value: "equipmentName", sortable: false },
    ],

    items: [],
    loading: false,
    totalCount: 0,
    pageNum: 1,

    //pagination
    options: {},
  }),

  watch: {
    options: {
      handler() {
        console.log(this.options)
        this.load(this.options.page, this.options.itemsPerPage)
      },
      deep: true,
    },
  },

  mounted() {
    this.search()
  },

  methods: {
    refresh() {
      this.load(this.options.page, this.options.itemsPerPage)
    },
    search() {
      this.pageNum = 1
      this.load()
    },

    async load(page = 1, itemPerPage = 10) {
      let params = {
        limit: itemPerPage,
        offset: (page - 1) * itemPerPage,
      }

      try {
        this.loading = true
        const result = await this.$http.get("/api/print/getListWithCount", {
          params,
        })
        console.log(result.data)
        this.items = result.data.data
        this.totalCount = result.data.totalCount
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    openImage(imageUrl) {
      var img = new Image()
      img.src = imageUrl
      var img_width = 514
      var win_width = img_width
      var height = 600

      var OpenWindow = window.open("", "_blank", "width=" + img_width + ", height=" + height + ", menubars=no, scrollbars=auto")

      OpenWindow.document.write("<style>body{margin:0px;}</style><img src='" + imageUrl + "' width='" + win_width + "'>")
    },
  },
}
</script>
<style></style>
