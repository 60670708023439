<template>
  <v-card>
    <v-card-title>시작 시간</v-card-title>
    <div class="text-center font-weight-medium text-h6" style="position: relative">
      <span>{{ selectedHour }}시 {{ selectedMinute }}분</span>
      <v-btn style="position: absolute; right: 40px" class="ml-4" depressed color="teal" outlined @click="setCurrentTime">현재</v-btn>
    </div>
    <v-card-text>
      <!-- 시간 -->
      <v-subheader>HOUR(시)</v-subheader>
      <v-chip-group v-model="selectedHourIdx" mandatory active-class="deep-purple--text text--accent-4">
        <v-chip v-for="(time, index) in getHourList" :key="time" :value="index" class="d-flex justify-center py-5">{{ time }} 시</v-chip>
      </v-chip-group>

      <!-- 분 -->
      <v-subheader>MINUTE(분)</v-subheader>
      <div class="d-flex">
        <v-btn color="accent" class="mx-1" v-for="item in quick_minutes" depressed outlined small @click="setQuickMinutes(item)" :key="item">{{ item }}분</v-btn>
      </div>
      <v-slider v-model="selectedMinute" min="0" max="59" :thumb-size="40" thumb-label="always" class="mt-16">
        <template v-slot:prepend>
          <v-icon color="primary" @click="decrementMinutes"> mdi-minus </v-icon>
        </template>
        <template v-slot:append>
          <v-icon color="primary" @click="incrementMinutes"> mdi-plus </v-icon>
        </template>
        <template v-slot:thumb-label="{ value }"> {{ value }}분 </template>
      </v-slider>
    </v-card-text>
    <v-container fluid class="d-flex justify-center" style="gap: 10px">
      <v-checkbox v-model="selctedOptions" label="드레스" value="드레스"></v-checkbox>
      <v-checkbox v-model="selctedOptions" label="귀걸이" value="귀걸이"></v-checkbox>
      <v-checkbox v-model="selctedOptions" label="목걸이" value="목걸이"></v-checkbox>
      <v-checkbox v-model="selctedOptions" label="장갑" value="장갑"></v-checkbox>
      <v-checkbox v-model="selctedOptions" label="머리" value="머리"></v-checkbox>
    </v-container>
    <v-card-actions class="mb-4">
      <v-spacer></v-spacer>
      <v-btn class="px-6" depressed @click="$emit('cancel')">취소</v-btn>
      <v-btn color="primary" class="px-6 mr-10" depressed @click="submit">입장</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    selectedHourIdx: 0,
    selectedMinute: 0,

    selctedOptions: ["드레스"],
    quick_minutes: [0, 10, 20, 30, 40, 50],
  }),
  mounted() {
    this.setCurrentTime()
  },

  computed: {
    selectedHour: function () {
      return Math.min(this.selectedHourIdx + 12, 19)
    },
    getHourList() {
      return [12, 13, 14, 15, 16, 17, 18, 19]
    },
    getMinuteList() {
      return [...Array(60).keys()]
    },
  },

  methods: {
    submit: _.debounce(async function () {
      this.$emit("save", { hour: this.selectedHour, minute: this.selectedMinute, accessories: this.selctedOptions })
    }, 500),

    decrementMinutes() {
      if (this.selectedMinute > 0) {
        this.selectedMinute--
      }
    },
    incrementMinutes() {
      if (this.selectedMinute < 59) {
        this.selectedMinute++
      }
    },

    setQuickMinutes(quick) {
      this.selectedMinute = quick
    },

    setCurrentTime() {
      this.selectedHourIdx = Math.max(this.dayjs().hour() - 12, 0)
      this.selectedMinute = this.dayjs().minute()
    },
  },
}
</script>
<style scoped>
.scrollable {
  height: 272px;
  max-height: 272px;
  overflow-y: auto;
}
</style>
