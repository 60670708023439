<template>
  <div>
    <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">통계 관리</font>
    </v-sheet>

    <v-sheet outlined class="ma-2 pa-2 mb-2 d-flex align-center">
      <span>서비스 선택</span>
      <v-sheet width="300" class="mx-6">
        <v-select :items="service_list" v-model="selectedService" outlined hide-details></v-select>
      </v-sheet>
      <!-- <v-btn depressed color="primary">조회하기 </v-btn> -->
    </v-sheet>
    <v-sheet>
      <SelaDressReservStats v-if="selectedService == 'dressReserve'" />
      <SelaReviewStats v-else-if="selectedService == 'review'" />
      <SelaUserStats v-else-if="selectedService == 'user'" />
      <SelaConceptStats v-else-if="selectedService == 'concept'" />
      <div v-else>Not found!!</div>
    </v-sheet>
  </div>
</template>
<script>
import SelaDressReservStats from "./components/selaDressReservStats"
import SelaReviewStats from "./components/selaReviewStats"
import SelaUserStats from "./components/selaUserStats"
import SelaConceptStats from "./components/selaConceptStats"

export default {
  components: {
    SelaDressReservStats,
    SelaReviewStats,
    SelaUserStats,
    SelaConceptStats,
  },
  data: () => ({
    service_list: [
      {
        text: "드레스 예약 현황",
        value: "dressReserve",
      },
      {
        text: "회원 현황",
        value: "user",
      },
      {
        text: "리뷰 현황",
        value: "review",
      },
      {
        text: "컨셉 촬영 현황",
        value: "concept",
      },
    ],
    selectedService: "dressReserve",
  }),
}
</script>
<style></style>
