<template>
  <div>
    <v-sheet class="pa-2">
      <v-img height="400" :src="'/upload/concept/thumbnail_image/' + concept.thumbnail_image" position="top center" style="position: relative"></v-img>
      <div class="mx-4 text-center">
        <p class="primary--text text-h6 font-weight-bold mt-5 mb-3" style="line-height: 20px">{{ concept.title }}<br /></p>
        <p class="grey--text text--darken-1 text-subtitle-2 font-weight-bold" style="line-height: 20px">
          달성률 <span class="primary--text font-weight-bold mr-4">{{ (concept.new_amount / concept.new_max) * 100 }}%</span> 현재 펀딩금액:
          <span class="grey--text text--darken-1 font-weight-bold">{{ $toComma(concept.new_max) }}</span
          >원
        </p>
      </div>
    </v-sheet>
    <v-divider></v-divider>
    <!-- 목록 -->
    <v-sheet>
      <!-- 탭 제목 -->
      <v-tabs v-model="tabs" color="primary" fixed-tabs>
        <v-tab v-for="item in menu" :key="item" width="50%" class="text-subtitle-1 font-weight-medium">
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>

      <!-- 탭 본문 -->
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <TipTapReader v-if="options.content" class="tiptap_style pa-4" :options="options" />
        </v-tab-item>
        <v-tab-item>
          <v-sheet class="py-16 text-center">
            <v-icon size="48" color="primary" class="mb-3">mdi-close-octagon-outline</v-icon>
            <p class="primary--text">리뷰가 아직 없습니다</p>
          </v-sheet>
          <!-- <div
                        v-for="(item, index) in review_list" :key="index"
                    >
                        <v-sheet
                            class="d-flex align-center mt-2 mb-3 mx-3"
                        >
                            <!~~ 프로필 이미지 ~~>
                            <v-sheet
                                class="mr-2"
                            >
                                <v-img
                                    class="rounded-circle mt-1px"
                                    style="width:20px; height:20px; cursor:pointer;"
                                    :src="'/upload/user/profile_image/' + item.profile_image"
                                ></v-img>
                            </v-sheet>

                            <!~~ 실명 ~~>
                            <span class="text-body-2 font-weight-medium">
                                <font class="primary--text">{{replaceSecondChar(item.name)}}</font>님
                            </span>
                            <v-spacer></v-spacer>

                            <!~~ 작성일 ~~>
                            <span class="text-body-2 grey--text">
                                {{new Date(item.created).toLocaleDateString()}}
                            </span>
                        </v-sheet>

                        <v-sheet
                            class="d-flex mx-2"
                        >
                            <v-sheet class="d-flex" width="100%">
                                <!~~ 리뷰 이미지 ~~>
                                <v-sheet
                                    width="70"
                                    class="mr-2"
                                >
                                    <video
                                        v-if="classifyFileByExtension(item.image) == 'video'"
                                        width="70"
                                        height="90"
                                        style="border-radius: 8px;"
                                    >
                                        <source
                                            :src="'/upload/review/' + item.image"
                                            type="video/mp4"
                                        >
                                        <source
                                            :src="'/upload/review/' + item.image"
                                            type="video/webm"
                                        >
                                        <source
                                            :src="'/upload/review/' + item.image"
                                            type="video/ogg"
                                        >
                                    </video>
                                    <v-img
                                        v-if="classifyFileByExtension(item.image) == 'image'"
                                        class="rounded mb-2 mx-auto"
                                        width="70"
                                        height="90"
                                        :src="'/upload/review/' + item.image"
                                    ></v-img>
                                </v-sheet>

                                <!~~ 내용 ~~>
                                <div
                                    style="position:relative; width:100%;"
                                >
                                    <p
                                        style="white-space:pre-line; font-size:13px; height:34px; line-height:18px; overflow:hidden;"
                                        class="mb-2 mr-1"
                                    >
                                        {{item.content}}
                                    </p>
                                    <p
                                        class="text-caption primary--text text-end mr-1"
                                        style="cursor:pointer; position:absolute; right:4px; bottom:0px;"
                                        @click="openReviewDetail(item)"
                                    >
                                        자세히 보기
                                    </p>
                                </div>
                            </v-sheet>
                        </v-sheet>
                        <v-divider v-if="index != review_list.length"></v-divider>
                    </div>
                    <div class="mt-4 mb-6">
                        <v-pagination
                            v-model="page"
                            :length="1"
                        ></v-pagination>
                    </div>-->
        </v-tab-item>
        <v-tab-item>
          <v-sheet class="pt-6 pb-16 text-center">
            <v-btn color="primary lighten-3" depressed class="rounded-10 mx-1 px-6"> 예약 </v-btn>
            <v-btn color="grey lighten-1" depressed class="rounded-10 mx-1 px-6 white--text"> 촬영 </v-btn>

            <p class="primary--text mt-16">HTML 입력</p>
          </v-sheet>
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>

    <!-- 버튼 -->
    <v-sheet class="d-flex align-center px-6 py-3" style="border-top: 1px solid #dedede">
      <p class="font-weight-medium text-caption grey--text mb-0">펀딩이 성공할 수 있게<br />주변에 공유해주세요!</p>
      <v-spacer></v-spacer>
      <v-btn height="48" class="rounded-lg white--text" depressed color="grey lighten-1 mr-2">공유</v-btn>
      <v-btn width="200" height="48" class="rounded-lg" depressed color="primary lighten-2" @click="bottom.fund = true">펀딩하기</v-btn>
    </v-sheet>

    <v-bottom-sheet v-model="bottom.fund" content-class="rounded-t-xl">
      <v-sheet class="rounded-t-xl pt-4">
        <div class="d-flex px-6 mt-4">
          <div style="width: 50%" class="mr-2">
            <v-img class="rounded-lg" width="100%" :src="'/upload/concept/thumbnail_image/' + concept.pattern_1_image" position="top center" style="position: relative"></v-img>
            <v-sheet class="rounded-lg d-flex align-center pl-4 mt-2 py-3" color="#ffd8d8">
              <v-checkbox class="ma-0 pa-0" v-model="pattern_1" label="A 패턴" hide-details></v-checkbox>
            </v-sheet>
            <v-sheet v-if="pattern_1" class="rounded-lg d-flex align-center pl-4 mt-2 py-3" color="#ffd8d8">
              <v-checkbox class="ma-0 pa-0" v-model="pattern_1_price" label="1:1 보정 (+50,000원)" hide-details></v-checkbox>
            </v-sheet>
          </div>
          <div style="width: 50%">
            <v-img class="rounded-lg" width="100%" :src="'/upload/concept/thumbnail_image/' + concept.pattern_2_image" position="top center" style="position: relative"></v-img>
            <v-sheet class="rounded-lg d-flex align-center pl-4 mt-2 py-3" color="#ffd8d8">
              <v-checkbox class="ma-0 pa-0" v-model="pattern_2" label="B 패턴" hide-details></v-checkbox>
            </v-sheet>
            <v-sheet v-if="pattern_2" class="rounded-lg d-flex align-center pl-4 mt-2 py-3" color="#ffd8d8">
              <v-checkbox class="ma-0 pa-0" v-model="pattern_2_price" label="1:1 보정 (+50,000원)" hide-details></v-checkbox>
            </v-sheet>
          </div>
        </div>
        <p class="text-h6 font-weight-bold primary--text text--lighten-2 text-end mr-4 mt-10">{{ $toComma((pattern_2_price || pattern_1_price ? 50000 : 0) + concept.price) }}원</p>

        <v-sheet class="d-flex align-center px-6 py-3">
          <v-btn width="50%" height="48" class="rounded-lg white--text" depressed color="grey lighten-1 mr-2">장바구니</v-btn>
          <v-btn width="50%" height="48" class="rounded-lg" depressed color="primary lighten-2" @click="bottom.fund = true">펀딩하기</v-btn>
        </v-sheet>
      </v-sheet>
    </v-bottom-sheet>

    <!-- 리뷰 상세보기 (dialog) -->
    <v-dialog v-model="dialog.review.read" max-width="440" content-class="rounded-xl">
      <v-sheet class="px-5 py-6" v-if="selectedItem.image">
        <!-- 제목 -->
        <p class="mb-6 font-weight-bold text-center" style="font-size: 22px">리뷰</p>

        <video v-if="classifyFileByExtension(selectedItem.image) == 'video'" controls="true" width="100%" style="border-radius: 8px">
          <source :src="'/upload/review/' + selectedItem.image" type="video/mp4" />
          <source :src="'/upload/review/' + selectedItem.image" type="video/webm" />
          <source :src="'/upload/review/' + selectedItem.image" type="video/ogg" />
        </video>

        <v-img v-if="classifyFileByExtension(selectedItem.image) == 'image'" class="rounded-10 mb-2" style="aspect-ratio: 70/90" :src="'/upload/review/' + selectedItem.image"></v-img>

        <p style="white-space: pre-line; font-size: 16px; line-height: 20px; overflow: hidden" class="mt-3 mb-6 mx-1">
          {{ selectedItem.content }}
        </p>

        <!-- 버튼 (작성자) -->
        <div v-if="selectedItem.user_id == $store.state.lafia_user.user_id" class="d-flex justify-space-between">
          <!-- 수정 -->
          <v-btn width="31%" depressed color="grey lighten-3" class="font-weight-bold rounded-10 red--text text--lighten-2" @click="updateReviewOpen()"> 수정 </v-btn>

          <!-- 삭제 -->
          <v-btn width="31%" depressed color="grey lighten-3" class="font-weight-bold rounded-10 red--text text--lighten-2" @click="deleteReview()"> 삭제 </v-btn>

          <!-- 닫기 -->
          <v-btn width="31%" depressed color="grey lighten-3" class="font-weight-bold rounded-10" @click="dialog.review.read = false"> 닫기 </v-btn>
        </div>

        <!-- 버튼 (작성자가 아닐시) -->
        <div v-else class="d-flex justify-center">
          <!-- 닫기 -->
          <v-btn width="120" depressed color="grey lighten-3" class="font-weight-bold rounded-10" @click="dialog.review.read = false"> 닫기 </v-btn>
        </div>
      </v-sheet>
    </v-dialog>

    <!-- 리뷰 수정하기 (dialog) -->
    <v-dialog v-model="dialog.review.update" max-width="440" content-class="rounded-xl">
      <v-sheet class="px-5 py-6">
        <!-- 제목 -->
        <p class="mb-6 font-weight-bold text-center" style="font-size: 22px">리뷰 수정</p>

        <v-file-input placeholder="이미지 또는 동영상 (수정)" outlined dense show-size hide-details prepend-icon color="primary" class="rounded-10" v-model="uploadImage">
          <template v-slot:prepend-inner>
            <v-icon class="mr-1" color="primary">mdi-image-filter-hdr</v-icon>
          </template>
        </v-file-input>

        <v-textarea class="mt-2 mb-5 rounded-10" outlined hide-details placeholder="리뷰 내용을 작성해주세요" v-model="review_content" rows="4"></v-textarea>

        <!-- 버튼 -->
        <div class="d-flex justify-center">
          <!-- 취소 -->
          <v-btn width="120" depressed color="grey lighten-3" class="font-weight-bold rounded-10 mx-2" @click="dialog.review.update = false"> 취소 </v-btn>

          <!-- 수정 -->
          <v-btn width="120" depressed color="primary lighten-1" class="font-weight-bold rounded-10 mx-2" @click="reviewSubmit()"> 수정 </v-btn>
        </div>
      </v-sheet>
    </v-dialog>
  </div>
</template>
<script>
import TipTapReader from "@/components/tiptap/Reader"

export default {
  components: {
    TipTapReader,
  },

  data: () => ({
    tabs: null,
    menu: ["상세보기", "리뷰", "문의"],

    //TipTap
    options: {
      content: "",
      readonly: true,
      editable: true,
      supportImage: true,
      supportVideo: true,
    },

    page: 1,

    concept: {},

    review_list: [],

    selectedItem: {},

    dialog: {
      review: {
        read: false,
        update: false,
      },
    },

    pattern_1: false,
    pattern_1_price: false,
    pattern_2: false,
    pattern_2_price: false,

    uploadImage: {},

    review_content: "",

    bottom: {
      fund: false,
    },
  }),

  watch: {
    pattern_1() {
      if (this.pattern_1) {
        this.pattern_2 = false
        this.pattern_2_price = false
      }
    },

    pattern_2() {
      if (this.pattern_2) {
        this.pattern_1 = false
        this.pattern_1_price = false
      }
    },
  },

  mounted() {
    // 불러오기
    this.load()

    // 리뷰 목록 불러오기
    this.loadReviewList()
  },

  methods: {
    classifyFileByExtension(filename) {
      // 파일 확장자를 소문자로 추출합니다.
      const ext = filename.split(".").pop().toLowerCase()

      // 주요 이미지 파일 확장자 목록입니다.
      const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"]

      // 주요 동영상 파일 확장자 목록입니다.
      const videoExtensions = ["mp4", "avi", "mkv", "webm", "mov", "wmv"]

      // 확장자를 확인하여 파일을 분류합니다.
      if (imageExtensions.includes(ext)) {
        return "image"
      } else if (videoExtensions.includes(ext)) {
        return "video"
      }
    },

    // 불러오기
    load() {
      this.$http
        .post("/api/concept/select/specific", {
          params: {
            id: this.$route.query.id,
          },
        })
        .then((res) => {
          this.concept = res.data[0]
          this.options.content = res.data[0].content
        })
    },

    // 리뷰 목록 불러오기
    loadReviewList() {
      this.$http
        .post("/api/review/select/room", {
          params: {
            room_id: this.$route.query.id,
          },
        })
        .then((res) => {
          this.review_list = res.data
        })
    },

    // 문자열 모자이크
    replaceSecondChar(str) {
      if (str.length >= 2) {
        return str[0] + "*" + str.substring(2)
      } else {
        return str
      }
    },

    // 리뷰 상세보기
    openReviewDetail(item) {
      this.selectedItem = item
      this.dialog.review.read = true
    },

    // 리뷰 수정하기 열기
    updateReviewOpen() {
      this.dialog.review.update = true
      this.review_content = this.selectedItem.content
    },

    // 리뷰 수정하기 제출
    reviewSubmit: _.debounce(async function () {
      // 이미지가 있을 경우
      if (this.uploadImage.name) {
        var file = this.uploadImage
        var formData = new FormData()
        formData.append("image", file)

        // 이미지 업로드
        await this.$http
          .post("/api/image/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            params: {
              type: "review",
            },
          })
          .then((res) => {
            // DB 저장
            this.$http
              .post("/api/review/update", {
                params: {
                  image: res.data,
                  content: this.review_content,
                  id: this.selectedItem.id,
                },
              })
              .then((res) => {
                if (res.data.affectedRows) {
                  this.loadReviewList()
                  this.dialog.review.read = false
                  this.dialog.review.update = false
                }
              })
          })
        // 이미지가 없을 경우
      } else {
        // DB 저장
        this.$http
          .post("/api/review/update/only/content", {
            params: {
              content: this.review_content,
              id: this.selectedItem.id,
            },
          })
          .then((res) => {
            if (res.data.affectedRows) {
              this.loadReviewList()
              this.dialog.review.read = false
              this.dialog.review.update = false
            }
          })
      }
    }, 500),

    // 리뷰 삭제하기
    deleteReview() {
      this.$http
        .post("/api/review/delete", {
          params: {
            id: this.selectedItem.id,
          },
        })
        .then((res) => {
          if (res.data.affectedRows) {
            this.$http
              .post("/api/order/update/status", {
                params: {
                  status: "접수완료",
                  id: this.selectedItem.order_id,
                },
              })
              .then((res) => {
                if (res.data.affectedRows) {
                  this.loadReviewList()
                  this.dialog.review.read = false
                }
              })
          }
        })
    },
  },
}
</script>
<style scoped>
.test {
  /* background: url('~@/assets/list_main.svg'); */
  background-size: 100%;
}
</style>
