<template>
  <div>
    <v-divider></v-divider>

    <v-sheet>
      <div class="d-flex justify-center align-center py-2 px-4">
        <span>{{ $t(operationGuideText) }}</span>
        <span>
          <v-img src="@/assets/twinkle-star.svg" width="36" />
        </span>
      </div>
      <PSDEditorView :editItem="editItem" :guideImage="guideImage" :document="layer" ref="editor" v-if="layer" :onload="onLayerImageLoaded" />
      <div class="d-flex justify-space-between align-center py-2 px-4">
        <v-btn outlined class="sela deactivate largeText" width="33%" @click="showGuideSample = !showGuideSample" :disabled="concept == null"> {{ $t(sampleGuideText) }} </v-btn>
        <v-btn outlined class="sela deactivate largeText" width="30%" @click="$refs.editor.flipX()"> {{ $t("page.mypage.picture.flip-left-right-btn-title") }} </v-btn>
        <v-btn outlined class="sela largeText" width="30%" @click="showConfirm = true"> {{ $t("page.mypage.picture.work-complete-btn-title") }} </v-btn>
      </div>

      <ConfirmDialog @confirm="submit" @cancel="showConfirm = false" :visible="showConfirm" :message="$t('dialog.edit-confirm')" />
    </v-sheet>

    <div v-if="isLoading" style="position: absolute; display: flex; width: 100%; height: 100%; justify-content: center; align-items: center; left: 0; top: 0; background-color: rgba(0, 0, 0, 0.5)">
      <v-progress-circular color="white" indeterminate></v-progress-circular>
    </div>
  </div>
</template>
<script>
import PSDEditorView from "./components/EditPicture/PSDEditorView"
import ConfirmDialog from "@/views/components/ConfirmDialog"
import { fetchPSDLayer, newImageFileFromPSDLayer, getLayerImageCount } from "@/models/PSDLayer"
import { psdLayerToEditablePSDLayer, editablePSDLayerToPSDLayer } from "@/models/PSDEditLayer"
import { fetchConceptByTitle } from "@/models/Concept"
import { fetchMyPictureItem } from "@/models/MyPicture"
import { updateMyPictureItem } from "@/models/MyPicture"
import { isMobile } from "@/util/utils"

export default {
  components: {
    PSDEditorView,
    ConfirmDialog,
  },

  props: {},

  computed: {
    guideImage() {
      if (this.concept) {
        return this.showGuideSample ? this.concept.imageUrl : null
      } else {
        return null
      }
    },
    operationGuideText() {
      return isMobile() ? "page.mypage.picture.operation-guide-text-mobile" : "page.mypage.picture.operation-guide-text-desktop"
    },
    sampleGuideText() {
      return this.showGuideSample ? "page.mypage.picture.my-picture-view-btn-title" : "page.mypage.picture.sample-view-btn-title"
    },
  },

  data: () => ({
    isLoading: true,
    showGuideSample: false,
    showConfirm: false,
    editItem: null,
    concept: null,
    layer: null,
    imageCount: 0,
  }),

  mounted() {
    this.load()
  },

  methods: {
    onLayerImageLoaded() {
      --this.imageCount
      if (this.imageCount <= 0) {
        this.isLoading = false
      }
    },

    async load() {
      try {
        setTimeout(function () {
          // This hides the address bar:
          window.scrollTo(0, 1)
        }, 0)
        this.editItem = await fetchMyPictureItem(this, this.$route.params.id)
        const layer = await fetchPSDLayer(this.editItem)
        this.imageCount = getLayerImageCount(layer)
        this.layer = psdLayerToEditablePSDLayer(layer)
        this.concept = await fetchConceptByTitle(this, this.editItem.title)
      } catch (e) {
        console.error(e)
        alert(this.$t("alert.error_failed_get_information"))
        this.$router.back()
      } finally {
      }
    },

    async submit() {
      try {
        this.showConfirm = false
        this.isLoading = true
        await updateMyPictureItem(this, this.editItem.id, await newImageFileFromPSDLayer(this.layer, this.editItem.filename), editablePSDLayerToPSDLayer(this.layer))
        //this.$router.go(-2)
        this.$router.replace(`/mypage/home?type=picture`)
      } catch (e) {
        console.error(e)
        alert(this.$t("alert.error_failed_save_information"))
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
