<template>
  <v-card class="d-flex flex-column justify-center align-center">
    <div style="position: absolute; right: 10px; top: 10px">
      <v-btn depressed fab small @click="$emit('close')">
        <v-icon size="28">mdi-close</v-icon>
      </v-btn>
    </div>
    <v-card-title>{{ info.type }} &nbsp; 결제 요청 </v-card-title>
    <v-divider></v-divider>
    <v-card-subtitle class="my-2 pa-0"> 아래 정보를 확인하세요</v-card-subtitle>
    <v-card-text>
      <table class="table mt-4">
        <tr>
          <th>아이디</th>
          <th style="min-width: 60px">이름</th>
          <th>방번호</th>
        </tr>
        <tr>
          <td class="font-weight-medium black--text text-body-1">{{ info.user_id }}</td>
          <td class="font-weight-medium black--text text-body-1">{{ info.user_name }}</td>
          <td>
            {{ info.roomNo }}
          </td>
        </tr>
      </table>
      <v-sheet class="text-center mt-6">
        <div>
          결제금액 : <span class="font-danjunghae mx-2" style="font-size: 24px">{{ $toComma(info.pay_amount) }}</span
          >원
        </div>
      </v-sheet>
    </v-card-text>
    <v-card-actions class="mb-4">
      <v-btn width="140px" color="warning" large class="px-8" depressed @click="cancel" :loading="saving">결제요청 취소</v-btn>
      <v-btn width="140px" color="success" large class="px-4" depressed @click="submit" :loading="saving">결제 확인 및 저장</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapState } from "vuex"

export default {
  props: ["info"],
  data: () => ({
    saving: false,
  }),
  mounted() {},

  computed: {
    ...mapState(["lafia_admin"]),

    internalType() {
      switch (this.info.type) {
        case "키오스크컨셉구매":
          return "현장컨셉구매"
        case "키오스크추가촬영":
          return "현장추가촬영"
        case "키오스크추가보정신청":
          return "현장추가보정신청"
        default:
          return "현장기타"
      }
    },
  },
  methods: {
    submit: _.debounce(async function () {
      if (!this.info || !this.info.id) {
        alert("잘못된 항목입니다.")
        return
      }
      this.saving = true

      let orderId = Math.floor(Math.random() * 1000000000)
      const orderRes = await this.$http.post("/api/order/v2/order", {
        orderId: orderId.toString(),
        userId: this.info.user_id,
        orderDetailList: [
          {
            productName: "현장" + this.info.type,
            orderNum: 1,
            price: this.info.price,
            totalPrice: this.info.price,
            finalPrice: this.info.pay_amount,
            couponId: this.info.couponId,
          },
        ],
        memo: "",
        type: this.internalType,
        isSite: true,
      })

      // 주문 정보를 저장
      await this.$http.post("/api/qrcode/pay/setOrderId", {
        pay_qrcode: this.info.pay_qrcode,
        orderListId: orderRes.data.id,
      })

      await this.$http.post("/api/payments/offline/payComplete", {
        id: this.info.id,
        order_list_id: orderRes.data.id,
        pay_amount: this.info.pay_amount,
        admin_id: this.lafia_admin.user_id,
        couponName: this.info.couponName,
      })

      if (this.info.couponId) {
        await this.$http.post("/api/coupon/useCoupon", {
          user_id: this.info.user_id,
          registered_coupon_id: this.info.couponId,
        })
      }

      this.saving = false
      this.$emit("close")
    }, 500),

    cancel: _.debounce(async function () {
      if (!this.info || !this.info.id) {
        alert("잘못된 항목입니다.")
        return
      }
      this.saving = true
      await this.$http.post("/api/payments/offline/payCancel", {
        id: this.info.id,
        pay_qrcode: this.info.pay_qrcode,
        admin_id: this.lafia_admin.user_id,
      })
      this.saving = false
      this.$emit("close")
    }),
  },
}
</script>
<style scoped>
.table {
  width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;
  text-align: center;
}
tr th {
  padding: 4px 8px;
  height: 64px;
  border: 1px solid #ccc;
  border-collapse: collapse;
  background: rgba(195, 126, 120, 0.15);
}
tr td {
  height: 64px;
  vertical-align: middle;
  margin-top: auto;
  margin-bottom: auto;
  border-collapse: collapse;
  border: 1px solid #ccc;
}
.timeSelectBtn {
  border: 1px solid lightgray;
  color: #515151;
  font-size: 16px;
  font-weight: normal;
  margin: 0 3px;
  background-color: white !important;
}
.enable {
  background: #8d75cb !important;
  color: white !important;
}
</style>
