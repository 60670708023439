<template></template>
<script>
import { mapMutations } from "vuex"

export default {
  methods: {
    ...mapMutations(["logout"]),
  },
  created() {
    this.$gtag.event("logout")
    this.logout()
    this.$router.replace("/")
  },
}
</script>
