<template>
  <v-sheet :width="width" class="d-flex justify-space-between">
    <!-- 년 -->
    <div class="d-flex align-center" v-if="!isEng">
      <!-- < -->
      <v-icon size="24" :color="color" @click="changeYear(-1)"> mdi-chevron-left </v-icon>
      <v-select
        style="max-width: 60px"
        hide-details
        dense
        flat
        rounded
        append-icon=""
        :items="getYearArray()"
        v-model="selected_year"
        item-text="text"
        item-value="value"
        :key="yearComponentKey"
      ></v-select>
      <v-icon size="24" :color="color" @click="changeYear(1)"> mdi-chevron-right </v-icon>
    </div>

    <!-- 월 -->
    <div class="d-flex align-center">
      <v-icon size="24" :color="color" @click="changeMonth(-1)"> mdi-chevron-left </v-icon>
      <v-select
        style="max-width: 60px"
        hide-details
        dense
        flat
        rounded
        append-icon=""
        :items="getMonthArray()"
        v-model="selected_month"
        item-text="text"
        item-value="value"
        :key="monthComponentKey"
      ></v-select>
      <v-icon size="24" :color="color" @click="changeMonth(1)"> mdi-chevron-right </v-icon>
    </div>

    <!-- 일 -->
    <div class="d-flex align-center">
      <v-icon size="24" :color="color" @click="changeDay(-1)"> mdi-chevron-left </v-icon>
      <v-select
        style="max-width: 60px"
        hide-details
        dense
        flat
        rounded
        append-icon=""
        :items="getDayArray()"
        v-model="selected_day"
        item-text="text"
        item-value="value"
        :key="dayComponentKey"
      ></v-select>
      <v-icon size="24" :color="color" @click="changeDay(1)"> mdi-chevron-right </v-icon>
    </div>

    <!-- 년 -->
    <div class="d-flex align-center" v-if="isEng">
      <!-- < -->
      <v-icon size="24" :color="color" @click="changeYear(-1)"> mdi-chevron-left </v-icon>
      <v-select
        style="max-width: 60px"
        hide-details
        dense
        flat
        rounded
        append-icon=""
        :items="getYearArray()"
        v-model="selected_year"
        item-text="text"
        item-value="value"
        :key="yearComponentKey"
      ></v-select>
      <v-icon size="24" :color="color" @click="changeYear(1)"> mdi-chevron-right </v-icon>
    </div>
  </v-sheet>
</template>
<script>
import { mapState } from "vuex"

export default {
  props: ["color", "width", "dateInit"],

  data: () => ({
    date: null,
    selected_day: 0,
    selected_month: 0,
    selected_year: 2024,

    dayComponentKey: 0,
    monthComponentKey: 0,
    yearComponentKey: 0,
  }),

  created() {
    this.date = this.dateInit ? this.dateInit : this.dayjs()
    if (this.date.hour() > 19) {
      this.date = this.date.add(1, "day")
    }

    this.selected_day = this.date.get("date")
    this.selected_month = this.date.get("month")
    this.selected_year = this.date.get("year")

    this.dayComponentKey++
    this.monthComponentKey++
    this.yearComponentKey++

    // 솔로프리미엄 예약하기시 하루 추가
    // if (this.$route.path == "/reservation/buy" && this.$route.query.category == "솔로프리미엄") {
    //   this.date = this.date.add(1, "day")
    // }
  },
  computed: {
    ...mapState(["lang"]),
    isEng() {
      return this.lang === "en" ? true : false
    },
  },

  watch: {
    date: {
      handler(newVal, oldVal) {
        if (!newVal.isSame(oldVal, "day")) {
          this.$emit("dateUpdated", this.date)
          if (this.date.get("date") !== this.selected_day) {
            this.selected_day = this.date.get("date")
          }
          if (this.date.get("year") !== this.selected_year) {
            this.selected_year = this.date.get("year")
          }
          if (this.date.get("month") !== this.selected_month) {
            this.selected_month = this.date.get("month")
          }
          this.dayComponentKey++
          this.monthComponentKey++
          this.yearComponentKey++
        }
      },
      immediate: false,
    },
    selected_day: function (val) {
      this.date = this.date.set("date", val)
    },
    selected_month: function (val) {
      this.date = this.date.set("month", val)
    },
    selected_year: function (val) {
      this.date = this.date.set("year", val)
    },
  },
  methods: {
    changeDay(increase) {
      if (increase > 0) {
        this.date = this.date.add(1, "day")
      } else {
        this.date = this.date.subtract(1, "day")
      }
    },
    changeMonth(increase) {
      if (increase > 0) {
        this.date = this.date.add(1, "month")
      } else {
        this.date = this.date.subtract(1, "month")
      }
    },
    changeYear(increase) {
      if (increase > 0) {
        const year = this.date.get("year")
        if (year <= 2024) {
          this.date = this.date.add(1, "year")
        }
      } else {
        const year = this.date.get("year")
        if (year > 2024) {
          this.date = this.date.subtract(1, "year")
        }
      }
    },
    getDayArray() {
      //日
      let startAt = 1
      let lastDay = this.date.endOf("month").get("date")
      //if (this.date.format("YYYY-MM") === this.dayjs().format("YYYY-MM")) {
      //  startAt = this.dayjs().date()
      //}
      let size = Math.max(lastDay - startAt + 1, 1)
      let dayUnit = this.lang === "jp" ? "日" : this.lang === "ko" ? "일" : ""

      return [...Array(size).keys()].map((i) => ({ text: `${i + startAt}${dayUnit}`, value: i + startAt }))
    },
    getMonthArray() {
      if (this.lang === "jp" || this.lang === "ko") {
        //月
        let monthUnit = this.lang === "jp" ? "月" : "월"
        return [...Array(12).keys()].map((i) => ({ text: `${i + 1}${monthUnit}`, value: i }))
      } else {
        let engMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        return [...Array(12).keys()].map((i) => ({ text: engMonths[i], value: i }))
      }
    },
    getYearSuffix() {
      let suffix = ""
      switch (this.lang) {
        case "ko":
          suffix = "년"
          break
        case "jp":
          suffix = "年"
          break
      }
      return suffix
    },
    getYearArray() {
      let suffix = this.getYearSuffix()
      return [
        {
          text: "2024" + suffix,
          value: 2024,
        },
        {
          text: "2025" + suffix,
          value: 2025,
        },
      ]
    },
  },
}
</script>
<style scoped>
.v-icon:hover:after {
  background: none !important;
}
::v-deep .v-input__control > .v-input__slot {
  margin: 0px !important;
  padding: 0px !important;
  justify-content: center;
  background-color: #8d75cb;
  width: 60px;
  height: 40px;
  border-radius: 0.625rem !important;
}
::v-deep .v-select__selections {
  justify-content: center;
  color: white !important;
  font-size: 0.875rem !important;
}

::v-deep .v-select__selections input {
  display: none !important;
}
::v-deep .v-select__selection--comma {
  margin: 0px !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
}
::v-deep .v-input {
  margin-top: 0px;
}
</style>
