<template>
  <v-dialog width="660" :value="visible" @input="$emit('input', $event)" content-class="rounded-xl" style="z-index: 15">
    <v-sheet class="pa-6" min-height="400" style="position: relative">
      <p class="text-h6 font-weight-bold grey--text text--darken-3 text-center mb-6">쿠폰 선택</p>
      <div style="position: absolute; right: 20px; top: 20px">
        <v-btn large depressed text @click="$emit('input', false)">
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
      </div>
      <v-sheet class="mt-2 px-4">
        <v-row align="center" justify="start">
          <v-chip class="ma-1 py-8" v-for="coupon in coupons_info" :key="coupon.id" color="success" outlined @click.stop="addCoupon(coupon)">
            <div class="d-flex flex-column">
              <span class="text-center">{{ coupon.title }}</span>
              <span class="text-center black--text">{{ $toComma(coupon.discount_KRW) }}원</span>
            </div>
          </v-chip>
        </v-row>
      </v-sheet>
    </v-sheet>
  </v-dialog>
</template>
<script>
export default {
  inject: ["coupons_info"],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    coupons: [],
  }),

  mounted() {},

  methods: {
    addCoupon(coupon) {
      this.$emit("addCoupon", coupon)
    },
  },
}
</script>
<style></style>
