<template>
  <div>
    <!-- 제목 -->
    <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">직원 관리</font>
    </v-sheet>

    <!-- 본문 -->
    <v-sheet outlined class="pa-2 ma-2">
      <!-- 목록 -->
      <v-radio-group v-model="selected" hide-details>
        <v-data-table
          hide-default-header
          :items="list"
          :footer-props="{
            'items-per-page-options': [10, 20, 100, -1],
            itemsPerPageText: '페이지당 직원 수',
          }"
          no-data-text=""
        >
          <template v-slot:header>
            <tr>
              <th style="width: 52px"></th>
              <th style="width: 100px">유형</th>
              <th style="width: 200px">이름</th>
              <th style="width: 150px">아이디</th>
              <th style="width: 100px">휴대폰번호</th>
              <th style="width: 100px">알림톡수신</th>
              <th style="width: 200px">마지막 로그인 시간</th>
            </tr>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <v-radio class="ml-1" hide-details dense :value="item" @change="selectedItem = item"></v-radio>
              </td>
              <td class="text-truncate">
                {{ item.type }}
              </td>
              <td class="text-truncate">
                {{ item.name }}
              </td>
              <td class="text-truncate">
                {{ item.user_id }}
              </td>
              <td class="text-truncate">
                {{ item.phoneNumber }}
              </td>
              <td class="text-truncate">
                {{ item.notification ? "Y" : "N" }}
              </td>
              <td class="text-truncate">
                {{ new Date(item.last_login).toLocaleString() }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-radio-group>

      <!-- 버튼 -->
      <v-sheet class="d-flex justify-end mt-2 pa-2" outlined>
        <v-btn class="px-6 ml-3" color="orange" large dark depressed @click="dialog.update = true">
          <v-icon small left>mdi-circle-edit-outline</v-icon>
          수정
        </v-btn>
        <v-btn class="px-6 ml-3" color="blue" large dark depressed @click="createItem()">
          <v-icon small left>mdi-shape-circle-plus</v-icon>
          추가
        </v-btn>
        <v-btn class="px-6 ml-3" color="red" large dark depressed @click="deleteItem()">
          <v-icon small left>mdi-close-circle-outline</v-icon>
          삭제
        </v-btn>
      </v-sheet>
    </v-sheet>

    <!-- 수정 (dialog) -->
    <v-dialog v-model="dialog.update" width="auto" content-class="rounded-xl">
      <Update :selectedItem="selectedItem" @close="dialog.update = false" @updated="load" :key="componentKey" />
    </v-dialog>

    <!-- 추가 (dialog) -->
    <v-dialog v-model="dialog.insert" width="auto" content-class="rounded-xl">
      <Insert @close="dialog.insert = false" @inserted="load" :key="componentCreateKey" />
    </v-dialog>
  </div>
</template>
<script>
import Update from "./staff/Update"
import Insert from "./staff/Insert"

export default {
  components: {
    Update,
    Insert,
  },

  data: () => ({
    list: [],
    selected: false,
    selectedItem: {},

    dialog: {
      update: false,
      insert: false,
    },

    componentKey: 0,
    componentCreateKey: 0,
  }),

  mounted() {
    // 목록
    this.load()
  },

  watch: {
    selectedItem() {
      this.componentKey++
    },
  },

  methods: {
    // 목록
    load() {
      this.$http.post("/api/staff/select").then((res) => {
        if (res.data.length) {
          this.list = res.data
        }
      })
    },

    createItem() {
      this.componentCreateKey++
      this.dialog.insert = true
    },

    // 삭제하기
    deleteItem() {
      if (!Object.keys(this.selectedItem).length) {
        alert("선택한 직원이 없습니다")
      } else {
        if (confirm("정말 해당 직원을 삭제하겠습니까?")) {
          this.$http
            .post("/api/staff/delete", {
              params: {
                id: this.selectedItem.id,
              },
            })
            .then((res) => {
              if (res.data.affectedRows) {
                this.load()
                this.selected = false
                this.selectedItem = {}
              } else {
                alert("해당 직원을 삭제하는 도중 오류가 발생하였습니다.")
              }
            })
        }
      }
    },
  },
}
</script>
