<template>
  <v-card class="mt-3" flat>
    <v-sheet class="" style="position: relative">
      <v-row class="flex-child guide" :class="{ small: $vuetify.breakpoint.mobile }" dense no-gutters>
        <!-- <v-col cols="4" md="5" class="pa-4 pa-sm-6">
          <v-img width="100%" src="@/assets/concept/concept-booth-title-img.png"></v-img>
        </v-col> -->
        <v-col cols="12" class="pl-1 pl-sm-2 pr-1 pr-sm-2 d-flex justify-center lucid-card" :class="{ small: $vuetify.breakpoint.mobile }">
          <v-sheet class="d-flex flex-column align-center desc" :class="{ small: $vuetify.breakpoint.mobile }">
            <span
              class="primary--text font-weight-medium text-center text-body-1 text-md-h6"
              style="margin-top: 2px"
              v-html="$t('page.conceptbooth.box-intro-1')"
              :style="{ fontSize: $vuetify.breakpoint.mobile ? '16px' : '20px', lineHeight: $vuetify.breakpoint.mobile ? '0.9' : '1' }"
            >
            </span>
            <span class="my-1" style="color: #595755; line-height: 1" :style="{ fontSize: $vuetify.breakpoint.mobile ? '11px' : '14px' }">
              {{ $t("page.conceptbooth.box-intro-2") }}
            </span>
          </v-sheet>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet>
      <v-slide-group v-model="tag" mandatory show-arrows>
        <v-slide-item v-for="item in tags" v-slot="{ active, toggle }" :key="item.id" class="ma-2">
          <v-btn :color="active ? '#d9bddd' : 'white'" class="mx-1 rounded-1" :class="active ? 'active' : 'not-selected'" depressed :elevation="0" @click="toggle" small>{{
            $t(`tags.${item.id}`)
          }}</v-btn>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>

    <v-row v-if="list.length" no-gutters>
      <v-col v-for="(item, index) in list" :key="item.id" cols="6" class="py-2" :class="index % 2 == 0 ? 'pr-1' : 'pl-1'">
        <v-card :to="'/castle/' + item.id" flat>
          <!-- 이미지 -->
          <v-img :lazy-src="require('@/assets/placeholder.jpg')" :src="'/upload/concept/thumbnail_image/' + item.pattern_1_image" position="top center" aspect-ratio="0.83">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0 align-center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-sheet class="d-flex justify-end align-center mt-1 mt-md-2 px-2">
            <!-- tag -->
            <div class="d-inline-flex" v-for="tag in getTags(item.tag)" :key="tag">
              <v-chip style="margin-left: 1px; padding-left: 4px; padding-right: 4px" color="grey" :ripple="false" label outlined x-small pill>{{ getConceptTagStr(tag) }}</v-chip>
            </div>
            <span class="px-1 px-sm-2 text-caption text-sm-body-2" style="color: #545454">{{ getTitle(item) }}</span>
            <!-- <span class="pl-1 pl-sm-2 text-caption text-sm-body-2" color="grey">{{ $toComma(item.price) }}원</span> -->
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
    <v-sheet v-if="!list.length" class="d-flex justify-center align-center font-weight-medium" style="min-height: 100px"> {{ $t("page.conceptbooth.concept_not_exists") }} </v-sheet>
  </v-card>
</template>
<script>
import { AllTag, Tags } from "@/constant"
import { mapActions, mapState } from "vuex"

export default {
  data: () => ({
    tags: [AllTag, ...Tags],
    list: [],
    category: "무인촬영",
    tag: null,

    loading: false,
  }),
  computed: {
    ...mapState(["lang"]),
  },
  mounted() {
    this.load()
  },
  watch: {
    async category() {
      await this.load()
    },

    async tag() {
      await this.load()
    },
  },
  methods: {
    ...mapActions(["loadConcepts"]),
    // 목록 불러오기
    load() {
      this.loadConcepts().then((concepts) => {
        if (concepts.length) {
          this.list = concepts
          if (this.tag !== 0) {
            const tagStr = this.tags[this.tag].title
            this.list = this.list.filter((e) => e.tag.includes(tagStr))
          }
        }
      })
      /*
      this.$http.post("/api/concept/select").then((res) => {
        if (res.data.length) {
          this.list = res.data

          // this.list = this.list.filter((e) => e.category.includes(this.category))

          if (this.tag !== 0) {
            const tagStr = this.tags[this.tag].title
            this.list = this.list.filter((e) => e.tag.includes(tagStr))
          }

          //if (this.tag != "전체") {
          // this.list = this.list.filter((e) => e.tag.includes(this.tag))
          //}
        }
      })*/
    },

    getTags(tagStr) {
      if (!tagStr) {
        return []
      }
      return tagStr.split(",")
    },

    getConceptTagStr(tag) {
      const tagKey = Tags.find((t) => t.title === tag).id
      return tagKey ? this.$t(`tags.${tagKey}`) : tag
    },

    getTitle(item) {
      switch (this.lang) {
        case "en":
          return item.title_en || item.title
        case "jp":
          return item.title_jp || item.title
        default:
          return item.title
      }
    },
  },
}
</script>
<style scoped>
::v-deep .v-slide-group__prev,
::v-deep .v-slide-group__next {
  min-width: 20px !important;
}
::v-deep .v-slide-group__content {
  justify-content: center;
}
.v-btn {
  border: 2px solid rgb(223, 223, 223) !important;
}
.active {
  color: black;
}
.not-selected {
  color: rgb(141, 141, 141);
}

.guide {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 96%;
  height: 360px;
  aspect-ratio: 2.4;
  background-image: url("~@/assets/concept/concept_border.png");
  background-size: contain;
}

.guide.small {
  height: 260px !important;
}

.lucid-card {
  background-size: cover;
  background-image: url("~@/assets/concept/lucid-card.png");
  background-size: 90%;
  background-position: 50% 10%;
}

.lucid-card .desc {
  background-color: transparent;
  margin-top: 90px;
}

.lucid-card .desc.small {
  margin-top: 62px !important;
}

.lucid-card.small {
  background-position: 50% 12%;
}
</style>
