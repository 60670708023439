<template>
  <v-sheet class="d-flex justify-space-between align-center px-4 py-4" :style="$route.path === '/' ? 'background-color: transparent' : 'background-color: white'">
    <!-- 메뉴 -->
    <v-icon size="32" :color="$route.path === '/' ? 'black' : 'grey darken-4'" @click.stop="menuBtnClick()"> {{ isMenu ? "mdi-menu" : "mdi-arrow-left" }} </v-icon>

    <!-- 로고 -->
    <v-img width="156" height="24" style="cursor: pointer" class="shrink" contain :src="require('@/assets/logo/logo-title.png')" @click="link('/')"></v-img>

    <!-- 계정 -->
    <div>
      <v-icon v-if="!lafia_user.is_logined" size="28" style="cursor: pointer" @click="link('/auth/login')" color="black"> mdi-account-circle-outline </v-icon>

      <div v-if="lafia_user.is_logined">
        <v-img class="rounded-circle" style="width: 28px; height: 28px; cursor: pointer" :src="lafiaUser_profile_img_path" @click="link('/mypage/setting')"></v-img>
      </div>
    </div>

    <!-- 사이드바 -->
    <v-navigation-drawer v-model="drawer" height="100vh" absolute temporary>
      <v-list nav class="mt-16">
        <v-list-item-group v-model="group">
          <v-list-item>
            <v-list-item-title @click="link('/')">
              <span class="font-weight-medium">{{ $t("sidemenu.main") }}</span>
            </v-list-item-title>
          </v-list-item>

          <!-- <v-list-item>
            <v-list-item-title @click="link('/board')">
              <span class="font-weight-medium">후기/팁</span>
              <span class="ml-2 text-caption">Review/Tip</span>
            </v-list-item-title>
          </v-list-item> -->

          <v-list-item>
            <v-list-item-title @click="link('/location')">
              <span class="font-weight-medium">{{ $t("sidemenu.location") }}</span>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-divider class="mt-6 mb-2 mx-2"></v-divider>
      <LanguageSelectComponentVue />

      <!-- <div style="position: fixed; bottom: 0px; width: 100%"></div> -->
      <div :class="['logo', { 'mobile-bottom': $vuetify.breakpoint.mobile }]">
        <v-img class="mt-6 mx-auto" width="55%" src="@/assets/logo/menu-logo.png"></v-img>
        <p class="mt-2 grey--text px-4 text-caption text-center">ver {{ appVer }}</p>
      </div>
    </v-navigation-drawer>
  </v-sheet>
</template>
<script>
import LanguageSelectComponentVue from "@/components/LanguageSelectComponent.vue"
import { mapState, mapGetters, mapMutations } from "vuex"

import { AppVersion, isMenuPage } from "../../constant"

export default {
  components: {
    LanguageSelectComponentVue,
  },
  data: () => ({
    drawer: false,
    group: null,

    appVer: AppVersion,
  }),
  computed: {
    ...mapState(["lafia_user"]),
    ...mapGetters(["lafiaUser_profile_img_path"]),

    path() {
      return this.$route.path
    },
    isMenu() {
      return isMenuPage(this.$route.path)
    },
  },

  methods: {
    link(url) {
      if (this.$route.path != url) {
        this.$router.push(url)
      }
    },
    menuBtnClick() {
      if (this.isMenu) {
        this.drawer = !this.drawer
      } else {
        console.log("history.length", window.history.length)
        if (window.history.length > 1) {
          this.$router.back()
        } else {
          this.$router.replace("/")
        }
      }
    },
  },
}
</script>
<style scoped>
.v-btn::before {
  background-color: white !important;
}
.logo {
  position: fixed;
  width: 100%;
  bottom: 70px;
}
.mobile-bottom {
  bottom: 120px;
}
.v-navigation-drawer {
  z-index: 9999 !important;
}
</style>
