<template>
  <div class="d-flex justify-center align-center">
    <img src="@/assets/warning.png" />
    <slot></slot>
  </div>
</template>
<script>
export default {}
</script>
<style scoped>
img {
  background: white;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
</style>
