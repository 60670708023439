<template>
  <div>
    <v-img src="@/assets/deco/card-top-deco.png" max-width="240px" class="mx-auto" />

    <div class="text-center primary--text text-h6 font-weight-medium">{{ $t("page.reservation.early-title") }}</div>

    <div class="text-center text-body-2">{{ $t("page.reservation.early-subtitle") }}</div>

    <!-- 얼리 패키지 상품 리스트 -->
    <early-product-list-item />
  </div>
</template>
<script>
import EarlyProductListItem from "./EarlyProductListItem.vue"
export default {
  components: { EarlyProductListItem },
}
</script>
<style></style>
