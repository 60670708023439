<template>
  <v-card>
    <v-card-title> 종료 시간 </v-card-title>
    <div class="text-center font-weight-medium text-h6" style="position: relative">
      <span>{{ selectedHour }}시 {{ selectedMinute }}분</span>
      <v-btn style="position: absolute; right: 40px" class="ml-4" depressed color="teal" outlined @click="setCurrentTime">현재</v-btn>
    </div>
    <v-card-text>
      <!-- 시간 -->
      <v-subheader>HOUR(시)</v-subheader>
      <v-chip-group v-model="selectedHourIdx" mandatory active-class="deep-purple--text text--accent-4">
        <v-chip v-for="(time, index) in getHourList" :key="time" :value="index" class="d-flex justify-center py-5">{{ time }} 시</v-chip>
      </v-chip-group>

      <!-- 분 -->
      <v-subheader>MINUTE(분)</v-subheader>
      <div class="d-flex">
        <v-btn color="accent" class="mx-1" v-for="item in quick_minutes" depressed outlined small @click="setQuickMinutes(item)" :key="item">{{ item }}분</v-btn>
      </div>
      <v-slider v-model="selectedMinute" min="0" max="59" :thumb-size="40" thumb-label="always" class="mt-16">
        <template v-slot:prepend>
          <v-icon color="primary" @click="decrementMinutes"> mdi-minus </v-icon>
        </template>
        <template v-slot:append>
          <v-icon color="primary" @click="incrementMinutes"> mdi-plus </v-icon>
        </template>
        <template v-slot:thumb-label="{ value }"> {{ value }}분 </template>
      </v-slider>
    </v-card-text>
    <v-container fluid style="width: 400px" class="pa-0">
      <v-row dense>
        <v-col cols="5" class="text-end">시작시간</v-col>
        <v-col class="ml-10">{{ info.start_time || "-" }}</v-col>
      </v-row>
      <v-row dense>
        <v-col cols="5" class="text-end">대여물품</v-col>
        <v-col class="ml-10">{{ info.accessories || "-" }}</v-col>
      </v-row>
      <v-row dense>
        <v-col cols="5" class="text-end">추가 이용시간</v-col>
        <v-col class="ml-10">{{ extra_use_minutes || "0" }}분 / {{ extra_use_price || "0" }}원</v-col>
      </v-row>
    </v-container>
    <v-card-actions class="my-4">
      <v-spacer></v-spacer>
      <v-btn class="px-10" depressed @click="$emit('cancel')">취소</v-btn>
      <v-btn color="primary" class="px-6" depressed @click="submit">결제확인 및 저장</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>
<script>
import { Holidays } from "@/constant"

export default {
  props: {
    info: {
      type: Object,
      default: {
        start_time: "",
        accessories: "",
      },
    },
  },
  data: () => ({
    selectedHourIdx: 0,
    selectedMinute: 0,

    selctedOptions: ["드레스"],
    quick_minutes: [0, 10, 20, 30, 40, 50],

    extra_use_minutes: 0,
  }),
  mounted() {
    this.setCurrentTime()
  },

  watch: {
    selectedHourIdx() {
      this.calculatePrice()
    },
    selectedMinute() {
      this.calculatePrice()
    },
  },

  computed: {
    extra_use_price: function () {
      return 0
    },
    selectedHour: function () {
      return Math.min(this.selectedHourIdx + 13, 20)
    },
    getHourList() {
      return [13, 14, 15, 16, 17, 18, 19, 20]
    },
    getMinuteList() {
      return [...Array(60).keys()]
    },
  },

  methods: {
    submit: _.debounce(async function () {
      const productName = "시간초과 10분" + (this.isWeekendOrHoliday() ? "(주말/공휴일)" : "(평일)")
      this.$emit("save", { hour: this.selectedHour, minute: this.selectedMinute, extra_use_minutes: this.extra_use_minutes, extra_use_price: this.extra_use_price, productName })
    }, 500),

    decrementMinutes() {
      if (this.selectedMinute > 0) {
        this.selectedMinute--
      }
    },
    incrementMinutes() {
      if (this.selectedMinute < 59) {
        this.selectedMinute++
      }
    },

    setQuickMinutes(quick) {
      this.selectedMinute = quick
    },

    setCurrentTime() {
      console.log(this.dayjs().hour())
      const hourIdx = this.dayjs().hour() - 13
      this.selectedHourIdx = Math.min(this.dayjs().hour() - 13, 7)
      this.selectedMinute = this.dayjs().minute()
    },

    calculatePrice() {
      const startTimes = this.info.start_time.split(":")
      let hourDiff = Math.max(Number(this.selectedHour) - Number(startTimes[0]), 0)
      let minuteDiff = Number(this.selectedMinute) - Number(startTimes[1])

      if (minuteDiff < 0) {
        minuteDiff = minuteDiff + 60
        hourDiff--
      }
      console.log("calculatePrice", this.info.start_time, hourDiff, this.selectedHour)
      this.extra_use_minutes = Math.max(hourDiff * 60 + minuteDiff - 60, 0)
      console.log("extra_use_minutes", this.extra_use_minutes)
    },

    isWeekendOrHoliday() {
      const date = this.dayjs()
      return date.day() === 6 || date.day() === 0 || Holidays.includes(date.format("YYYY-MM-DD"))
    },
  },
}
</script>
<style scoped>
.scrollable {
  height: 272px;
  max-height: 272px;
  overflow-y: auto;
}
</style>
