<template>
  <div class="magicBtn" :class="[{ small: $vuetify.breakpoint.mobile }]">
    <span class="accent--text font-danjunghae">{{ number }}</span>
  </div>
</template>
<script>
export default {
  props: ["number"],
}
</script>
<style scoped>
.magicBtn {
  background-image: url("~@/assets/reservation/dial-bg.png");
  background-size: cover;
  aspect-ratio: 1;
  height: 100%;
  margin-left: -10px;
  font-size: 24px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.magicBtn.small {
  font-size: 20px !important;
}
</style>
