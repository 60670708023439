<template>
  <v-sheet class="text-center py-16">
    <v-progress-circular indeterminate color="primary" size="48"></v-progress-circular>
    <p class="mt-4 text-caption">Naver 로그인중</p>
  </v-sheet>
</template>
<script>
import { mapActions, mapMutations } from "vuex"

export default {
  mounted() {
    var self = this
    this.$http
      .post("/api/test/naver", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          code: `${self.$route.query.code}`,
          state: `${self.$route.query.state}`,
        },
      })
      .then((res) => {
        console.log(res)

        this.$http
          .post("/api/user/insert", {
            params: {
              user_id: res.data.email.split("@")[0],
              password: res.data.id,
              name: res.data.name,
              phone: res.data.mobile,
              email: res.data.email,
            },
          })
          .then(() => {
            console.log(res.data.email)
            let user = {
              user_id: res.data.email.split("@")[0],
              name: res.data.name,
              phone: res.data.mobile,
              //email: res.data.email,
              profile_image: res.data.profile_image,
              type: "SNS",
              status: "인증완료",
              created: new Date(),
            }

            this.login({
              ...user,
              profile_image: user.profile_image ? user.profile_image : res.data.profile_image,
              type: "SNS",
            })

            this.$router.replace("/")
          })
      })
  },

  methods: {
    ...mapActions(["login"]),
  },
}
</script>
