<template>
  <div>
    <Mobile v-if="$vuetify.breakpoint.mobile" />

    <Pc v-if="!$vuetify.breakpoint.mobile" />
  </div>
</template>
<script>
import Mobile from "./category/Mobile"
import Pc from "./category/Pc"

export default {
  components: {
    Mobile,
    Pc,
  },
}
</script>
