<template>
  <div>
    <div class="py-8">
      <v-sheet class="py-4 px-4 mx-auto rounded-lg text-center" :width="$vuetify.breakpoint.mobile ? '80%' : 480" outlined>
        <!-- 로고 & 문구 -->
        <div class="mb-10 mx-auto" style="width: 150px">
          <v-img aspect-ratio="1.25" src="@/assets/logo/menu-logo.png"></v-img>
        </div>

        <v-sheet :width="$vuetify.breakpoint.mobile ? '94%' : 280" class="mx-auto">
          <!-- 아이디 -->
          <v-text-field
            outlined
            flat
            dense
            autofocus
            :placeholder="$t('page.login.id')"
            v-model="user_id"
            @change="$v.user_id.$touch()"
            @blur="$v.user_id.$touch()"
            :error-messages="user_id_Errors"
            @keyup.enter="submit()"
          >
          </v-text-field>

          <!-- 비밀번호 -->
          <v-text-field
            outlined
            flat
            dense
            type="password"
            :placeholder="$t('page.login.password')"
            v-model="password"
            @change="$v.password.$touch()"
            @blur="$v.password.$touch()"
            :error-messages="password_Errors"
            @keyup.enter="submit()"
          >
          </v-text-field>

          <!-- 로그인 버튼 -->
          <v-btn width="100%" class="font-weight-bold" color="primary" block dark large depressed @click="submit()"> {{ $t("button.login") }} </v-btn>

          <!-- SNS -->
          <v-sheet class="my-4 mx-2 d-flex align-center justify-center">
            <v-divider class="grey lighten-2"></v-divider>
            <p class="my-0 mx-4 text-caption grey--text">{{ $t("page.login.social-media-login-guideline") }}</p>
            <v-divider class="grey lighten-2"></v-divider>
          </v-sheet>

          <!-- 버튼 -->
          <v-sheet class="mt-2">
            <!-- Google -->
            <v-btn width="100%" class="font-weight-regular" block color="grey" large outlined depressed @click="googleLogin()">
              <div width="100%" class="d-flex align-center black--text text-caption">
                <v-img class="mr-2" height="20" width="20" contain src="@/assets/sns/google.png"></v-img>
                <span>{{ $t("button.social-google-login") }}</span>
              </div>
            </v-btn>

            <!-- 카카오 -->
            <v-btn width="100%" class="font-weight-regular mt-3" block color="#FEE500" large depressed @click="social_kakao()">
              <div width="100%" class="d-flex align-center black--text text-caption">
                <v-img class="mr-2" height="20" width="20" contain :src="require('@/assets/sns/kakao.svg')"></v-img>
                <span>{{ $t("button.social-kakao-login") }}</span>
              </div>
            </v-btn>

            <!-- 네이버 -->
            <!-- <v-sheet
                            height="42"
                            style="cursor:pointer"
                            @click="social_naver()"
                            class="mt-3 rounded-5"
                        >
                            <v-img
                                height="42"
                                class="rounded-5"
                                src="@/assets/sns/naver.png"
                            ></v-img>
                        </v-sheet>

                        <div class="mt-3">
                            <div id="naver_id_login"></div>
                        </div> -->
          </v-sheet>
        </v-sheet>

        <!-- 버튼 -->
        <div class="d-flex justify-center mt-4">
          <v-btn width="90" color="grey darken-1" small text to="/auth/find/confirm"> {{ $t("page.login.find-id-title") }} </v-btn>
          <v-btn width="90" :class="$vuetify.breakpoint.mobile ? '' : 'mx-2'" color="grey darken-1" small text to="/auth/find/password"> {{ $t("page.login.reset-password") }} </v-btn>
          <v-btn width="90" color="grey darken-1" small text :to="registerPath"> {{ $t("page.login.register-member-title") }} </v-btn>
        </div>

        <v-divider class="grey lighten-2 mx-auto my-4" :width="$vuetify.breakpoint.mobile ? '94%' : 280"></v-divider>

        <!-- 약관 버튼 -->
        <div class="d-flex justify-center mt-1">
          <v-btn color="grey darken-1" text small to="/policy/rule?type=read"> {{ $t("bottom.use_terms") }} </v-btn>
          <v-btn class="font-weight-bold" color="grey darken-1" text small to="/policy/privacy?type=read"> {{ $t("bottom.private_terms") }}</v-btn>
        </div>

        <!-- SNS 로그인 신규 가입 dialog -->
        <v-dialog max-width="440" v-model="show_confirm_dialog" content-class="rounded-10" style="z-index: 999">
          <v-sheet class="rounded-10 pa-4">
            <p class="text-center mb-0">
              <v-icon size="80" color="#f2b8bf" class="">mdi-alert-circle-outline</v-icon>
            </p>

            <p class="text-center text-body mt-3 px-1" style="letter-spacing: 0px !important" v-html="$t('page.login.alert-info-new-member-guide')"></p>

            <div class="d-flex justify-space-between mt-6">
              <v-btn color="#ddd" class="rounded-10" dark width="48%" depressed @click="show_confirm_dialog = false">{{ $t("button.no") }} </v-btn>

              <v-btn color="#f2b8bf" class="rounded-10" dark width="48%" depressed @click="next_register()">{{ $t("button.yes") }}</v-btn>
            </div>
          </v-sheet>
        </v-dialog>
        <v-dialog max-width="440" v-model="show_warn_dialog" content-class="rounded-10" style="z-index: 999">
          <v-sheet class="rounded-10 pa-4">
            <p class="text-center mb-0">
              <v-icon size="80" color="#f2b8bf" class="">mdi-alert-circle-outline</v-icon>
            </p>

            <p class="text-center text-body mt-3 px-1" style="letter-spacing: 0px !important">
              {{ warn_error_message }}
            </p>

            <div class="d-flex justify-center mt-6">
              <v-btn color="#f2b8bf" class="rounded-10" dark width="48%" depressed @click="loginCancle()"> {{ $t("button.ok") }} </v-btn>
            </div>
          </v-sheet>
        </v-dialog>
      </v-sheet>
    </div>
    <InappBrowserWarningDialog @input="(e) => (show_warning_dialog = e)" @confirm="show_warning_dialog = false" :visible="show_warning_dialog" />
  </div>
</template>
<script>
// 검증
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"
import { mapState, mapMutations } from "vuex"
import authService from "@/api/auth-service"
import InappBrowserWarningDialog from "@/views/components/InappBrowserWarningDialog"

export default {
  mixins: [validationMixin],
  components: {
    InappBrowserWarningDialog,
  },

  validations: {
    user_id: {
      required,
    },
    password: {
      required,
    },
  },

  data: () => ({
    show_warning_dialog: false,
    show_confirm_dialog: false,
    warn_error_message: "",
    user_id: "",
    password: "",
  }),

  mounted() {
    if ((this.$route.query.qrcode || this.$route.query.visitQrcode || this.$route.query.pay_qrcode) && this.lafia_user.is_logined && this.lafia_user.user_id) {
      //자동 로그인 수행
      this.autoQrLogin(this.lafia_user.user_id)
      return
    }
    this.show_warning_dialog = !this.isShowinappBrowserWarning
    if (this.show_warning_dialog) {
      this.SET_INAPPBROWER_STATE(true)
      //this.$store.commit("SET_INAPPBROWER_STATE", true)
    }
  },

  computed: {
    ...mapState(["lafia_user", "isShowinappBrowserWarning"]),

    registerPath() {
      let regPath = "/auth/join/agree"
      if (this.$route.query.qrcode) {
        regPath = regPath + `?qrcode=${this.$route.query.qrcode}`
      } else if (this.$route.query.visitQrcode) {
        regPath = regPath + `?visitQrcode=${this.$route.query.visitQrcode}`
      } else if (this.$route.query.pay_qrcode) {
        regPath = regPath + `?pay_qrcode=${this.$route.query.pay_qrcode}`
      }
      if (this.$route.query.event) {
        const delim = regPath.includes("?") ? "&" : "?"
        regPath = regPath + `${delim}event=${this.$route.query.event}`
      } else if (this.$route.query.packageEvent) {
        const delim = regPath.includes("?") ? "&" : "?"
        if (this.$route.query.productId) {
          regPath = regPath + `${delim}productId=${this.$route.query.productId}`
        }
        regPath = regPath + `${delim}packageEvent=${this.$route.query.packageEvent}`
      }
      if (this.$route.query.callbackUrl) {
        const delim = regPath.includes("?") ? "&" : "?"
        regPath = regPath + `${delim}callbackUrl=${this.$route.query.callbackUrl}`
      }
      console.log(regPath)
      return regPath
    },

    user_id_Errors() {
      const errors = []
      if (!this.$v.user_id.$dirty) return errors
      !this.$v.user_id.required && errors.push(this.$t("page.login.input-id-hint"))
      return errors
    },

    password_Errors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push(this.$t("page.login.input-password-hint"))
      return errors
    },
    show_warn_dialog: {
      get() {
        return !!this.warn_error_message
      },
      set(value) {
        if (!value) {
          this.warn_error_message = ""
        }
      },
    },
  },

  methods: {
    ...mapMutations(["login", "ADD_TEMP_SNS_USER", "SET_INAPPBROWER_STATE"]),

    // 로그인
    async submit() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        // 아이디 & 비밀번호 확인
        let res
        try {
          res = await authService.loginUserIdPassword(this.user_id.trim(), this.password)
          //백업 비밀번호를 다시 체크...
          if (!res.data.length) {
            res = await authService.checkBackupPassword(this.user_id.trim(), this.password)
          }

          if (!res.data.length) {
            alert(this.$t("alert.error_invalid_id_or_password"))
          } else {
            const user = res.data[0]

            this.login({
              ...user,
              profile_image: "/upload/user/profile_image/" + user.profile_image,
            })
            this.$gtag.event("login", { method: "email" })
            try {
              await authService.updateLastLoginAt(res.data[0].user_id)
              if (this.$route.query.qrcode) {
                await authService.updateQrCode(this.user_id.trim(), this.$route.query.qrcode)
              } else if (this.$route.query.visitQrcode) {
                await authService.updatePayQrcode(this.user_id.trim(), this.$route.query.visitQrcode)
              } else if (this.$route.query.pay_qrcode) {
                await authService.updatePayQrcode(this.user_id.trim(), this.$route.query.pay_qrcode)
              }
            } catch (e) {
              console.log(JSON.stringify(e))
              // update error ignore
              console.log(e)
            }
            if (this.$route.query.callbackUrl) {
              this.$router.replace(this.$route.query.callbackUrl)
            } else if (this.$route.query.qrcode) {
              this.$router.replace("/?qrlogin=true")
            } else if (this.$route.query.event) {
              this.$router.replace(`/reservation/buy?event=${this.$route.query.event}`)
            } else if (this.$route.query.packageEvent) {
              let pathUrl = `/reservation/package?packageEvent=${this.$route.query.packageEvent}`
              if (this.$route.query.productId) {
                pathUrl += `&productId=${this.$route.query.productId}`
              }
              this.$router.replace(pathUrl)
            } else if (this.$route.query.pay_qrcode) {
              this.$router.replace(`/onlinePayConnect?pay_qrcode=${this.$route.query.pay_qrcode}`)
            } else {
              this.$router.replace("/")
            }
          }
        } catch (e) {
          console.log(e)
          alert(this.$t("alert.error_page"))
        }
      }
    },

    // 소셜로그인 > Google
    googleLogin() {
      const clientId = process.env.VUE_APP_GOOGLE_CLIENT_ID
      let redirectUri = window.location.origin + process.env.VUE_APP_GOOGLE_REDIRECT_URI

      // state 파라미터에 qrcode 값을 추가
      // let state = {};
      // if(this.$route.query.qrcode) {
      //     state.qrcode = this.$route.query.qrcode;
      // }
      // const stateParam = encodeURIComponent(JSON.stringify(state))
      let state = null

      const scope = "email https://www.googleapis.com/auth/userinfo.profile"
      let authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code`

      if (this.$route.query.qrcode) {
        state = {
          ...state,
          qrcode: this.$route.query.qrcode,
        }
      } else if (this.$route.query.visitQrcode) {
        state = {
          ...state,
          visitQrcode: this.$route.query.visitQrcode,
        }
      } else if (this.$route.query.pay_qrcode) {
        state = {
          ...state,
          pay_qrcode: this.$route.query.pay_qrcode,
        }
      }

      if (this.$route.query.event) {
        state = {
          ...state,
          event: this.$route.query.event,
        }
      }

      if (this.$route.query.packageEvent) {
        state = {
          ...state,
          packageEvent: this.$route.query.packageEvent,
          productId: this.$route.query.productId,
        }
      }

      if (this.$route.query.callbackUrl) {
        state = {
          ...state,
          callbackUrl: this.$route.query.callbackUrl,
        }
      }

      if (state) {
        authUrl = authUrl + `&state=${btoa(encodeURIComponent(JSON.stringify(state)))}`
      }

      window.location = authUrl
    },

    // 소셜로그인 > Kakao
    social_kakao() {
      if (!window.Kakao.isInitialized()) {
        window.Kakao.init(process.env.VUE_APP_KAKAO_JAVASCRIPT_KEY)
      }

      window.Kakao.Auth.login({
        success: () => {
          window.Kakao.API.request({
            url: "/v2/user/me",
            data: {
              property_keys: ["kakao_account.profile", "kakao_account.email"],
            },
            success: async (response) => {
              this.kakaoLogin(response)
            },
            fail: function (error) {
              console.log(error)
            },
          })
        },
        fail: function (error) {
          console.log(error)
        },
      })
    },

    // 카카오 로그인 (정보입력)
    async kakaoLogin(response) {
      console.log(response)

      const email = response.kakao_account.email

      if (!email) {
        alert("email이 존재하지 않는 계정은 로그인이 불가합니다.")
        return
      }
      try {
        let user = await authService.checkDuplicateEmail(email)
        console.log(user)
        if (!user) {
          // 회원정보가 없음.
          console.log("회원정보가 없음.")
          user = {
            user_id: response.kakao_account.email,
            //password: response.id,
            name: response.kakao_account.profile.nickname,
            profile_image: response.kakao_account.profile.thumbnail_image_url,
            //phone: "SNS 로그인",
            //email: response.kakao_account.email,
            sns_id: response.id,
            provider_type: "kakao",
          }
          this.ADD_TEMP_SNS_USER(user)
          // show popup
          this.show_confirm_dialog = true
        } else {
          // 회원정보가 있음.
          if (/* user.sns_id == response.id && */ user.provider_type === "kakao") {
            try {
              await authService.updateLastLoginAt(user.user_id)
            } catch (e) {
              console.log(e)
            }
          } else if (user.phone === "SNS 로그인") {
            //DB 이전의 호환을 위해서
            try {
              user = {
                ...user,
                name: response.kakao_account.profile.nickname,
                sns_id: response.id,
                provider_type: "kakao",
              }
              await authService.updateSnsUserInfo(user)
            } catch (e) {
              console.log(e)
            }
          } else {
            // 이미 가입된 계정안내
            // TODO show POPUP
            let prefix = "이메일 회원으로 가입된 계정입니다. "
            switch (user.provider_type.toLowerCase()) {
              case "google":
                prefix = "구글 계정으로 가입된 계정입니다. "
                break
              case "naver":
                prefix = "네이버 계정으로 가입된 계정입니다. "
                break
              default:
                break
            }

            this.warn_error_message = prefix + "계정 정보를 확인하여 다시 로그인 해주세요.(" + user.user_id + ")"
            return
          }
          try {
            if (this.$route.query.qrcode) {
              await authService.updateQrCode(user.user_id, this.$route.query.qrcode)
            } else if (this.$route.query.visitQrcode) {
              await authService.updatePayQrcode(user.user_id, this.$route.query.visitQrcode)
            } else if (this.$route.query.pay_qrcode) {
              await authService.updatePayQrcode(user.user_id, this.$route.query.pay_qrcode)
            }
          } catch (e) {
            console.log(e)
            alert(this.$t("alert.error_failed_qrocde_authentication"))
            this.$router.replace("/auth/login")
            return
          }

          const profile_image = user.profile_image !== "default.png" ? "/upload/user/profile_image/" + user.profile_image : response.kakao_account.profile.thumbnail_image_url

          this.login({ ...user, profile_image, type: "SNS" })
          this.$gtag.event("login", { method: "Kakao" })
          if (this.$route.query.callbackUrl) {
            this.$router.replace(this.$route.query.callbackUrl)
          } else if (this.$route.query.qrcode) {
            this.$router.replace("/?qrlogin=true")
          } else if (this.$route.query.event) {
            this.$router.replace(`/reservation/buy?event=${this.$route.query.event}`)
          } else if (this.$route.query.packageEvent) {
            let pathUrl = `/reservation/package?packageEvent=${this.$route.query.packageEvent}`
            if (this.$route.query.productId) {
              pathUrl += `&productId=${this.$route.query.productId}`
            }
            this.$router.replace(pathUrl)
          } else if (this.$route.query.pay_qrcode) {
            this.$router.replace(`/onlinePayConnect?pay_qrcode=${this.$route.query.pay_qrcode}`)
          } else {
            this.$router.replace("/")
          }
        }
      } catch (e) {
        console.log(e)
        alert(this.$t("alert.error_fail_to_register_member"))
        this.$router.replace("/auth/login")
      }
    },

    // 네이버 로그인
    social_naver() {
      var client_id = "vooqoznVXJJnNHZAG8tZ"
      var callbackUrl = "https://sela-castle.com/auth/sns/naver"
      var url = "https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=" + client_id + "&redirect_uri=" + callbackUrl + "&state=1234"
      window.location.replace(url)
    },

    // sns 인증 다음 화면으로 이동
    next_register: _.debounce(async function () {
      this.$router.push(this.registerPath)
    }, 500),

    loginCancle() {
      this.$router.back()
    },

    async autoQrLogin(user_id) {
      try {
        await authService.updateLastLoginAt(user_id)
        if (this.$route.query.qrcode) {
          await authService.updateQrCode(user_id, this.$route.query.qrcode)
        } else if (this.$route.query.visitQrcode) {
          await authService.updatePayQrcode(user_id, this.$route.query.visitQrcode)
        } else if (this.$route.query.pay_qrcode) {
          await authService.updatePayQrcode(user_id, this.$route.query.pay_qrcode)
        }
      } catch (e) {
        // update error ignore
        console.log(e)
      }
      if (this.$route.query.qrcode) {
        this.$router.replace("/?qrlogin=true")
      } else if (this.$route.query.pay_qrcode) {
        this.$router.replace(`/onlinePayConnect?pay_qrcode=${this.$route.query.pay_qrcode}`)
      } else {
        this.$router.replace("/")
      }
    },
  },
}
</script>
