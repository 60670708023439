<template>
  <v-sheet class="d-flex" height="100">
    <v-sheet v-for="(item, index) in menus" :key="item.key" @click="() => selectTab(index)" class="tabItem">
      <img :src="require(`@/assets/menus/${getMenuIcon(item.key, selected === index)}`)" />
    </v-sheet>
  </v-sheet>
</template>
<script>
import { mapState } from "vuex"
export default {
  props: ["selected"],
  data: () => ({
    menus: [
      // {
      //   key: "early",
      // },
      {
        key: "package",
      },
      {
        key: "makeup",
      },
    ],
  }),
  computed: {
    ...mapState(["lang"]),
  },

  methods: {
    getMenuIcon(key, isActive) {
      let iconPath = this.getLangPath()

      switch (key) {
        case "early":
          iconPath += isActive ? "early-active.png" : "early.png"
          break
        case "package":
          iconPath += isActive ? "package-active.png" : "package.png"
          break
        case "makeup":
          iconPath += isActive ? "makeup-active.png" : "makeup.png"
          break
        default:
          break
      }
      return iconPath
    },
    getLangPath() {
      switch (this.lang) {
        case "ko":
          return ""
        case "jp":
          return "jp/"
        default:
          return "en/"
      }
    },

    selectTab(index) {
      this.$emit("update:selected", index)
    },
  },
}
</script>
<style scoped>
.tabItem {
  cursor: pointer;
  flex-grow: 1;
  text-align: center;
  margin: auto;
}
.tabItem img {
  height: 60px;
}
</style>
