<template>
  <v-sheet class="rounded-lg text-center">
    <v-card class="py-4 py-md-10 px-2" flat>
      <v-img src="@/assets/deco/card-top-deco.png" max-width="240px" class="mx-auto" />

      <div class="font-danjunghae primary--text text-center" :style="{ fontSize: $vuetify.breakpoint.mobile ? '28px' : '36px' }" :lang="lang">{{ $t("page.picturePrintReq.complete-title") }}</div>

      <v-card-title class="text-h6 text-md-h5 text-center mx-auto font-weight-light d-block mt-0 pt-0" style="color: #545454">
        {{ $t("page.picturePrintReq.complete-subtitle") }}
      </v-card-title>

      <v-img src="@/assets/print/print-complete.png" max-width="60%" class="mx-auto my-4 my-md-8" />
      <v-sheet class="px-8">
        <v-progress-linear v-model="progress" height="25" rounded color="primary" style="pointer-events: none">
          <strong>{{ progress }}% </strong>
        </v-progress-linear>
      </v-sheet>

      <v-card-actions class="d-flex justify-center mt-10">
        <v-btn color="#d9bddd" class="dialog_btn rounded-2" style="font-size: 20px" width="35%" depressed :to="retUrl" large replace :disabled="progress !== 100"> {{ $t("button.ok") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex"
export default {
  data: () => ({
    progress: 0,
  }),
  computed: {
    ...mapState(["lang"]),
    retUrl() {
      return "/mypage/home?type=picture"
    },
  },

  mounted() {
    this.startProgress()
  },

  methods: {
    startProgress() {
      const duration = 5000
      const interval = 200
      const step = (100 / duration) * interval

      let intervalId = setInterval(() => {
        this.progress += step
        if (this.progress >= 100) {
          this.progress = 100
          clearInterval(intervalId)
        }
      }, interval)
    },
  },
}
</script>
<style scoped>
::v-deep .v-btn {
  color: #625072;
}
</style>
