<template>
  <div v-if="numbers" class="d-flex align-center flex-grow-1 justify-end" style="height: 100%; gap: -10px">
    <span style="color: #545454" class="pr-4 text-body-2 text-lg-body-1">{{ $t("page.mypage.reserv.magicCodeLabel") }}: </span>
    <MagicCodeComponentVue v-for="(item, index) in numbers" :key="index" :number="item" />
  </div>
</template>
<script>
import MagicCodeComponentVue from "./MagicCodeComponent.vue"

export default {
  props: ["numbers"],
  components: {
    MagicCodeComponentVue,
  },
}
</script>
<style scoped></style>
