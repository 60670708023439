<template>
  <v-sheet>
    <early-product-item v-for="item in items" :key="item.id" :data="item" />
  </v-sheet>
</template>
<script>
import EarlyProductItem from "./EarlyProductItem"

export default {
  components: { EarlyProductItem },

  data: () => ({
    loading: false,
    items: [],
  }),

  created() {},

  mounted() {
    this.laodItem()
  },

  methods: {
    async laodItem() {
      this.loading = true
      const res = await this.$http.get("/api/early/list")
      this.items = res.data

      this.loading = false
    },
  },
}
</script>
<style scoped></style>
