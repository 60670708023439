<template>
  <div>
    <v-sheet outlined class="pa-2 ma-2 d-flex align-center">
      <div v-if="!isDetail">
        <!-- title -->
        <span class="primary--text font-weight-bold"> {{ dayjs().year() }}년</span>
        <span class="font-weight-bold mx-2">총 드레스 예약</span>
        <span class="text-h6 mx-4"> {{ $toComma(totalAmount) }} 명</span>
      </div>
      <div v-else>
        <v-btn depressed @click="prev" color="white"><v-icon> mdi-arrow-left </v-icon></v-btn> {{ selectedMonth + 1 }}월
      </div>
    </v-sheet>

    <!-- 본문 -->
    <v-sheet outlined class="pa-2 ma-2" width="1024">
      <v-data-table v-show="!isDetail" :headers="listTableHeaders" :items="list_items" hide-default-footer :items-per-page="-1" :loading="isLoading" loading-text="Loading... Please wait">
        <template v-slot:[`item.date`]="{ item }">
          <span>{{ Number(item.date.split("-")[1]) }}월</span>
        </template>
        <template v-slot:[`item.total_people_count`]="{ item }">
          <span>{{ $toComma(Number(item.payed_people_count) + Number(item.canceled_people_count)) }}</span>
        </template>
        <template v-slot:[`item.cancel_amount`]="{ item }">
          <span>{{ $toComma(Number(item.canceled_people_count)) }}</span>
        </template>
        <template v-slot:[`item.payed_people_count`]="{ item }">
          <span>{{ $toComma(Number(item.payed_people_count)) }}&nbsp;&nbsp;({{ getTotalRatio(item) }}%)</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn small depressed color="primary" outlined @click="openDetail(item)">세부내역서</v-btn>
        </template>
      </v-data-table>

      <v-sheet v-if="isDetail" width="1024">
        <v-sheet v-if="isLoading">
          <span>Loading... Please wait</span>
        </v-sheet>
        <v-simple-table v-else>
          <template v-slot:default>
            <thead>
              <tr>
                <th>{{ selectedMonth + 1 }} 월</th>
                <th>드레스 예약</th>
                <th>드레스 예약 취소</th>
                <th>예약 집계(명)</th>
                <th>예약율</th>
              </tr>
            </thead>
            <tbody>
              <tr class="accentBg">
                <td class="text-center">계</td>
                <td>{{ $toComma(Number(selectedItem.payed_people_count) + Number(selectedItem.canceled_people_count)) }}</td>
                <td>{{ $toComma(Number(selectedItem.canceled_people_count)) }}</td>
                <td>{{ $toComma(Number(selectedItem.payed_people_count)) }}</td>
                <td>{{ getTotalRatio(selectedItem) }}%</td>
              </tr>
              <tr v-for="(item, index) in detail_items" :key="index" :class="{ greyBg: dayjs(item.date).day() === 1 }">
                <td :class="{ 'red--text': dayjs(item.date).day() === 0 || dayjs(item.date).day() === 6 }">{{ dayjs(item.date).format("D") }}일</td>
                <td>{{ $toComma(Number(item.payed_people_count) + Number(item.canceled_people_count)) }}</td>
                <td>{{ $toComma(Number(item.canceled_people_count)) }}</td>
                <td>{{ $toComma(Number(item.payed_people_count)) }}</td>
                <td>{{ getRatio(item) }}%</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-sheet>
    </v-sheet>
  </div>
</template>
<script>
import statusService from "@/api/stats-service"
export default {
  data: () => ({
    isLoading: false,
    isDetail: false,
    selectedMonth: 1,
    selectedItem: null,
    list_items: [],
    detail_items: [],
    totalAmount: 0,
    listTableHeaders: [
      { value: "date", text: "날짜" },
      { value: "total_people_count", text: "드레스 예약", align: "right" },
      { value: "canceled_people_count", text: "드레스 예약 취소", align: "right" },
      { value: "payed_people_count", text: "예약 집계(명)", align: "right" },
      { text: "세부내역", value: "actions", width: "200px", sortable: false },
    ],
    detailTableHeaders: [
      { value: "date", text: "날짜" },
      { value: "total_people_count", text: "드레스 예약", align: "right" },
      { value: "canceled_people_count", text: "드레스 예약 취소", align: "right" },
      { value: "payed_people_count", text: "예약 집계(명)", align: "right" },
      { value: "reserve_ratio", text: "예약 율", align: "right" },
    ],
  }),
  created() {
    this.selectedMonth = this.dayjs().month()
  },
  mounted() {
    this.loadList()
  },
  methods: {
    async loadList() {
      try {
        this.isLoading = true

        let from = this.dayjs().startOf("year").format("YYYY-MM-DD")
        let to = this.dayjs().add(3, "month").endOf("month").format("YYYY-MM-DD")

        const result = await statusService.getDressVistorStats(true, from, to)
        this.list_items = result

        this.totalAmount = result.reduce((sum, e) => sum + Number(e.payed_people_count), 0)
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },

    async loadDetail(month) {
      this.detail_items = []
      try {
        this.isLoading = true

        let fromDayjs = this.dayjs().month(month).startOf("month")
        let toDayjs = fromDayjs.endOf("month")

        const from = fromDayjs.format("YYYY-MM-DD")
        const to = toDayjs.format("YYYY-MM-DD")

        const result = await statusService.getDressVistorStats(false, from, to)
        this.detail_items = result
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },

    openDetail(item) {
      this.selectedMonth = Number(item.date.split("-")[1]) - 1
      this.selectedItem = item
      this.isDetail = true
      this.loadDetail(this.selectedMonth)
    },
    prev() {
      this.isDetail = false
      this.selectedItem = null
    },

    getRatio(item) {
      let max_available_room = 15
      let max_count = max_available_room * 8
      let ratio = Math.round((Number(item.payed_people_count) * 100) / max_count)
      return ratio
    },
    getTotalRatio(item) {
      const month = Number(item.date.split("-")[1]) - 1
      let max_available_room = 15

      let fromDayjs = this.dayjs().month(month).startOf("month")
      let toDayjs = fromDayjs.endOf("month")

      let count = 0
      const diffDay = toDayjs.diff(fromDayjs, "day")
      for (let i = 0; i < diffDay; i++) {
        let tempDay = fromDayjs.add(i, "day")
        if (tempDay.day() !== 1) {
          count++
        }
      }

      let workdays = count
      let max_count = max_available_room * 8 * workdays
      let ratio = Math.round((Number(item.payed_people_count) * 100) / max_count)
      return ratio
    },
  },
}
</script>
<style scoped>
.greyBg {
  background-color: rgb(220, 220, 220);
}

.accentBg {
  background-color: rgb(220, 182, 220);
}
</style>
