<template>
  <div>
    <!-- 제목 -->
    <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">매출 관리</font>
    </v-sheet>

    <!-- 본문 -->
    <v-sheet outlined class="ma-2 pa-4">
      <v-sheet outlined class="pa-10" width="800">
        <p class="text-h6 font-weight-bold"><span class="primary--text">최근 1년</span> 총 매출</p>
        <p class="text-h4 font-weight-bold mb-0">{{ $toComma(year_amount) }}<span class="ml-1 text-h6 font-weight-medium">원</span></p>
      </v-sheet>

      <v-sheet class="d-flex justify-space-between mt-4" width="800">
        <v-sheet outlined class="pa-4" width="154">
          <p class="text-subtitle-1 font-weight-bold">
            <span class="primary--text">{{ (currentMonth - 4 + 12) % 12 == 0 ? 12 : (currentMonth - 4 + 12) % 12 }}월 달</span> 총 매출
          </p>
          <p class="text-h5 font-weight-bold mb-0">{{ $toComma(past_past_past_past_past_month_amount) }}<span class="ml-1 text-subtitle-1 font-weight-medium">원</span></p>
        </v-sheet>

        <v-sheet outlined class="pa-4" width="154">
          <p class="text-subtitle-1 font-weight-bold">
            <span class="primary--text">{{ (currentMonth - 3 + 12) % 12 == 0 ? 12 : (currentMonth - 3 + 12) % 12 }}월 달</span> 총 매출
          </p>
          <p class="text-h5 font-weight-bold mb-0">{{ $toComma(past_past_past_past_month_amount) }}<span class="ml-1 text-subtitle-1 font-weight-medium">원</span></p>
        </v-sheet>

        <v-sheet outlined class="pa-4" width="154">
          <p class="text-subtitle-1 font-weight-bold">
            <span class="primary--text">{{ (currentMonth - 2 + 12) % 12 == 0 ? 12 : (currentMonth - 2 + 12) % 12 }}월 달</span> 총 매출
          </p>
          <p class="text-h5 font-weight-bold mb-0">{{ $toComma(past_past_past_month_amount) }}<span class="ml-1 text-subtitle-1 font-weight-medium">원</span></p>
        </v-sheet>

        <v-sheet outlined class="pa-4" width="154">
          <p class="text-subtitle-1 font-weight-bold">
            <span class="primary--text">{{ (currentMonth - 1 + 12) % 12 == 0 ? 12 : (currentMonth - 1 + 12) % 12 }}월 달</span> 총 매출
          </p>
          <p class="text-h5 font-weight-bold mb-0">{{ $toComma(past_past_month_amount) }}<span class="ml-1 text-subtitle-1 font-weight-medium">원</span></p>
        </v-sheet>

        <v-sheet outlined class="pa-4" width="154">
          <p class="text-subtitle-1 font-weight-bold">
            <span class="primary--text">{{ (currentMonth + 12) % 12 }}월 달</span> 총 매출
          </p>
          <p class="text-h5 font-weight-bold mb-0">{{ $toComma(past_month_amount) }}<span class="ml-1 text-subtitle-1 font-weight-medium">원</span></p>
        </v-sheet>
      </v-sheet>

      <v-sheet outlined class="pa-10 mt-2" width="800">
        <p class="text-h6 font-weight-bold">
          <span class="primary--text">{{ currentMonth + 1 }}월 달</span> 총 매출
        </p>
        <p class="text-h4 font-weight-bold mb-0">{{ $toComma(month_amount) }}<span class="ml-1 text-h6 font-weight-medium">원</span></p>
      </v-sheet>
    </v-sheet>
  </div>
</template>
<script>
export default {
  data: () => ({
    currentMonth: 0,
    year_amount: 0,
    month_amount: 0,

    past_month_amount: 0,
    past_past_month_amount: 0,
    past_past_past_month_amount: 0,
    past_past_past_past_month_amount: 0,
    past_past_past_past_past_month_amount: 0,
  }),

  mounted() {
    // 전체 매출 가져오기
    this.load()
  },

  methods: {
    // 전체 매출 가져오기
    load() {
      this.$http.post("/api/order/select").then((res) => {
        console.log(res)

        this.year_amount = res.data.reduce((accumulator, current) => accumulator + current.pay_amount, 0)

        let currentMonth = new Date().getMonth()
        let currentYear = new Date().getFullYear()

        this.currentMonth = currentMonth

        let thisMonthSales = res.data.filter((item) => {
          let itemDate = new Date(item.created)
          return itemDate.getMonth() === currentMonth && itemDate.getFullYear() === currentYear
        })

        this.month_amount = thisMonthSales.reduce((accumulator, current) => accumulator + current.pay_amount, 0)

        let pastMonthSales = res.data.filter((item) => {
          let itemDate = new Date(item.created)
          return itemDate.getMonth() === currentMonth - 1 && itemDate.getFullYear() === currentYear
        })

        this.past_month_amount = pastMonthSales.reduce((accumulator, current) => accumulator + current.pay_amount, 0)

        let pastpastMonthSales = res.data.filter((item) => {
          let itemDate = new Date(item.created)
          return itemDate.getMonth() === currentMonth - 2 && itemDate.getFullYear() === currentYear
        })

        this.past_past_month_amount = pastpastMonthSales.reduce((accumulator, current) => accumulator + current.pay_amount, 0)

        let pastpastpastMonthSales = res.data.filter((item) => {
          let itemDate = new Date(item.created)
          return itemDate.getMonth() === currentMonth - 3 && itemDate.getFullYear() === currentYear
        })

        this.past_past_month_amount = pastpastpastMonthSales.reduce((accumulator, current) => accumulator + current.pay_amount, 0)

        let pastpastpastpastMonthSales = res.data.filter((item) => {
          let itemDate = new Date(item.created)
          return itemDate.getMonth() === currentMonth - 3 && itemDate.getFullYear() === currentYear
        })

        this.past_past_past_month_amount = pastpastpastpastMonthSales.reduce((accumulator, current) => accumulator + current.pay_amount, 0)

        let pastpastpastpastpastMonthSales = res.data.filter((item) => {
          let itemDate = new Date(item.created)
          return itemDate.getMonth() === currentMonth - 3 && itemDate.getFullYear() === currentYear
        })

        this.past_past_past_past_month_amount = pastpastpastpastpastMonthSales.reduce((accumulator, current) => accumulator + current.pay_amount, 0)
      })
    },
  },
}
</script>
