<template>
  <div>
    <v-img width="180" height="32" contain class="mx-auto mt-2" src="@/assets/deco/line_0.png"></v-img>
    <p class="text-center font-danjunghae mb-2" style="color: #b67a7a; font-size: 30px">솔로프리미엄</p>

    <p class="text-center mb-4" style="color: #333; font-size: 11px">
      전문가의 촬영 디렉팅과, 원하는대로 수정이 가능한<br />
      1대1 대면보정 프리미엄 촬영을 경험해보세요
    </p>

    <v-sheet class="px-3">
      <iframe width="100%" style="aspect-ratio: 16/9" src="https://www.youtube.com/embed/4mAgEi63IkY?si=wjC8prJt_LP8HDjq" frameborder="0" allowfullscreen></iframe>
    </v-sheet>

    <v-divider class="mt-3 mb-2"></v-divider>

    <v-sheet class="d-flex justify-center align-center py-2">
      <v-btn
        depressed
        class="font-weight-medium rounded-10 mr-2"
        width="140"
        :color="$route.path == '/concept/lafia_castle' ? '#ffaabe' : 'grey lighten-2 grey--text'"
        :dark="$route.path == '/concept/lafia_castle'"
        @click="$router.push('/concept/lafia_castle')"
      >
        라피아캐슬
      </v-btn>

      <v-btn
        depressed
        class="font-weight-medium rounded-10"
        width="140"
        :color="$route.path == '/concept/solo_premium' ? '#ffaabe' : 'grey lighten-2 grey--text'"
        :dark="$route.path == '/concept/solo_premium'"
        @click="$router.push('/concept/solo_premium')"
      >
        솔로프리미엄
      </v-btn>
    </v-sheet>

    <div class="d-flex justify-center mt-2 mb-4">
      <v-btn :color="tag == '전체' ? '#ffc0c0' : 'grey'" :outlined="tag != '전체'" depressed small class="mr-2 rounded-lg" style="padding-bottom: 2px" @click="tag = '전체'"> 전체 </v-btn>
      <v-btn :color="tag == '러블리' ? '#ffc0c0' : 'grey'" :outlined="tag != '러블리'" depressed small class="mr-2 rounded-lg" style="padding-bottom: 2px" @click="tag = '러블리'"> 러블리 </v-btn>
      <v-btn :color="tag == '몽환' ? '#ffc0c0' : 'grey'" :outlined="tag != '몽환'" depressed small class="mr-2 rounded-lg" style="padding-bottom: 2px" @click="tag = '몽환'"> 몽환 </v-btn>
      <v-btn :color="tag == '큐티' ? '#ffc0c0' : 'grey'" :outlined="tag != '큐티'" depressed small class="mr-2 rounded-lg" style="padding-bottom: 2px" @click="tag = '큐티'"> 큐티 </v-btn>
      <v-btn :color="tag == '판타지' ? '#ffc0c0' : 'grey'" :outlined="tag != '판타지'" depressed small class="rounded-lg" style="padding-bottom: 2px" @click="tag = '판타지'"> 판타지 </v-btn>
    </div>

    <v-row no-gutters>
      <v-col v-for="item in list" :key="item.id" cols="6" class="pa-2">
        <!-- 이미지 -->
        <v-img :src="'/upload/concept/thumbnail_image/' + item.pattern_1_image" position="top center" class="rounded-lg"></v-img>

        <!-- 텍스트 -->
        <div class="text-end">
          <!-- 가격 -->
          <p class="text-h6 font-weight-bold mt-1 mb-0" style="line-height: 20px">
            {{ $toComma(item.price) }}
          </p>

          <!-- 제목 -->
          <p class="text-subtitle-1 mb-1 mt-0">
            {{ item.title }}
          </p>

          <!-- 1대1 / 임의 -->
          <v-btn dark depressed small color="pink lighten-3" class="rounded-lg pa-1 mb-8"> 1대1보정 </v-btn>
        </div>
      </v-col>
      <!-- <v-col
                v-if="!list.length"
            >
                <div>
                    <v-icon size="54" color="primary lighten-1" class="d-flex justify-center mb-4">mdi-close-circle-outline</v-icon>
                    <p
                        class="text-center font-weight-bold primary--text"
                    >
                        해당 조건에 맞는 컨셉사진이 없습니다
                    </p>
                </div>
            </v-col> -->
    </v-row>
  </div>
</template>
<script>
export default {
  data: () => ({
    list: [],

    category: "솔로프리미엄",
    tag: "전체",
  }),

  mounted() {
    // 목록 불러오기
    this.load()
  },

  watch: {
    async category() {
      await this.load()
    },

    async tag() {
      await this.load()
    },
  },

  methods: {
    // 목록 불러오기
    load() {
      this.$http.post("/api/concept/select").then((res) => {
        console.log(res)
        if (res.data.length) {
          this.list = res.data

          this.list = this.list.filter((e) => e.category == this.category)

          if (this.tag != "전체") {
            this.list = this.list.filter((e) => e.tag.includes(this.tag))
          }
        }
      })
    },
  },
}
</script>
