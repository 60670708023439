<template>
  <v-card flat class="py-4" style="min-height: 200px">
    <!-- QNS 버튼-->
    <v-sheet class="d-flex justify-center align-center">
      <v-btn depressed class="py-4 rounded-1 mr-2" width="140" height="40" :color="selected === 0 ? '#d9bddd' : 'white grey--text'" @click="selected = 0">
        {{ $t("page.conceptbooth.btn_reservation_concern") }}
      </v-btn>

      <v-btn depressed class="py-2 rounded-1" width="140" height="40" :color="selected === 1 ? '#d9bddd' : 'white  grey--text'" @click="selected = 1">
        {{ $t("page.conceptbooth.btn_service_concern") }}
      </v-btn>
    </v-sheet>

    <v-container v-show="selected === 0">
      <HtmlReader id="castle-preservation-qna" />
    </v-container>

    <v-container v-show="selected === 1">
      <HtmlReader id="castle-service-qna" />
    </v-container>
    <div style="min-height: 100px"></div>
  </v-card>
</template>
<script>
import HtmlReader from "./HtmlReader"
export default {
  components: {
    HtmlReader,
  },
  data: () => ({
    selected: 0,
  }),
  methods: {},
}
</script>
<style scoped>
::v-deep .v-btn {
  border: 2px solid #ccc !important;
  font-size: 20px;
}
</style>
