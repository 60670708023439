<template>
  <v-dialog max-width="440" :value="visible" content-class="rounded-10" style="z-index: 15" @input="$emit('input', $event)">
    <v-sheet class="rounded-10 px-2 px-md-4 py-4 py-md-10 d-flex flex-column align-center" color="#f7f2f7">
      <img src="@/assets/warning.png" class="icon" />

      <v-container fluid>
        <p class="text-center px-1 text-body-1" v-html="$t(msg)"></p>
      </v-container>

      <v-container fluid class="d-flex justify-center mt-6">
        <v-btn color="#d9bddd" class="dialog_btn rounded-2 ml-2" style="font-size: 20px" width="35%" depressed @click="confirmClick" large> {{ $t("button.ok") }} </v-btn>
      </v-container>
    </v-sheet>
  </v-dialog>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    msg: {
      type: String,
      default: "",
    },
  },

  methods: {
    confirmClick() {
      this.$emit("confirm")
    },
  },
}
</script>
<style scoped>
.icon {
  width: 60px;
  height: 60px;
}
.dialog_btn {
  border: 2px solid #dadada !important;
  font-size: 16px;
}
::v-deep .v-btn:not(.v-btn--disabled) {
  color: rgb(71, 71, 71);
}
</style>
