<template>
  <div class="pb-10">
    <v-sheet outlined class="ma-2 pa-2 mb-2 d-flex align-center">
      <v-btn depressed @click="$emit('prev')" color="white"><v-icon> mdi-arrow-left </v-icon></v-btn>
      &nbsp;&nbsp;세부 내역 ({{ code }})
    </v-sheet>

    <!-- 본문 -->
    <v-sheet outlined class="pa-2 ma-2">
      <v-sheet v-if="isLoading">
        <span>Loading... Please wait</span>
      </v-sheet>
      <v-sheet v-else>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>{{ month + 1 }} 월</th>
                <th>예약 결제</th>
                <th>예약 취소</th>
                <th>매출 합계</th>
              </tr>
            </thead>
            <tbody>
              <tr class="accentBg">
                <td class="text-center">계</td>
                <td>{{ $toComma(total.payed_amount) }}</td>
                <td>{{ $toComma(total.cancel_amount) }}</td>
                <td>{{ $toComma(total.total_amount) }}</td>
              </tr>
              <tr v-for="(item, index) in items" :key="index">
                <td :class="{ 'red--text': dayjs(item.date).day() === 0 || dayjs(item.date).day() === 6 }">{{ dayjs(item.date).format("D") }}일</td>
                <td>{{ $toComma(item.payed_amount) }}</td>
                <td>{{ $toComma(item.cancel_amount) }}</td>
                <td>{{ $toComma(item.total_amount) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-sheet>
    </v-sheet>
  </div>
</template>
<script>
import statusService from "@/api/stats-service"
export default {
  props: {
    code: {
      type: String,
      required: true,
    },
    month: {
      type: Number,
      required: true,
    },
    total: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    items: [],
  }),
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      if (!this.code) {
        return
      }
      try {
        this.isLoading = true
        let fromDayjs = this.dayjs().month(this.month).startOf("month")
        let toDayjs = fromDayjs.endOf("month")

        const from = fromDayjs.format("YYYY-MM-DD")
        const to = toDayjs.format("YYYY-MM-DD")

        let result = await statusService.getPromotionSalesStats(false, from, to, this.code)
        result.sort((a, b) => a.date - b.date)
        this.items = result
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
<style></style>
