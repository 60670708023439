import axios from "@/api/restApi"

export default {
  /// 페이레터 결제 URL 요청
  async requestInternationalPay({ storeorderno, amount, payerid, payeremail, payinfo, currency = "KRW" }) {
    let returnurl = window.location.origin + "/api/payletter/result"
    const res = await axios.post("/api/payments/payletter/request", {
      storeorderno,
      amount,
      payerid,
      payeremail,
      payinfo,
      currency,
      returnurl,
    })

    return res.data
  },

  async cancelOrder({ orderListId, paymentKey, cancelAmount, cancelReason, isAdmin, isAll, currency = "KRW" }) {
    const res = await axios.post("/api/payments/payletter/order/cancel", {
      orderListId,
      paymentKey,
      cancelAmount,
      cancelReason,
      isAdmin,
      isAll,
      currency,
    })

    return res.data
  },
}
