<template>
  <div class="pb-16">
    <TitleLogo :title="$t('page.mypage.info.title')" />

    <!-- 목록 -->
    <v-sheet>
      <Update ref="myInfo" />
    </v-sheet>
  </div>
</template>
<script>
import TitleLogo from "@/views/components/TitleLogo"
import Update from "./components/Update"

export default {
  components: {
    TitleLogo,
    Update,
  },

  created() {
    // 비 로그인 시
    if (!this.$store.state.lafia_user.is_logined) {
      alert(this.$t("alert.error_need_authentication"))
      this.$router.push("/auth/login")
    }
  },
}
</script>
<style></style>
