<template>
  <div class="pb-10">
    <TitleLogo title="메이크업" />
    <v-divider></v-divider>
    <p class="text-center py-4" style="color: #595755; font-size: 14px">
      촬영의 퀄리티를 높일 수 있는, 전문가의 메이크업!<br />
      세라캐슬과 연계된 업체를 소개해 드려요!
    </p>
    <p class="warning--text text-center" :class="$vuetify.breakpoint.mobile ? 'small' : 'normal'">
      ** 세라캐슬과 별개의 업체이므로 모든 문의는 해당 업체로 해주셔야 합니다 **<br />
      ** 위치는 세라캐슬 내부에 있는 팝업 샵입니다 **
    </p>
    <MakerComponent name="민지" address="https://open.kakao.com/o/s9hdL8jg">
      <template v-slot:profile>
        <v-img class="ma-1 rounded-0" src="@/assets/maker/maker_minji.png" aspect-ratio="0.61" />
      </template>
      <template v-slot:title>
        <div :class="$vuetify.breakpoint.mobile ? 'small' : 'normal'" class="font-weight-medium">* 메이크업 & 헤어 스타일링 *</div>
        <ul :class="$vuetify.breakpoint.mobile ? 'xsmall' : 'medium'" class="mt-1" style="color: #595755">
          <li>
            (전) 아모레퍼시픽 뷰티 교육강사<br />
            서울패션위크 루키즈 페스티벌 패션쇼 메이크업 총괄 진행
          </li>
          <li>연극 행복을 찾아서 , 뮤지컬 급식왕, 오페라 마술피리 외 다수 공연 헤어&메이크업 진행</li>
          <li>삼성 갤럭시, 부산은행 외 다수 광고 촬영 헤어&메이크업 진행</li>
          <li>타이거 JK X 태권크리 MV 촬영 헤어&메이크업 진행</li>
          <li>김포공항 행사 퍼스널 컬러 클래스 진행</li>
          <li>개인화보, 프로필, 야외스냅 등 룩북 촬영 헤어&메이크업 다수 진행</li>
        </ul>
      </template>
    </MakerComponent>
    <!-- <MakerComponent name="지성" address="https://pf.kakao.com/_rxdWCxj">
      <template v-slot:profile>
        <v-img class="ma-1 rounded-0" src="@/assets/maker/maker_jisung.png" aspect-ratio="0.61" />
      </template>
      <template v-slot:title>
        <div :class="$vuetify.breakpoint.mobile ? 'small' : 'normal'" class="font-weight-medium">* 메이크업 & 헤어 스타일링 *</div>
        <ul :class="$vuetify.breakpoint.mobile ? 'xsmall' : 'medium'" class="mt-1" style="color: #595755">
          <li>정화예술대학교 메이크업과 졸업</li>
          <li>국가자격증 (메이크업,피부,네일) 보유</li>
          <li>미용사 종합면허증 보유</li>
          <li>대회수상경력 다량 보유</li>
          <li>2016년 ~현재 경력 약 8년</li>
          <li>광고 유튜브 패션쇼 등 다양한 분야 활동 중</li>
        </ul>
      </template>
    </MakerComponent> -->
  </div>
</template>
<script>
import TitleLogo from "@/views/components/TitleLogo"
import MakerComponent from "./components/MakerComponent"
export default {
  components: {
    TitleLogo,
    MakerComponent,
  },
}
</script>
<style scoped>
.xsmall {
  font-size: 8px;
}
.small {
  font-size: 10px;
}
.medium {
  font-size: 12px;
}

.normal {
  font-size: 14px;
}

ul {
  padding-left: 12px !important;
}
</style>
