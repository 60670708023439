function getPoint(e) {
  if (e.touches) {
    return { x: e.touches[0].clientX, y: e.touches[0].clientY }
  }
  return { x: e.clientX, y: e.clientY }
}

class Moveable {
  constructor() {
    this.x = 0
    this.y = 0
  }

  onTouchStart(e) {
    const point = getPoint(e)
    this.startX = point.x
    this.startY = point.y
    this.lastX = this.x
    this.lastY = this.y
  }

  onTouchMove(e) {
    const point = getPoint(e)
    this.x = this.lastX + (point.x - this.startX)
    this.y = this.lastY + (point.y - this.startY)
  }

  onTouchEnd(e) {}

  reset() {
    this.x = 0
    this.y = 0
  }
}
class Zoomable {
  constructor() {
    this.scale = 1
    this.origin = { x: 0, y: 0 }
  }

  onTouchStart(e, targetEl) {
    this.startPinchX = (e.touches[0].clientX + e.touches[1].clientX) / 2
    this.startPinchY = (e.touches[0].clientY + e.touches[1].clientY) / 2
    this.startPinchDistance = Math.hypot(e.touches[0].clientX - e.touches[1].clientX, e.touches[0].clientY - e.touches[1].clientY)
    this.lastScale = this.scale

    this.origin = {
      x: (e.touches[0].clientX + e.touches[1].clientX) / 2 - targetEl.getBoundingClientRect().left,
      y: (e.touches[0].clientY + e.touches[1].clientY) / 2 - targetEl.getBoundingClientRect().top,
    }
  }

  onTouchMove(e) {
    this.pinchX = (e.touches[0].clientX + e.touches[1].clientX) / 2
    this.pinchY = (e.touches[0].clientY + e.touches[1].clientY) / 2
    this.pinchDistance = Math.hypot(e.touches[0].clientX - e.touches[1].clientX, e.touches[0].clientY - e.touches[1].clientY)
    this.scale = (this.lastScale * this.pinchDistance) / this.startPinchDistance
  }

  onTouchEnd(e) {}

  onMouseWheel(e, targetEl) {
    const targetRect = targetEl.getBoundingClientRect()

    this.origin = {
      x: e.clientX - targetRect.left,
      y: e.clientY - targetRect.top,
    }

    this.scale = Math.min(10, Math.max(0.5, this.scale + e.deltaY * -0.01))
  }

  reset() {
    this.scale = 1
    this.origin = {
      x: 0,
      y: 0,
    }
  }
}

export class PinchZoom {
  constructor(eventEl, targetEl, onStartEditing, onEditing, onFinishEditing) {
    this.targetEl = targetEl
    this.eventEl = eventEl
    this.moveable = new Moveable()
    this.zoomable = new Zoomable()
    this.onStartEditing = onStartEditing
    this.onEditing = onEditing
    this.onFinishEditing = onFinishEditing
    this.targetEl.style.transformOrigin = "top left"
    this.lastMousePoint = { x: 0, y: 0 }
    this.mousePressed = false
    this.init()
  }

  isTouchable() {
    return navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
  }

  init() {
    if (!this.isTouchable()) {
      this.eventEl.addEventListener("mousedown", this.onMouseDown.bind(this))
      this.eventEl.addEventListener("mousemove", this.onMouseMove.bind(this))
      this.eventEl.addEventListener("mouseup", this.onMouseEnd.bind(this))
      this.eventEl.addEventListener("mousewheel", this.onMouseWheel.bind(this))
    } else {
      this.eventEl.addEventListener("touchstart", this.onTouchStart.bind(this))
      this.eventEl.addEventListener("touchmove", this.onTouchMove.bind(this))
      this.eventEl.addEventListener("touchend", this.onTouchEnd.bind(this))
    }
  }

  onMouseDown(e) {
    if (e.target.tagName == "BUTTON") return
    this.mousePressed = true
    this.moveable.onTouchStart(e)
    this.onStartEditing()
    e.preventDefault()
    e.stopPropagation()
  }

  onMouseMove(e) {
    if (!this.mousePressed) return
    this.lastMousePoint = { x: e.clientX, y: e.clientY }
    if (e.buttons !== 1) return
    this.moveable.onTouchMove(e)
    this.targetEl.style.transform = `translate(${this.moveable.x}px, ${this.moveable.y}px)`
    this.onEditing()
  }

  onMouseEnd(e) {
    if (!this.mousePressed) return
    this.mousePressed = false
    this.moveable.onTouchEnd(e)
    this.onFinishEditing()
  }

  onMouseWheel(e) {
    this.onStartEditing()
    this.zoomable.onMouseWheel(e, this.targetEl)
    const origin = this.zoomable.origin
    this.targetEl.style.transform = `translate(${origin.x}px, ${origin.y}px) scale(${this.zoomable.scale}) translate(${-origin.x}px, ${-origin.y}px)`
    this.onEditing()
    this.onFinishEditing()

    e.preventDefault()
    e.stopPropagation()
  }

  onTouchStart(e) {
    if (e.target.tagName == "BUTTON") return
    if (e.touches.length > 2 || e.touches.length == 0) return
    this.mousePressed = true
    if (e.touches.length === 1) {
      this.moveable.onTouchStart(e)
    } else if (e.touches.length === 2) {
      this.zoomable.onTouchStart(e, this.targetEl)
    }
    this.onStartEditing()
    e.preventDefault()
    e.stopPropagation()
  }

  onTouchMove(e) {
    if (!this.mousePressed) return

    if (e.touches.length === 1) {
      this.moveable.onTouchMove(e)
      this.targetEl.style.transform = `translate(${this.moveable.x}px, ${this.moveable.y}px)`
    } else if (e.touches.length === 2) {
      this.zoomable.onTouchMove(e)
      const origin = this.zoomable.origin
      this.targetEl.style.transform = `translate(${origin.x}px, ${origin.y}px) scale(${this.zoomable.scale}) translate(${-origin.x}px, ${-origin.y}px)`
    }
    this.onEditing()
  }

  onTouchEnd(e) {
    if (!this.mousePressed) return
    this.mousePressed = false

    this.moveable.onTouchEnd(e)
    this.zoomable.onTouchEnd(e)
    this.onFinishEditing()
  }

  reset() {
    this.moveable.reset()
    this.zoomable.reset()
  }
}
