<template>
  <v-sheet class="py-6 px-8">
    <p class="text-h6 font-weight-bold grey--text text--darken-3 text-center mb-6">프로모션 수정하기</p>

    <v-sheet class="mt-2 px-4">
      <v-text-field class="rounded-10" label="이름" dense outlined v-model.trim="updateItem.name"></v-text-field>
      <v-text-field class="rounded-10" label="코드" dense outlined v-model.trim="updateItem.code"></v-text-field>
      <v-text-field class="rounded-10" label="대여가격(KRW)" dense outlined type="number" hide-spin-buttons v-model="updateItem.clothPrice"></v-text-field>
      <v-text-field class="rounded-10" label="주말대여가격(KRW)" dense outlined type="number" hide-spin-buttons v-model="updateItem.clothPriceWeekend"></v-text-field>
      <v-text-field class="rounded-10" label="10분연장가격(KRW)" dense outlined type="number" hide-spin-buttons v-model="updateItem.tenOverTimePrice"></v-text-field>
      <v-text-field class="rounded-10" label="드레스변경(KRW)" dense outlined type="number" hide-spin-buttons v-model="updateItem.changeDressPrice"></v-text-field>

      <div class="font-weight-bold text-body-1 py-2">프로모션 유효기간</div>
      <v-row>
        <v-menu v-model="openAtMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="updateItem.openAt" label="링크시작일" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="green"></v-text-field>
          </template>
          <v-date-picker v-model="updateItem.openAt" no-title scrollable color="green" @input="openAtMenu = false"></v-date-picker>
        </v-menu>
        <v-menu v-model="closeAtMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="updateItem.closeAt" label="링크종료일" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="warning"></v-text-field>
          </template>
          <v-date-picker v-model="updateItem.closeAt" no-title scrollable color="warning" @input="closeAtMenu = false"> </v-date-picker>
        </v-menu>
      </v-row>

      <div class="font-weight-bold text-body-1 py-2">예약 가능 기간</div>
      <v-row>
        <v-menu v-model="startAtMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="updateItem.startAt" label="예약 시작일자" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="green"></v-text-field>
          </template>
          <v-date-picker v-model="updateItem.startAt" no-title scrollable color="green" @input="startAtMenu = false"> </v-date-picker>
        </v-menu>
        <v-menu v-model="endAtMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="updateItem.endAt" label="예약 종료일자" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="warning"></v-text-field>
          </template>
          <v-date-picker v-model="updateItem.endAt" no-title scrollable color="warning" @input="endAtMenu = false"> </v-date-picker>
        </v-menu>
      </v-row>

      <v-checkbox v-model="updateItem.enabled" class="shrink mt-4 ml-4" label="활성화 상태" color="primary" hide-details dense></v-checkbox>

      <v-row class="mt-5">
        <PromotionCoupons :coupons="updateItem.editCoupons" @addCoupon="visibleCouponSelect = true" />
      </v-row>

      <div class="font-weight-bold text-h6 ml-4 mt-4">한글</div>
      <v-sheet class="mt-2">
        <div class="d-flex justify-space-between">
          <span class="grey--text text-body-2">필요한 상품에 대한 설명을 입력해 주세요</span>
          <v-btn color="primary" depressed outlined small @click="clear()">삭제</v-btn>
        </div>
        <!-- 설명 -->
        <TipTapWriter class="tiptap_style mt-2" :options="options" :key="componentKey" />
      </v-sheet>

      <v-sheet class="mt-2">
        <div class="d-flex justify-space-between">
          <span class="grey--text text-body-2">필요한 상품에 대한 설명(2)을 입력해 주세요</span>
          <v-btn color="primary" depressed outlined small @click="clearDesc2()">삭제</v-btn>
        </div>
        <!-- 설명 -->
        <TipTapWriter class="tiptap_style mt-2" :options="desc2Options" :key="'desc2' + desc2ComponentKey" />
      </v-sheet>

      <div class="font-weight-bold text-h6 ml-4 mt-4">영어</div>
      <v-sheet class="mt-2">
        <div class="d-flex justify-space-between">
          <span class="grey--text text-body-2">필요한 상품에 대한 설명(1)을 입력해 주세요</span>
          <v-btn color="primary" depressed outlined small @click="clear('en')">삭제</v-btn>
        </div>
        <!-- 설명 -->
        <TipTapWriter class="tiptap_style mt-2" :options="en.options" :key="'en-desc1' + en.componentKey" />
      </v-sheet>

      <v-sheet class="mt-2">
        <div class="d-flex justify-space-between">
          <span class="grey--text text-body-2">필요한 상품에 대한 설명(2)을 입력해 주세요</span>
          <v-btn color="primary" depressed outlined small @click="clearDesc2('en')">삭제</v-btn>
        </div>
        <!-- 설명 -->
        <TipTapWriter class="tiptap_style mt-2" :options="en.desc2Options" :key="'en-desc2' + en.desc2ComponentKey" />
      </v-sheet>

      <div class="font-weight-bold text-h6 ml-4 mt-4">일본어</div>
      <v-sheet class="mt-2">
        <div class="d-flex justify-space-between">
          <span class="grey--text text-body-2">필요한 상품에 대한 설명(1)을 입력해 주세요</span>
          <v-btn color="primary" depressed outlined small @click="clear('jp')">삭제</v-btn>
        </div>
        <!-- 설명 -->
        <TipTapWriter class="tiptap_style mt-2" :options="jp.options" :key="'jp-desc1' + jp.componentKey" />
      </v-sheet>

      <v-sheet class="mt-2">
        <div class="d-flex justify-space-between">
          <span class="grey--text text-body-2">필요한 상품에 대한 설명(2)을 입력해 주세요</span>
          <v-btn color="primary" depressed outlined small @click="clearDesc2('jp')">삭제</v-btn>
        </div>
        <!-- 설명 -->
        <TipTapWriter class="tiptap_style mt-2" :options="jp.desc2Options" :key="'jp-desc2' + jp.desc2ComponentKey" />
      </v-sheet>
    </v-sheet>
    <v-sheet class="d-flex justify-center mt-5">
      <v-btn class="rounded-10 px-6 mr-4" color="grey" large dark depressed @click="$emit('close')">
        <v-icon small left>mdi-cancel</v-icon>
        취소하기
      </v-btn>
      <v-btn class="rounded-10 px-6" color="primary" large dark depressed @click="updateSubmit()">
        <v-icon small left>mdi-database-edit-outline</v-icon>
        수정하기
      </v-btn>
    </v-sheet>
    <PackageCouponSelect :visible="visibleCouponSelect" @input="(e) => (visibleCouponSelect = e)" :packageCode="updateItem.code" @addCoupon="addCoupon" />
  </v-sheet>
</template>
<script>
import TipTapWriter from "@/components/tiptap/Writer"
import PromotionCoupons from "./PromotionCoupons"
import PackageCouponSelect from "./PackageCouponSelect"
export default {
  props: ["updateItem_import"],
  components: {
    TipTapWriter,
    PromotionCoupons,
    PackageCouponSelect,
  },
  inject: ["coupons_info"],
  data: () => ({
    yesOrNo: ["Y", "N"],
    updateItem: {},

    startAtMenu: false,
    endAtMenu: false,
    openAtMenu: false,
    closeAtMenu: false,

    //TipTap
    options: {
      content: "",
      editable: true,
      supportImage: true,
      supportVideo: true,
    },
    componentKey: 0,

    //TipTap
    desc2Options: {
      content: "",
      editable: true,
      supportImage: true,
      supportVideo: true,
    },
    desc2ComponentKey: 0,

    en: {
      //TipTap
      options: {
        content: "",
        editable: true,
        supportImage: true,
        supportVideo: true,
      },
      componentKey: 0,

      //TipTap
      desc2Options: {
        content: "",
        editable: true,
        supportImage: true,
        supportVideo: true,
      },
      desc2ComponentKey: 0,
    },
    jp: {
      //TipTap
      options: {
        content: "",
        editable: true,
        supportImage: true,
        supportVideo: true,
      },
      componentKey: 0,

      //TipTap
      desc2Options: {
        content: "",
        editable: true,
        supportImage: true,
        supportVideo: true,
      },
      desc2ComponentKey: 0,
    },

    visibleCouponSelect: false,
  }),

  mounted() {
    this.updateItem = {
      ...this.updateItem_import,
      editCoupons: this.updateItem_import.regCoupons && this.updateItem_import.regCoupons.slice(),
      isTraveler: this.updateItem_import.role === "TRAVELER" ? "Y" : "N",
      openAt: this.updateItem_import.openAt ? this.dayjs(this.updateItem_import.openAt).format("YYYY-MM-DD") : null,
      closeAt: this.updateItem_import.closeAt ? this.dayjs(this.updateItem_import.closeAt).format("YYYY-MM-DD") : null,
      startAt: this.updateItem_import.startAt ? this.dayjs(this.updateItem_import.startAt).format("YYYY-MM-DD") : null,
      endAt: this.updateItem_import.endAt ? this.dayjs(this.updateItem_import.endAt).format("YYYY-MM-DD") : null,
    }
    this.options.content = this.updateItem.content
    this.desc2Options.content = this.updateItem.content2

    this.en.options.content = this.updateItem.content_en
    this.en.desc2Options.content = this.updateItem.content2_en

    this.jp.options.content = this.updateItem.content_jp
    this.jp.desc2Options.content = this.updateItem.content2_jp

    this.componentKey++
    this.desc2ComponentKey++

    this.en.componentKey++
    this.en.desc2ComponentKey++

    this.jp.componentKey++
    this.jp.desc2ComponentKey++

    console.log(this.updateItem)
  },

  methods: {
    clear(lang = "ko") {
      if (lang === "ko") {
        this.options.content = ""
        this.componentKey++
      } else if (lang === "en") {
        this.en.options.content = ""
        this.en.componentKey++
      } else if (lang === "jp") {
        this.jp.options.content = ""
        this.jp.componentKey++
      }
    },
    clearDesc2(lang = "ko") {
      if (lang === "ko") {
        this.desc2Options.content = ""
        this.desc2ComponentKey++
      } else if (lang === "en") {
        this.en.desc2Options.content = ""
        this.en.desc2ComponentKey++
      } else if (lang === "jp") {
        this.jp.desc2Options.content = ""
        this.jp.desc2ComponentKey++
      }
    },
    addCoupon(aCoupon) {
      console.log("addCoupon", aCoupon, this.updateItem.editCoupons)
      // const found = this.updateItem.editCoupons.find((coupon) => coupon.id == aCoupon.id)
      // if (found) {
      //   this.$toast.error("이미 등록되었습니다.")
      //   return
      // }
      this.updateItem.editCoupons.push({
        id: aCoupon.id,
        title: aCoupon.title,
      })
      this.visibleCouponSelect = false
    },

    removeCoupon(index) {
      this.updateItem.editCoupons.splice(index, 1)
    },
    // 목록
    updateSubmit: _.debounce(async function () {
      // 제목 검증
      // if (this.updateItem.name == "") {
      //   alert("이름을 입력해주세요")
      //   return
      // }

      // 컨셉소개 내용 검증
      if (this.updateItem.code == "") {
        alert("프로모션 코드를 입력해주세요")
        return
      }

      // 가격 검증
      if (Number(this.updateItem.clothPrice) < 0 || Number(this.updateItem.clothPriceWeekend) < 0 || Number(this.updateItem.tenOverTimePrice) < 0 || Number(this.updateItem.changeDressPrice) < 0) {
        alert("유효한 가격 정보를 입력해주세요")
        return
      }

      if (!this.updateItem.endAt) {
        alert("종료일자를 입력해주세요")
        return
      }

      this.$http
        .post("/api/promotion/update", {
          params: {
            code: this.updateItem.code,
            name: this.updateItem.name,
            clothPrice: Number(this.updateItem.clothPrice),
            clothPriceWeekend: Number(this.updateItem.clothPriceWeekend),
            tenOverTimePrice: Number(this.updateItem.tenOverTimePrice),
            changeDressPrice: Number(this.updateItem.changeDressPrice),
            role: "PACKAGE",
            enabled: this.updateItem.enabled,
            openAt: this.updateItem.openAt,
            closeAt: this.updateItem.closeAt,
            startAt: this.updateItem.startAt,
            endAt: this.updateItem.endAt,
            description: this.options.content,
            description2: this.desc2Options.content ? this.desc2Options.content : null,

            description_en: this.en.options.content,
            description2_en: this.en.desc2Options.content ? this.en.desc2Options.content : null,

            description_jp: this.jp.options.content,
            description2_jp: this.jp.desc2Options.content ? this.jp.desc2Options.content : null,

            id: this.updateItem.id,
          },
        })
        .then(async (res) => {
          if (res) {
            try {
              await this.$http.post("/api/promotion/coupons/delete", {
                packageCode: this.updateItem.code,
              })
              await Promise.all(this.updateItem.editCoupons.map((coupon) => this.InsertPromotionCoupon(coupon)))
            } catch (e) {
              this.$toast.error("쿠폰이 정상발급이 되지 않았습니다.")
            }
            alert("정상적으로 등록되었습니다.")
            this.$emit("close")
          } else {
            alert("등록되지 않았습니다")
          }
        })
        .catch((e) => {
          console.log(e)
          alert(this.$t("alert.error_page"))
        })
    }, 500),

    async InsertPromotionCoupon(coupon) {
      return await this.$http.post("/api/promotion/coupons/insert", {
        packageCode: this.updateItem.code,
        coupon_id: coupon.id,
      })
    },
  },
}
</script>
<style scoped>
.my-clipper {
  width: 100%;
}

.placeholder {
  width: 100%;
  height: 150px;
}
</style>
