<template>
  <div>
    <!-- 제목 -->
    <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">지급된 쿠폰 관리</font>
    </v-sheet>

    <v-sheet outlined class="ma-2" v-if="stage !== RegisterCouponStages[0]">
      <UserSelect v-if="stage === RegisterCouponStages[1]" @selectUsers="addUsers"></UserSelect>
      <v-divider v-show="stage === RegisterCouponStages[1]" class="py-2" style="background-color: rgb(247, 247, 247)"></v-divider>
      <!-- 선택된 사용자 -->
      <v-sheet class="ma-2 pa-4 font-weight-medium">
        <div v-if="selected_users.length === 0">지급할 대상자가 없습니다.</div>
        <v-chip v-else v-for="user in selected_users" :key="user.id" close @click:close="removeUser(user)" class="mx-1 my-1" large>{{ user.name }}({{ user.user_id }})</v-chip>
        <div class="d-flex justify-end mt-2">
          <v-btn class="px-6 ml-3" color="warning" outlined dark depressed @click.stop="clearSelectedUser" :disabled="selected_users.length === 0">
            <v-icon small left>mdi-account-multiple-remove-outline</v-icon>
            선택 해제
          </v-btn>
          <v-btn v-show="selected_users.length > 0" class="px-6 ml-3" color="primary" outlined dark depressed @click.stop="stage = RegisterCouponStages[2]" :disabled="selected_users.length === 0">
            <v-icon small left>mdi-gift-outline</v-icon>
            쿠폰 선택
          </v-btn>
        </div>
      </v-sheet>
      <CouponSelect v-if="stage === RegisterCouponStages[2]" @selectCoupons="selectCoupons"></CouponSelect>
      <v-divider class="py-2" style="background-color: rgb(247, 247, 247)"></v-divider>
      <CouponDateSelect
        v-if="stage === RegisterCouponStages[3]"
        :start="dayjs(selected_coupon.startAt).format('YYYY-MM-DD')"
        :end="dayjs(selected_coupon.expireAt).format('YYYY-MM-DD')"
        @confirm="confirmDate"
      />
      <v-divider class="py-2" style="background-color: rgb(247, 247, 247)"></v-divider>

      <v-sheet v-show="issueCouponProgress">
        <v-card flat class="pa-4">
          <div class="d-flex flex-column justify-center align-center mt-2">
            <v-progress-linear indeterminate color="cyan" style="width: 280px"></v-progress-linear>
            <div class="mt-4">(총 {{ selected_users.length }}명 발급진행중... 잠시만 기다려주세요.)</div>
          </div>
        </v-card>
      </v-sheet>
    </v-sheet>

    <!-- 본문 -->
    <v-sheet outlined class="pa-2 ma-2" v-show="stage == RegisterCouponStages[0]">
      <!-- 검색 -->
      <v-sheet outlined class="pa-2 mb-2 d-flex">
        <v-sheet class="d-flex align-center" width="800">
          <!-- 유형 -->
          <v-sheet width="160" class="mr-3">
            <v-select outlined dense hide-details :items="select_list.search_type" v-model="search_type"></v-select>
          </v-sheet>

          <!-- 키워드 -->
          <v-text-field placeholder="검색어를 입력해주세요" outlined dense hide-details v-model="keyword" @keyup.enter="search()" clearable></v-text-field>

          <!-- 제출 버튼 -->
          <v-btn height="40" class="px-6 ml-3" color="primary" dark depressed @click="search()">
            <v-icon small left>mdi-magnify</v-icon>
            검색하기
          </v-btn>
        </v-sheet>
        <v-spacer></v-spacer>

        <!-- 새로고침 버튼 -->
        <v-btn height="40" class="px-3 ml-2 my-auto" color="primary" dark depressed @click="refresh()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-sheet>

      <v-data-table
        :items="registeredCoupons"
        item-key="issuedId"
        :headers="tableHeaders"
        no-data-text="발급된 쿠폰이 없습니다."
        :loading="loading"
        loading-text="Loading... Please wait"
        :options.sync="options"
        :server-items-length="totalCount"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50],
          'show-current-page': true,
          'show-first-last-page': true,
        }"
        :page.sync="pageNum"
      >
        <template v-slot:[`item.title`]="{ item }">
          <span>{{ item.title || "삭제된 쿠폰" }}</span>
        </template>
        <template v-slot:[`item.issuedCreated`]="{ item }">
          <span>{{ dayjs(item.issuedCreated).format("YYYY.MM.DD HH:mm") }}</span>
        </template>
        <template v-slot:[`item.startAt`]="{ item }">
          <span>{{ dayjs(item.startAt).format("YYYY.MM.DD") }}</span>
        </template>
        <template v-slot:[`item.expireAt`]="{ item }">
          <span>{{ dayjs(item.expireAt).format("YYYY.MM.DD") }}</span>
        </template>
        <template v-slot:[`item.usedAt`]="{ item }">
          <span>{{ item.usedAt && dayjs(item.usedAt).format("YYYY.MM.DD HH:mm") }}</span>
        </template>
        <template v-slot:[`item.isExpired`]="{ item }">
          <span>{{ item.isExpired ? "Y" : "N" }}</span>
        </template>
        <template v-slot:[`item.discount_KRW`]="{ item }">
          <span>{{ $toComma(item.discount_KRW) }}원</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon @click="deleteCoupon(item)" v-show="!item.usedAt">mdi-delete</v-icon>
        </template>
      </v-data-table>

      <!-- 버튼 -->
      <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
        <v-btn class="px-6 ml-3" color="blue" large dark depressed @click.stop="stage = 'select_user'">
          <v-icon small left>mdi-database-plus-outline</v-icon>
          쿠폰 개별 발급
        </v-btn>
      </v-card>
    </v-sheet>
  </div>
</template>
<script>
import UserSelect from "../components/UserSelect"
import CouponSelect from "../components/CouponSelect.vue"
import CouponDateSelect from "../components/CouponDateSelect.vue"

export default {
  components: {
    UserSelect,
    CouponSelect,
    CouponDateSelect,
  },
  data: () => ({
    RegisterCouponStages: ["list", "select_user", "select_coupon", "select_date"],
    stage: "list",
    select_list: {
      search_type: ["아이디", "쿠폰"],
    },
    search_type: "아이디",
    keyword: "",

    selected_users: [],
    selected_coupon: null,
    issueCouponProgress: false,

    loading: false,
    registeredCoupons: [],
    totalCount: 0,
    pageNum: 1,

    //pagination
    options: {},

    tableHeaders: [
      { text: "아이디", value: "user_id", width: "260px" },
      { text: "이름", value: "user_name" },
      { text: "쿠폰", value: "title" },
      { text: "지급일", value: "issuedCreated" },
      { text: "사용일", value: "usedAt" },
      { text: "할인금액(원)", value: "discount_KRW" },
      { text: "시작일", value: "startAt" },
      { text: "종료일", value: "expireAt" },
      { text: "만료", value: "isExpired", width: "80px" },
      { text: "종류", value: "type", width: "160px" },
      { text: "Actions", value: "actions", width: "80px", sortable: false },
    ],
  }),

  watch: {
    options: {
      handler() {
        console.log(this.options)
        this.load(this.options.page, this.options.itemsPerPage)
      },
      deep: true,
    },
  },

  mounted() {
    this.search()
  },

  computed: {},

  methods: {
    refresh() {
      this.load(this.options.page, this.options.itemsPerPage)
    },
    search() {
      this.pageNum = 1
      this.load()
    },

    async load(page = 1, itemPerPage = 10) {
      console.log("search", this.search_type, this.keyword)
      console.log(process.env.VUE_APP_CONSOLE_ENABLE)

      let params = {
        limit: itemPerPage,
        offset: (page - 1) * itemPerPage,
      }

      if (this.keyword && this.search_type === "아이디") {
        params["user_id"] = this.keyword
      } else if (this.keyword && this.search_type === "쿠폰") {
        params["coupon_name"] = this.keyword
      }

      try {
        this.loading = true
        const result = await this.$http.get("/api/coupon/getCoupons", {
          params,
        })
        console.log(result.data)
        this.registeredCoupons = result.data.data
        this.totalCount = result.data.totalCount
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    addUsers(users) {
      let max_users = 20
      users.forEach((user) => {
        let index = this.selected_users.indexOf(user)
        if (index < 0) {
          this.selected_users.push(user)
        }
      })
      if (this.selected_users.length > max_users) {
        alert("최대 20명까지만 선택이 가능합니다.")
        this.selected_users = this.selected_users.slice(0, max_users)
      }
    },
    removeUser(user) {
      let index = this.selected_users.indexOf(user)
      if (index >= 0) {
        this.selected_users.splice(index, 1)
      }
    },

    clearSelectedUser() {
      this.selected_users = []
      this.stage = this.RegisterCouponStages[0]
      this.selected_coupon = null
    },
    selectCoupons(coupons) {
      console.log("selectCoupons", coupons)
      if (coupons && coupons.length > 0) {
        this.selected_coupon = coupons[0]
        this.stage = this.RegisterCouponStages[3]
        // this.issueCoupons()
      } else {
        this.selected_coupon = null
      }
    },
    confirmDate({ start, end }) {
      console.log("confirmDate", this.selected_coupon)
      this.selected_coupon.startAt = start
      this.selected_coupon.expireAt = end
      this.issueCoupons()
    },

    issueCoupons: _.debounce(async function () {
      console.log("selectCoupons")

      if (!this.selected_coupon) {
        alert("쿠폰을 선택해 주세요")
        return
      }
      if (!this.selected_users.length === 0) {
        alert("사용자를 선택해 주세요")
        return
      }

      try {
        this.issueCouponProgress = true
        const issuePromise = this.selected_users.map((user) => {
          return this.$http.post("/api/coupon/issueCoupon", {
            coupon_id: this.selected_coupon.id,
            user_id: user.user_id,
            user_name: user.name,
            admin_id: this.$store.state.lafia_admin.user_id,
            startAt: this.dayjs(this.selected_coupon.startAt).format("YYYY-MM-DD"),
            expireAt: this.selected_coupon.expireAt ? this.dayjs(this.selected_coupon.expireAt).format("YYYY-MM-DD") : this.dayjs().format("YYYY-MM-DD"),
          })
        })

        await Promise.all([...issuePromise, new Promise((resolve) => setTimeout(() => resolve(), 1000))])

        this.search()
        this.stage = this.RegisterCouponStages[0]
        this.selected_users = []
        this.selected_coupon = null
        alert("쿠폰을 정상적으로 발급하였습니다.")
      } catch (e) {
        console.log(e)
        alert("쿠폰발급에 실패하였습니다.")
      } finally {
        this.issueCouponProgress = false
      }
    }, 500),

    deleteCoupon: _.debounce(async function (coupon) {
      if (coupon.usedAt) {
        return
      }
      if (confirm(`${coupon.user_name} 에게 발급된 ${coupon.title} 쿠폰을 삭제하시겠습니까?`))
        try {
          const res = await this.$http.post("/api/coupon/revokeCoupon", {
            registered_coupon_id: coupon.issuedId.toString(),
          })
          if (res.data.affectedRows > 0) {
            this.search()
            this.$toast.success("발급한 쿠폰을 회수하였습니다.")
          } else {
            this.$toast.error("발급한 쿠폰을 회수하지 못했습니다.")
          }
        } catch (e) {
          this.$toast.error("발급한 쿠폰을 회수하지 못했습니다.")
        }
    }, 500),
  },
}
</script>
<style></style>
