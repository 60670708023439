<template>
  <div>
    <!-- 본문 -->
    <div class="py-8">
      <v-sheet class="py-4 px-4 mx-auto rounded-lg text-center" width="80%" outlined>
        <!-- 로고 & 문구 -->
        <div class="d-flex mb-2 mx-auto">
          <v-img height="120" width="150" contain src="@/assets/logo/menu-logo.png"></v-img>
        </div>
        <p class="text-center text-subtitle-1 font-weight-medium mt-2 grey--text text--darken-2">{{ $t("page.register.title") }}</p>

        <!-- 입력 & 버튼 -->
        <v-sheet width="94%" class="mt-8 mx-auto">
          <!-- 아이디 -->
          <v-text-field
            :class="$vuetify.breakpoint.mobile ? '' : 'mx-16'"
            outlined
            dense
            v-model.trim="user_id"
            :label="$t('page.login.id')"
            :placeholder="$t('page.register.input-email-placeholder')"
            persistent-placeholder
            @change="$v.user_id.$touch()"
            @blur="$v.user_id.$touch()"
            :error-messages="user_id_Errors"
          ></v-text-field>

          <!-- 비밀번호 -->
          <v-text-field
            :class="$vuetify.breakpoint.mobile ? '' : 'mx-16'"
            class="mt-2"
            outlined
            dense
            v-model="password"
            :label="$t('page.login.password')"
            persistent-placeholder
            type="password"
            @change="$v.password.$touch()"
            @blur="$v.password.$touch()"
            :error-messages="password_Errors"
          ></v-text-field>

          <!-- 비밀번호 확인 -->
          <v-text-field
            :class="$vuetify.breakpoint.mobile ? '' : 'mx-16'"
            class="mt-2"
            outlined
            dense
            v-model="password_check"
            :label="$t('page.register.password-check-label')"
            persistent-placeholder
            type="password"
            @change="$v.password_check.$touch()"
            @blur="$v.password_check.$touch()"
            :error-messages="password_check_Errors"
          ></v-text-field>

          <!-- 실명 -->
          <v-text-field
            :class="$vuetify.breakpoint.mobile ? '' : 'mx-16'"
            class="mt-2"
            outlined
            dense
            v-model.trim="name"
            :label="$t('page.mypage.info.name')"
            persistent-placeholder
            @change="$v.name.$touch()"
            @blur="$v.name.$touch()"
            :error-messages="name_Errors"
          ></v-text-field>

          <!-- 연락처 -->
          <v-text-field
            :class="$vuetify.breakpoint.mobile ? '' : 'mx-16'"
            class="mt-2"
            outlined
            dense
            v-model.trim="phone"
            :label="$t('page.mypage.info.phone')"
            type="number"
            hide-spin-buttons
            persistent-placeholder
            @change="$v.phone.$touch()"
            @blur="$v.phone.$touch()"
            :error-messages="phone_Errors"
          ></v-text-field>

          <!-- 이메일 -->
          <!-- <v-text-field
            :class="$vuetify.breakpoint.mobile ? '' : 'mx-16'"
            class="mt-2"
            outlined
            dense
            v-model="email"
            label="이메일"
            persistent-placeholder
            @change="$v.email.$touch()"
            @blur="$v.email.$touch()"
            :error-messages="email_Errors"
          ></v-text-field> -->

          <!-- 가입하기 -->
          <div class="mt-3" :class="$vuetify.breakpoint.mobile ? '' : 'mx-16'">
            <v-btn color="primary" width="100%" dark large depressed @click="submit()"> {{ $t("page.register.register-btn-title") }} </v-btn>
          </div>
        </v-sheet>
      </v-sheet>
    </div>
  </div>
</template>
<script>
// 검증
import { validationMixin } from "vuelidate"
import { required, sameAs, minLength, email } from "vuelidate/lib/validators"
import authService from "@/api/auth-service"
import { mapGetters, mapMutations } from "vuex"

export default {
  mixins: [validationMixin],

  validations: {
    user_id: {
      email,
      required,
      minLength: minLength(6),
    },

    password: {
      required,
      minLength: minLength(6),
    },

    password_check: {
      required,
      sameAsPassword: sameAs("password"),
    },

    name: {
      required,
    },

    phone: {
      required,
    },
  },

  data: () => ({
    // 입력값
    user_id_dup_error: false,
    user_id: "",
    password: "",
    password_check: "",
    name: "",
    phone: "",
    // email: "",

    // 중복 체크용 목록
    //user_id_list: [],
    //email_list: [],
  }),

  computed: {
    ...mapGetters(["isKRW"]),
    // 아이디 Error 문구
    user_id_Errors() {
      const errors = []
      if (!this.$v.user_id.$dirty) return errors
      !this.$v.user_id.required && errors.push(this.$t("page.login.input-id-hint"))
      !this.$v.user_id.minLength && errors.push("아이디는 최소 6자 이상이여야합니다")
      !this.$v.user_id.email && errors.push("이메일 형식으로 입력해주세요")
      this.user_id_dup_error && errors.push("이미 가입된 아이디가 존재합니다.")
      return errors
    },

    // 비밀번호 Error 문구
    password_Errors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push(this.$t("page.login.input-password-hint"))
      !this.$v.password.minLength && errors.push(this.$t("page.mypage.info.password-min-length-rule"))
      return errors
    },

    // 비밀번호 확인 Error 문구
    password_check_Errors() {
      const errors = []
      if (!this.$v.password_check.$dirty) return errors
      !this.$v.password_check.required && errors.push(this.$t("page.register.input-re-password"))
      !this.$v.password_check.sameAsPassword && errors.push(this.$t("page.register.password-confirm-mismatch"))
      return errors
    },

    // 실명 Error 문구
    name_Errors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.required && errors.push(this.$t("page.findId.warning-input-name"))
      return errors
    },

    // 연락처 Error 문구
    phone_Errors() {
      const errors = []
      if (!this.$v.phone.$dirty) return errors
      !this.$v.phone.required && errors.push(this.$t("page.findId.warning-input-phone"))
      return errors
    },

    // 이메일 Error 문구
    // email_Errors() {
    //   const errors = []
    //   if (!this.$v.email.$dirty) return errors
    //   !this.$v.email.required && errors.push("이메일을 입력해주세요")
    //   !this.$v.email.email && errors.push("이메일 형식으로 입력해주세요")
    //   this.email_list.includes(this.$v.email.$model) && errors.push("입력하신 이메일은 이미 존재합니다.")
    //   return errors
    // },

    trimedUserId() {
      return this.user_id.trim()
    },

    trimedName() {
      this.name.trim()
    },
  },

  created() {
    // 기존 유저정보 검색
    // this.loadUserList()
  },

  methods: {
    ...mapMutations(["login"]),
    // 기존 유저정보 검색
    // async loadUserList() {
    //   await this.$http.post("/api/user/select").then((res) => {
    //     if (res.data.length) {
    //       this.user_id_list = res.data.map((e) => e.user_id)
    //       this.email_list = res.data.map((e) => e.email)
    //     }
    //   })
    // },

    // 가입하기
    submit: _.debounce(async function () {
      console.log("submit")
      this.user_id_dup_error = false
      // 입력값 기본 검증
      this.$v.$touch()

      // 입력값 기본 검증 통과 시
      if (!this.$v.$invalid) {
        try {
          let res = await authService.checkDuplicateEmail(this.$v.user_id.$model)
          console.log(res)
          if (res) {
            let loginTypeStr = res.user_id
            switch (res.provider_type.toLowerCase()) {
              case "google":
                loginTypeStr = this.isKRW ? "구글 계정" : "Google Account"
                break
              case "kakao":
                loginTypeStr = this.isKRW ? "카카오 계정" : "Kakao Account"
                break
              case "naver":
                loginTypeStr = this.isKRW ? "네이버 계정" : "Naver Account"
                break
              default:
                break
            }

            this.user_id_dup_error = true
            alert(`이미 ${loginTypeStr} 으로 가입된 계정입니다.`)
          } else {
            const user = {
              user_id: this.user_id,
              password: this.password,
              name: this.name,
              phone: this.phone,
              email: this.user_id,
              provider_type: "normal",
            }
            // db 에 저장
            await authService.doUserInsert(user)

            try {
              if (this.$route.query.qrcode) {
                await authService.updateQrCode(user.user_id, this.$route.query.qrcode)
              } else if (this.$route.query.visitQrcode) {
                await authService.updatePayQrcode(this.user_id, this.$route.query.visitQrcode)
              } else if (this.$route.query.pay_qrcode) {
                await authService.updatePayQrcode(this.user_id, this.$route.query.pay_qrcode)
              }
            } catch (e) {
              console.log(e)
              alert(this.$t("alert.error_failed_qrocde_authentication"))
              this.$router.replace("/auth/login")
              return
            }

            this.login({ ...user, profile_image: "/upload/user/profile_image/default.png" })

            if (this.$route.query.callbackUrl) {
              this.$router.replace(this.$route.query.callbackUrl)
            } else if (this.$route.query.qrcode) {
              this.$router.replace("/?qrlogin=true")
            } else if (this.$route.query.event) {
              this.$router.replace(`/reservation/buy?event=${this.$route.query.event}`)
            } else if (this.$route.query.packageEvent) {
              let pathUrl = `/reservation/package?packageEvent=${this.$route.query.packageEvent}`
              if (this.$route.query.productId) {
                pathUrl += `&productId=${this.$route.query.productId}`
              }
              this.$router.replace(pathUrl)
            } else if (this.$route.query.pay_qrcode) {
              this.$router.replace(`/onlinePayConnect?pay_qrcode=${this.$route.query.pay_qrcode}`)
            } else {
              this.$router.replace("/")
            }
          }
        } catch (e) {
          console.log(e)
          alert(this.$t("alert.error_page"))
        }
      }
    }, 500),
  },
}
</script>
<style scoped>
/* v-text-field, v-select */
.v-text-field--outlined >>> fieldset {
  border-color: #e0e0eb;
  border-radius: 8px;
}

.v-text-field--outlined >>> label {
  color: #8e73a2;
  font-size: 16px;
  font-weight: 400;
}

::v-deep .v-text-field input {
  margin-left: 8px;
  font-size: 16px;
}

::v-deep .v-select input {
  margin-left: 8px;
  font-size: 16px;
}
</style>
