<template>
  <v-sheet class="rounded-lg text-center">
    <v-card class="py-4 py-md-10 px-2" flat>
      <v-img src="@/assets/deco/card-top-deco.png" max-width="240px" class="mx-auto" />

      <div class="font-danjunghae primary--text text-center" style="font-size: 28px" :lang="lang">{{ $t("page.onlinePayComplete.pay_complete_title") }}</div>

      <v-card-title class="text-body-1 text-md-h6 text-center mx-auto font-weight-bold d-block mt-0" style="color: #545454">
        <div v-html="$t('page.onlinePayComplete.content_guide_1')"></div>
      </v-card-title>
      <v-card-text class="warning--text pb-2 text-center">
        <div v-html="$t('page.onlinePayComplete.content_guide_2')"></div>
      </v-card-text>
      <v-card-actions class="d-flex justify-center mt-10">
        <v-btn color="#d9bddd" class="dialog_btn rounded-2" style="font-size: 20px" width="35%" depressed to="/" large>{{ $t("button.goHome") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex"
export default {
  computed: {
    ...mapState(["lang"]),
  },
}
</script>
<style scoped>
::v-deep .v-btn {
  color: #625072;
}
</style>
