<template>
  <div>
    <!-- <TitleLogo title="공주의 앨범 상세보기" />
    <v-divider></v-divider> -->
    <v-sheet v-if="isLoading" class="text-center mt-10">
      <div class="font-weight-medium text-body-1 pb-4">Loading...</div>
      <v-progress-circular size="48" :width="2" color="primary" indeterminate></v-progress-circular>
    </v-sheet>

    <v-sheet v-else class="pb-4 mx-auto">
      <v-img class="sela-dialog-card-content" :src="item.imageUrl" :lazy-src="item.thumbnailUrl" :key="item.imageUrl" contain>
        <template v-slot:placeholder>
          <div class="d-flex align-center justify-center fill-height">
            <v-progress-circular color="white" indeterminate></v-progress-circular>
          </div>
        </template>
      </v-img>

      <div class="px-4 py-2">
        <PanelButton @click="showEditConfirm = true" :disabled="!isEditable(item)" :title="$t('dialog.edit-composite')" :message="$t('dialog.edit-guide')" />

        <div style="height: 6px"></div>

        <PanelButton @click="goToPrintReq" :title="$t('page.pictureDetail.print-title')" :message="$t('page.pictureDetail.print-subtitle')" />

        <div class="d-flex justify-center align-center py-2" style="gap: 8px">
          <v-btn outlined class="sela deactivate text-body-1" @click="shareImage()" style="flex-grow: 1"> {{ $t("button.friend-share") }} </v-btn>
          <v-btn outlined class="sela deactivate text-body-1" @click="writeReview()" width="32%"> {{ hasReview ? $t("button.review-edit") : $t("button.review-write") }} </v-btn>
          <v-btn outlined class="sela text-body-1" @click="openUrlForDownload()" width="25%"> {{ $t("button.savein-myphone") }}</v-btn>
        </div>
      </div>

      <ReviewWriteDialog v-if="reviewDialog" :visible="reviewDialog" :item="item" @close="reviewDialog = false" :key="item.id" />
      <ConfirmDialog @confirm="editPicture()" @cancel="showEditConfirm = false" :visible="showEditConfirm" :message="$t('dialog.edit-start-confirm')" />
    </v-sheet>
  </div>
</template>
<script>
import TitleLogo from "@/views/components/TitleLogo"
import DialogHeader from "@/views/components/DialogHeader"
import ReviewWriteDialog from "./components/ReviewWriteDialog"
import { fetchMyPictureItem } from "@/models/MyPicture"
import ConfirmDialog from "@/views/components/ConfirmDialog"
import PanelButton from "@/views/components/PanelButton"

export default {
  components: {
    TitleLogo,
    DialogHeader,
    ReviewWriteDialog,
    ConfirmDialog,
    PanelButton,
  },

  data: () => ({
    item: null,
    isLoading: true,
    reviewDialog: false,
    hasReview: false,
    showEditConfirm: false,
  }),

  created() {},

  mounted() {
    fetchMyPictureItem(this, this.$route.params.id)
      .then((item) => {
        this.item = item
        console.log("MyPictureDialog mounted", this.item)

        if (this.item) {
          this.$http
            .post("/api/review/select/request", {
              params: {
                requestId: this.item.id,
              },
            })
            .then((res) => {
              if (res.data.length > 0) {
                this.hasReview = true
              } else {
                this.hasReview = false
              }
            })
        }
      })
      .catch((e) => {
        console.log(e)
        alert(this.$t("alert.error_failed_get_information"))
        this.$router.back()
      })
      .finally(() => {
        this.isLoading = false
      })
  },

  methods: {
    async shareImage() {
      await this.$shareLink(this.item.imageUrl)
    },

    writeReview() {
      this.reviewDialog = true
    },

    downloadImage() {
      const a = document.createElement("a")
      a.href = this.item.imageUrl
      a.download = this.item.filename
      a.click()
    },
    openUrlForDownload() {
      window.open("https://front.sela-castle.com/imageViewer-v1.html?image=" + this.item.filename, "_blank")
    },
    editPicture() {
      this.$router.push(`/mypage/picEdit/${this.item.id}`)
    },

    isEditable(item) {
      if (!item.editable || !item.completeDate) {
        return false
      }
      const isBaseEditableDate = "24.5.1"
      const isBaseEditableDay = this.dayjs(isBaseEditableDate, "YY.M.D", true)
      const completeDay = this.dayjs(item.completeDate, "YY.M.D", true)

      if (completeDay.isBefore(isBaseEditableDay)) {
        return false
      } else {
        return true
      }
    },

    goToPrintReq() {
      this.$router.push({ name: "printPicPay", params: { id: this.$route.params.id, pic: this.item } })
    },
  },
}
</script>
<style></style>
