<template>
  <div class="mt-4" style="overflow-x: hidden">
    <swiper
      class="swiper"
      style="position: relative"
      :style="{
        width: PcWidth * 1.5 + 'px',
        left: -PcWidth / 4 + 'px',
      }"
      :options="swiperOption"
    >
      <swiper-slide>
        <v-img src="@/assets/reservation/lafia_castle_card.png" contain>
          <div>
            <v-sheet :width="PcWidth * 0.524" :height="PcWidth * 0.524" class="rounded-circle mx-auto mt-12" style="background: rgba(69, 38, 38, 0.4); padding: 4px">
              <v-img :width="PcWidth * 0.524" :height="PcWidth * 0.524" class="rounded-circle" style="transform: scale(1) !important" src="@/assets/reservation/category_lafia_castle.png"></v-img>
            </v-sheet>

            <v-img style="margin-top: -20px" class="mx-auto" width="140" height="30" contain src="@/assets/deco/line_0.png"></v-img>

            <p class="text-center font-danjunghae white--text" style="font-size: 32px; text-shadow: 3px 3px 3px rgba(69, 38, 38, 0.6); line-height: 40px">라피아캐슬</p>

            <p class="text-center white--text mt-10" :style="'font-size:' + PcWidth / 30 + 'px'">
              드레스를 입고 공주가 되어, 마음 껏 거닐며<br />
              사진이란 마법의 여행을 떠나보세요.
            </p>

            <v-btn
              width="140"
              height="40"
              depressed
              x-small
              class="font-danjunghae primary--text text--darken-1 rounded-10"
              style="font-size: 22px; letter-spacing: 4px; box-shadow: 0px 0px 12px 6px rgba(69, 38, 38, 0.2); position: absolute; left: 50%; transform: translateX(-50%)"
              :style="'bottom:60px'"
              @click="CheckAndLinkToBuy('라피아캐슬')"
            >
              예약하기
            </v-btn>
          </div>
        </v-img>
      </swiper-slide>
      <swiper-slide>
        <v-img src="@/assets/reservation/maker_card.png" contain>
          <div>
            <v-sheet :width="PcWidth * 0.524" :height="PcWidth * 0.524" class="rounded-circle mx-auto mt-12" style="background: rgba(69, 38, 38, 0.4); padding: 4px">
              <v-img :width="PcWidth * 0.524" :height="PcWidth * 0.524" class="rounded-circle" style="transform: scale(1) !important" src="@/assets/reservation/for_card.jpeg"></v-img>
            </v-sheet>

            <v-img style="margin-top: -20px" class="mx-auto" width="140" height="30" contain src="@/assets/deco/line_0.png"></v-img>

            <p class="text-center font-danjunghae white--text" style="font-size: 32px; text-shadow: 3px 3px 3px rgba(69, 38, 38, 0.6); line-height: 40px">솔로프리미엄</p>

            <p class="text-center white--text mt-10" :style="'font-size:' + PcWidth / 30 + 'px'">
              담당 메이커님이 모든 것을 리드해 드리며<br />
              세밀한 1대1 대면보정까지.
            </p>

            <v-btn
              width="140"
              height="40"
              depressed
              x-small
              class="font-danjunghae primary--text text--darken-1 rounded-10"
              style="font-size: 22px; letter-spacing: 4px; box-shadow: 0px 0px 12px 6px rgba(69, 38, 38, 0.2); position: absolute; left: 50%; transform: translateX(-50%)"
              :style="'bottom:60px'"
              @click="$router.push('/reservation/buy?category=솔로프리미엄')"
            >
              예약하기
            </v-btn>
          </div>
        </v-img>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper"
import "swiper/css/swiper.css"

export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      PcWidth: 580,

      swiperOption: {
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 2,
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 20,
          modifier: 1,
          slideShadows: true,
        },
      },
    }
  },

  methods: {
    // 로그인 상태 확인 후 로그인/예약하기로 이동
    CheckAndLinkToBuy(category) {
      // 비 로그인 시
      if (!this.$store.state.lafia_user.is_logined) {
        alert(this.$t("alert.error_need_authentication"))
        this.$router.push("/auth/login")
      }
      // 로그인 시
      else {
        this.$router.push("/reservation/buy?category=" + category)
      }
    },
  },
}
</script>
<style scoped>
.swiper-slide {
  margin: 0 auto;
  transition: all 0.25s ease-out;
  opacity: 1;
}

.swiper-slide-active + .swiper-slide ~ .swiper-slide {
  opacity: 0.35;
}

.swiper-slide .v-image {
  transition: all 0.25s ease-out;
  transform: scale(0.75);
  transform-origin: center;
}

.swiper-slide-active .v-image {
  transform: scale(1);
}
</style>
