<template>
  <div>
    <v-img width="180" height="32" contain class="mx-auto mt-2" src="@/assets/deco/line_0.png"></v-img>
    <p class="text-center font-danjunghae" style="color: #b67a7a; font-size: 30px" :lang="lang">쿠폰 등록하기</p>

    <!-- 본문 -->
    <v-sheet class="rounded-lg px-4 pb-16 pt-8">
      <!-- 완료 -->
      <div class="text-center">
        <v-otp-input style="width: 320px; margin: 0 auto" length="6" type="text" v-model="serial" :disabled="!$store.state.lafia_user.is_logined"></v-otp-input>

        <div v-if="!$store.state.lafia_user.is_logined">
          <p class="mt-2 mb-4">
            쿠폰 등록은
            <br />
            <span class="primary--text font-weight-medium"> 로그인 </span>
            후 이용 가능합니다
          </p>
          <v-btn depressed color="primary" dark large width="200" class="font-weight-regular mb-4" to="/auth/login"> 로그인하기 </v-btn>
          <p class="text-center text-body-2 primary--text">회원가입 하러가기</p>
        </div>

        <div v-if="$store.state.lafia_user.is_logined">
          <p class="mt-2 mb-4">
            받으신 쿠폰은
            <br />
            <span class="primary--text">
              마이페이지
              <v-icon small class="mb-1" color="primary">mdi-chevron-right</v-icon>
              쿠폰 목록 </span
            ><br />
            에서 확인하실 수 있습니다.
          </p>
          <v-btn depressed color="primary" dark large width="200" class="font-weight-regular mb-4 rounded-xl" @click="submit()"> 쿠폰 등록 </v-btn>
        </div>
      </div>
    </v-sheet>
  </div>
</template>
<script>
import { mapState } from "vuex"
export default {
  data: () => ({
    serial: "",
  }),

  computed: {
    ...mapState(["lang"]),
  },

  watch: {
    serial(newVal) {
      if (newVal && /[a-z]/.serial(newVal)) {
        this.serial = newVal.toUpperCase()
      }
    },
  },

  methods: {
    submit() {
      if (this.serial.length < 6) {
        alert("쿠폰 번호 6자를 전부 입력해주세요")
      } else {
        this.$http
          .post("/api/admin/coupon/select/is_have", {
            params: {
              serial: this.serial,
              user_id: this.$store.state.lafia_user.user_id,
            },
          })
          .then((res) => {
            console.log(res)
            if (res.data.length) {
              alert("이미 등록하신 쿠폰입니다.")
            } else {
              this.$http
                .post("/api/admin/coupon/select/specific", {
                  params: {
                    serial: this.serial,
                  },
                })
                .then((res) => {
                  if (res.data.length) {
                    this.$http
                      .post("/api/admin/coupon/update/owner", {
                        params: {
                          id: res.data[0].id,
                          owner: this.$store.state.lafia_user.user_id,
                        },
                      })
                      .then((res) => {
                        if (res.data.affectedRows) {
                          alert("쿠폰이 정상적으로 등록되었습니다!")
                        } else {
                          alert("쿠폰 등록중 오류가 발생하였습니다")
                        }
                      })
                  } else {
                    alert("없는 쿠폰이거나 이미 등록이 완료된 쿠폰번호입니다")
                  }
                })
            }
          })
      }
    },
  },
}
</script>
