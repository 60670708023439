<template>
  <v-card>
    <v-card-title>입장 시간</v-card-title>
    <div class="text-center font-weight-medium text-h6" style="position: relative">
      <span class="text-h4 font-weight-bold">{{ curr_time }}</span>
    </div>

    <v-card-text class="d-flex justify-center text-center"> </v-card-text>
    <v-container fluid class="d-flex justify-center" style="gap: 10px">
      <v-checkbox v-model="selctedOptions" label="드레스" value="드레스"></v-checkbox>
      <v-checkbox v-model="selctedOptions" label="귀걸이" value="귀걸이"></v-checkbox>
      <v-checkbox v-model="selctedOptions" label="목걸이" value="목걸이"></v-checkbox>
      <v-checkbox v-model="selctedOptions" label="장갑" value="장갑"></v-checkbox>
      <v-checkbox v-model="selctedOptions" label="머리" value="머리"></v-checkbox>
    </v-container>
    <v-card-actions class="mb-4">
      <v-spacer></v-spacer>
      <v-btn class="px-6" depressed @click="$emit('cancel')">취소</v-btn>
      <v-btn color="primary" class="px-6 mr-10" depressed @click="submit">입장</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    curr_date: null,
    minutesTimer: null,
    selctedOptions: ["드레스"],
  }),
  mounted() {
    console.log("mounted")
    this.setCurrentTime()
    this.minutesTimer = setInterval(() => {
      console.log("setCurrentTime")
      this.setCurrentTime()
    }, 30000)
  },
  destroyed() {
    console.log("destroyed")
    if (this.minutesTimer) {
      clearInterval(this.minutesTimer)
      this.minutesTimer = null
    }
  },

  computed: {
    curr_time: function () {
      return this.curr_date ? this.curr_date.format("HH시 mm분") : "-"
    },
  },

  methods: {
    submit: _.debounce(async function () {
      this.$emit("save", { hour: this.dayjs().hour(), minute: this.dayjs().minute(), accessories: this.selctedOptions })
    }, 500),
    setCurrentTime() {
      this.curr_date = this.dayjs()
    },
  },
}
</script>
<style scoped>
.scrollable {
  height: 272px;
  max-height: 272px;
  overflow-y: auto;
}
</style>
