<!-- 현장 결제 관리 -->
<template>
  <div>
    <!-- 제목 -->
    <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">현장 결제 관리</font>
    </v-sheet>
    <!-- 본문 -->
    <v-sheet outlined class="pa-2 ma-2">
      <!-- 검색 -->
      <v-sheet outlined class="pa-2 mb-2 d-flex align-center">
        <v-sheet class="d-flex align-center">
          <!-- 유형 -->
          <v-sheet width="160" class="mr-3">
            <v-select outlined dense hide-details :items="select_list.search_type" v-model="search_type"></v-select>
          </v-sheet>

          <!-- 키워드 -->
          <v-text-field placeholder="검색어를 입력해주세요" outlined dense hide-details v-model="keyword" @keyup.enter="search()"></v-text-field>

          <!-- 제출 버튼 -->
          <v-btn height="40" class="px-6 ml-3" color="primary" dark depressed @click="search()">
            <v-icon small left>mdi-magnify</v-icon>
            검색하기
          </v-btn>

          <v-sheet class="px-4">
            <v-menu v-model="dateMenu" :close-on-content-click="false" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="date" label="일자" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="success"></v-text-field>
              </template>
              <v-date-picker v-model="date" no-title scrollable color="success" @input="dateMenu = false"></v-date-picker>
            </v-menu>
          </v-sheet>

          <!-- 활성화 여부 체크 박스-->
          <v-checkbox v-model="isOnlyRequest" class="shrink mt-0 ml-4" label="결제요청만" color="primary" hide-details dense> 결제요청만</v-checkbox>
        </v-sheet>
        <v-spacer></v-spacer>
        <!-- 제출 버튼 -->
        <v-btn height="40" class="px-3 ml-2" color="primary" dark depressed @click="search()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-sheet>
      <v-data-table :items="list" :headers="tableHeaders" :loading="laoding" loading-text="Loading... Please wait">
        <template v-slot:[`item.status`]="{ item }">
          <span v-if="item.status != '접수'"> {{ item.status }}</span>
          <v-btn v-else outlined color="warning" @click="payRequest(item)">결제요청</v-btn>
        </template>
        <template v-slot:[`item.price`]="{ item }">
          <span class="font-weight-medium">{{ $toComma(item.price) }}원</span>
        </template>
        <!-- <template v-slot:[`item.discount_amount`]="{ item }">
          <span class="font-weight-medium">{{ item.discount_amount ? `${$toComma(item.discount_amount)}원` : "-" }}</span>
        </template>
        <template v-slot:[`item.discount_code`]="{ item }">
          <span class="font-weight-medium">{{ item.discount_code || "-" }}</span>
        </template> -->
        <template v-slot:[`item.pay_amount`]="{ item }">
          <span class="font-weight-medium text-body-1">{{ $toComma(item.pay_amount) }}원</span>
        </template>
        <template v-slot:[`item.created`]="{ item }">
          <span>{{ new Date(item.created).toLocaleString() }}</span>
        </template>
        <template v-slot:[`item.approvedAt`]="{ item }">
          <span>{{ item.approvedAt ? new Date(item.approvedAt).toLocaleString() : "" }}</span>
        </template>
      </v-data-table>
    </v-sheet>
    <v-dialog v-model="showPayDialog" max-width="600" content-class="rounded-xl d-flex justify-center" :key="showDialogComponentKey" @input="payDialogClose" persistent>
      <OfflinePayReqDoc :info="selectedItem" @close="payDialogClose"></OfflinePayReqDoc>
    </v-dialog>
  </div>
</template>
<script>
import OfflinePayReqDoc from "./components/OfflinePayReqDoc"
export default {
  components: {
    OfflinePayReqDoc,
  },
  data: () => ({
    dateMenu: false,
    date: null,

    select_list: {
      search_type: ["아이디", "이름", "부스"],
    },

    tableHeaders: [
      { text: "방번호", value: "roomNo", width: "80px", align: "center", sortable: false },
      { text: "상품명", value: "productName", align: "center", sortable: false },
      { text: "아이디", value: "user_id", align: "center", sortable: false },
      { text: "이름", value: "user_name", align: "center", sortable: false },
      { text: "결제금액", value: "pay_amount", align: "center", sortable: false },
      { text: "상태", value: "status", align: "center", sortable: false },
      // { text: "할인코드", value: "discount_code", align: "center", sortable: false },
      // { text: "할인금액", value: "discount_amount", width: "120px", align: "center", sortable: false },
      { text: "쿠폰", value: "couponName", align: "center", sortable: false },
      { text: "상품금액", value: "price", width: "120px", align: "center", sortable: false },
      { text: "요청일", value: "created", align: "center", sortable: false },
      { text: "승인일", value: "approvedAt", align: "center", sortable: false },
    ],

    laoding: false,
    search_type: "아이디",
    keyword: "",

    list: [],
    isOnlyRequest: true,

    selectedItem: null,
    showPayDialog: false,
    showDialogComponentKey: 0,
  }),
  mounted() {
    this.date = this.dayjs().format("YYYY-MM-DD")
    this.search()
  },

  watch: {
    isOnlyRequest() {
      this.search()
    },
    date() {
      this.search()
    },
  },

  methods: {
    // 목록
    search() {
      this.loading = true
      this.list = []
      this.$http
        .get("/api/payments/offline", {
          params: {
            search_type: this.search_type,
            keyword: this.keyword,
            isOnlyRequest: this.isOnlyRequest,
            date: this.date,
          },
        })
        .then((res) => {
          if (res.data.length) {
            this.list = res.data
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    payRequest: _.debounce(async function (item) {
      console.log("payRequest")
      // offline 결제 완료로 판단.
      this.selectedItem = item
      this.showDialogComponentKey++
      this.showPayDialog = true
    }, 500),

    payDialogClose() {
      console.log("payDialogClose")
      this.selectedItem = null
      this.showDialogComponentKey++
      this.showPayDialog = false
      this.search()
    },
  },
}
</script>
<style scoped></style>
