<template>
  <div>
    <v-sheet class="text-center py-16">
      <v-progress-circular indeterminate color="primary" size="48"></v-progress-circular>
      <p class="mt-4 text-caption" v-html="$t('page.pay.pay-progress-text')"></p>
    </v-sheet>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex"

export default {
  created() {},
  mounted() {
    let amount = this.$route.query.amount
    let orderId = this.$route.query.orderId
    let paymentKey = this.$route.query.paymentKey

    this.doPayCompleted({
      orderNo: orderId,
      paymentKey,
      amount: Number(amount),
      isAbroad: true,
    })
      .then((res) => {})
      .catch((e) => {
        console.log(e)
        alert(this.$t("alert.error_doing_pay_unknown_error"))
      })
  },

  methods: {
    ...mapActions(["doPayCompleted"]),
  },
}
</script>
<style></style>
