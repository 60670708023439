<template>
  <v-sheet class="px-2 py-4 mb-4" width="100%" outlined rounded="lg">
    <div class="font-weight-bold text-body-1 py-2">
      <span>등록된 쿠폰</span>
      <v-btn class="ml-3" color="primary" depressed outlined small @click="$emit('addCoupon')">쿠폰추가</v-btn>
      <v-divider class="mt-3" />
    </div>
    <v-chip class="ma-1" v-for="(coupon, index) in coupons" :key="coupon.title + index" small close @click:close="removeCoupon(index)">{{ coupon.title }}</v-chip>
    <div class="grey--text text-body-2" v-show="!coupons || !coupons.length">선택된 쿠폰이 없습니다.</div>
  </v-sheet>
</template>
<script>
export default {
  inject: ["coupons_info"],
  props: {
    coupons: {
      type: Array,
      require: true,
    },
  },

  methods: {
    removeCoupon(index) {
      this.coupons.splice(index, 1)
    },
  },
}
</script>
<style></style>
