<template>
  <div class="pb-16">
    <v-sheet class="px-4 mt-4">
      <v-data-table
        :items="list"
        :headers="tableHeaders"
        :page.sync="pageNum"
        :items-per-page="itemsPerPage"
        :server-items-length="totalCount"
        :loading="loading"
        :show-select="false"
        loading-text="Loading... Please wait"
        :no-data-text="$t('page.mypage.payHistory.paid-history-empty')"
        @page-count="pageCount = $event"
        hide-default-header
        hide-default-footer
      >
        <template v-slot:[`item.type`]="{ item }">
          <span>{{ getOrderName(item.type) }}</span>
        </template>
        <template v-slot:[`item.approvedAt`]="{ item }">
          <span>{{ getDateStr(item) }}</span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <span :class="[{ colorRed: item.status === '취소완료' }]" class="text-capitalize">{{ getStatusStr(item.status) }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon @click="link(item.receipt)">mdi-receipt-text</v-icon>
        </template>

        <template v-slot:[`item.totalAmount`]="{ item }">
          <span class="text-body-1 font-weight-medium" :class="{ 'text-decoration-line-through': item.status === '취소완료' }">{{ $toComma(item.pay_amount) }}</span
          >{{ $t("common.krw") }}
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination v-model="pageNum" :length="pageCount"> </v-pagination>
      </div>
    </v-sheet>
  </div>
</template>
<script>
import { mapState } from "vuex"
export default {
  data: () => ({
    loading: false,

    pageNum: 1,
    list: [],
    totalCount: 0,
    pageCount: 0,
    itemsPerPage: 10,

    tableHeaders: [
      { text: "일자", value: "approvedAt", sortable: false },
      { text: "상품명", value: "type", sortable: false },
      { text: "상태", value: "status", sortable: false },
      { text: "금액", value: "totalAmount", sortable: false },
    ],
  }),

  watch: {
    pageNum: {
      handler() {
        this.load(this.pageNum)
      },
      deep: true,
    },
  },

  computed: {
    ...mapState(["lafia_user", "lang"]),
  },

  mounted() {
    this.load()
  },

  methods: {
    link(url) {
      window.open(url, "_blank")
    },
    async load() {
      console.log("load page: " + this.pageNum)
      let page = this.pageNum
      let restApiUrl = "/api/order/v2/orderList"

      let params = {
        user_id: this.lafia_user.user_id,
        limit: this.itemsPerPage,
        offset: (page - 1) * this.itemsPerPage,
        desc: true,
      }
      this.loading = true

      try {
        const result = await this.$http.get(restApiUrl, {
          params,
        })
        this.list = result.data.data
        this.totalCount = result.data.totalCount
      } catch (e) {
        this.$toast.error($t("toast.error-get-datas"))
      } finally {
        this.loading = false
      }
    },

    getDateStr(item) {
      if (item.cancels) {
        let cancels = item.cancels
        const cancelItems = eval(cancels)
        return this.dayjs(cancelItems[0].canceledAt).format("M/D HH:mm")
      } else {
        return this.dayjs(item.approvedAt).format("M/D HH:mm")
      }
    },

    getStatusStr(status) {
      if (this.lang === "ko") return status
      switch (status) {
        case "결제완료":
          return "paid"
        case "취소완료":
          return "canceled"
        default:
          return status
      }
    },

    getOrderName(type) {
      if (this.lang === "ko") return type
      if (type.includes("이용권") || type.includes("예약")) {
        return "Ticket"
      } else if (type.includes("인쇄")) {
        return "Print"
      } else if (type.includes("보정")) {
        return "Photo Touch"
      } else if (type.includes("드레스")) {
        return "Dress"
      } else {
        return type
      }
    },
  },

  created() {
    if (!this.lafia_user.is_logined) {
      alert(this.$t("alert.need-auth-menu"))
      this.$router.push("/auth/login")
      return
    }
  },
}
</script>
<style scoped>
::v-deep .v-data-table__mobile-row {
  min-height: 26px !important;
  font-size: 15px !important;
  border: none !important;
}

::v-deep .v-data-table__mobile-row:nth-child(even) {
  width: 70% !important;
  display: flex;
  justify-content: end;
}
::v-deep .v-data-table__mobile-row:nth-child(odd) {
  width: 30% !important;
}

::v-deep .v-data-table__mobile-table-row {
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  background-color: white !important;
  display: flex !important;
  flex-wrap: wrap;
}

::v-deep .v-data-table__mobile-table-row :odd ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 8px !important;
}
.colorRed {
  color: red;
}
</style>
