<template>
  <v-dialog v-model="show" activator="parent" content-class="sela-dialog" v-if="item">
    <div class="sela-dialog-card">
      <DialogHeader @close="show = false" />
      <div class="sela-dialog-card-content" style="height: 100%; top: 0px !important">
        <v-img contain class="" style="width: 100%; max-height: 100%" :src="item.imageUrl" :lazy-src="item.thumbnailUrl">
          <template v-slot:placeholder>
            <div class="d-flex align-center justify-center fill-height">
              <v-progress-circular color="white" indeterminate></v-progress-circular>
            </div>
          </template>
        </v-img>

        <div class="review-area">
          <div class="d-flex" style="flex-direction: column">
            <div class="d-flex align-center" style="column-gap: 5px">
              <v-img cover class="rounded-circle" style="max-width: 14px" :src="item.profileImageUrl"></v-img>
              <div class="sela-caption" style="color: white">
                {{ item.user_id }}
              </div>
            </div>

            <div class="d-flex" style="column-gap: 5px">
              <v-icon size="19" color="white" style="align-self: end" @click.stop="toggleLike(item)"> mdi-heart-outline </v-icon>
              <div class="sela-caption" style="color: white">
                {{ $formatLikeCount(item.like_count) }}
              </div>
            </div>

            <v-btn style="margin-top: 26px" v-if="item.editable" @click.stop="edit()">수정</v-btn>
          </div>

          <div class="sela-caption" style="flex-grow: 1; white-space: pre-line">
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>

    <ReviewWriteDialog :visible="showEdit" :item="editItem" @update="(x) => (this.item.content = x)" @close="showEdit = false" :key="item.id" />
  </v-dialog>
</template>
<script>
import ReviewWriteDialog from "../mypage/components/ReviewWriteDialog"
import DialogHeader from "@/views/components/DialogHeader"

export default {
  components: {
    ReviewWriteDialog,
    DialogHeader,
  },

  props: {
    item: Object,
    visible: Boolean,
  },

  data: () => ({
    showEdit: false,
  }),

  computed: {
    show: {
      get() {
        return this.visible
      },
      set(newValue) {
        if (newValue === false) {
          this.$emit("close")
        }
      },
    },
    editItem: {
      get() {
        return {
          id: this.item.requestId,
          content: this.item.content,
          imageUrl: this.item.imageUrl,
          title: this.item.title,
        }
      },
    },
  },

  mounted() {},

  methods: {
    edit() {
      this.showEdit = true
    },

    toggleLike(item) {
      if (!this.$store.state.lafia_user.user_id) {
        alert(this.$t("alert.error_need_authentication"))
        this.$router.push("/auth/login")
        return
      }
      this.$http
        .post("/api/review/update/like", {
          params: {
            reviewId: item.id,
            requestId: item.requestId,
            user_id: this.$store.state.lafia_user.user_id,
          },
        })
        .then((res) => {
          item.like_count = res.data.like_count || 0
        })
    },
  },
}
</script>
