<template>
  <div>
    <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">프로모션 매출 관리</font>
    </v-sheet>

    <SelaPromotionEachDate v-show="!isDetail" @open="openDetail" />
    <SelaPromotionDetail v-if="isDetail" :month="selectedMonth" :code="selectedPromotionCode" :total="selectedItem" @prev="openList" />
  </div>
</template>
<script>
import SelaPromotionEachDate from "./components/selaPromotionEachDate"
import SelaPromotionDetail from "./components/selaPromotionDetail"

export default {
  components: {
    SelaPromotionEachDate,
    SelaPromotionDetail,
  },
  data: () => ({
    isDetail: false,
    selectedMonth: 1,
    selectedItem: null,
    selectedPromotionCode: null,
  }),
  created() {
    this.selectedMonth = this.dayjs().month()
  },
  methods: {
    openDetail({ item, code }) {
      console.log(`openDetail`, item, code)
      this.selectedMonth = Number(item.date.split("-")[1]) - 1
      this.selectedPromotionCode = code
      this.selectedItem = item
      this.isDetail = true
    },
    openList() {
      this.selectedItem = null
      this.selectedPromotionCode = null
      this.isDetail = false
    },
  },
}
</script>
<style></style>
