<template>
  <div>
    <!-- 제목 -->
    <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">컨셉부스 관리</font>
    </v-sheet>

    <!-- 본문 -->
    <div class="d-flex">
      <v-sheet width="740" class="pa-2 ma-2" outlined>
        <v-select :items="supportedLang" label="언어" v-model="selected_lang" solo></v-select>

        <v-select :items="items" label="타입" item-text="title" item-value="field" v-model="selected_type" solo></v-select>

        <!-- 본문 -->
        <TipTapWriter v-if="selected_type" :key="writeComponentKey" style="min-height: 400px" class="tiptap_style mt-4" :options="options" @contentUpdated="contentUpdate" />

        <!-- 버튼 -->
        <v-sheet class="d-flex justify-center pa-1 mt-2">
          <v-btn class="mx-2 px-10 rounded-10" color="#ADAFCA" dark large depressed @click="cancel()"> 취소하기 </v-btn>
          <v-btn class="mx-2 px-10 rounded-10" color="primary" dark large depressed @click="submit()"> 등록하기 </v-btn>
        </v-sheet>
      </v-sheet>
      <v-sheet class="pa-2 ma-2" outlined>
        <p class="mb-0 text-center text-subtitle-1 font-weight-medium">작은 모바일</p>
        <p class="text-center text-body-2 primary--text">(320)</p>
        <div style="width: 320px">
          <v-sheet class="test">
            <!-- 대문 -->
          </v-sheet>

          <TipTapReader v-if="options.content" class="tiptap_style pa-4" :options="options" :key="componentKey + '412'" />
        </div>
      </v-sheet>

      <v-sheet class="pa-2 ma-2" outlined>
        <p class="mb-0 text-center text-subtitle-1 font-weight-medium">큰 모바일</p>
        <p class="text-center text-body-2 primary--text">(412)</p>
        <div style="width: 412px">
          <v-sheet class="test">
            <!-- 대문 -->
          </v-sheet>

          <TipTapReader v-if="options.content" class="tiptap_style pa-4" :options="options" :key="componentKey + '320'" />
        </div>
      </v-sheet>
    </div>
  </div>
</template>
<script>
import TipTapWriter from "@/components/tiptap/Writer"
import TipTapReader from "@/components/tiptap/Reader"
import { CASTLE_CONTENT_CATEGORY } from "@/constant"
import htmlServce from "@/api/html-service"

export default {
  components: {
    TipTapWriter,
    TipTapReader,
  },

  data: () => ({
    supportedLang: ["한글", "영어", "일어"],
    items: [
      {
        id: "home",
        title: "홈",
        field: "home",
      },
      {
        id: "selfie",
        title: "셀카존",
        field: CASTLE_CONTENT_CATEGORY.selfie,
      },
      {
        id: "dessert",
        title: "디저트",
        field: CASTLE_CONTENT_CATEGORY.dessert,
      },
      {
        id: "preserve-qna",
        title: "예약 QNA",
        field: CASTLE_CONTENT_CATEGORY.preservationQna,
      },
      {
        id: "service-qna",
        title: "서비스 QNA",
        field: CASTLE_CONTENT_CATEGORY.serviceQna,
      },
    ],
    selected_type: "",
    selected_lang: "한글",

    //TipTap
    options: {
      content: "",
      editable: true,
      supportImage: true,
      supportVideo: true,
    },

    writeComponentKey: 0,
    componentKey: 0,
  }),

  watch: {
    selected_type: function (val) {
      if (val) {
        this.options.content = ""
        this.load()
      }
    },
    selected_lang: function (val) {
      this.options.content = ""
      this.load()
    },
  },

  mounted() {
    if (this.selected_type) {
      this.load()
    }
  },

  methods: {
    // 내용 Count 값 import
    contentUpdate() {
      this.componentKey++
    },

    // 내용 load
    load() {
      if (this.selected_type) {
        htmlServce
          .getHtmlBlobData(this.selected_type)
          .then((res) => {
            if (res) {
              this.options.content = this.getContent(res)
            } else {
              this.options.content = ""
            }
            this.writeComponentKey += 1
          })
          .catch((e) => {
            console.log(e)
            alert("정보를 가져오지 못했습니다.")
          })
      } else {
        this.writeComponentKey += 1
      }
    },

    getContent(resource) {
      switch (this.selected_lang) {
        case "일어":
          return resource.content_jp
        case "영어":
          return resource.content_en
        default:
          return resource.content
      }
    },

    // 취소하기
    cancel() {
      if (confirm("지금 작성하고 계신 글이 저장되지 않습니다.\n페이지를 이동하시겠습니까?")) {
        this.$router.go(-1)
      }
    },

    // 등록하기
    async submit() {
      if (!this.options.content.length) {
        alert("내용을 입력해주세요")
      } else {
        try {
          const isSuccess = await htmlServce.updateHtmlBlobData(this.selected_type, this.options.content, this.selected_lang)
          if (isSuccess) {
            alert("정상적으로 등록되었습니다.")
          } else {
            if (this.selected_lang !== "한글") {
              alert("한글 데이터를 먼저 입력해 주세요.")
              return
            }
            await htmlServce.insertHtmlBlocData(this.selected_type, this.options.content)
            alert("정상적으로 등록되었습니다.")
          }
        } catch (e) {
          console.log(e)
          alert("등록에 실패하였습니다.")
        }
      }
    },

    showSuccess() {},
  },
}
</script>
<style scoped>
.table_style {
  width: 100%;
  background: white;
  border: 1px solid #ddd;
  border-collapse: collapse;
}

.table_style tr td {
  border: 1px solid #ddd;
  padding: 4px;
  text-align: center;
}

.filled {
  background: #ddd;
  color: #999;
  font-weight: 300;
}

.reservation {
  background: #bba8ca;
  color: #84679a;
  font-weight: 300;
}
</style>

<style scoped>
.my-clipper {
  width: 100%;
}

.placeholder {
  width: 100%;
  height: 150px;
}
</style>
<style scoped>
.test {
  /* background: url('~@/assets/list_main.svg'); */
  background-size: 100%;
}
</style>
