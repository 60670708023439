<template>
  <div class="pb-16">
    <!-- 본문 -->
    <v-sheet class="pt-6 mx-auto">
      <v-sheet class="d-flex justify-center align-center">
        <v-btn depressed class="py-2 rounded-1 font-weight-normal mr-2" width="120" height="40" :color="filter === 'reservation' ? '#d9bddd' : 'white  grey--text'" @click="setFilter('reservation')">
          {{ $t("button.ticket") }}
        </v-btn>

        <v-btn depressed class="py-4 rounded-1 font-weight-normal mr-2" width="120" height="40" :color="filter === 'coupon' ? '#d9bddd' : 'white grey--text'" @click="setFilter('coupon')">
          {{ $t("button.coupon") }}
        </v-btn>

        <v-btn depressed class="py-4 rounded-1 font-weight-normal" width="120" height="40" :color="filter === 'payhistory' ? '#d9bddd' : 'white grey--text'" @click="setFilter('payhistory')">
          {{ $t("button.payHistory") }}
        </v-btn>
      </v-sheet>

      <ReservationList v-if="filter == 'reservation'" :key="'reserve' + reserveComponentKey" />
      <CouponList v-if="filter == 'coupon'" :key="'reserve' + couponComponentKey" />
      <PayHistory v-if="filter == 'payhistory'" :key="'payhist' + payHistoryComponentKey" />
    </v-sheet>
  </div>
</template>
<script>
import CouponList from "./CouponList"
import ReservationList from "./ReservationList"
import PayHistory from "./MyPayHistory"

export default {
  components: {
    CouponList,
    ReservationList,
    PayHistory,
  },

  data: () => ({
    filter: "reservation",
    reserveComponentKey: 0,
    couponComponentKey: 0,
    payHistoryComponentKey: 0,
  }),

  created() {
    switch (this.$route.query.type) {
      case "coupon":
        this.filter = "coupon"
        break
      case "payhistory":
        this.filter = "payhistory"
        break

      default:
        this.filter = "reservation"
        break
    }
  },

  mounted() {
    this.load()
  },

  methods: {
    load() {
      if (this.filter === "coupon") {
        this.couponComponentKey++
      } else if (this.filter === "payhistory") {
        this.payHistoryComponentKey++
      } else {
        this.reserveComponentKey++
      }
    },

    setFilter(filter) {
      this.filter = filter
    },
  },
}
</script>
<style scoped>
::v-deep .v-btn {
  border: 2px solid #ccc !important;
  font-size: 18px;
}
</style>
