<template>
  <div>
    <v-btn @click="test()"> </v-btn>
  </div>
</template>
<script>
export default {
  data: () => ({
    test2: "",
  }),

  mounted() {
    // 중복 체크
    this.$http
      .post("/api/order/select/check_duplicate", {
        params: {
          toss_id: this.$route.query.orderId,
        },
      })
      .then((res) => {
        console.log(res)

        // 무인촬영기
        if (res.data[0].memo == "무인촬영기") {
          this.$http
            .post("/api/qrcode/update/status/byOrderId", {
              params: {
                order_id: res.data[0].order_list_id,
                status: "선택완료",
              },
            })
            .then((res) => {
              // 정상 결제
              this.payment()
            })
        } else {
        }
      })
  },
}
</script>
