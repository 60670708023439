<template>
  <v-sheet class="pa-16">
    <p class="text-h5 font-weight-bold text-center mb-10">Rocket-works</p>

    <div class="d-flex align-center mx-auto mb-4" style="width: 300px">
      <v-text-field placeholder="시작 번호" hide-details outlined reverse dense class="rounded-10 text-h6" v-model.number="start" prefix=".jpg"></v-text-field>

      <p class="mb-0 mx-4 text-h6 font-weight-bold">~</p>

      <v-text-field placeholder="끝 번호" hide-details outlined reverse dense class="rounded-10 text-h6" v-model.number="end" prefix=".jpg"></v-text-field>
    </div>
    <v-btn color="primary" depressed large class="px-10 d-flex rounded-10 mx-auto mt-6" @click="remove_background()"> remove_background </v-btn>

    <v-btn color="primary" depressed large class="px-10 d-flex rounded-10 mx-auto mt-6" @click="photoshop()"> photoshop </v-btn>
  </v-sheet>
</template>
<script>
export default {
  data: () => ({
    start: 0,
    end: 0,
  }),

  methods: {
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },

    async remove_background() {
      for (let fileNumber = this.start; fileNumber <= this.end; fileNumber++) {
        try {
          // file_number를 파라미터로 넣어서 요청 보내기
          let res = await this.$http.post("/api/tests/rocket-works/remove_background/file_save", {
            params: { file_number: fileNumber },
          })
          console.log(`File ${fileNumber} response:`, res)

          // 다음 파일 처리 전에 10초(10000ms) 딜레이 주기
          await this.delay(10000)
        } catch (error) {
          console.error(`Error processing file ${fileNumber}:`, error)
          alert(`오류 발생 파일번호 ${fileNumber}:`, error)
          // 에러 발생 시 반복문 중단을 원하지 않는 경우, 이 줄을 제거하세요.
          break
        }
      }
      alert("모든 파일 처리 완료")
    },

    async photoshop() {
      for (let fileNumber = this.start; fileNumber <= this.end; fileNumber++) {
        try {
          // file_number를 파라미터로 넣어서 요청 보내기
          let res = await this.$http.post("/api/tests/rocket-works/photoshop", {
            params: { file_number: fileNumber },
          })
          console.log(`File ${fileNumber} response:`, res)

          // 다음 파일 처리 전에 10초(10000ms) 딜레이 주기
          await this.delay(10000)
        } catch (error) {
          console.error(`Error processing file ${fileNumber}:`, error)
          alert(`오류 발생 파일번호 ${fileNumber}:`, error)
          // 에러 발생 시 반복문 중단을 원하지 않는 경우, 이 줄을 제거하세요.
          break
        }
      }
      alert("모든 파일 처리 완료")
    },
  },
}
</script>
