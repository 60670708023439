<template>
  <v-sheet style="background: #f1f1f1; height: 100%">
    <v-sheet max-width="580" class="mx-auto" style="position: relative; background: white; height: 100%; box-shadow: 0 0 16px 8px #e1e1e1">
      <Header />
      <router-view style="margin-bottom: 80px" :key="$route.fullPath"></router-view>
      <Footer style="position: fixed; bottom: 0px; max-width: 580px" />
    </v-sheet>
  </v-sheet>
</template>

<script>
import Header from "./Header"
import Footer from "./Footer"

export default {
  components: {
    Header,
    Footer,
  },
}
</script>
