<template>
  <div :style="rootStyle" id="PSDEditorView" ref="root">
    <div :style="rendererStyle" id="render-container" ref="rendererContainer">
      <PSDRenderLayer v-if="document != null" :layer="document" :onload="onload" />
      <img :src="guideImage" :style="guideStyle" v-if="guideImage" />
    </div>
    <div :style="targetContainerStyle" ref="targetContainer">
      <div ref="target" class="target" id="edit-target" :style="targetStyle" />
    </div>
  </div>
</template>

<script>
import { getLayerByName, getLayersRect } from "@/models/PSDLayer"
import PSDRenderLayer from "./PSDRenderLayer"
import { PinchZoom } from "./PinchZoom"

export default {
  components: {
    PSDRenderLayer,
  },
  props: {
    editItem: Object,
    guideImage: null,
    document: Object,
    onload: Function,
  },

  computed: {},

  data: () => ({
    subjectLayer: null,
    shadowLayer: null,
    shadowRelativeTransform: null,
    scale: 1,
    maxHeightScale: 1,
    targetRect: { left: 0, top: 0, right: 0, bottom: 0 },
    pinchZoom: null,
    showGuideText: true,

    rootStyle: {
      position: "relative",
      width: "100%",
      height: "100%",
      overflow: "hidden",
      backgroundColor: "black",
    },

    guideStyle: {
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      transform: "",
      opacity: 0.9,
    },

    targetContainerStyle: {
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      userSelect: "none",
      pointerEvents: "none",
    },

    rendererStyle: {
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      transform: "",
    },

    targetStyle: {
      left: 0,
      top: 0,
      width: 0,
      height: 0,
      position: "absolute",
    },
  }),

  watch: {
    maxHeightScale(newValue, oldValue) {
      this.onResize()
    },

    scale(newValue, oldValue) {
      this.updateTargetRect()
    },

    targetRect(newValue, oldValue) {
      this.pinchZoom?.reset()
      this.updateStyles()
    },
  },

  methods: {
    getContainerSize() {
      // const width = this.$refs.root.getBoundingClientRect().width
      return {
        width: Math.min(window.innerWidth, 580),
        height: window.innerHeight,
      }
    },

    onResize() {
      const width = this.getContainerSize().width
      this.scale = width / this.document.width
      this.updateStyles()
    },

    updateStyles() {
      const { width, height } = this.getContainerSize()

      const left = (width - (this.document?.width || 0) * this.scale) / 2
      const top = (height - (this.document?.height || 0) * this.scale) / 2

      this.targetContainerStyle = {
        ...this.targetContainerStyle,
        width: (this.document?.width || 0) + "px",
        height: (this.document?.height || 0) + "px",
        left: `${left}px`,
        top: `${top}px`,
      }

      this.rootStyle = {
        ...this.rootStyle,
        width: (this.document?.width || 0) * this.scale + "px",
        height: (this.document?.height || 0) * this.scale + "px",
      }

      this.rendererStyle = {
        ...this.rendererStyle,
        transform: `translate(-50%, -50%) scale(${this.scale}) translate(50%, 50%)`,
      }

      const targetWidth = this.targetRect.right - this.targetRect.left
      const targetHeight = this.targetRect.bottom - this.targetRect.top
      this.targetStyle = {
        ...this.targetStyle,
        left: this.targetRect.left + "px",
        top: this.targetRect.top + "px",
        width: targetWidth + "px",
        height: targetHeight + "px",
      }

      this.guideStyle = {
        ...this.guideStyle,
        width: this.document.width + "px",
        height: this.document.height + "px",
      }
    },

    // target layer 대상이 변경되면 target layer 정보값으로 moveable 을 업데이트
    updateTargetRect() {
      const targetRect = getLayersRect([this.subjectLayer])
      const scale = this.scale
      targetRect.left *= scale
      targetRect.top *= scale
      targetRect.right *= scale
      targetRect.bottom *= scale

      if (this.$refs.target) {
        this.$refs.target.style.transform = ""
      }
      this.targetRect = targetRect
    },

    // target layer의 transform 이 변경되면 해당 layer 에 적용
    onUpdateEdit(rect) {
      const rectContainer = this.$refs.targetContainer.getBoundingClientRect()

      const scale = this.scale
      const targetRect = this.targetRect

      const left = rect.left - rectContainer.left
      const top = rect.top - rectContainer.top
      const width = Math.max(this.document.width * scale * 0.1, rect.width)
      const height = Math.max(this.document.height * scale * 0.1, rect.height)

      // console.log(left, top, width, height);

      const offsetLeft = (left - targetRect.left) / scale
      const offsetTop = (top - targetRect.top) / scale
      const offsetWidth = (width - (targetRect.right - targetRect.left)) / scale
      const offsetHeight = (height - (targetRect.bottom - targetRect.top)) / scale

      const layer = this.subjectLayer
      layer.left = layer.startRect.left + offsetLeft
      layer.top = layer.startRect.top + offsetTop
      layer.width = layer.startRect.width + offsetWidth
      layer.height = layer.startRect.height + offsetHeight
      layer.refreshSubject?.next(0)

      if (this.shadowLayer && this.shadowRelativeTransform) {
        this.shadowLayer.left = layer.left + this.shadowRelativeTransform.left * layer.width
        this.shadowLayer.top = layer.top + this.shadowRelativeTransform.top * layer.height
        this.shadowLayer.width = this.shadowRelativeTransform.width * layer.width
        this.shadowLayer.height = this.shadowRelativeTransform.height * layer.height
        this.shadowLayer.refreshSubject?.next(0)
      }
    },

    onEndEdit() {
      const layer = this.subjectLayer
      layer.startRect.left = layer.left
      layer.startRect.top = layer.top
      layer.startRect.width = layer.width
      layer.startRect.height = layer.height
      this.updateTargetRect()
    },

    flipX() {
      this.subjectLayer.flipX = !this.subjectLayer.flipX
      this.subjectLayer.refreshSubject?.next(0)

      if (this.shadowLayer) {
        this.shadowLayer.flipX = !this.shadowLayer.flipX
        this.shadowLayer.refreshSubject?.next(0)
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize)
    const editableDoc = this.document
    this.subjectLayer = getLayerByName(editableDoc, "인물")
    this.shadowLayer = getLayerByName(editableDoc, "그림자")
    if (this.shadowLayer) {
      this.shadowRelativeTransform = {
        left: (this.shadowLayer.left - this.subjectLayer.left) / this.subjectLayer.width,
        top: (this.shadowLayer.top - this.subjectLayer.top) / this.subjectLayer.height,
        width: this.shadowLayer.width / this.subjectLayer.width,
        height: this.shadowLayer.height / this.subjectLayer.height,
      }
    }
    const logoLayer = getLayerByName(editableDoc, "로고")
    if (logoLayer) {
      logoLayer.hidden = false
    }

    const targetRect = getLayersRect([this.subjectLayer])
    const height = targetRect.bottom - targetRect.top
    this.maxHeightScale = window.innerHeight / height

    this.pinchZoom = new PinchZoom(
      this.$refs.root,
      this.$refs.target,
      () => {
        if (this.showGuideText) {
          this.showGuideText = false
        }
      },
      () => {
        this.onUpdateEdit(this.$refs.target.getBoundingClientRect())
      },
      () => {
        this.onEndEdit()
      }
    )
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize)
  },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
