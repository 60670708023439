<template>
  <v-dialog max-width="440" :value="visible" content-class="rounded-10" style="z-index: 15" @input="$emit('input', $event)">
    <v-img src="@/assets/deco/popup-top-logo.png" max-width="150" class="mx-auto" />
    <v-card class="py-2 py-md-10 px-2" color="#f7f2f7">
      <v-img src="@/assets/deco/card-top-deco.png" max-width="240px" class="mx-auto" />

      <div class="font-danjunghae primary--text text-center" style="font-size: 28px" :lang="lang">{{ $t("dialog.qrcode-login-complte-title") }}</div>

      <v-card-title class="text-body-1 text-md-h6 text-center mx-auto font-weight-bold d-block mt-0" style="color: #545454">
        <div v-html="$t('dialog.qrcode-login-complte-subtitle')"></div>
      </v-card-title>
      <v-card-text class="warning--text pb-2 text-center">
        <div v-html="$t('dialog.qrcode-login-complte-subtitle-2')"></div>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn color="#d9bddd" class="dialog_btn rounded-2" style="font-size: 20px" width="35%" depressed @click="confirmClick" large> {{ $t("button.ok") }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex"
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["lang"]),
  },
  methods: {
    confirmClick() {
      this.$emit("close")
    },
  },
}
</script>
<style scoped>
.dialog_btn {
  border: 2px solid #dadada !important;
  font-size: 16px;
  color: #625072 !important;
}
::v-deep .v-dialog {
  box-shadow: none;
  margin-top: -20px;
}
::v-deep .v-card {
  border: 3px solid #dadada !important;
  border-radius: 10px;
}
</style>
