<template>
  <div>
    <TipTapReader class="tiptap_style" :options="desc1_options" :key="'desc1' + product_desc_1_key" style="min-height: 20px" />

    <v-sheet class="text-center my-4 my-md-6">
      <div class="text-h6">
        <span>{{ $t("page.reservation.weekday") }} : </span>
        <span class="primary--text"> {{ $toComma(package_product_info.clothPrice) }} </span><span>{{ $t("common.krw") }}</span>
      </div>
      <div class="text-h6">
        <span>{{ $t("page.reservation.weekend") }} : </span>
        <span class="primary--text"> {{ $toComma(package_product_info.clothPriceWeekend) }} </span><span>{{ $t("common.krw") }}</span>
      </div>
      <div class="text-center" style="line-height: 1; font-size: 11px">{{ $t("page.reservation.package-price-per-person") }}</div>
      <!-- <div class="d-flex flex-column my-4 warning--text text-caption" style="line-height: 1.3">
        <span>{{ $t("page.reservation.package-warning-guide-1") }}</span>
        <span>{{ $t("page.reservation.package-warning-guide-2") }}</span>
      </div> -->
    </v-sheet>

    <TipTapReader clasks="tiptap_style" :options="desc2_options" :key="'desc2' + product_desc_2_key" style="min-height: 20px" />

    <!-- <PackageProduct :basePrice="basePrice" />
      <IndoorProduct :basePrice="basePrice" /> -->
    <div class="bottomFixedButton">
      <v-btn large width="50%" color="primary" style="font-size: 25px" class="rounded-10 darken-1" depressed @click="goPay">
        {{ $t("button.do-reserve") }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import TipTapReader from "@/components/tiptap/Reader"
import { DEFAULT_PACKAGE_CODE } from "@/constant"
import { mapState } from "vuex"

export default {
  components: {
    TipTapReader,
  },
  inject: ["package_product_info", "promotion_info"],

  data: () => ({
    product_desc_1_key: 0,
    product_desc_2_key: 0,

    product_desc_1_content: "",
    product_desc_2_content: "",

    //TipTap
    desc1_options: {
      content: "",
      editable: false,
      supportImage: true,
      supportVideo: true,
    },
    desc2_options: {
      content: "",
      editable: false,
      supportImage: true,
      supportVideo: true,
    },
  }),

  watch: {
    lang() {
      console.log("watch lang")
      this.setDescription()
    },
  },

  computed: {
    ...mapState(["lafia_user", "lang", "default_package_price"]),
  },

  created() {
    console.log("PackageStore created", this.package_product_info)
    this.setDescription()
  },

  mounted() {
    const early = this.$route.query.early
    if (early === "true") {
      this.goPay()
    }
  },

  methods: {
    loginCheck() {
      if (!this.lafia_user.is_logined) {
        let promotionCode = this.promotion_info && this.promotion_info.code
        if (promotionCode) {
          this.$store.commit("ADD_PROMOTION_CODE", promotionCode)
        }
        alert(this.$t("alert.error_need_authentication"))
        let loginPath = "/auth/login"
        if (this.package_product_info.code !== DEFAULT_PACKAGE_CODE) {
          loginPath += `?productId=${this.package_product_info.code}`
        }
        if (promotionCode) {
          const delim = loginPath.includes("?") ? "&" : "?"
          loginPath = loginPath + `${delim}packageEvent=${promotionCode}`
        }
        this.$router.push(loginPath)
        return false
      }
      return true
    },
    goPay() {
      const loginCheck = this.loginCheck()
      if (loginCheck) {
        this.$router.push({ name: "packagePay", params: { name: "온라인이용권", base: this.package_product_info, promotion: this.promotion_info } })
      }
    },

    setDescription() {
      let key1 = "content"
      let key2 = "content2"

      this.desc1_options.content = this.package_product_info[key1] ? this.package_product_info[key1] : this.default_package_price[key1]
      this.desc2_options.content = this.package_product_info[key2] ? this.package_product_info[key2] : this.default_package_price[key2]

      if (this.lang === "en" || this.lang === "jp") {
        key1 = key1 + `_${this.lang}`
        key2 = key2 + `_${this.lang}`

        if (this.package_product_info[key1]) {
          this.desc1_options.content = this.package_product_info[key1]
          if (this.promotion_info && this.promotion_info[key1]) {
            this.desc1_options.content = this.promotion_info.content
          }
        } else {
          this.desc1_options.content = this.package_product_info["content"]
            ? this.package_product_info["content"]
            : this.default_package_price[key1]
              ? this.default_package_price[key1]
              : this.default_package_price["content"]
        }

        if (this.package_product_info[key2]) {
          this.desc2_options.content = this.package_product_info[key2]
          if (this.promotion_info && this.promotion_info[key2]) {
            this.desc2_options.content = this.promotion_info[key2]
          }
        } else {
          this.desc2_options.content = this.default_package_price["content2"]
            ? this.package_product_info["content2"]
            : this.default_package_price[key2]
              ? this.default_package_price[key2]
              : this.default_package_price["content2"]
        }
      }

      this.product_desc_1_key++
      this.product_desc_2_key++
    },
  },
}
</script>
<style scoped>
.bottomFixedButton {
  max-width: 580px;
  width: 100%;
  height: 70px;
  position: fixed;
  bottom: 72px;
  border-top: 1px solid #e0e0e0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
</style>
