import { render, staticRenderFns } from "./rule.vue?vue&type=template&id=96095940&scoped=true"
import script from "./rule.vue?vue&type=script&lang=js"
export * from "./rule.vue?vue&type=script&lang=js"
import style0 from "./rule.vue?vue&type=style&index=0&id=96095940&prod&scoped=true&lang=css"
import style1 from "./rule.vue?vue&type=style&index=1&id=96095940&prod&scoped=true&lang=css"
import style2 from "./rule.vue?vue&type=style&index=2&id=96095940&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96095940",
  null
  
)

export default component.exports