<template>
  <v-sheet class="has_margin">
    <v-sheet v-if="loading" class="d-flex justify-center align-center" style="min-height: 200px">
      <v-progress-circular :size="48" :width="2" color="primary" indeterminate></v-progress-circular>
    </v-sheet>
    <v-sheet v-else>
      <div style="position: relative; margin-top: 40px; cursor: pointer" @click.stop="goToDetail">
        <div class="conceptName font-danjunghae">{{ data.conceptName }}</div>
        <div class="dday text-center px-6">
          <span>{{ untilDay }}</span
          ><br />
          <span class="font-danjunghae primary--text text-h5 font-weight-bold">{{ ddayStr }}</span>
        </div>
        <v-img width="100%" :aspect-ratio="16 / 10" :src="'/upload/concept/thumbnail_image/' + data.thumbnail_image"></v-img>
      </div>
      <div class="text-center font-weight-medium mt-2">{{ data.subTitle }}</div>
      <div class="text-center font-weight-light">
        <span>{{ $t("page.reservation.acc-sales") }}</span>
        <span class="primary--text ml-2 font-weight-medium">{{ $toComma(amount) }}</span
        ><span class="ml-1">{{ $t("common.krw") }}</span>
      </div>
      <div class="text-center mt-2 font-weight-light">
        <span>{{ $t("page.reservation.package-sales") }}</span>
        <span class="font-danjunghae font-weight-bold text-h5 primary--text ml-4">{{ $toComma(minAmount) }}</span>
        <span class="ml-1">{{ $t("common.krw") }}</span>
        <span>{{ $t("page.reservation.package-sales-min-tailfix") }}</span>
      </div>
    </v-sheet>
  </v-sheet>
</template>
<script>
import statusService from "@/api/stats-service"

export default {
  props: {
    data: {
      type: Object,
      require: true,
    },
  },

  data: () => ({
    loading: false,
    promotions: [],
    minAmount: 0,
    baseAmount: 0,
    totalAmount: 0,
  }),

  computed: {
    untilDay() {
      return this.dayjs(this.data.endAt).format("YY.M.D") + " 까지"
    },
    ddayStr() {
      return "D" + this.dayjs().diff(this.dayjs(this.data.endAt).format("YYYY-MM-DD"), "day")
    },

    promotionCodes() {
      return this.data.promotionCodes.split(",").map((e) => e.trim())
    },

    amount() {
      return this.totalAmount + this.baseAmount
    },
  },

  mounted() {
    console.log(this.data)
    this.load()
  },
  methods: {
    async load() {
      this.loading = true
      try {
        const result = await this.loadPromotionInfo(this.promotionCodes)
        this.promotions = result
        this.initialize()
        console.log("early product item", result)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async loadPromotionInfo(codes) {
      console.log(codes)
      return (
        await this.$http.get(`/api/promotion/select/code`, {
          params: {
            code: codes,
          },
        })
      ).data
    },

    async loadPromotionSales(promotionCode) {
      const promotion = this.promotions.find((p) => p.code === promotionCode)
      if (!promotion) {
        return 0
      }
      const from = this.dayjs(promotion.openAt).format("YYYY-MM-DD")
      const to = this.dayjs(promotion.endAt).endOf("month").format("YYYY-MM-DD")

      const result = await statusService.getPromotionSalesStats(true, from, to, promotionCode)
      console.log(result)
      return result.reduce((sum, e) => sum + Number(e.total_amount), 0)
    },

    async initialize() {
      this.minAmount = Math.min(...this.promotions.map((e) => e.clothPrice))
      this.baseAmount = this.data.baseAmount

      const result = await Promise.all(this.promotionCodes.map((code) => this.loadPromotionSales(code)))
      this.totalAmount = result.reduce((sum, e) => sum + e, 0)
    },

    goToDetail() {
      this.$router.push({ name: "earlyDetail", params: { src: this.data } })
    },
  },
}
</script>
<style scoped>
.conceptName {
  position: absolute;
  z-index: 2;
  border-radius: 10px;
  border: 3px solid #b9aadb;
  text-align: center;
  color: #66546d;
  font-size: 26px;
  width: 240px;
  top: -20px;
  left: calc(50% - 120px);
  background-color: white;
}
.dday {
  position: absolute;
  z-index: 2;
  border-radius: 10px;
  border: 2px solid #b2b2b2;
  bottom: 6px;
  right: 6px;
  background-color: rgba(255, 255, 255, 0.8);
}

.has_margin {
  margin-bottom: 90px;
}
</style>
