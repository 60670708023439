<template>
  <v-app>
    <v-container>
      <h3 class="text-center mt-10">{{ $t("page.pay.pay-fail-title") }}</h3>
      <v-btn class="primary rounded-10 d-flex mx-auto mt-6" depressed @click="$router.push('/')"> {{ $t("page.pay.go-home") }} </v-btn>
    </v-container>
  </v-app>
</template>

<script>
import { mapActions } from "vuex"

export default {
  mounted() {
    let tossId = this.$route.query.orderId
    let message = this.$route.query.message || ""
    console.log("fail order id ", tossId)

    this.$http
      .post("/api/order/select/check_duplicate", {
        params: {
          toss_id: tossId,
        },
      })
      .then((res) => {
        let orderListId = res.data[0].order_list_id
        this.cancelPayStatusUpdate({ order_id: orderListId, reason: message })
      })
  },

  methods: {
    ...mapActions(["cancelPayStatusUpdate"]),
  },
}
</script>
