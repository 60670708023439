<template>
  <div>
    <v-btn
      text
      class="sela d-flex deactivate py-2 px-4"
      @click="$emit('click')"
      :disabled="disabled"
      style="gap: 20px; width: 100%; border-radius: 15px; border: 5px solid #d9bddc; height: auto; position: relative"
    >
      <div class="d-flex" style="gap: 12px; justify-content: space-between; width: 100%">
        <div class="" style="width: 35%; max-width: 56px; flex-shrink: 0">
          <img src="@/assets/edit.png" style="width: 100%; height: 100%; object-fit: contain" />
        </div>
        <div style="flex-grow: 1">
          <div class="d-flex" style="text-align: left">
            <span class="primary--text font-weight-medium text-none text-body-1">{{ title }}</span>
            <span>
              <v-img src="@/assets/twinkle-star.svg" width="36" />
            </span>
          </div>
          <div class="text-body-2" style="text-align: left; text-transform: none; word-break: break-all; white-space: break-spaces" v-html="message"></div>
        </div>
        <div class="" style="width: 15%; max-width: 18px">
          <img src="@/assets/left-arrow.png" style="width: 100%; height: 100%; object-fit: contain" />
        </div>
      </div>
    </v-btn>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    test() {
      console.log("test")
    },
  },
}
</script>
<style></style>
