<template>
  <div class="pb-10">
    <TitleLogo title="트래블러 미션" />
    <v-divider></v-divider>
    <v-sheet class="d-flex justify-end mx-4 mt-2">
      <v-btn outlined color="primary" to="/traveler/ranking">랭킹</v-btn>
    </v-sheet>
    <v-list>
      <v-list-item v-for="item in list" :key="item.id">
        <v-card style="width: 100%" class="pa-4 mt-2" flat outlined @click="itemSelect(item)">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
          <v-list-item-subtitle class="mt-1"> {{ new Date(item.created).toLocaleDateString() }} </v-list-item-subtitle>
        </v-card>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import TipTapReader from "@/components/tiptap/Reader"
import TitleLogo from "@/views/components/TitleLogo"
export default {
  components: {
    TipTapReader,
    TitleLogo,
  },
  data: () => ({
    list: [],
  }),
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      try {
        const result = await this.$http.get("/api/missions/select")
        this.list = result.data
        console.log(this.list)
      } catch (e) {
        console.log(e)
      }
    },
    itemSelect(item) {
      this.$router.push(`/traveler/missions/${item.id}`)
    },
  },
}
</script>
<style></style>
