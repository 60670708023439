<template>
  <div>
    <!-- 제목 -->
    <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">리뷰 관리</font>
    </v-sheet>

    <!-- 본문 -->
    <v-sheet outlined class="pa-2 ma-2">
      <!-- 검색 -->
      <v-sheet outlined class="pa-2 mb-2 d-flex">
        <v-sheet class="d-flex align-center" width="800">
          <!-- 유형 -->
          <v-sheet width="160" class="mr-3">
            <v-select outlined dense hide-details :items="select_list.search_type" v-model="search_type"></v-select>
          </v-sheet>

          <!-- 키워드 -->
          <v-text-field placeholder="검색어를 입력해주세요" outlined dense hide-details v-model="keyword" @keyup.enter="search()" clearable></v-text-field>

          <!-- 제출 버튼 -->
          <v-btn height="40" class="px-6 ml-3" color="primary" dark depressed @click="search()">
            <v-icon small left>mdi-magnify</v-icon>
            검색하기
          </v-btn>

          <!-- 활성화 여부 체크 박스-->
          <v-checkbox v-model="isHidden" class="shrink mt-0 ml-4" label="숨긴댓글만" color="primary" hide-details dense> 숨긴댓글만</v-checkbox>
        </v-sheet>
        <v-spacer></v-spacer>

        <!-- 새로고침 버튼 -->
        <v-btn height="40" class="px-3 ml-2 my-auto" color="primary" dark depressed @click="refresh()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-sheet>

      <!-- 목록 -->

      <v-data-table
        :items="list"
        item-key="id"
        :headers="tableHeaders"
        no-data-text="검색된 리뷰가 없습니다."
        :loading="loading"
        loading-text="Loading... Please wait"
        :options.sync="options"
        :server-items-length="totalCount"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50],
          'show-current-page': true,
          'show-first-last-page': true,
        }"
        :page.sync="pageNum"
      >
        <template v-slot:[`item.created`]="{ item }">
          <span>{{ dayjs(item.created).format("YYYY.MM.DD HH:mm") }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon @click="hiddenItem(item)"> {{ item.hidden ? "mdi-delete-restore" : "mdi-delete" }} </v-icon>
        </template>
      </v-data-table>
    </v-sheet>
  </div>
</template>
<script>
export default {
  data: () => ({
    select_list: {
      search_type: ["아이디"],
    },
    search_type: "아이디",
    keyword: "",
    list: [],

    loading: false,
    totalCount: 0,
    pageNum: 1,

    options: {},

    tableHeaders: [
      { text: "번호", width: "80px", value: "id", sortable: false },
      { text: "컨셉", width: "150px", value: "conceptName", sortable: false },
      { text: "작성자", width: "150px", value: "userName", sortable: false },
      { text: "내용", value: "content", sortable: false },
      { text: "아이디", width: "260px", value: "user_id", sortable: false },
      { text: "작성일", width: "150px", value: "created", sortable: false },
      { text: "Actions", value: "actions", width: "100px", sortable: false },
    ],

    isHidden: false,
  }),

  watch: {
    options: {
      handler() {
        this.load(this.options.page, this.options.itemsPerPage)
      },
      deep: true,
    },
    isHidden() {
      this.search()
    },
  },

  mounted() {
    // 목록
    this.search()
  },

  methods: {
    refresh() {
      this.load(this.options.page, this.options.itemsPerPage)
    },

    search() {
      this.pageNum = 1
      this.load()
    },

    // 목록
    async load(page = 1, itemPerPage = 10) {
      let params = {
        limit: itemPerPage,
        offset: (page - 1) * itemPerPage,
      }

      if (this.keyword && this.search_type === "아이디") {
        params["user_id"] = this.keyword
      }

      if (this.isHidden) {
        params["hidden"] = this.isHidden
      }

      try {
        this.loading = true
        const result = await this.$http.get("/api/review/select/all", {
          params,
        })
        this.list = result.data.data
        this.totalCount = result.data.totalCount
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    hiddenItem: _.debounce(function (item) {
      console.log(item)
      if (item.hidden) {
        if (confirm(`${item.id} 번호 댓글을 복원 처리하시겠습니까?`)) {
          this.$http
            .post("/api/review/update/restore", {
              admin_id: this.$store.state.lafia_admin.user_id,
              id: item.id,
            })
            .then((res) => {
              if (res.data.affectedRows) {
                alert("댓글을 복원 처리 하였습니다.")
                this.search()
              }
            })
            .catch((err) => {
              console.log(err)
            })
        }
      } else if (confirm(`${item.id} 번호 댓글을 숨김처리하시겠습니까?`)) {
        this.$http
          .post("/api/review/update/hidden", {
            admin_id: this.$store.state.lafia_admin.user_id,
            id: item.id,
          })
          .then((res) => {
            if (res.data.affectedRows) {
              alert("댓글을 숨김 처리 하였습니다.")
              this.search()
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }, 500),
  },
}
</script>
