<template>
  <div>
    <v-sheet class="pa-2 ma-2">
      <!-- 검색 -->
      <v-sheet outlined class="pa-2 mb-2 d-flex">
        <v-sheet class="d-flex" width="660">
          <!-- 유형 -->
          <v-sheet width="160" class="mr-3">
            <v-select outlined dense hide-details :items="select_list.search_type" v-model="search_type"></v-select>
          </v-sheet>

          <!-- 키워드 -->
          <v-text-field placeholder="검색어를 입력해주세요" outlined dense hide-details v-model.trim="keyword" @keyup.enter="search()"></v-text-field>

          <!-- 제출 버튼 -->
          <v-btn height="40" class="px-6 ml-3" color="primary" dark depressed @click="search()">
            <v-icon small left>mdi-magnify</v-icon>
            검색하기
          </v-btn>
        </v-sheet>
      </v-sheet>

      <!-- 목록 -->
      <v-data-table
        v-model="selected"
        :headers="tableHeaders"
        item-key="id"
        show-select
        :items="list"
        no-data-text="검색된 회원이 없습니다."
        :loading="loading"
        loading-text="Loading... Please wait"
        :footer-props="{
          'items-per-page-options': [10, 20, 100, -1],
          itemsPerPageText: '페이지당 회원 수',
        }"
      >
        <template v-slot:[`header.data-table-select`]></template>
        <template v-slot:[`item.created`]="{ item }">
          <span>{{ dayjs(item.created).format("YYYY.MM.DD") }}</span>
        </template>
        <template v-slot:[`item.profile_image`]="{ item }">
          <v-avatar color="rgb(219, 219, 219)" size="52">
            <v-img :src="'/upload/user/profile_image/' + item.profile_image"></v-img>
          </v-avatar>
        </template>
      </v-data-table>

      <!-- 버튼 -->
      <v-card class="mt-2 pa-2 d-flex justify-end" flat>
        <v-btn class="px-6 ml-3" color="success" large dark depressed @click="selectUsers()"> 선택 </v-btn>
      </v-card>
    </v-sheet>
  </div>
</template>
<script>
export default {
  data: () => ({
    select_list: {
      search_type: ["아이디", "이름", "전화번호", "이메일"],
    },
    search_type: "아이디",
    keyword: "",

    list: [],
    loading: false,
    selected: [],

    tableHeaders: [
      { text: "", value: "data-table-select", width: "80px", align: "center" },
      { text: "프로필", width: "80px", value: "profile_image", align: "center", sortable: false },
      { text: "아이디", value: "user_id", sortable: false },
      { text: "이름", value: "name", sortable: false },
      { text: "전화번호", width: "200px", value: "phone", sortable: false },
      { text: "가입일", width: "160px", value: "created", sortable: false },
    ],
  }),
  methods: {
    search() {
      this.loading = true
      this.$http
        .post("/api/user/select/search", {
          params: {
            search_type: this.search_type,
            keyword: this.keyword,
          },
        })
        .then((res) => {
          this.list = res.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    selectUsers() {
      console.log(this.selected)
      this.$emit("selectUsers", [...this.selected])
      this.selected = []
      this.list = []
      this.keyword = ""
    },
  },
}
</script>
<style></style>
