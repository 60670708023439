<template>
  <div>
    <!-- 페이지 제목 -->
    <div v-if="!!promotionPriceInfo" style="position: absolute; left: calc(50% - 142px); top: 50px" class="mx-auto">
      <img src="@/assets/reservation/promotion-deco.png" width="300" />
    </div>

    <ReservationTitleLogo v-if="!!promotionPriceInfo" :warning="true"> {{ getTitle(promotionPriceInfo) }} <br v-if="promotionPriceInfo && promotionPriceInfo.name" />비밀 예약 </ReservationTitleLogo>

    <TitleLogo v-else :title="$t('page.reservation.title')" :warning="false" />
    <v-divider></v-divider>
    <div class="text-center text-body-2 font-weight-light py-2 py-md-3" style="position: relative; background-color: white">{{ $t("page.reservation.top-dress-guide") }}</div>

    <v-img src="@/assets/reservation/dress-room.png" width="100%" aspect-ratio="2.3" />

    <div class="warning--text text-center mt-4 mb-2" color="warning" style="font-size: 12px">
      {{ promotionPriceInfo ? $t("page.reservation.reservation-warn-1") : $t("page.reservation.reservation-warn-2") }}<br />
      {{ $t("page.reservation.reservation-warn-3") }}
    </div>

    <!-- <v-sheet class="px-3">
      <iframe width="100%" style="aspect-ratio: 16/9" src="https://www.youtube.com/embed/lSVLnVx4mEU?si=6PgGF87jgSqBcmLi" frameborder="0" allowfullscreen></iframe>
    </v-sheet> -->

    <!-- 세라캐슬 가격안내표 -->
    <CastlePriceTable :loading="priceInfoLoading" :main="defaultPriceInfo" :promotion="promotionPriceInfo" />

    <!-- <WarningText>
      <p class="warning--text mt-4" style="font-size: 12px">
        [입장료] [드레스 대여]만 사전예약이 가능하며,<br />
        무인촬영기 / 디저트등 다른 콘텐츠는 별도 현장 추가 결제
      </p>
    </WarningText>
    <v-divider class="my-7"></v-divider> -->

    <!-- 날짜 선택 -->
    <DateSelector
      :color="$vuetify.theme.currentTheme.primary"
      :width="330"
      class="mt-7 mb-4 mx-auto"
      @dateUpdated="dateUpdated_handler"
      :dateInit="date"
      :key="'DateSelector' + dateSelectComponentKey"
    />

    <!-- 시간 선택 -->
    <div v-if="!loading" class="d-flex justify-center mt-5 flex-wrap mx-auto" style="width: 350px" :key="componentKey + 'time'">
      <v-btn
        v-for="item in time_option_list"
        :key="item"
        height="36"
        depressed
        class="timeSelectBtn rounded-10 px-2 mb-1 my-1"
        :class="{ enable: selected_time == item }"
        :disabled="already_reserved_time_list.includes(item)"
        @click="!already_reserved_time_list.includes(item) && selected_time != item ? (selected_time = item) : (selected_time = '')"
      >
        {{ item }}
      </v-btn>
    </div>

    <!-- 로딩 (시간 선택) -->
    <v-progress-circular v-if="loading" indeterminate color="primary" size="40" style="height: 72px" class="d-flex mx-auto mt-8"></v-progress-circular>

    <!-- 가능한 인원 표시하기 -->
    <p v-if="selected_time" class="text-body-2 text-center mt-8 grey--text text--darken-1">
      {{ $t("page.reservation.left-seats") }}
      <span class="ml-2 text-darken-2" style="font-size: 20px; font-weight: 600">{{ getMaxAvailableRoom - selected_time_already_reserved }}</span>
      {{ $t("page.reservation.left-seats-unit") }}
    </p>
    <div v-else style="height: 54px"></div>

    <!-- 대여/미대여 인원 입력 -->
    <div class="mx-8 mt-6">
      <div class="d-flex align-center justify-center text-body-2 font-weight-medium">
        <div class="d-flex align-center">
          {{ $t("page.reservation.people-number") }}
          <v-select
            style="width: 72px; margin: 0 6px; z-index: 5"
            class="rounded-10 shrink my-0"
            hide-details
            outlined
            :disabled="selected_time_already_reserved == getMaxAvailableRoom"
            dense
            append-icon="mdi-chevron-down"
            :items="select_list.number"
            v-model.number="cloth"
            @change="checkTotalPerson('cloth')"
            :key="componentKey + 'cloth'"
          >
            <template v-slot:selection="{ item }">
              <span class="pl-1 pb-2px">{{ item }}</span>
            </template>
            <template v-slot:append>
              <v-icon style="width: 12px">mdi-chevron-down</v-icon>
            </template>
          </v-select>
          {{ $t("page.reservation.people-unit") }}
        </div>

        <div class="d-flex align-center ml-8"><span class="text-h6 px-2">1</span>{{ $t("common.hour") }}</div>
      </div>

      <WarningText>
        <p class="warning--text mt-4" style="font-size: 12px" v-html="$t('page.reservation.man-child-dress-warning')"></p>
      </WarningText>
    </div>
    <!-- 대여 & 미대여 입력 end -->
    <!-- 추가 가능한 옵션 -->
    <v-container class="mt-6" style="margin-bottom: 260px; padding: 0" v-if="isNeedShowOptions">
      <div style="position: relative" class="text-center">
        <span class="font-danjunghae accent--text text-center font-danjunghae" style="font-size: 30px" :lang="lang"> {{ $t("page.reservation.options") }}</span>
        <span class="text-caption font-weight-light">({{ $t("page.reservation.per-person") }})</span>
      </div>

      <v-sheet style="border-top: 1px solid #c2bcba" class="py-6 px-6">
        <v-row no-gutter>
          <v-col cols="5">
            <v-sheet class="d-flex flex-column">
              <div style="color: #545454; font-size: 17px; font-weight: 500">{{ $t("page.reservation.option-time-extension") }}</div>
              <div class="text-caption text-md-body-2 font-weight-light" style="color: #545454">{{ $t("page.reservation.option-time-extension-subtitle") }}</div>
            </v-sheet>
          </v-col>
          <v-col cols="4">
            <div class="d-flex flex-column align-end justify-center">
              <!-- <div class="text-caption font-weight-light">평일 <span style="color: #545454; font-size: 17px; font-weight: 500">3,500원</span></div>
              <div class="text-caption font-weight-light">주말 <span style="color: #545454; font-size: 17px; font-weight: 500">4,000원</span></div> -->
              <div class="text-caption font-weight-light">
                <span style="color: #545454; font-size: 17px; font-weight: 500">{{
                  defaultPriceInfo && getDispPriceWithConfig(defaultPriceInfo.tenOverTimePrice, defaultPriceInfo.tenOverTimePriceUSD)
                }}</span>
              </div>
            </div>
          </v-col>
          <v-col cols="3">
            <div class="d-flex align-center justify-center">{{ $t("page.reservation.option-add-requet") }}</div>
          </v-col>
        </v-row>
      </v-sheet>
      <v-sheet style="border-top: 1px solid #c2bcba; border-bottom: 1px solid #c2bcba" class="py-6 px-6">
        <v-row no-gutter>
          <v-col cols="5">
            <v-sheet class="d-flex flex-column">
              <div style="color: #545454; font-size: 17px; font-weight: 500">{{ $t("page.reservation.option-dress-change") }}</div>
              <div class="text-caption text-md-body-2 font-weight-light" style="color: #545454">{{ $t("page.reservation.option-dress-change-subtitle") }}</div>
            </v-sheet>
          </v-col>
          <v-col cols="4">
            <div class="d-flex align-center justify-end">
              <span style="color: #545454; font-size: 17px; font-weight: 500">
                {{ defaultPriceInfo && getDispPriceWithConfig(defaultPriceInfo.changeDressPrice, defaultPriceInfo.changeDressPriceUSD) }}
              </span>
            </div>
          </v-col>
          <v-col cols="3">
            <div class="d-flex align-center justify-center">{{ $t("page.reservation.option-add-requet") }}</div>
          </v-col>
        </v-row>
      </v-sheet>
    </v-container>
    <v-container v-else style="margin-bottom: 260px; padding: 0"></v-container>

    <!-- 예약하기 하단 -->
    <v-sheet style="width: 100%; max-width: 580px; position: fixed; bottom: 72px; border-top: 1px solid #e0e0e0; z-index: 10">
      <!-- 최종가격 -->
      <p class="text-end font-danjunghae ml-1 px-6 mt-2 mb-0" style="font-size: 40px; color: #5b4a62">
        <span v-if="selected_coupon" class="mr-2 text-body-2 warning--text font-weight-light">{{ $t("page.pay.coupon-apply-label", { amount: getDiscountPriceStr }) }}</span>
        <!-- <span v-if="!!promotionPriceInfo" class="mr-2 text-body-2 warning--text font-weight-light">(할인 적용가)</span> -->
        <span class="mr-2 text-body-1 grey--text text--darken-2" style="vertical-align: 10%">{{ $t("page.reservation.total-reserv-persons", { person: this.cloth }) }}</span>
        {{ $toComma(computed_total_price) }}<span class="text-body-1 ml-1 grey--text text--darken-2">{{ $t("common.krw") }}</span>
      </p>

      <!-- 쿠폰, 예약하기 -->
      <div class="d-flex px-6 pb-4">
        <v-btn
          large
          width="40%"
          color="#f1b2ba"
          style="font-size: 20px; color: #693838"
          class="flex-grow-1 rounded-10 pb-1"
          depressed
          outlined
          :disabled="selected_time == '' || !cloth"
          @click="showCouponSelectDialog()"
        >
          {{ $t("button.coupon") }}<span v-show="available_coupon_cnt">({{ available_coupon_cnt }})</span>
        </v-btn>
        <v-btn
          large
          width="40%"
          color="primary"
          style="font-size: 25px"
          class="flex-grow-1 rounded-10 pb-1 ml-4 darken-1"
          depressed
          :disabled="selected_time == '' || !cloth"
          @click="onReservationWithApplicableCouponCheck"
          :loading="pay_loading"
        >
          {{ $t("button.do-reserve") }}
        </v-btn>
      </div>
    </v-sheet>

    <MyCouponSelectList
      type="드레스대여"
      :visible="show_coupon_select"
      @close="show_coupon_select = false"
      @input="(e) => (show_coupon_select = e)"
      :key="'coupon' + show_coupon_select_componentKey"
      @apply="(item) => applyCoupon(item)"
      :showNoCoupon="showNoCoupon"
    />

    <TwoButtonDialogVue
      :msg="twobutton_dialog_messsage"
      @input="twoButtonDialogInputEvent"
      positiveText="button.pay"
      negativeText="button.coupon-select"
      @positive="positiveDialogEvent"
      @negative="negativeDialogEvent"
      :visible="show_twobutton_dialog"
      :key="'twobutton' + show_twobutton_dialog_componentKey"
    />

    <NormalWarningDialog :msg="warning_dialog_messsage" @input="warningDialogInputEvent" @confirm="confirmDialog" :visible="show_warning_dialog" :key="'normal' + show_warning_dialog_componentKey" />

    <!-- <RefundPolicyGuideDialog
      @agree="agreeRefundPolicy"
      @cancel="show_refund_policy = false"
      @input="(e) => (show_refund_policy = e)"
      :visible="show_refund_policy"
      :key="refund_policy_dialog_componentKey"
    /> -->
    <!-- <OptionTimeExtGuideDialog :visible="show_timeext_guide" @close="show_timeext_guide = false" @input="(e) => (show_timeext_guide = e)" /> -->
    <!-- <OptionDessertGuideDialog :visible="show_dessert_guide" @close="show_dessert_guide = false" @input="(e) => (show_dessert_guide = e)" /> -->
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex"
import CastlePriceTable from "./Castle/CastlePriceTable"
import DateSelector from "@/components/DateSelector"
import TitleLogo from "@/views/components/TitleLogo"
import ReservationTitleLogo from "@/views/components/ReservationTitleLogo"
import WarningText from "./Castle/WarningText"
import { Holidays, RESERV_CAN_TIME_LIST } from "@/constant"
import { mapGetters } from "vuex"
import MyCouponSelectList from "@/views/components/MyCouponSelectList"
import NormalWarningDialog from "@/views/components/NormalWarningDialog"
import TwoButtonDialogVue from "@/views/components/TwoButtonDialog"
import payletterService from "@/api/payletter-service"
// import RefundPolicyGuideDialog from "@/views/components/RefundPolicyGuideDialog"
// import OptionTimeExtGuideDialog from "@/views/components/OptionTimeExtGuideDialog"
// import OptionDessertGuideDialog from "@/views/components/OptionDessertGuideDialog"

export default {
  components: {
    CastlePriceTable,
    DateSelector,
    TitleLogo,
    ReservationTitleLogo,
    WarningText,
    MyCouponSelectList,
    NormalWarningDialog,
    TwoButtonDialogVue,
    // RefundPolicyGuideDialog,
    // OptionTimeExtGuideDialog,
    // OptionDessertGuideDialog,
  },

  data: () => ({
    select_list: {
      number: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },

    date: null,
    dateSelectComponentKey: 0,

    time_option_list: RESERV_CAN_TIME_LIST,
    selected_time: "",

    already_reserved_time_list: [],
    already_reserved_person_list: [],

    cloth: 0,
    componentKey: 0,

    loading: true,
    defaultPriceInfo: null,
    matchedPromotionInfo: null,
    promotionPriceInfo: null,
    priceInfoLoading: true,

    lafia_castle_already_person: 0,

    tossPayments: null,
    reservation_dogin: false,

    show_warning_dialog: false,
    warning_dialog_messsage: "",
    show_warning_dialog_componentKey: 0,

    // show_refund_policy: false,
    // refund_policy_dialog_componentKey: 0,

    show_coupon_select_componentKey: 0,
    // show_timeext_guide: false,
    // show_dessert_guide: false,
    show_coupon_select: false,

    selected_coupon: null,
    available_coupon_cnt: 0,

    show_twobutton_dialog: false,
    twobutton_dialog_messsage: "",
    show_twobutton_dialog_componentKey: 0,
    showNoCoupon: true,

    //결제 진행중
    pay_loading: false,

    //payletter window
    payletterWindow: null,
    payletterWindowEventListener: null,
  }),

  computed: {
    ...mapState(["lang", "lafia_user", "isSupportAbroadPay"]),
    ...mapGetters(["isKRW"]),
    isNotJpn() {
      return this.lang !== "jp"
    },

    getMaxAvailableRoom() {
      return 15
    },

    getClothPrice() {
      if (this.isWeekendOrHoliday) {
        return (
          (this.promotionPriceInfo && (this.isKRW ? this.promotionPriceInfo.clothPriceWeekend : this.promotionPriceInfo.clothPriceWeekendUSD)) ||
          (this.defaultPriceInfo && (this.isKRW ? this.defaultPriceInfo.clothPriceWeekend : this.defaultPriceInfo.clothPriceWeekendUSD))
        )
      } else {
        return (
          (this.promotionPriceInfo && (this.isKRW ? this.promotionPriceInfo.clothPrice : this.promotionPriceInfo.clothPriceUSD)) ||
          (this.defaultPriceInfo && (this.isKRW ? this.defaultPriceInfo.clothPrice : this.defaultPriceInfo.clothPriceUSD))
        )
      }
    },

    getDiscountPrice() {
      if (!this.selected_coupon) {
        return 0
      }
      const price = this.isKRW ? this.selected_coupon.discount_KRW : this.selected_coupon.discount_USD
      return price || 0
    },
    getDiscountPriceStr() {
      const price = this.getDiscountPrice
      return `${this.$toComma(Math.min(price || 0, this.computed_total_price_original))}${this.$t("common.krw")}`
    },

    // 주말 또는 공휴일인지 확인
    isWeekendOrHoliday() {
      if (this.date) {
        return this.date.day() === 6 || this.date.day() === 0 || Holidays.includes(this.date.format("YYYY-MM-DD"))
      } else {
        return false
      }
    },

    getCurrencySymbol() {
      return this.isKRW ? "₩" : "$"
    },

    getCurrency() {
      return this.isKRW ? "원" : "USD"
    },

    computed_total_price_original() {
      return this.getClothPrice * this.cloth
    },

    // 최종 가격 계산
    computed_total_price() {
      return Math.max(this.getClothPrice * this.cloth - this.getDiscountPrice, 0)
    },

    // 선택한 타임의 기예약인원
    selected_time_already_reserved() {
      return this.already_reserved_person_list.find((item) => item.time === this.selected_time)?.person || 0
    },

    getPromotionCode() {
      return this.$route.query.event /*|| this.$store.state.promotionCode*/
    },

    isNeedShowOptions() {
      return !this.promotionPriceInfo || (this.promotionPriceInfo && this.promotionPriceInfo.role === "TRAVELER")
    },
  },

  watch: {
    selected_time(newTime, oldTime) {
      // 해당 타임에 기예약인원 확인
      let already_reserved = this.already_reserved_person_list.find((item) => item.time === newTime)?.person || 0

      // 기예약인원 포함 15명 이상일시
      if (this.cloth + already_reserved > this.getMaxAvailableRoom) {
        alert(newTime + " 타임은 현재 " + (this.getMaxAvailableRoom - already_reserved) + "명까지 예약하실 수 있습니다")
        this.selected_time = oldTime
        this.componentKey++
      }
    },

    // 날짜가 변경이 된 경우
    date: {
      handler(newVal, oldVal) {
        // 날짜 변경시 promotion 정보 업데이트 - 날짜 유효성 여부 체크
        if (newVal && !oldVal) {
          this.checkPromotionDate()
        } else if (newVal && oldVal && !newVal.isSame(oldVal, "day")) {
          this.checkPromotionDate()
        }
      },
      immediate: true,
    },
  },

  async mounted() {
    // 토스 기본설정
    await this.tossInit()
    await this.loadPriceInfo()

    if (this.lafia_user.is_logined && this.lafia_user.user_id) {
      this.$http
        .get("/api/coupon/getUserCoupons", {
          params: {
            user_id: this.lafia_user.user_id,
            type: "드레스대여",
          },
        })
        .then((res) => {
          this.available_coupon_cnt = res.data.length
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },

  methods: {
    ...mapActions(["cancelPayStatusUpdate"]),

    async loadPriceInfo() {
      try {
        this.priceInfoLoading = true
        const result = await this.$http.get("/api/promotion/select")

        let promotionCode = this.getPromotionCode

        if (promotionCode) {
          this.matchedPromotionInfo = result.data.find((e) => e.code == promotionCode)
        }

        this.defaultPriceInfo = result.data.find((e) => e.code == "default")
        this.checkPromotionDate(true)

        if (!this.defaultPriceInfo) {
          this.$router.go(-1)
          alert(this.$t("alert.error_page"))
          //가격 테이블이 없는 경우
        }
      } catch (e) {
        //if (e.status === 404) {
        this.$router.go(-1)
        alert(this.$t("alert.error_page"))
        //가격 테이블이 없는 경우

        //}
      } finally {
        this.priceInfoLoading = false
      }
    },

    // 인원 확인
    checkTotalPerson(lastValue) {
      // 인원 15명 이상 입력 시
      if (this.cloth > this.getMaxAvailableRoom) {
        if (lastValue == "cloth") {
          this.cloth = 0
        }
        alert("세라캐슬 한 타임에 최대인원을 초과입력하셨습니다.")
        this.componentKey++

        // 인원 15명 이하 입력시
      } else {
        // 해당 타임에 기예약인원 확인
        let already_reserved = this.already_reserved_person_list.find((item) => item.time === this.selected_time)?.person || 0

        // 기예약인원 포함 15명 이상일시
        if (this.cloth + already_reserved > this.getMaxAvailableRoom) {
          if (lastValue == "cloth") {
            this.cloth = 0
          }
          alert(this.selected_time + " 타임에 현재 최대 " + (this.getMaxAvailableRoom - already_reserved) + "명까지 예약하실 수 있습니다")
          this.componentKey++
        }
      }
    },

    // 토스 기본설정
    tossInit() {
      let recaptchaScript = document.createElement("script")
      recaptchaScript.setAttribute("src", "https://js.tosspayments.com/v1")
      document.head.appendChild(recaptchaScript)
    },

    // DateSelector에서 date 가져오기
    dateUpdated_handler(date) {
      this.date = date.startOf("day")
      this.loading = true

      //날짜가 바뀐 경우 정보 초기화
      this.cloth = 0

      this.checkOpenTime()
    },

    // 선택된 날짜의 닫힌 시간 확인하기
    checkOpenTime() {
      // 선택한 시간 초기화
      this.selected_time = ""

      let defaultExpireTime = null

      if (this.defaultPriceInfo && this.defaultPriceInfo.endAt) {
        console.log(this.defaultPriceInfo.endAt)
        defaultExpireTime = this.dayjs(this.defaultPriceInfo.endAt)
      }

      // 2024-03-01 이전
      if (this.date.isBefore(this.dayjs("2024-03-01"), "date")) {
        // 전부 닫기
        this.already_reserved_time_list = this.time_option_list
        this.loading = false
      }

      // 기본 가격에 따른 endAt 날짜에 따라 open 여부 결정
      else if (defaultExpireTime && this.date.isAfter(defaultExpireTime, "date")) {
        // 전부 닫기
        this.already_reserved_time_list = this.time_option_list
        this.loading = false
      }

      // 지난 날짜의 경우
      else if (this.date.isBefore(this.dayjs(), "date")) {
        // 전부 닫기
        this.already_reserved_time_list = RESERV_CAN_TIME_LIST
        this.loading = false
      }
      // 월요일의 경우
      // 화 close
      else if (this.date.day() == 1 || this.date.day() == 2) {
        // 전부 닫기
        this.already_reserved_time_list = RESERV_CAN_TIME_LIST
        this.loading = false
      }
      // 월요일 외
      else {
        // 초기화
        this.already_reserved_time_list = []
        this.already_reserved_person_list = []

        // 오늘 날짜일시 예약시간이 지났는지 확인
        this.TodayTimePassCheck()

        // 모든 요청이 저장될 프로미스 배열
        let promises = []

        // 15명인 시간 닫기 (각 시간별 예약인원 체크)
        for (let time of this.time_option_list) {
          // 각 요청을 프로미스 배열에 추가
          let promise = this.$http
            .post("/api/reservation/select/specific/time/castle/check", {
              params: {
                year: this.date.year(),
                month: this.date.month() + 1,
                day: this.date.date(),
                time: time,
              },
            })
            .then((res) => {
              let person = 0
              if (res.data.length) {
                person = Number(res.data[0].total_cloth)
              }

              // 각 타임별 기예약인원 배열로 저장
              this.already_reserved_person_list.push({
                time: time,
                person: person,
              })

              // 15명 채운 타임 닫기
              if (person == this.getMaxAvailableRoom) {
                this.already_reserved_time_list.push(time)
              }
            })

          promises.push(promise)
        }

        // 모든 프로미스가 완료되면 로딩 상태 변경
        Promise.all(promises)
          .then(() => {
            this.loading = false
          })
          .catch((error) => {
            console.error("An error occurred:", error)
            this.loading = false
          })
      }
    },

    // 오늘 날짜일시 예약시간이 지났는지 확인
    TodayTimePassCheck() {
      // 오늘 날짜일 경우
      if (this.dayjs().isSame(this.date, "date")) {
        this.time_option_list.forEach((time) => {
          // 30 분 이전 까지만 예약 가능
          const now = this.dayjs().add(30, "minute")

          let timeToCheck = this.dayjs()
            .hour(parseInt(time.split(":")[0]))
            .minute(parseInt(time.split(":")[1]))

          // 현재 시각이 지났을 경우
          if (timeToCheck.isBefore(now)) {
            this.already_reserved_time_list.push(time)
          }
        })
      }
    },

    onReservationWithApplicableCouponCheck: _.debounce(async function () {
      if (this.available_coupon_cnt > 0 && !this.selected_coupon) {
        this.showTwoButtonDialog("dialog.confirm_applicable_coupons")
      } else {
        this.reservation()
      }
    }, 500),

    // 예약하기
    reservation: _.debounce(async function () {
      if (!this.isKRW) {
        alert("외화 결제는 준비중입니다.")
        return
      }

      if (!this.lafia_user.is_logined) {
        // 이벤트가 있다면 저장
        if (this.$route.query.event) {
          this.$store.commit("ADD_PROMOTION_CODE", this.$route.query.event)
        }
        alert(this.$t("alert.error_need_authentication"))
        let loginPath = "/auth/login"
        if (this.$route.query.event) {
          loginPath = loginPath + `?event=${this.$route.query.event}`
        }
        this.$router.push(loginPath)
        return
      }

      let orderId = Math.floor(Math.random() * 1000000000)
      let promotionCode = this.getPromotionCode

      try {
        // 결제 진행중
        this.pay_loading = true
        // 오늘 날짜일시 예약시간이 지났는지 재확인
        await this.TodayTimePassCheck()

        // 시간 지났을 경우 종료
        if (this.already_reserved_time_list.includes(this.selected_time)) {
          this.pay_loading = false // 결제 진행 중 취소
          this.$router.go(0)
          return
        }

        // 결제 전 기예약인원 재확인
        const res = await this.$http.post("/api/reservation/select/specific/time/castle/check", {
          params: {
            year: this.date.year(),
            month: this.date.month() + 1,
            day: this.date.date(),
            time: this.selected_time,
          },
        })

        // 기예약인원 포함 15명 이상일 시 종료
        let already_reserved = 0

        if (res.data.length) {
          already_reserved = Number(res.data[0].total_cloth)
        }

        if (this.cloth + already_reserved > this.getMaxAvailableRoom) {
          alert(
            "예약 도중 다른 예약으로 인해 " + this.selected_time + " 현재 최대 " + (this.getMaxAvailableRoom - already_reserved) + "명까지 예약하실 수 있으므로 인원 또는 타임을 변경해주시기 바랍니다"
          )
          this.pay_loading = false // 결제 진행 중 취소
          return
        }

        /*
        if (this.dessertCnt > 99) {
          alert("디저트 주문 최대 수량 99개를 초과하였습니다.")
          this.pay_loading = false // 결제 진행 중 취소
          return
        } */

        let defaultClothPrice = this.isWeekendOrHoliday ? this.defaultPriceInfo.clothPriceWeekend : this.defaultPriceInfo.clothPrice
        let clothOrder = {
          productName: this.isWeekendOrHoliday ? "주말드레스대여" : "드레스대여",
          orderNum: this.cloth,
          price: defaultClothPrice,
          totalPrice: this.cloth * defaultClothPrice,
          finalPrice: Math.max(this.cloth * defaultClothPrice - this.getDiscountPrice, 0),
          couponId: this.selected_coupon && this.selected_coupon.issuedId,
        }
        if (this.promotionPriceInfo) {
          let promotionClothPrice = this.isWeekendOrHoliday ? this.promotionPriceInfo.clothPriceWeekend : this.promotionPriceInfo.clothPrice
          clothOrder = {
            ...clothOrder,
            discountPrice: promotionClothPrice,
            discountRate: this.getDiscountRate(defaultClothPrice, promotionClothPrice),
            discountCode: promotionCode,
            finalPrice: Math.max(this.cloth * promotionClothPrice - this.getDiscountPrice, 0),
          }
        }

        let orderList = []
        this.cloth && orderList.push(clothOrder)

        const orderRes = await this.$http.post("/api/order/v2/order", {
          orderId: orderId.toString(),
          userId: this.lafia_user.user_id,
          orderDetailList: orderList,
          memo: promotionCode || "",
          type: "온라인예약",
        })
        console.log("api/order/v2/order complete")

        // 주문 입력하기
        // const orderRes = await this.$http.post("/api/order/insert", {
        //   params: {
        //     toss_id: orderId,
        //     user_id: this.$store.state.lafia_user.user_id,
        //     pay_amount: this.computed_total_price,
        //     pay_status: "결제미완료",
        //     status: "접수완료",
        //     memo: this.$route.query.event == "lafia_castle_open_event" ? "라피아캐슬 오픈이벤트" : null,
        //   },
        // })

        console.log(orderRes)

        let params = {
          order_id: orderRes.data.id,
          user_id: this.lafia_user.user_id,
          category: "라피아캐슬",
          maker: null,
          concept: null,
          year: this.date.year(),
          month: this.date.month() + 1,
          day: this.date.date(),
          time: this.selected_time,
          cloth: this.cloth,
          clothPrice: this.cloth * this.getClothPrice,
          uncloth: 0,
          unclothPrice: 0,
          promotionCode: promotionCode,
        }

        // 시간예약 입력하기
        await this.$http.post("/api/reservation/insert", {
          params,
        })

        // 모든 작업이 완료된 후 토스 결제
        if (window.location.host.startsWith("localhost") && window.confirm("테스트 결제 하기")) {
          this.$router.replace("/toss/success?orderId=" + orderId + "&paymentKey=test&amount=" + this.computed_total_price)
        } else if (this.computed_total_price_original > 0 && this.computed_total_price === 0) {
          this.$router.replace("/toss/success?orderId=" + orderId + "&paymentKey=free&amount=" + this.computed_total_price)
        } else {
          let orderName = "세라캐슬" + (this.cloth ? " 대여 " + this.cloth + "명" : "")
          if (this.isSupportAbroadPay && !this.isKRW) {
            const { online_url, mobile_url } = await payletterService.requestInternationalPay({
              storeorderno: orderId.toString(),
              amount: this.computed_total_price,
              currency: "KRW",
              payinfo: orderName,
              payeremail: this.lafia_user.email,
              payerid: this.lafia_user.user_id,
            })

            var popOption = "width=430, height=555, resizable=no, scrollbars=no, status=no;"
            var popGlOption = "width=800, height=600, resizable=no, scrollbars=no, status=no;"
            if (this.$vuetify.breakpoint.mobile) {
              this.payletterWindow = window.open(mobile_url, "", popOption)
            } else {
              this.payletterWindow = window.open(online_url, "", popGlOption)
            }

            if (!this.payletterWindow || this.payletterWindow.closed || typeof this.payletterWindow.closed == "undefined") {
              alert(this.$t("alert.error_popup_blocked"))
              return
            }

            if (this.payletterWindow) {
              this.payletterWindowEventListener = (event) => {
                if (event.data.message === "pay-completed") {
                  const payloadData = event.data.data
                  this.payletterEventUnregister()
                  const { retCode, orderNo, status } = payloadData
                  if (retCode == "0") {
                    // 성공
                    this.$router.replace("/payletter/success?orderId=" + orderId + "&amount=" + this.computed_total_price)
                  } else {
                    // 실패
                    this.$router.push("/fail?orderId=" + orderId + "&message=" + status)
                  }
                }
              }
              window.addEventListener("message", this.payletterWindowEventListener)
            }
          } else {
            var tossPayments = TossPayments(process.env.VUE_APP_TOSS_PAYMENT_CLIENT_KEY)
            tossPayments
              .requestPayment("카드", {
                amount: this.computed_total_price,
                orderId: orderId,
                orderName: orderName,
                customerName: this.lafia_user.name,
                customerEmail: this.lafia_user.email,
                successUrl: window.location.origin + "/toss/success",
                failUrl: window.location.origin + "/fail",
                useEscrow: false,
              })
              .catch((error) => {
                console.error("토스페이먼츠 결제", error)
                this.cancelPayStatusUpdate({ order_id: orderRes.data.id, reason: "사용자취소" })
                this.pay_loading = false // 결제 진행 중 취소
              })
          }
        }
      } catch (e) {
        console.log(e)
        alert(this.$t("alert.error_page"))
      } finally {
        this.pay_loading = false // 결제 진행 중 취소
      }
    }, 500),

    getDiscountRate(original, final) {
      return ((original - final) / original) * 100
    },

    getTitle(promotionPriceInfo) {
      let name = promotionPriceInfo && promotionPriceInfo.name
      return name ? `${name} 님의` : ""
    },

    checkPromotionDate(isSkip = false) {
      // 프로모션 기간인지 아닌지 체크가 필요함.
      if (this.matchedPromotionInfo) {
        const startAt = this.dayjs(this.matchedPromotionInfo.startAt)
        const endAt = this.dayjs(this.matchedPromotionInfo.endAt)
        if (this.date.isBetween(startAt, endAt, "day", "[]")) {
          this.promotionPriceInfo = this.matchedPromotionInfo
        } else {
          this.promotionPriceInfo = null

          if (!isSkip) {
            // 해당 프로 모션의 기간이 아닙니다.
            if (this.dayjs().isAfter(endAt, "day")) {
              this.showWarningDialog("dialog.promotion-warning-end-guide")
            } else {
              this.showWarningDialog(this.$t("dialog.promotion-warning-date-guide", { startAt: startAt.format("YYYY/MM/DD"), endAt: endAt.format("YYYY/MM/DD") }))
            }
          } else {
            if (this.dayjs().isBefore(startAt)) {
              this.date = startAt.startOf("day")
            } else {
              this.date = this.dayjs().startOf("day")
            }
            this.dateSelectComponentKey++
          }
        }
      }
    },

    getDispPriceWithConfig(krw, usd) {
      const price = this.$toComma(this.isKRW ? krw : usd)
      return this.isKRW ? `${price}원` : `${price} USD`
    },

    showCouponSelectDialog(showNoCoupon = true) {
      this.showNoCoupon = showNoCoupon
      this.show_coupon_select_componentKey++
      this.show_coupon_select = true
    },

    applyCoupon(coupon) {
      console.log("applyCoupon", coupon)
      this.selected_coupon = null

      if (!coupon) {
        return
      }
      const { discount_KRW, discount_USD, startAt, expireAt, type, isExpired } = coupon
      if (isExpired) {
        return
      } else if (!this.dayjs().isBetween(this.dayjs(startAt), this.dayjs(expireAt), "day", "[]")) {
        return
      } else if (type !== "드레스금액할인") {
        return
      }
      this.selected_coupon = coupon
    },

    showWarningDialog(message) {
      this.warning_dialog_messsage = message
      this.show_warning_dialog = true
      this.show_warning_dialog_componentKey++
    },

    confirmDialog() {
      this.show_warning_dialog = false
      if (this.warning_dialog_messsage === "dialog.promotion-warning-end-guide") {
        this.$router.replace("/reservation/buy")
      } else {
        if (this.matchedPromotionInfo) {
          const startAt = this.dayjs(this.matchedPromotionInfo.startAt).startOf("day")
          if (this.dayjs().isBefore(startAt)) {
            this.date = startAt.startOf("day")
          } else {
            this.date = this.dayjs().startOf("day")
          }
          this.dateSelectComponentKey++
        }
      }
      this.warning_dialog_messsage = ""
      this.show_warning_dialog_componentKey++
    },

    warningDialogInputEvent(e) {
      !e && this.confirmDialog()
    },

    showTwoButtonDialog(message) {
      this.twobutton_dialog_messsage = message
      this.show_twobutton_dialog = true
      this.show_twobutton_dialog_componentKey++
    },

    closeTwoButtonDialog() {
      this.show_twobutton_dialog = false
      this.twobutton_dialog_messsage = ""
      this.show_twobutton_dialog_componentKey++
    },

    positiveDialogEvent() {
      this.closeTwoButtonDialog()
      this.reservation()
    },

    negativeDialogEvent() {
      this.closeTwoButtonDialog()
      this.showCouponSelectDialog(false)
    },

    twoButtonDialogInputEvent(e) {
      if (!e) {
        this.closeTwoButtonDialog()
      }
    },
    payletterEventUnregister() {
      console.log("payletterEventUnregister")
      if (this.payletterWindowEventListener) {
        window.removeEventListener("message", this.payletterWindowEventListener)
      }
      this.payletterWindowEventListener = null
      this.payletterWindow = null
    },
  },
  beforeDestroy() {
    this.payletterEventUnregister()
  },
}
</script>
<style scoped>
.timeSelectBtn {
  border: 1px solid lightgray;
  color: #515151;
  font-size: 16px;
  font-weight: normal;
  margin: 0 3px;
  background-color: white !important;
}

.enable {
  background: #8d75cb !important;
  color: white !important;
}
</style>
