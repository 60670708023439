<template>
  <!-- 세라캐슬 가격안내표 -->
  <div class="px-3" style="position: relative; background-color: white">
    <v-sheet v-if="loading" class="text-center py-10">
      <v-progress-circular indeterminate color="primary" size="48"></v-progress-circular>
    </v-sheet>
    <table v-else class="priceGuide" v-show="!options.content">
      <tr>
        <th>{{ $t("page.reservation.price-table-th-1") }}</th>
        <th>{{ $t("page.reservation.price-table-th-2") }}</th>
        <th>{{ $t("page.reservation.price-table-th-3") }}</th>
        <th>{{ $t("page.reservation.price-table-th-4") }}</th>
        <th>{{ $t("page.reservation.price-table-th-5") }}</th>
      </tr>
      <tr>
        <td rowspan="2" class="text-body-2" style="background-color: #e9e4ff">{{ $t("page.reservation.price-table-td-1") }}</td>
        <td rowspan="2">
          <span v-html="$t('page.reservation.price-table-td-2')"></span>
          <span class="primary--text font-weight-medium">{{ $t("page.reservation.price-table-td-3") }}</span>
        </td>
        <td rowspan="2">{{ $t("page.reservation.price-table-td-4") }}</td>
        <td>{{ $t("page.reservation.price-table-td-5-1") }}</td>
        <td>
          <span v-if="!inOnlyShowPromotionPrice" :class="{ 'text-decoration-line-through': isShowPromotionclothPrice, 'warning--text': isShowPromotionclothPrice }">{{
            getDispPriceWithConfig(main.clothPrice, main.clothPriceUSD)
          }}</span>
          <br v-if="!inOnlyShowPromotionPrice" />
          <span v-if="isShowPromotionclothPrice">{{ getDispPriceWithConfig(promotion.clothPrice, promotion.clothPriceUSD) }}</span>
        </td>
      </tr>
      <tr>
        <td>{{ $t("page.reservation.price-table-td-5-2") }}</td>
        <td>
          <span v-if="!inOnlyShowPromotionPrice" :class="{ 'text-decoration-line-through': isShowPromotionclothPriceWeekend, 'warning--text': isShowPromotionclothPriceWeekend }">{{
            getDispPriceWithConfig(main.clothPriceWeekend, main.clothPriceWeekendUSD)
          }}</span>
          <br v-if="!inOnlyShowPromotionPrice" />
          <span v-if="isShowPromotionclothPriceWeekend">{{ getDispPriceWithConfig(promotion.clothPriceWeekend, promotion.clothPriceWeekendUSD) }}</span>
        </td>
      </tr>
    </table>

    <div class="mt-2">
      <TipTapReader class="tiptap_style" :options="options" v-if="options.content" :key="componentKey" style="min-height: 20px" />
    </div>
  </div>
</template>
<script>
import TipTapReader from "@/components/tiptap/Reader"
import { mapGetters } from "vuex"

export default {
  components: {
    TipTapReader,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    main: Object,
    promotion: Object,
  },

  data: () => ({
    //TipTap
    options: {
      content: "",
      editable: false,
      supportImage: true,
      supportVideo: true,
    },
    componentKey: 0,
  }),

  computed: {
    ...mapGetters(["isKRW"]),

    inOnlyShowPromotionPrice() {
      return this.promotion && this.promotion.clothPrice > this.main.clothPrice
    },

    isShowPromotionclothPrice() {
      return this.promotion && this.promotion.clothPrice !== this.main.clothPrice
    },

    isShowPromotionclothPriceWeekend() {
      return this.promotion && this.promotion.clothPriceWeekend !== this.main.clothPriceWeekend
    },

    isShowPromotionunclothPrice() {
      return this.promotion && this.promotion.unclothPrice !== this.main.unclothPrice
    },

    isShowPromotionunclothPriceWeekend() {
      return this.promotion && this.promotion.unclothPriceWeekend !== this.main.unclothPriceWeekend
    },
  },

  mounted() {},
  methods: {
    getDispPriceWithConfig(krw, usd) {
      const price = this.$toComma(this.isKRW ? krw : usd)
      return this.isKRW ? `${price}원` : `${price} USD`
    },
  },

  watch: {
    promotion() {
      if (this.promotion) {
        this.options.content = this.promotion.content
        this.componentKey++
      } else {
        this.options.content = ""
        this.componentKey++
      }
    },
  },
}
</script>
<style scoped>
.priceGuide {
  width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;
  text-align: center;
  font-size: 12px;
}

.priceGuide tr th {
  padding: 4px 6px;
  border: 1px solid #ccc;
  background-color: #f7f2f7;
}

.priceGuide tr td {
  padding: 4px 6px;
  border: 1px solid #ccc;
}

.reservation {
  background: #ffe1e1;
  color: white;
}

.done {
  background: #aaaaaa;
  color: #777777;
  font-weight: 300;
}
</style>
