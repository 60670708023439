<template>
  <v-sheet class="d-flex px-4 my-4" style="gap: 4px">
    <v-btn x-small depressed dense :color="lang === item.symbol ? '#d9bddd' : 'white grey--text'" class="text-caption pa-0" v-for="item in supportLang" :key="item.title" @click="setLang(item.symbol)">
      {{ item.title }}</v-btn
    >
  </v-sheet>
</template>
<script>
import { mapState, mapGetters, mapMutations } from "vuex"
export default {
  data: () => ({
    supportLang: [
      {
        title: "KOR",
        symbol: "ko",
      },
      {
        title: "ENG",
        symbol: "en",
      },
      {
        title: "JPN",
        symbol: "jp",
      },
    ],
  }),
  computed: {
    ...mapState(["lang"]),
  },

  methods: {
    ...mapMutations(["SET_LANG"]),
    setLang(item) {
      this.SET_LANG(item)
    },
  },
}
</script>
<style scoped>
::v-deep .v-btn {
  border: 1px solid #ccc !important;
  font-size: 10px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
}
</style>
