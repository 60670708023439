<template>
  <div>
    <!-- 제목 -->
    <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">알림 내역</font>
    </v-sheet>

    <!-- 본문 -->
    <v-sheet outlined class="pa-2 ma-2">
      <!-- 검색 -->
      <v-sheet outlined class="pa-2 mb-2 d-flex">
        <v-sheet class="d-flex align-center">
          <!-- 유형 -->
          <v-sheet width="160" class="mr-3">
            <v-select outlined dense hide-details :items="select_list.search_type" v-model="search_type"></v-select>
          </v-sheet>

          <!-- 키워드 -->
          <v-text-field placeholder="검색어를 입력해주세요" outlined dense hide-details v-model.trim="keyword" @keyup.enter="search()"></v-text-field>

          <!-- 제출 버튼 -->
          <v-btn height="40" class="px-6 ml-3" color="primary" dark depressed @click="search()">
            <v-icon small left>mdi-magnify</v-icon>
            검색하기
          </v-btn>

          <v-radio-group v-model="level" mandatory row class="ml-4">
            <v-radio v-for="item in level_infos" :label="item" :value="item" :key="item"></v-radio>
          </v-radio-group>
        </v-sheet>

        <v-spacer></v-spacer>

        <!-- 새로고침 버튼 -->
        <v-btn height="40" class="px-3 ml-2 my-auto" color="primary" dark depressed @click="refresh()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-sheet>

      <!-- 목록 -->

      <v-data-table
        :items="list"
        hide-default-header
        no-data-text="데이터가 존재하지 않습니다."
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50],
          'show-current-page': true,
          'show-first-last-page': true,
        }"
      >
        <template v-slot:header>
          <tr>
            <th style="width: 50px">From</th>
            <th style="width: 50px">레벨</th>
            <th style="width: 100px">내용</th>
            <th style="width: 50px">일자</th>
          </tr>
        </template>

        <template v-slot:item="{ item }">
          <tr>
            <td class="text-truncate">
              {{ item.from }}
            </td>
            <td class="text-truncate">
              {{ item.level }}
            </td>
            <td style="word-wrap: break-word; line-break: auto; word-break: break-all">
              {{ item.notification }}
            </td>
            <td class="text-truncate">
              {{ new Date(item.created).toLocaleString() }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-sheet>
  </div>
</template>
<script>
export default {
  data: () => ({
    select_list: {
      search_type: ["내용", "From"],
    },
    search_type: "내용",
    keyword: "",
    level: "DEBUG",
    level_infos: ["FATAL", "ERROR", "WARN", "INFO", "DEBUG"],

    loading: false,
    list: [],
  }),
  mounted() {
    this.load()
  },

  watch: {
    level() {
      this.load()
    },
  },

  methods: {
    refresh() {
      this.load()
    },

    search() {
      this.load()
    },

    async load() {
      this.loading = true

      try {
        const result = await this.$http.get("/api/notification", {
          params: {
            level: this.level,
            keyword: this.keyword,
            type: this.search_type,
          },
        })
        this.list = result.data
        //this.totalCount = result.data.totalCount
        console.log(this.list)
      } catch (e) {
        alert("목록을 가져오지 못했습니다.")
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style></style>
