<template>
  <v-card>
    <v-card-title> 드레스 및 악세사리 반납 </v-card-title>
    <div class="text-center font-weight-medium text-h6" style="position: relative">
      <span class="text-h4 font-weight-bold">{{ curr_time }}</span>
      <!-- <v-btn style="position: absolute; right: 40px" class="ml-4" depressed color="teal" outlined @click="setCurrentTime">현재</v-btn> -->
    </div>
    <v-container fluid class="mt-4 px-6">
      <v-row dense align="center" class="ma-0">
        <v-col cols="2" class="text-start text-body-1">시작시간</v-col>
        <v-col cols="2" class="ml-2 text-h6 font-weight-bold">{{ info.start_time || "-" }}</v-col>
        <v-col cols="3" class="text-start text-body-1">추가 이용시간</v-col>
        <v-col cols="2" class="ml-2 text-h6 font-weight-bold">{{ extra_use_minutes || "-" }}분</v-col>
      </v-row>
      <v-row dense align="center" class="ma-0">
        <v-col cols="2" class="text-start">대여물품</v-col>
        <v-col class="ml-2 text-body-1 font-weight-bold">{{ info.accessories || "-" }}</v-col>
      </v-row>
    </v-container>
    <v-card-text>
      <!-- 시간 -->
      <v-subheader>추가 요금 입력(시간)</v-subheader>
      <v-chip-group v-model="selectedHour" mandatory active-class="deep-purple--text text--accent-4">
        <v-chip v-for="(time, index) in getHourList" :key="time" :value="index" class="d-flex justify-center py-5" small>{{ time }} 시간</v-chip>
      </v-chip-group>

      <v-subheader>(분)</v-subheader>
      <div class="d-flex mt-2">
        <v-btn color="accent" class="mx-1" v-for="(item, index) in quick_minutes" depressed outlined small @click="setQuickMinutes(index)" :key="item" :class="{ active: index == selectedMinuteIdx }"
          >{{ item }}분</v-btn
        >
      </div>
      <!-- 분 -->
    </v-card-text>
    <v-container fluid class="my-0 px-6 py-0">
      <v-row dense class="ma-0" align="center">
        <v-col cols="5" class="text-end">추가 요금</v-col>
        <v-col class="ml-10">
          <span class="font-danjunghae" style="font-size: 26px">{{ $toComma(extra_use_price) || "0" }}</span>
          <span class="ml-2">원</span>
        </v-col>
      </v-row>
    </v-container>

    <v-card-actions class="my-4">
      <v-spacer></v-spacer>
      <v-btn class="px-10" depressed @click="$emit('cancel')">취소</v-btn>
      <v-btn color="primary" class="px-6" depressed @click="submit">결제확인 및 저장</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>
<script>
import { Holidays } from "@/constant"

export default {
  props: {
    info: {
      type: Object,
      default: {
        start_time: "",
        accessories: "",
      },
    },
  },
  data: () => ({
    curr_date: null,
    selectedHour: 0,
    selectedMinuteIdx: 0,
    defaultPriceInfo: null,

    selctedOptions: ["드레스"],
    quick_minutes: [0, 10, 20, 30, 40, 50],

    extra_use_minutes: 0,

    minutesTimer: null,
  }),
  mounted() {
    this.loadPriceInfo()
    this.setCurrentTime()
    this.calculatePrice()

    this.minutesTimer = setInterval(() => {
      this.setCurrentTime()
    }, 30000)
  },
  destroyed() {
    if (this.minutesTimer) {
      clearInterval(this.minutesTimer)
      this.minutesTimer = null
    }
  },

  watch: {
    selectedHour() {
      this.calculatePrice()
    },
    selectedMinuteIdx() {
      this.calculatePrice()
    },
  },

  computed: {
    curr_time: function () {
      return this.curr_date ? this.curr_date.format("HH시 mm분") : "-"
    },
    extra_use_price: function () {
      return this.getExtrPriceUnit() * (this.selectedHour * 6 + this.selectedMinuteIdx)
    },

    getHourList() {
      return [0, 1, 2, 3, 4]
    },
    getMinuteList() {
      return [...Array(60).keys()]
    },
  },

  methods: {
    submit: _.debounce(async function () {
      const productName = "시간초과 10분" + (this.isWeekendOrHoliday() ? "(주말/공휴일)" : "(평일)")
      this.$emit("save", { hour: this.selectedHour, minute: this.selectedMinute, extra_use_minutes: this.extra_use_minutes, extra_use_price: this.extra_use_price, productName })
    }, 500),

    setQuickMinutes(quick) {
      this.selectedMinuteIdx = quick
    },

    setCurrentTime() {
      this.curr_date = this.dayjs()
    },

    calculatePrice() {
      const startTimes = this.info.start_time.split(":")
      let hourDiff = Math.max(Number(this.curr_date.hour()) - Number(startTimes[0]), 0)
      let minuteDiff = Number(this.curr_date.minute()) - Number(startTimes[1])

      if (minuteDiff < 0) {
        minuteDiff = minuteDiff + 60
        hourDiff--
      }
      console.log("calculatePrice", this.info.start_time, hourDiff, this.selectedHour)
      this.extra_use_minutes = Math.max(hourDiff * 60 + minuteDiff - 90, 0) // 기본 이용시간 60분 -> 90분으로
      console.log("extra_use_minutes", this.extra_use_minutes)
    },

    getExtrPriceUnit() {
      // return this.isWeekendOrHoliday() ? 4000 : 3500
      return (this.defaultPriceInfo && this.defaultPriceInfo.tenOverTimePrice) || 3900
    },

    isWeekendOrHoliday() {
      const date = this.dayjs()
      return date.day() === 6 || date.day() === 0 || Holidays.includes(date.format("YYYY-MM-DD"))
    },

    async loadPriceInfo() {
      try {
        const result = await this.$http.get("/api/promotion/select")
        this.defaultPriceInfo = result.data.find((e) => e.code == "default-package")

        if (!this.defaultPriceInfo) {
          this.$router.go(-1)
          alert("페이지에 오류가 있습니다.")
          //가격 테이블이 없는 경우
        }
      } catch (e) {
        //if (e.status === 404) {
        this.$router.go(-1)
        alert("페이지에 오류가 있습니다.")
        //가격 테이블이 없는 경우
        //}
      }
    },
  },
}
</script>
<style scoped>
.scrollable {
  height: 272px;
  max-height: 272px;
  overflow-y: auto;
}
.active {
  background-color: #66546d;
  color: white !important;
}
</style>
