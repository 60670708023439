<template>
  <div>
    <!-- 제목 -->
    <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">드레스 운영 관리</font>
    </v-sheet>

    <!-- 본문 -->
    <v-sheet outlined class="pa-2 ma-2">
      <!-- 날짜 선택 -->
      <v-sheet class="d-flex justify-space-between">
        <DateSelector :color="$vuetify.theme.currentTheme.primary" :width="330" class="mt-1 mb-4" @dateUpdated="dateUpdated_handler" />

        <!-- 새로고침 버튼 -->
        <v-btn height="40" class="px-3 ml-2 my-auto" color="primary" dark depressed @click="refresh()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-sheet>

      <!-- 시간 탭 -->
      <v-chip-group v-model="selected_time" mandatory active-class="deep-purple--text text--accent-4">
        <v-chip v-for="(time, index) in time_list" :key="time" :value="time" style="min-width: 100px" class="d-flex justify-center py-5">{{ time }}({{ time_list_persons[index] }}명)</v-chip>
      </v-chip-group>

      <!-- 테이블 -->
      <v-data-table :items="getFilteredList" hide-default-header hide-default-footer disable-pagination>
        <template v-slot:header>
          <tr>
            <th style="width: 50px; height: 57px">고객타입</th>
            <th style="width: 100px">아이디</th>
            <th style="width: 50px">이름</th>
            <th style="width: 50px">인원 수</th>
            <th style="width: 100px">옵션</th>
            <th style="width: 50px">시작시간</th>
            <th style="width: 80px">종료시간</th>
            <th style="width: 50px" class="text-center">추가요금</th>
            <th style="width: 50px">의상교체</th>
          </tr>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td v-if="item.member_number === 1">
              {{ item.toss_id > 0 ? "예약고객" : "현장고객" }}
            </td>
            <td v-if="item.member_number === 1">
              {{ item.user_id }}
            </td>
            <td v-if="item.member_number === 1">
              {{ item.name }}
            </td>
            <td :colspan="item.member_number === 1 ? 1 : 4" class="text-end" :class="{ greyBg: item.member_number != 1 }">인원 {{ item.member_number }}</td>
            <td>{{ getAccessoriesInfo(item) || "-" }}</td>
            <td>
              <v-btn v-if="!getStartTime(item)" depressed color="primary" outlined @click="clickStartTime(item)">시작</v-btn>
              <span v-else>{{ getStartTime(item) }}</span>
            </td>
            <td>
              <span v-if="!getStartTime(item)">-</span>
              <v-btn v-else-if="!getEndTime(item)" depressed color="primary" outlined @click="clickEndTime(item)">종료</v-btn>
              <span v-else>{{ getEndTime(item) }}</span>
            </td>
            <td class="text-center">{{ getExtraUsePriceStr(item) }}</td>
            <td>
              <span v-if="!getStartTime(item) || getEndTime(item)"></span>
              <v-btn v-else-if="!getChangeDressTime(item)" depressed small outlined color="success" @click.stop="clickChangeDress(item)">의상교체</v-btn>
              <span v-else>{{ getChangeDressTime(item) }}</span>
            </td>
          </tr>
        </template>
      </v-data-table>

      <!-- 버튼 -->
      <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
        <v-btn class="px-6 ml-3" color="blue" large dark depressed @click="createNewOnSite()">
          <v-icon small left>mdi-database-plus-outline</v-icon>
          현장 추가
        </v-btn>
      </v-card>
    </v-sheet>
    <v-dialog v-model="showLoginCheck" width="600" min-height="400" content-class="rounded-xl d-flex justify-center" :key="'loginCheck' + componentKey" persistent>
      <LoginCheck v-if="!guest_login_info" @create="createNewOnSite" @cancel="cancelInputNewOnSite" :qrcode="getUrlWithQrcode"></LoginCheck>
      <!-- 결제 확인 창-->
      <InsertEnterCastleData v-else :info="guest_login_info" @save="(payload) => save(payload)" :saving="saving" :date="date" @cancel="cancelInputNewOnSite" />
    </v-dialog>
    <v-dialog v-model="showStartTime" width="600" min-height="400" content-class="rounded-xl d-flex justify-center" :key="'startTime' + startTimeComponentKey" @input="cancelClickStartTime">
      <!-- <InputStartTime @cancel="cancelClickStartTime" @save="inputStartTime" /> -->
      <InputStartTime @cancel="cancelClickStartTime" @save="inputStartTime" />
    </v-dialog>

    <v-dialog v-model="showEndTime" width="600" min-height="400" content-class="rounded-xl d-flex justify-center" :key="'endTime' + endTimeComponentKey" @input="cancelClickEndTime">
      <!-- <InputEndTime @cancel="cancelClickEndTime" @save="inputEndTime" /> -->
      <InputEndTime :info="selectedVisitMember" @cancel="cancelClickEndTime" @save="inputEndTime" />
    </v-dialog>

    <v-dialog v-model="showDressChangePopup" max-width="440" content-class="rounded-xl d-flex justify-center" :key="'dressChangePopup' + showDressChangeComponentKey" @input="cancelChangeDress">
      <v-card>
        <v-card-title> 의상 교체 </v-card-title>
        <v-card-text class="my-6 text-center">
          <span>이용금액 : </span>
          <span class="font-weight-medium text-h6 mx-1">{{ $toComma(default_price.changeDressPrice) }}</span>
          <span>원</span>
        </v-card-text>
        <v-card-actions class="my-4">
          <v-spacer></v-spacer>
          <v-btn class="px-12" depressed outlined @click="cancelChangeDress">취소</v-btn>
          <v-btn color="primary" class="px-3" depressed @click="submitExtraDress" :loading="payingExtraDress">결제확인 및 저장</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DateSelector from "@/components/DateSelector"
import LoginCheck from "./components/logincheck"
import InsertEnterCastleData from "./components/InsertEnterCastleData"
import InputStartTime from "./components/InputStartTime"
import InputStartTimeV2 from "./components/InputStartTimeV2"
import InputEndTime from "./components/InputEndTime"
import InputEndTimeV2 from "./components/InputEndTimeV2"
import { RESERV_CAN_TIME_LIST, Holidays } from "@/constant"
import { sleep } from "@/util/utils"
import authService from "@/api/auth-service"
import { mapActions, mapState } from "vuex"

export default {
  components: {
    DateSelector,
    LoginCheck,
    InsertEnterCastleData,
    InputStartTime,
    InputStartTimeV2,
    InputEndTime,
    InputEndTimeV2,
  },
  data: () => ({
    selected_time: "12:00",
    time_list: RESERV_CAN_TIME_LIST,
    time_list_persons: Array(RESERV_CAN_TIME_LIST.length).fill(0),

    date: null,

    guest_login_info: null,
    list: [],
    visit_time_list: [],

    saving: false,

    componentKey: 0,
    qrCode: null,

    showLoginCheck: false,

    isLoginCheckTimeout: false,
    loginCheckTimer: null,

    selectedMember: null,
    showStartTime: false,
    startTimeComponentKey: 0,

    selectedVisitMember: null,
    showEndTime: false,
    endTimeComponentKey: 0,

    showDressChangeComponentKey: 0,
    payingExtraDress: false,
    showDressChangePopup: false,
  }),

  watch: {
    date(newValue, oldValue) {
      console.log("date changed")
      // 라피아캐슬 오프라인 예약 불러오기
      if (!newValue.isSame(oldValue, "date")) {
        this.load()
        this.selected_time = "12:00"
        this.time_list_persons = [0, 0, 0, 0, 0, 0, 0, 0]
        this.load_visit_member()
      }
    },

    selected_time(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        // 해당 시간의 데이터를 가져오기
        this.load_visit_member()
      }
    },
  },

  computed: {
    ...mapState(["default_price"]),

    getUrlWithQrcode() {
      return `${window.location.origin}/auth/login?visitQrcode=${this.qrCode}`
    },

    getFilteredList() {
      const resultList = []
      const filteredList = this.list.filter((e) => e.time === this.selected_time)

      filteredList.forEach((element) => {
        resultList.push({ ...element, isChild: false, member_number: 1 })

        let count = Math.max(element.cloth - 1, 0)
        for (var i = 0; i < count; i++) {
          resultList.push({ ...element, isChild: true, member_number: i + 2 })
        }
      })

      return resultList
    },
  },

  methods: {
    ...mapActions(["getPromotionPrice"]),
    load_visit_member() {
      // 해당 시간의 데이터를 가져오기
      this.$http
        .get("/api/reservation/visit/list", {
          params: {
            year: String(this.date.year()),
            month: String(this.date.month() + 1),
            day: String(this.date.date()),
            time: this.selected_time,
          },
        })
        .then((res) => {
          //console.log(res.data)
          this.visit_time_list = res.data
        })
    },
    load() {
      console.log("load")
      this.$http
        .post("/api/reservation/select/specific/day/category/all", {
          params: {
            category: "라피아캐슬",
            year: this.date.year(),
            month: this.date.month() + 1,
            day: this.date.date(),
          },
        })
        .then((res) => {
          console.log(this.date.format("YYYY년 MM월 DD일"))
          console.log("예약 목록:", res.data)
          this.list = res.data
          this.time_list_persons = [0, 0, 0, 0, 0, 0, 0, 0]
          this.list.forEach((e) => {
            const index = this.time_list.findIndex((t) => t === e.time)
            this.time_list_persons[index] += e.cloth
          })
        })
    },
    async refresh() {
      this.load()
      this.load_visit_member()
    },
    // DateSelector에서 date 가져오기
    dateUpdated_handler(date) {
      this.date = date
    },

    initLoginCheckTimeout() {
      if (this.loginCheckTimer) {
        clearTimeout(this.loginCheckTimer)
        this.loginCheckTimer = null
      }
      this.isLoginCheckTimeout = false
    },

    startTimeOutCheck() {
      this.initLoginCheckTimeout()
      this.loginCheckTimer = setTimeout(() => {
        this.isLoginCheckTimeout = true
      }, 300000)
    },

    async watingLoginCheck() {
      try {
        for (var i = 0; i < 100; i++) {
          const res = await this.offlineLoginCheck(this.qrCode)
          console.log("offlineLoginCheck result: ", res)
          if (res) {
            const user = await authService.getUser(res)
            console.log("authService.getUser result: ", user)
            if (user) {
              this.guest_login_info = { user_id: user.user_id, name: user.name }
              return
            } else {
              this.initLoginCheckTimeout()
              alert("등록된 사용자 정보가 없습니다. 확인 후 다시 시도해 주세요.")
              return
            }
          }
          if (!this.showLoginCheck) {
            //사용자 취소
            return
          } else if (this.isLoginCheckTimeout) {
            alert("유효 시간이 지났습니다. 다시 시도해 주세요.")
            return
          }

          await sleep(3000)

          if (!this.showLoginCheck) {
            //사용자 취소
            return
          } else if (this.isLoginCheckTimeout) {
            alert("5분이 경과되었습니다. 다시 시도해 주세요.")
            return
          }
        }
      } catch (e) {
        console.log(e)
        this.initLoginCheckTimeout()
        alert("로그인 체크에 오류가 발생하였습니다.")
      }
    },

    async offlineLoginCheck(qrCode) {
      try {
        const res = await this.$http.get("/api/qrcode/pay/check", {
          params: {
            qrcode: qrCode,
          },
        })
        //console.log(res)
        if (res && res.data && res.data.user_id) {
          return res.data.user_id
        } else {
          return false
        }
      } catch (e) {
        console.log(e)
        return false
      }
    },

    cancelInputNewOnSite() {
      console.log("cancelInputNewOnSite")
      this.qrCode = null
      this.showLoginCheck = false
      this.componentKey++
      this.guest_login_info = null
      this.initLoginCheckTimeout()
    },
    async createNewOnSite() {
      // qrcode 생성 요청 수신 받으면 qrcode 팝업
      // this.qrcode =
      console.log("createNewOnSite")
      try {
        const res = await this.createNewQrcode()
        //console.log(window.location.origin)
        if (res) {
          this.initLoginCheckTimeout()

          this.componentKey++
          this.qrCode = res
          this.showLoginCheck = true

          await sleep(5000)
          if (this.showLoginCheck) {
            this.watingLoginCheck()
          }
        }
      } catch (e) {
        console.log(e)
      }
    },

    async createNewQrcode() {
      const response = await this.$http.get("/api/qrcode/pay/generate", {
        params: { type: "현장예약" },
      })
      return response.data.qrcode
    },

    isWeekendOrHoliday() {
      const date = this.dayjs()
      return date.day() === 6 || date.day() === 0 || Holidays.includes(date.format("YYYY-MM-DD"))
    },

    save: _.debounce(async function (payload) {
      console.log("save", payload)
      console.log(this.guest_login_info)
      this.saving = true
      try {
        let orderId = Math.floor(Math.random() * 1000000000)
        const orderRes = await this.$http.post("/api/order/v2/order", {
          orderId: orderId.toString(),
          userId: this.guest_login_info.user_id,
          orderDetailList: [
            {
              productName: this.isWeekendOrHoliday() ? "주말드레스대여" : "드레스대여",
              orderNum: payload.count,
              price: payload.price,
              totalPrice: payload.price,
              finalPrice: payload.price,
            },
          ],
          memo: "",
          type: "현장예약",
          isSite: true,
        })

        let params = {
          order_id: orderRes.data.id,
          user_id: this.guest_login_info.user_id,
          category: "라피아캐슬",
          maker: null,
          concept: null,
          year: this.date.year(),
          month: this.date.month() + 1,
          day: this.date.date(),
          time: payload.time,
          cloth: payload.count,
          uncloth: 0,
          clothPrice: payload.price,
          unclothPrice: 0,
          memo: payload.memo,
        }
        // 시간예약 입력하기
        const result = await this.$http.post("/api/reservation/insert", {
          params,
        })
        if (result) {
          //정상등록완료
          this.load()
        }
      } catch (e) {
        console.log(e)
        alert("현장 등록에 실패하였습니다.")
      } finally {
        this.saving = false
        this.guest_login_info = null
        // 팝업 close 및 기타 timer clear
        this.cancelInputNewOnSite()
      }
    }, 500),
    clickStartTime(item) {
      this.selectedMember = item
      this.startTimeComponentKey++
      this.showStartTime = true
    },
    cancelClickStartTime() {
      this.selectedMember = null
      this.startTimeComponentKey++
      this.showStartTime = false
    },
    async inputStartTime(payload) {
      try {
        await this.$http.post("/api/reservation/visit/addVisitor", {
          reservation_id: this.selectedMember.id,
          member_number: this.selectedMember.member_number,
          start_time: `${payload.hour}:${String(payload.minute).padStart(2, "0")}`,
          accessories: payload.accessories.join(","),
        })
        this.startTimeComponentKey++
        this.showStartTime = false
        this.load_visit_member()
      } catch (e) {
        alert(this.$t("alert.error_page"))
      }
    },

    clickEndTime(item) {
      this.selectedMember = item
      this.selectedVisitMember = this.getVisitItem(this.selectedMember)
      this.endTimeComponentKey++
      this.showEndTime = true
    },
    cancelClickEndTime() {
      this.selectedMember = null
      this.selectedVisitMember = null
      this.endTimeComponentKey++
      this.showEndTime = false
    },
    async inputEndTime(payload) {
      try {
        if (payload.extra_use_price > 0) {
          let orderId = Math.floor(Math.random() * 1000000000)
          const orderRes = await this.$http.post("/api/order/v2/order", {
            orderId: orderId.toString(),
            userId: this.selectedMember.user_id,
            orderDetailList: [
              {
                productName: payload.productName,
                orderNum: 1,
                price: payload.extra_use_price,
                totalPrice: payload.extra_use_price,
                finalPrice: payload.extra_use_price,
              },
            ],
            memo: "",
            type: "현장대여연장",
            isSite: true,
          })
        }

        await this.$http.post("/api/reservation/visit/updateEndTime", {
          reservation_id: this.selectedMember.id,
          member_number: this.selectedMember.member_number,
          end_time: this.dayjs().format("HH:mm"),
          extra_use_minutes: payload.extra_use_minutes,
          extra_use_price: payload.extra_use_price,
        })
        this.endTimeComponentKey++
        this.showEndTime = false
        this.load_visit_member()
      } catch (e) {
        console.log(e)
        alert(this.$t("alert.error_page"))
      }
    },

    clickChangeDress(item) {
      this.selectedMember = item
      this.selectedVisitMember = this.getVisitItem(this.selectedMember)
      this.showDressChangeComponentKey++
      this.showDressChangePopup = true
    },
    cancelChangeDress() {
      this.selectedMember = null
      this.selectedVisitMember = null
      this.showDressChangeComponentKey++
      this.showDressChangePopup = false
    },

    getVisitItem(item) {
      //console.log("getStartTime", item)
      const visit_item = this.visit_time_list.find((e) => e.member_number === item.member_number && e.reservation_id === item.id)
      return visit_item
    },

    getStartTime(item) {
      const visit_item = this.getVisitItem(item)
      //console.log(visit_item)
      return visit_item && visit_item.start_time
    },
    getEndTime(item) {
      const visit_item = this.getVisitItem(item)
      return visit_item && visit_item.end_time
    },
    getExtraUseMinutes(item) {
      const visit_item = this.getVisitItem(item)
      return visit_item && visit_item.extra_use_minutes
    },
    getExtraUseMinutesStr(item) {
      const visit_item = this.getVisitItem(item)
      let extra_use_minutes = visit_item && visit_item.extra_use_minutes
      if (extra_use_minutes === null || extra_use_minutes === undefined) {
        return ""
      } else {
        return `${extra_use_minutes} 분`
      }
    },

    getExtraUsePriceStr(item) {
      const visit_item = this.getVisitItem(item)
      let extra_use_price = visit_item && visit_item.extra_use_price
      if (extra_use_price === null || extra_use_price === undefined) {
        return ""
      } else {
        return this.$toComma(extra_use_price)
      }
    },

    getAccessoriesInfo(item) {
      const visit_item = this.getVisitItem(item)
      let accessories = visit_item && visit_item.accessories
      return accessories || ""
    },

    getChangeDressTime(item) {
      const visit_item = this.getVisitItem(item)
      let dress_change_time = visit_item && visit_item.dress_change_time
      return dress_change_time || ""
    },

    submitExtraDress: _.debounce(async function () {
      this.payingExtraDress = true
      try {
        let orderId = Math.floor(Math.random() * 1000000000)
        const orderRes = await this.$http.post("/api/order/v2/order", {
          orderId: orderId.toString(),
          userId: this.selectedMember.user_id,
          orderDetailList: [
            {
              productName: "드레스교체",
              orderNum: 1,
              price: this.default_price.changeDressPrice || 9900,
              totalPrice: this.default_price.changeDressPrice || 9900,
              finalPrice: this.default_price.changeDressPrice || 9900,
            },
          ],
          memo: "",
          type: "현장드레스교체",
          isSite: true,
        })

        console.log("updateChangeDressPrice", this.selectedMember, this.dayjs().format("HH:mm"))

        await this.$http.post("/api/reservation/visit/updateChangeDressPrice", {
          reservation_id: this.selectedMember.id,
          change_dress_price: this.default_price.changeDressPrice || 9900,
          member_number: this.selectedMember.member_number,
          dress_change_time: this.dayjs().format("HH:mm"),
        })

        if (orderRes.data) {
          this.load_visit_member()
        }
      } catch (e) {
        console.log(e)
        alert("현장 등록에 실패하였습니다.")
      } finally {
        this.payingExtraDress = false
        this.cancelChangeDress()
      }
    }, 500),
  },
}
</script>
<style scoped>
.timeTable {
  /* width: 100%;
    max-width: 1030px; */
  width: 100%;
  min-width: 1030px;
  max-width: 1280px;
  border: 1px solid #ccc;
  border-collapse: collapse;
  text-align: center;
  font-weight: 500;
}

/* 상세보기 */
.table_detail {
  background: white;
}

.table_detail tr td {
  padding: 4px;
}

.table_detail tr td:first-child {
  width: 120px;
  padding: 4px;
}
.greyBg:not(:hover) {
  background-color: rgb(250, 250, 250);
}
</style>
