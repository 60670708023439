<template>
  <v-dialog v-model="show" activator="parent" content-class="sela-dialog" v-if="item">
    <div class="sela-dialog-card">
      <DialogHeader hideClose />
      <div class="sela-dialog-card-content">
        <div class="py-4 px-4" style="background-color: white">
          <div class="d-flex justify-space-between" style="max-height: 150px">
            <v-img class="mr-3" width="35%" :src="item.imageUrl" />

            <v-divider vertical />

            <div class="ml-3" style="word-break: keep-all">
              <div style="border: 1px solid lightgray; text-align: center; padding: 4px 16px; border-radius: 5px; width: fit-content">
                {{ item.title }}
              </div>
              <div style="font-size: 0.9rem; margin-top: 15px" v-html="$t('dialog.review-dialog-subtext')"></div>
            </div>
          </div>

          <v-divider class="my-4" />

          <v-textarea v-model="value" :maxlength="maxLength" :counter="maxLength" :rules="rules" clear-icon autofocus rows="7" outlined rounded-2 :label="$t('page.review.write-hint')" />
        </div>

        <div class="sela-dialog-card-footer relative">
          <v-btn outlined class="sela deactivate largeText" width="40%" style="visibility: hidden"> </v-btn>
          <v-btn outlined class="sela deactivate largeText" @click="show = false" width="28%">{{ $t("button.cancel") }} </v-btn>
          <v-btn outlined class="sela largeText" @click="submit()" width="28%" :disabled="(value || '').replace(/\s+/g, '').length == 0"> {{ $t("button.write-complete") }} </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import DialogHeader from "@/views/components/DialogHeader"

const kMaxLength = 512

export default {
  components: {
    DialogHeader,
  },

  props: {
    item: Object,
    visible: Boolean,
  },

  computed: {
    show: {
      get() {
        return this.visible
      },
      set(newValue) {
        if (newValue === false) {
          this.$emit("close")
        }
      },
    },
  },

  data: () => ({
    maxLength: kMaxLength,
    rules: [(v) => (v || "").length <= kMaxLength || `최대 ${kMaxLength}자까지 입력 가능합니다.`],
    value: "",
  }),

  mounted() {
    this.$http
      .post("/api/review/select/request", {
        params: {
          requestId: this.item.id,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          this.value = res.data[0].content || ""
        }
      })
  },

  methods: {
    submit() {
      this.$http
        .post("/api/review/update", {
          params: {
            user_id: this.$store.state.lafia_user.user_id,
            requestId: this.item.id,
            content: this.value || "",
          },
        })
        .then((res) => {
          console.log(res)
          this.$emit("update", this.value)
          this.show = false
        })
    },
  },
}
</script>
