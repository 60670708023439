<template>
  <v-sheet class="py-6 px-8">
    <p class="text-h6 font-weight-bold grey--text text--darken-3 text-center mb-6">프로모션 수정하기</p>

    <v-sheet class="mt-2 px-4">
      <v-text-field class="rounded-10" label="이름" dense outlined v-model.trim="updateItem.name"></v-text-field>
      <v-text-field class="rounded-10" label="코드" dense outlined v-model.trim="updateItem.code"></v-text-field>
      <v-row>
        <v-menu v-model="startAtMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="updateItem.startAt" label="예약 시작일자" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="green"></v-text-field>
          </template>
          <v-date-picker v-model="updateItem.startAt" no-title scrollable color="green" @input="startAtMenu = false"> </v-date-picker>
        </v-menu>
        <v-menu v-model="endAtMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="updateItem.endAt" label="예약 종료일자" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="warning"></v-text-field>
          </template>
          <v-date-picker v-model="updateItem.endAt" no-title scrollable color="warning" @input="endAtMenu = false"> </v-date-picker>
        </v-menu>
        <v-checkbox v-model="updateItem.enabled" class="shrink mt-0 ml-4" label="활성화 상태" color="primary" hide-details dense></v-checkbox>
      </v-row>
    </v-sheet>
    <v-sheet class="d-flex justify-center mt-5">
      <v-btn class="rounded-10 px-6 mr-4" color="grey" large dark depressed @click="$emit('close')">
        <v-icon small left>mdi-cancel</v-icon>
        취소하기
      </v-btn>
      <v-btn class="rounded-10 px-6" color="primary" large dark depressed @click="updateSubmit()">
        <v-icon small left>mdi-database-edit-outline</v-icon>
        수정하기
      </v-btn>
    </v-sheet>
  </v-sheet>
</template>
<script>
import TipTapWriter from "@/components/tiptap/Writer"
export default {
  props: ["updateItem_import"],
  components: {
    TipTapWriter,
  },
  data: () => ({
    yesOrNo: ["Y", "N"],
    updateItem: {},

    startAtMenu: false,
    endAtMenu: false,

    //TipTap
    options: {
      content: "",
      editable: true,
      supportImage: true,
      supportVideo: true,
    },
    componentKey: 0,

    //TipTap
    desc2Options: {
      content: "",
      editable: true,
      supportImage: true,
      supportVideo: true,
    },
    desc2ComponentKey: 0,
  }),

  mounted() {
    this.updateItem = {
      ...this.updateItem_import,
      isTraveler: this.updateItem_import.role === "TRAVELER" ? "Y" : "N",
      startAt: this.updateItem_import.startAt ? this.dayjs(this.updateItem_import.startAt).format("YYYY-MM-DD") : null,
      endAt: this.updateItem_import.endAt ? this.dayjs(this.updateItem_import.endAt).format("YYYY-MM-DD") : null,
    }
    this.options.content = this.updateItem.content
    this.desc2Options.content = this.updateItem.content2

    this.componentKey++
    this.desc2ComponentKey++
    console.log(this.updateItem)
  },

  methods: {
    clear() {
      this.options.content = ""
      this.componentKey++
    },
    clearDesc2() {
      this.desc2Options.content = ""
      this.desc2ComponentKey++
    },
    // 목록
    updateSubmit: _.debounce(async function () {
      // 제목 검증
      // if (this.updateItem.name == "") {
      //   alert("이름을 입력해주세요")
      //   return
      // }

      // 컨셉소개 내용 검증
      if (this.updateItem.code == "") {
        alert("프로모션 코드를 입력해주세요")
        return
      }

      if (!this.updateItem.endAt) {
        alert("종료일자를 입력해주세요")
        return
      }

      this.$http
        .post("/api/promotion/update", {
          params: {
            code: this.updateItem.code,
            name: this.updateItem.name,
            clothPrice: Number(this.updateItem.clothPrice),
            clothPriceUSD: Number(this.updateItem.clothPriceUSD),
            clothPriceWeekend: Number(this.updateItem.clothPriceWeekend),
            clothPriceWeekendUSD: Number(this.updateItem.clothPriceWeekendUSD),
            tenOverTimePrice: Number(this.updateItem.tenOverTimePrice),
            tenOverTimePriceUSD: Number(this.updateItem.tenOverTimePriceUSD),
            changeDressPrice: Number(this.updateItem.changeDressPrice),
            changeDressPriceUSD: Number(this.updateItem.changeDressPriceUSD),
            role: this.updateItem.isTraveler === "Y" ? "TRAVELER" : null,
            enabled: this.updateItem.enabled,
            startAt: this.updateItem.startAt,
            endAt: this.updateItem.endAt,
            description: this.options.content,
            description2: this.desc2Options.content ? this.desc2Options.content : null,
            id: this.updateItem.id,
          },
        })
        .then((res) => {
          if (res) {
            alert("정상적으로 등록되었습니다.")
            this.$emit("close")
          } else {
            alert("등록되지 않았습니다")
          }
        })
        .catch((e) => {
          console.log(e)
          alert(this.$t("alert.error_page"))
        })
    }, 500),
  },
}
</script>
<style scoped>
.my-clipper {
  width: 100%;
}

.placeholder {
  width: 100%;
  height: 150px;
}
</style>
