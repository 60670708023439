<template>
  <v-card flat class="ma-4" outlined style="border-color: #e6d7e8; border-width: 3px; border-radius: 12px !important">
    <v-row dense>
      <v-col cols="5">
        <slot name="profile"></slot>
      </v-col>
      <v-col cols="7" class="pt-1 pb-3 pl-2 pr-4 d-flex flex-column justify-space-between align-start">
        <v-sheet>
          <span class="primary--text text-h6 font-weight-medium">{{ name }} 아티스트</span>
          <slot name="title"></slot> <slot name="content"></slot>
        </v-sheet>
        <v-btn depressed class="rounded-1 mainBtn" target="_blank" :href="address">메이크업 문의하기</v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  //536 * 876
  props: ["name", "address"],

  computed: {
    getProfileImgPath() {
      return `@/assets/maker/${this.profile_img}`
    },
  },

  mounted() {
    console.log(this.profile_img)
  },
}
</script>
<style scoped>
.mainBtn {
  width: 90%;
  border: 2px solid #ccc !important;
  font-size: 17px;
  background-color: #d9bddd !important;
  color: #66546d !important;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}
</style>
