<template>
  <div>
    <!-- 제목 -->
    <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">HOME 관리</font>
    </v-sheet>

    <!-- 본문 -->
    <div class="d-flex">
      <v-sheet width="740" class="pa-2 ma-2" outlined>
        <!-- 본문 -->
        <TipTapWriter v-if="options.content" style="min-height: 400px" class="tiptap_style mt-2" :options="options" @contentUpdated="contentUpdate" />

        <!-- 버튼 -->
        <v-sheet class="d-flex justify-center pa-1 mt-2">
          <v-btn class="mx-2 px-10 rounded-10" color="#ADAFCA" dark large depressed @click="cancel()"> 취소하기 </v-btn>
          <v-btn class="mx-2 px-10 rounded-10" color="primary" dark large depressed @click="submit()"> 등록하기 </v-btn>
        </v-sheet>
      </v-sheet>
      <v-sheet class="pa-2 ma-2" outlined>
        <p class="mb-0 text-center text-subtitle-1 font-weight-medium">작은 모바일</p>
        <p class="text-center text-body-2 primary--text">(320)</p>
        <div style="width: 320px">
          <TipTapReader v-if="options.content" class="tiptap_style pa-4" :options="options" :key="componentKey + '320'" />
        </div>
      </v-sheet>

      <v-sheet class="pa-2 ma-2" outlined>
        <p class="mb-0 text-center text-subtitle-1 font-weight-medium">큰 모바일</p>
        <p class="text-center text-body-2 primary--text">(412)</p>
        <div style="width: 412px">
          <TipTapReader v-if="options.content" class="tiptap_style pa-4" :options="options" :key="componentKey + '320'" />
        </div>
      </v-sheet>
    </div>
  </div>
</template>
<script>
import TipTapWriter from "@/components/tiptap/Writer"
import TipTapReader from "@/components/tiptap/Reader"

export default {
  components: {
    TipTapWriter,
    TipTapReader,
  },

  data: () => ({
    tab: 0,
    items: ["BEST", "펀딩", "컨셉사진", "스토어"],

    // 기본 정보 > 썸네일 이미지
    uploadImage: {},
    url: "",

    category_list: [],
    category: "",
    title: "",
    subtitle: "",

    //TipTap
    options: {
      content: "",
      editable: true,
      supportImage: true,
      supportVideo: true,
    },

    componentKey: 0,
  }),

  mounted() {
    this.$http
      .post("/api/html/select/specific/title", {
        params: {
          title: "home",
        },
      })
      .then((res) => {
        this.options.content = res.data[0].content
      })
  },

  methods: {
    // 내용 Count 값 import
    contentUpdate() {
      this.componentKey++
    },

    // 취소하기
    cancel() {
      if (confirm("지금 작성하고 계신 글이 저장되지 않습니다.\n페이지를 이동하시겠습니까?")) {
        this.$router.go(-1)
      }
    },

    // 등록하기
    async submit() {
      if (!this.options.content.length) {
        alert("내용을 입력해주세요")
      } else {
        this.$http
          .post("/api/html/update", {
            params: {
              title: "home",
              content: this.options.content,
            },
          })
          .then((res) => {
            if (res.data.affectedRows) {
              alert("HOME이 정상적으로 수정되었습니다.")
            }
          })
      }
    },
  },
}
</script>
<style scoped>
.table_style {
  width: 100%;
  background: white;
  border: 1px solid #ddd;
  border-collapse: collapse;
}

.table_style tr td {
  border: 1px solid #ddd;
  padding: 4px;
  text-align: center;
}

.filled {
  background: #ddd;
  color: #999;
  font-weight: 300;
}

.reservation {
  background: #bba8ca;
  color: #84679a;
  font-weight: 300;
}
</style>

<style scoped>
.my-clipper {
  width: 100%;
}

.placeholder {
  width: 100%;
  height: 150px;
}
</style>
<style scoped>
.test {
  /* background: url('~@/assets/list_main.svg'); */
  background-size: 100%;
}
</style>
