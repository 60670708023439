<template>
  <v-sheet class="pa-4">
    <table class="table_style">
      <tr>
        <td class="ma-0 pa-0" style="background: white; text-align: center">
          <v-sheet class="d-flex justify-center py-10 px-4">
            <v-sheet max-width="200" max-heigth="200">
              <v-img :src="lafiaUser_profile_img_path"></v-img>
            </v-sheet>
          </v-sheet>
        </td>
      </tr>
      <tr>
        <td class="py-2 px-3" style="background: white">
          <v-file-input placeholder="업로드할 이미지를 선택해주세요" outlined dense show-size hide-details prepend-icon color="blue" v-model="uploadImage" @change="updated()">
            <template v-slot:prepend-inner>
              <v-icon class="mr-1" color="primary">mdi-image-filter-hdr</v-icon>
            </template>
          </v-file-input>
        </td>
      </tr>
      <tr>
        <td class="py-2 px-3">
          <v-btn @click="imageUpload" width="100%" depressed dark color="primary">{{ $t("button.uploadImage") }}</v-btn>
        </td>
      </tr>
    </table>
  </v-sheet>
</template>
<script>
import { mapState, mapMutations, mapGetters } from "vuex"

export default {
  data: () => ({
    uploadImage: {},
    user: {},
    url: "",
  }),

  mounted() {
    // 유저 정보 불러오기
    this.$http
      .post("/api/user/select/specific/user_id", {
        params: {
          user_id: this.$store.state.lafia_user.user_id,
        },
      })
      .then((res) => {
        this.user = res.data[0]
      })
  },

  computed: {
    ...mapState(["lafia_user"]),
    ...mapGetters(["lafiaUser_profile_img_path"]),
  },

  methods: {
    ...mapMutations(["update_profile_image"]),

    updated() {
      this.url = URL.createObjectURL(this.uploadImage)
    },

    // 프로필 이미지 변경
    imageUpload: _.debounce(function () {
      var file = this.uploadImage
      this.url = URL.createObjectURL(file)
      var formData = new FormData()
      formData.append("image", file)
      this.$http
        .post("/api/image/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          params: {
            type: "profile_image",
          },
        })
        .then((res) => {
          this.user.profile_image = res.data
          this.$http
            .post("/api/user/update/profile_image", {
              params: {
                change_user_img: this.user.profile_image,
                user_id: this.$store.state.lafia_user.user_id,
              },
            })
            .then((res) => {
              if (res.data) {
                this.update_profile_image("/upload/user/profile_image/" + this.user.profile_image)
                this.$emit("imageUpdated")
                this.$toast.success(this.$t("toast.success_to_update_profile_image"))
              } else {
                alert(this.$t("alert.fail_to_update_profile_image"))
                return false
              }
            })
        })
    }, 500),
  },
}
</script>
<style scoped>
.table_style {
  min-width: 240px;
  border: 1px solid #ccc;
  border-collapse: collapse;
}

.table_style tr td {
  border: 1px solid #ccc;
  padding: 10px;
}
</style>
