<template>
  <div class="mb-2 pa-2 pa-md-4" style="position: relative; border: 5px solid #cecece; border-radius: 16px">
    <!-- 컨셉 제목 -->
    <p class="font-danjunghae accent--text mb-1 ml-1 mt-3" style="font-size: 28px" :lang="lang">
      {{ getTitle(item.title) }}
    </p>

    <!-- 촬영시간 & 금액 & 버튼 -->
    <div class="d-flex justify-space-between">
      <!-- 촬영시간 & 날짜 -->
      <div class="d-flex justify-space-between">
        <!-- 촬영시간 -->
        <p class="text-body-1 mt-1 ml-5px mb-2 grey--text text--darken-1 font-weight-medium">
          {{ $t("page.mypage.shot-list.shot-card-date") }} : {{ this.dayjs(new Date(item.created)).format("YY-M-D / HH:mm") }}
        </p>
      </div>

      <!-- 선택날짜 -->
      <div>
        <p class="grey--text font-weight-bold" style="position: absolute; top: 28px; right: 16px; font-size: 12px">
          {{ $t("page.mypage.shot-list.shot-image-select-date-limit") }} {{ this.dayjs(this.untilDate).format("YY.M.D") }}
        </p>
      </div>

      <!-- 버튼 -->
      <div class="d-flex justify-end">
        <v-btn width="100" depressed class="sela" :disabled="isOver" style="border: 2px solid #d1d0cf; background: #f1b2ba" @click="$router.push('/mypage/select?id=' + item.id)">
          {{ $t(isOver ? "page.mypage.shot-list.shot-image-select-date-over" : "page.mypage.shot-list.shot-image-select") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
export default {
  props: {
    item: Object,
  },

  data: () => ({
    isOver: false,
    untilDate: new Date(),
  }),

  mounted() {
    this.untilDate = new Date(this.getTDaysLater(this.item.created, 7))
    this.untilDate.setHours(23, 59, 59)
    this.isOver = new Date() > this.untilDate
  },

  computed: {
    ...mapState(["lang", "concepts"]),
  },

  methods: {
    getTDaysLater(date, days) {
      let d = new Date(date)
      d.setDate(d.getDate() + days)
      return d
    },

    getTitle(conceptTitleKr) {
      const concept = this.concepts.find((c) => c.title === conceptTitleKr)
      switch (this.lang) {
        case "en":
          return (concept && concept.title_en) || conceptTitleKr
          break
        case "jp":
          return (concept && concept.title_jp) || conceptTitleKr

        default:
          return conceptTitleKr
      }
    },
  },
}
</script>
