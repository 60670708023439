<template>
  <v-sheet class="py-6 px-8">
    <p class="text-h6 font-weight-bold grey--text text--darken-3 text-center mb-6">프로모션 추가하기</p>

    <v-sheet class="mt-2 px-4">
      <v-text-field class="rounded-10" label="이름" dense outlined v-model.trim="create.name"></v-text-field>
      <v-text-field class="rounded-10" label="코드" dense outlined v-model.trim="create.code"></v-text-field>
      <v-text-field class="rounded-10" label="대여가격(KRW)" dense outlined type="number" hide-spin-buttons v-model="create.clothPrice"></v-text-field>
      <v-text-field class="rounded-10" label="주말대여가격(KRW)" dense outlined type="number" hide-spin-buttons v-model="create.clothPriceWeekend"></v-text-field>
      <v-text-field class="rounded-10" label="10분연장가격(KRW)" dense outlined type="number" hide-spin-buttons v-model="create.tenOverTimePrice"></v-text-field>
      <v-text-field class="rounded-10" label="드레스변경(KRW)" dense outlined type="number" hide-spin-buttons v-model="create.changeDressPrice"></v-text-field>

      <div class="font-weight-bold text-body-1 py-2">프로모션 유효기간</div>
      <v-row>
        <v-menu v-model="openAtMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="create.openAtDate" label="링크시작일" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="green"></v-text-field>
          </template>
          <v-date-picker v-model="create.openAtDate" no-title scrollable color="green" @input="openAtMenu = false"></v-date-picker>
        </v-menu>
        <v-menu v-model="closeAtMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="create.closeAtDate" label="링크종료일" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="warning"></v-text-field>
          </template>
          <v-date-picker v-model="create.closeAtDate" no-title scrollable color="warning" @input="closeAtMenu = false"> </v-date-picker>
        </v-menu>
      </v-row>

      <div class="font-weight-bold text-body-1 py-2">예약 가능 기간</div>
      <v-row>
        <v-menu v-model="startAtMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="create.startAtDate" label="예약 시작일자" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="green"></v-text-field>
          </template>
          <v-date-picker v-model="create.startAtDate" no-title scrollable color="green" @input="startAtMenu = false"></v-date-picker>
        </v-menu>
        <v-menu v-model="endAtMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="create.endAtDate" label="예약 종료일자" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="warning"></v-text-field>
          </template>
          <v-date-picker v-model="create.endAtDate" no-title scrollable color="warning" @input="endAtMenu = false"> </v-date-picker>
        </v-menu>
      </v-row>

      <v-row>
        <PromotionCoupons :coupons="create.editCoupons" @addCoupon="visibleCouponSelect = true" />
      </v-row>

      <div class="font-weight-bold text-h6 ml-4 mt-4">한글</div>
      <v-sheet class="mt-2">
        <div class="d-flex justify-space-between">
          <span class="grey--text text-body-2">필요한 상품에 대한 설명(1)을 입력해 주세요</span>
          <v-btn color="primary" depressed outlined small @click="clear()">삭제</v-btn>
        </div>
        <!-- 설명 -->
        <TipTapWriter class="tiptap_style mt-2" :options="options" :key="'desc1' + componentKey" />
      </v-sheet>

      <v-sheet class="mt-2">
        <div class="d-flex justify-space-between">
          <span class="grey--text text-body-2">필요한 상품에 대한 설명(2)을 입력해 주세요</span>
          <v-btn color="primary" depressed outlined small @click="clearDesc2()">삭제</v-btn>
        </div>
        <!-- 설명 -->
        <TipTapWriter class="tiptap_style mt-2" :options="desc2Options" :key="'desc2' + desc2ComponentKey" />
      </v-sheet>

      <div class="font-weight-bold text-h6 ml-4 mt-4">영어</div>
      <v-sheet class="mt-2">
        <div class="d-flex justify-space-between">
          <span class="grey--text text-body-2">필요한 상품에 대한 설명(1)을 입력해 주세요</span>
          <v-btn color="primary" depressed outlined small @click="clear('en')">삭제</v-btn>
        </div>
        <!-- 설명 -->
        <TipTapWriter class="tiptap_style mt-2" :options="en.options" :key="'en-desc1' + en.componentKey" />
      </v-sheet>

      <v-sheet class="mt-2">
        <div class="d-flex justify-space-between">
          <span class="grey--text text-body-2">필요한 상품에 대한 설명(2)을 입력해 주세요</span>
          <v-btn color="primary" depressed outlined small @click="clearDesc2('en')">삭제</v-btn>
        </div>
        <!-- 설명 -->
        <TipTapWriter class="tiptap_style mt-2" :options="en.desc2Options" :key="'en-desc2' + en.desc2ComponentKey" />
      </v-sheet>

      <div class="font-weight-bold text-h6 ml-4 mt-4">일본어</div>
      <v-sheet class="mt-2">
        <div class="d-flex justify-space-between">
          <span class="grey--text text-body-2">필요한 상품에 대한 설명(1)을 입력해 주세요</span>
          <v-btn color="primary" depressed outlined small @click="clear('jp')">삭제</v-btn>
        </div>
        <!-- 설명 -->
        <TipTapWriter class="tiptap_style mt-2" :options="jp.options" :key="'jp-desc1' + jp.componentKey" />
      </v-sheet>

      <v-sheet class="mt-2">
        <div class="d-flex justify-space-between">
          <span class="grey--text text-body-2">필요한 상품에 대한 설명(2)을 입력해 주세요</span>
          <v-btn color="primary" depressed outlined small @click="clearDesc2('jp')">삭제</v-btn>
        </div>
        <!-- 설명 -->
        <TipTapWriter class="tiptap_style mt-2" :options="jp.desc2Options" :key="'jp-desc2' + jp.desc2ComponentKey" />
      </v-sheet>
    </v-sheet>
    <v-sheet class="d-flex justify-center mt-5">
      <v-btn class="rounded-10 px-6 mr-4" color="grey" large dark depressed @click="$emit('close')">
        <v-icon small left>mdi-cancel</v-icon>
        취소하기
      </v-btn>
      <v-btn class="rounded-10 px-6" color="primary" large dark depressed @click="createSubmit()">
        <v-icon small left>mdi-database-plus-outline</v-icon>
        추가하기
      </v-btn>
    </v-sheet>
    <PackageCouponSelect :visible="visibleCouponSelect" @input="(e) => (visibleCouponSelect = e)" @addCoupon="addCoupon" />
  </v-sheet>
</template>
<script>
import TipTapWriter from "@/components/tiptap/Writer"
import PromotionCoupons from "./PromotionCoupons"
import PackageCouponSelect from "./PackageCouponSelect"
import { DEFAULT_PACKAGE_CODE } from "@/constant"

export default {
  components: {
    TipTapWriter,
    PromotionCoupons,
    PackageCouponSelect,
  },
  inject: ["coupons_info"],
  data: () => ({
    yesOrNo: ["Y", "N"],
    create: {
      name: "",
      code: "",
      clothPrice: 0,
      clothPriceUSD: 0,
      clothPriceWeekend: 0,
      clothPriceWeekendUSD: 0,
      tenOverTimePrice: 0,
      tenOverTimePriceUSD: 0,
      changeDressPrice: 0,
      changeDressPriceUSD: 0,
      isEnable: true,
      startAtDate: null,
      endAtDate: null,
      openAtDate: null,
      closeAtDate: null,
      isTraveler: "N",
      editCoupons: [],
    },
    startAtMenu: false,
    endAtMenu: false,
    openAtMenu: false,
    closeAtMenu: false,

    //TipTap
    options: {
      content: "",
      editable: true,
      supportImage: true,
      supportVideo: true,
    },
    componentKey: 0,

    //TipTap
    desc2Options: {
      content: "",
      editable: true,
      supportImage: true,
      supportVideo: true,
    },
    desc2ComponentKey: 0,

    en: {
      //TipTap
      options: {
        content: "",
        editable: true,
        supportImage: true,
        supportVideo: true,
      },
      componentKey: 0,

      //TipTap
      desc2Options: {
        content: "",
        editable: true,
        supportImage: true,
        supportVideo: true,
      },
      desc2ComponentKey: 0,
    },
    jp: {
      //TipTap
      options: {
        content: "",
        editable: true,
        supportImage: true,
        supportVideo: true,
      },
      componentKey: 0,

      //TipTap
      desc2Options: {
        content: "",
        editable: true,
        supportImage: true,
        supportVideo: true,
      },
      desc2ComponentKey: 0,
    },
    visibleCouponSelect: false,
  }),

  mounted() {
    this.$http.get("/api/promotion/select").then((res) => {
      const _defaultPrice = res.data.find((e) => e.code == "default")
      const _defaultPackagePrice = res.data.find((e) => e.code == DEFAULT_PACKAGE_CODE)
      const defaultPrice = _defaultPackagePrice || _defaultPrice
      if (defaultPrice) {
        this.create.clothPrice = defaultPrice.clothPrice
        this.create.clothPriceWeekend = defaultPrice.clothPriceWeekend
        this.create.tenOverTimePrice = defaultPrice.tenOverTimePrice
        this.create.changeDressPrice = defaultPrice.changeDressPrice
      }
    })
  },

  methods: {
    clear(lang = "ko") {
      if (lang === "ko") {
        this.options.content = ""
        this.componentKey++
      } else if (lang === "en") {
        this.en.options.content = ""
        this.en.componentKey++
      } else if (lang === "jp") {
        this.jp.options.content = ""
        this.jp.componentKey++
      }
    },
    clearDesc2(lang = "ko") {
      if (lang === "ko") {
        this.desc2Options.content = ""
        this.desc2ComponentKey++
      } else if (lang === "en") {
        this.en.desc2Options.content = ""
        this.en.desc2ComponentKey++
      } else if (lang === "jp") {
        this.jp.desc2Options.content = ""
        this.jp.desc2ComponentKey++
      }
    },
    addCoupon(aCoupon) {
      console.log("addCoupon", aCoupon, this.create.editCoupons)
      // const found = this.create.editCoupons.find((coupon) => coupon.id == aCoupon.id)
      // if (found) {
      //   this.$toast.error("이미 등록되었습니다.")
      //   return
      // }
      this.create.editCoupons.push({
        id: aCoupon.id,
        title: aCoupon.title,
      })
      this.visibleCouponSelect = false
    },

    removeCoupon(index) {
      this.create.editCoupons.splice(index, 1)
    },

    // 목록
    createSubmit: _.debounce(async function () {
      // 제목 검증
      // if (this.create.name == "") {
      //   alert("이름을 입력해주세요")
      //   return
      // }

      // 컨셉소개 내용 검증
      if (this.create.code == "") {
        alert("프로모션 코드를 입력해주세요")
        return
      }

      // 가격 검증
      if (Number(this.create.clothPrice) < 0 || Number(this.create.clothPriceWeekend) < 0 || Number(this.create.tenOverTimePrice) < 0 || Number(this.create.changeDressPrice) < 0) {
        alert("유효한 가격 정보를 입력해주세요")
        return
      }

      if (!this.create.endAtDate) {
        alert("종료일자를 입력해주세요")
        return
      }
      console.log(this.create.startAtDate)
      console.log(this.create.endAtDate)

      this.$http
        .post("/api/promotion/insert", {
          params: {
            code: this.create.code,
            name: this.create.name,
            clothPrice: Number(this.create.clothPrice),
            clothPriceWeekend: Number(this.create.clothPriceWeekend),
            tenOverTimePrice: Number(this.create.tenOverTimePrice),
            changeDressPrice: Number(this.create.changeDressPrice),
            role: "PACKAGE",
            enabled: this.create.isEnable,
            openAt: this.create.openAtDate ? this.dayjs(this.create.openAtDate).format("YYYY-MM-DD HH:mm:ss") : null,
            closeAt: this.create.closeAtDate ? this.dayjs(this.create.closeAtDate).format("YYYY-MM-DD HH:mm:ss") : null,
            startAt: this.create.startAtDate ? this.dayjs(this.create.startAtDate).format("YYYY-MM-DD HH:mm:ss") : null,
            endAt: this.create.endAtDate ? this.dayjs(this.create.endAtDate).format("YYYY-MM-DD HH:mm:ss") : null,
            description: this.options.content ? this.options.content : null,
            description2: this.desc2Options.content ? this.desc2Options.content : null,
            description_en: this.en.options.content ? this.en.options.content : null,
            description2_en: this.en.desc2Options.content ? this.en.desc2Options.content : null,
            description_jp: this.jp.options.content ? this.jp.options.content : null,
            description2_jp: this.jp.desc2Options.content ? this.jp.desc2Options.content : null,
          },
        })
        .then(async (res) => {
          if (res) {
            try {
              await this.$http.post("/api/promotion/coupons/delete", {
                packageCode: this.create.code,
              })
              await Promise.all(this.create.editCoupons.map((coupon) => this.InsertPromotionCoupon(coupon)))
            } catch (e) {
              console.log(e)
              this.$toast.error("쿠폰이 정상발급이 되지 않았습니다.")
            }

            alert("정상적으로 등록되었습니다.")
            this.$emit("close")
          } else {
            alert("등록되지 않았습니다")
          }
        })
        .catch((e) => {
          console.log(e)
          alert(this.$t("alert.error_page"))
        })
    }, 500),
    async InsertPromotionCoupon(coupon) {
      return await this.$http.post("/api/promotion/coupons/insert", {
        packageCode: this.create.code,
        coupon_id: coupon.id,
      })
    },
  },
}
</script>
<style scoped>
.my-clipper {
  width: 100%;
}

.placeholder {
  width: 100%;
  height: 150px;
}
</style>
