<template>
  <div>
    <!-- 제목 -->
    <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">회원 관리</font>
    </v-sheet>

    <!-- 본문 -->
    <v-sheet outlined class="pa-2 ma-2">
      <!-- 검색 -->
      <v-sheet outlined class="pa-2 mb-2 d-flex">
        <v-sheet class="d-flex" width="660">
          <!-- 유형 -->
          <v-sheet width="160" class="mr-3">
            <v-select outlined dense hide-details :items="select_list.search_type" v-model="search_type"></v-select>
          </v-sheet>

          <!-- 키워드 -->
          <v-text-field placeholder="검색어를 입력해주세요" outlined dense hide-details v-model="keyword" @keyup.enter="search()"></v-text-field>

          <!-- 제출 버튼 -->
          <v-btn height="40" class="px-6 ml-3" color="primary" dark depressed @click="search()">
            <v-icon small left>mdi-magnify</v-icon>
            검색하기
          </v-btn>
        </v-sheet>
      </v-sheet>

      <!-- 목록 -->
      <v-radio-group v-model="selected" hide-details>
        <v-data-table
          hide-default-header
          :items="list"
          :footer-props="{
            'items-per-page-options': [10, 20, 100, -1],
            itemsPerPageText: '페이지당 회원 수',
          }"
          no-data-text=""
        >
          <template v-slot:header>
            <tr>
              <th style="width: 52px"></th>
              <th style="width: 72px; text-align: center; padding-left: 0px">이미지</th>
              <th style="width: 220px">아이디</th>
              <th style="width: 240px">이름</th>
              <th style="width: 300px">전화번호</th>
              <th style="width: 100%">이메일</th>
              <th style="width: 300px">가입일</th>
            </tr>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <v-radio class="ml-1" hide-details dense :value="item" @change="selectedItem = item"></v-radio>
              </td>
              <td>
                <v-avatar color="rgb(219, 219, 219)" size="52">
                  <v-img :src="'/upload/user/profile_image/' + item.profile_image"></v-img>
                </v-avatar>
              </td>
              <td class="text-truncate">
                {{ item.user_id }}
              </td>
              <td class="text-truncate">
                {{ item.name }}
              </td>
              <td class="text-truncate">
                {{ item.phone }}
              </td>
              <td class="text-truncate">
                {{ item.email }}
              </td>
              <td class="text-truncate">
                {{ new Date(item.created).toLocaleString() }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-radio-group>

      <!-- 버튼 -->
      <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
        <v-btn class="px-6 ml-3" color="red" large dark depressed @click="deleteItem()">
          <v-icon small left>mdi-database-remove-outline</v-icon>
          삭제하기
        </v-btn>
      </v-card>
    </v-sheet>
  </div>
</template>
<script>
export default {
  data: () => ({
    select_list: {
      search_type: ["아이디", "이름", "전화번호", "이메일"],
    },

    search_type: "아이디",
    keyword: "",

    list: [],

    selected: false,
    selectedItem: {},
  }),

  mounted() {
    // 목록
    this.search()
  },

  methods: {
    // 목록
    search() {
      this.$http
        .post("/api/user/select/search", {
          params: {
            search_type: this.search_type,
            keyword: this.keyword,
          },
        })
        .then((res) => {
          if (res.data.length) {
            this.list = res.data
          }
        })
    },

    // 삭제하기
    deleteItem() {
      if (!Object.keys(this.selectedItem).length) {
        alert("선택한 회원이 없습니다")
      } else {
        if (confirm("정말 해당 회원을 삭제하겠습니까?")) {
          this.$http
            .post("/api/user/delete/id", {
              params: {
                id: this.selectedItem.id,
              },
            })
            .then((res) => {
              if (res.data.affectedRows) {
                this.search()
                this.selected = false
                this.selectedItem = {}
              }
            })
        }
      }
    },
  },
}
</script>
