<template>
  <div>
    <!-- 제목 -->
    <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">스토어 관리</font>
    </v-sheet>

    <!-- 본문 -->
    <v-sheet outlined class="pa-2 ma-2">
      <!-- 목록 -->
      <v-radio-group v-model="selected" hide-details>
        <v-data-table
          hide-default-header
          :items="list"
          :footer-props="{
            'items-per-page-options': [10, 20, 100, -1],
            itemsPerPageText: '페이지당 상품 수',
          }"
          no-data-text=""
        >
          <template v-slot:header>
            <tr>
              <th style="width: 52px"></th>
              <th style="width: 20%">상품</th>
              <th style="width: 20%">가격</th>
              <th style="width: 240px">등록일</th>
            </tr>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <v-radio class="ml-1" hide-details dense :value="item" @change="selectedItem = item"></v-radio>
              </td>
              <td class="text-truncate">
                {{ item.title }}
              </td>
              <td class="text-truncate">
                {{ item.price }}
              </td>
              <td class="text-truncate">
                {{ new Date(item.created).toLocaleString() }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-radio-group>
    </v-sheet>
  </div>
</template>
<script>
export default {
  data: () => ({
    list: [],
  }),

  mounted() {
    // 목록
    this.load()
  },

  methods: {
    // 목록
    load() {
      // this.$http.post('/api/user/select')
      // .then((res) => {
      //     if(res.data.length){
      //         this.list = res.data
      //     }
      // })
    },
  },
}
</script>
