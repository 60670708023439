<template>
  <div class="pa-2 ma-2">
    <div class="font-weight-bold text-body-1 py-2">쿠폰 사용 가능 기간</div>
    <v-sheet width="600">
      <v-sheet class="pa-2 ma-2 d-flex">
        <v-menu v-model="startAtMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="startAt" label="시작 일자" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="green"></v-text-field>
          </template>
          <v-date-picker v-model="startAt" no-title scrollable color="green" @input="startAtMenu = false"> </v-date-picker>
        </v-menu>
        <v-menu v-model="endAtMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="endAt" label="종료 일자" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" color="warning"></v-text-field>
          </template>
          <v-date-picker v-model="endAt" no-title scrollable color="warning" @input="endAtMenu = false"> </v-date-picker>
        </v-menu>
      </v-sheet>
      <div class="text-end">
        <v-btn class="ml-auto" depressed large @click="applyDate" color="primary">쿠폰 발행</v-btn>
      </div>
    </v-sheet>
  </div>
</template>
<script>
export default {
  props: {
    start: {
      type: String,
      required: true,
    },
    end: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    startAt: null,
    endAt: null,
    startAtMenu: false,
    endAtMenu: false,
  }),
  created() {
    this.startAt = this.start
    this.endAt = this.end
  },
  methods: {
    applyDate() {
      console.log("applyDate")
      this.$emit("confirm", {
        start: this.startAt,
        end: this.endAt,
      })
    },
  },
}
</script>
<style></style>
