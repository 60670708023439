<template>
  <div>
    <v-sheet class="pa-2 ma-2">
      <!-- 목록 -->
      <v-data-table
        v-model="selected"
        :headers="tableHeaders"
        item-key="id"
        show-select
        single-select
        :items="coupons"
        no-data-text="검색된 쿠폰이 없습니다."
        :loading="loading"
        loading-text="Loading... Please wait"
        :footer-props="{
          'items-per-page-options': [50, 100, -1],
          itemsPerPageText: '페이지당 회원 수',
        }"
        checkbox-color="primary"
      >
        <template v-slot:[`header.data-table-select`]></template>
        <template v-slot:[`item.created`]="{ item }">
          <span>{{ dayjs(item.created).format("YYYY.MM.DD") }}</span>
        </template>
        <template v-slot:[`item.startAt`]="{ item }">
          <span>{{ dayjs(item.startAt).format("YYYY.MM.DD") }}</span>
        </template>
        <template v-slot:[`item.expireAt`]="{ item }">
          <span>{{ dayjs(item.expireAt).format("YYYY.MM.DD") }}</span>
        </template>
      </v-data-table>

      <!-- 버튼 -->
      <v-card class="mt-2 pa-2 d-flex justify-end" flat>
        <v-btn class="px-6 ml-3" color="success" large dark depressed @click.stop="selectCoupon()"
          ><v-icon small left>mdi-wallet-giftcard</v-icon>
          쿠폰 선택 완료
        </v-btn>
      </v-card>
    </v-sheet>
  </div>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    coupons: [],
    selected: [],

    tableHeaders: [
      { text: "", value: "data-table-select", width: "80px", align: "center" },
      { text: "쿠폰이름", value: "title", align: "center", sortable: false },
      { text: "할인금액(KRW)", width: "200px", value: "discount_KRW", align: "center", sortable: false },
      // { text: "할인금액(USD)", width: "200px", value: "discount_USD", align: "center", sortable: false },
      { text: "쿠폰타입", value: "type", sortable: false },
      { text: "시작일", width: "160px", value: "startAt", sortable: false },
      { text: "종료일", width: "160px", value: "expireAt", sortable: false },
      { text: "생성일", width: "160px", value: "created", sortable: false },
    ],
  }),
  mounted() {
    this.loadCoupons()
  },
  methods: {
    async loadCoupons() {
      try {
        const result = await this.$http.get("/api/coupon/getCouponList/search", {
          params: {
            enabled: true,
          },
        })
        console.log(result)
        this.coupons = result.data
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
    selectCoupon() {
      console.log(this.selected)
      if (this.selected.length === 0) {
        alert("쿠폰을 선택해 주세요.")
        return
      }
      this.$emit("selectCoupons", this.selected)
      this.selected = []
    },
  },
}
</script>
<style></style>
