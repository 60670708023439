<template>
  <v-card class="d-flex flex-column justify-center align-center">
    <v-card-title> 현장 방문 추가 </v-card-title>
    <v-divider></v-divider>
    <v-card-subtitle class="mt-2 pa-0"> 아래 QR을 찍고 로그인 해 주세요 </v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <vue-qrcode :value="qrcode" :options="{ width: 300 }" />
        </v-col>
        <v-col cols="6" class="d-flex pt-10">
          <v-sheet>
            <div>* 고객이 로그인 완료 시까지 대기합니다. *</div>
            <div class="mt-4 d-flex">
              <span class="mr-4">로그인 진행 완료 확인중</span>
              <v-progress-circular :size="20" :width="2" color="primary" indeterminate></v-progress-circular>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="mb-4">
      <!-- <v-btn color="success" large class="px-4" depressed @click="$emit('create')">새로 생성</v-btn> -->
      <v-btn color="warning" large class="px-8" depressed @click="$emit('cancel')">취소</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import VueQrcode from "@chenfengyuan/vue-qrcode"
export default {
  props: ["qrcode"],
  components: {
    VueQrcode,
  },
}
</script>
<style></style>
