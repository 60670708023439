<template>
  <div class="main">
    <v-sheet>
      <div id="html"></div>
      <TipTapReader v-if="options.content" class="tiptap_style" :options="options" :key="componentKey" />
    </v-sheet>
    <div class="bottomFixedButton" :class="hasPromotionCode() ? 'd-flex' : 'd-none'">
      <v-btn large width="50%" color="primary" style="font-size: 25px" class="rounded-10 darken-1" depressed @click="goReserve">
        {{ $t("button.do-reserve") }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import TipTapReader from "@/components/tiptap/Reader"
import { mapState } from "vuex"
export default {
  components: {
    TipTapReader,
  },
  data: () => ({
    //TipTap
    options: {
      content: "",
      readonly: true,
      editable: true,
      supportImage: true,
      supportVideo: true,
    },
    componentKey: 0,

    eventData: {},
  }),

  mounted() {
    console.log("event: ", this.$route.params.eventCode)
    this.$http
      .get("/api/event/select/code", {
        params: {
          code: this.$route.params.eventCode,
        },
      })
      .then((res) => {
        this.eventData = res.data[0]
        console.log(this.eventData.isIncludeScript)

        this.setInnerHTML(document.getElementById("html"), "")
        this.options.content = ""

        if (this.eventData.isIncludeScript) {
          const htmlElement = document.getElementById("html")
          this.setInnerHTML(htmlElement, this.getContent())
        } else {
          this.options.content = this.getContent()
          this.componentKey++
        }
      })
  },
  watch: {
    lang() {
      this.setInnerHTML(document.getElementById("html"), "")
      this.options.content = ""
      if (this.eventData.isIncludeScript) {
        const htmlElement = document.getElementById("html")
        this.setInnerHTML(htmlElement, this.getContent())
      } else {
        this.options.content = this.getContent()
        this.componentKey++
      }
    },
  },

  computed: {
    ...mapState(["lang"]),
  },

  methods: {
    getContent() {
      if (!this.eventData) {
        return ""
      }
      switch (this.lang) {
        case "jp":
          return this.eventData.content_jp || this.eventData.content
        case "en":
          return this.eventData.content_en || this.eventData.content
        default:
          return this.eventData.content || ""
      }
    },
    hasPromotionCode() {
      return this.eventData.promotionCode ? true : false
    },
    goReserve: _.debounce(function () {
      console.log("goReserve")
      // 예약하기 화면으로 이동
      this.$router.push({ path: "/reservation/package", query: { productId: this.eventData.promotionCode } })
    }, 500),

    setInnerHTML(elm, html) {
      elm.innerHTML = html

      Array.from(elm.querySelectorAll("script")).forEach((oldScriptEl) => {
        const newScriptEl = document.createElement("script")

        Array.from(oldScriptEl.attributes).forEach((attr) => {
          newScriptEl.setAttribute(attr.name, attr.value)
        })

        const scriptText = document.createTextNode(oldScriptEl.innerHTML)
        newScriptEl.appendChild(scriptText)

        oldScriptEl.parentNode.replaceChild(newScriptEl, oldScriptEl)
      })
    },
  },
}
</script>
<style scoped>
.main {
  position: relative;
  overflow-y: auto;
  padding-bottom: 100px !important;
}

.bottomFixedButton {
  max-width: 580px;
  width: 100%;
  height: 100px;
  position: fixed;
  bottom: 72px;
  padding-bottom: 10px;
  border-top: 1px solid #e0e0e0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
</style>
