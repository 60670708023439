import { BehaviorSubject } from "rxjs"

const kMaxWindowSize = 580

function _psdLayerToEditablePSDLayer(layer, scale) {
  layer = { ...layer }
  layer.left *= scale
  layer.top *= scale
  layer.width *= scale
  layer.height *= scale

  const editableLayer = {
    ...layer,
    key: layer.name + layer.id,
    refreshSubject: new BehaviorSubject(0),
    startRect: {
      left: layer.left,
      top: layer.top,
      width: layer.width,
      height: layer.height,
    },
  }

  if (layer.children) {
    editableLayer.children = layer.children.map((x) => _psdLayerToEditablePSDLayer(x, scale))
  }

  return editableLayer
}

export function psdLayerToEditablePSDLayer(layer) {
  const scale = kMaxWindowSize / layer.width
  layer.scale = scale
  return _psdLayerToEditablePSDLayer(layer, scale)
}

export function _editablePSDLayerToPSDLayer(layer, scale) {
  layer = { ...layer }
  layer.left /= scale
  layer.top /= scale
  layer.width /= scale
  layer.height /= scale

  const psdLayer = { ...layer, children: layer.children?.map((x) => _editablePSDLayerToPSDLayer(x, scale)) }

  delete psdLayer["key"]
  delete psdLayer["refreshSubject"]
  delete psdLayer["startRect"]

  return psdLayer
}

export function editablePSDLayerToPSDLayer(layer) {
  const psdLayer = _editablePSDLayerToPSDLayer(layer, layer.scale)
  psdLayer.scale = 1
  return psdLayer
}
