<template>
  <div>
    <v-img width="180" height="32" contain class="mx-auto mt-2" src="@/assets/deco/line_0.png"></v-img>
    <p class="text-center font-danjunghae" style="color: #b67a7a; font-size: 30px">스토어</p>

    <v-divider class="mt-3 mb-2"></v-divider>

    <div class="d-flex justify-center mt-4 mb-4">
      <v-btn :color="category == '전체' ? '#ffc0c0' : 'grey'" :outlined="category != '전체'" depressed small class="mr-2 rounded-lg" style="padding-bottom: 2px" @click="category = '전체'">
        전체
      </v-btn>
      <v-btn :color="category == '액자' ? '#ffc0c0' : 'grey'" :outlined="category != '액자'" depressed small class="mr-2 rounded-lg" style="padding-bottom: 2px" @click="category = '액자'">
        액자
      </v-btn>
    </div>
    <div class="mt-10">
      <div class="d-flex justify-center">
        <v-icon size="54" color="primary lighten-1" class="mb-4"> mdi-close-circle-outline </v-icon>
      </div>
      <p class="text-center font-weight-bold primary--text">현재 등록된 스토어 상품이 없습니다</p>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    category: "전체",
  }),
}
</script>
