<template>
  <div>
    <!-- 제목 -->
    <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
      <font class="font-weight-bold text-h6 grey--text text--darken-2">솔로프리미엄 스케줄</font>
    </v-sheet>

    <!-- 본문 -->
    <v-sheet class="ma-2 pa-4" outlined>
      <!-- 날짜 선택 -->
      <DateSelector color="#f1b2ba" :width="300" class="mt-1 mb-4" @dateUpdated="dateUpdated_handler" />

      <!-- 본문 -->
      <main class="d-flex">
        <!-- 시간표 -->
        <table class="timeTable" style="width: 140px">
          <tr>
            <th class="corner"></th>
          </tr>
          <tr v-for="time in time_list" :key="time">
            <th>{{ time }}</th>
          </tr>
        </table>

        <!-- 메이커 시간표 -->
        <table v-for="maker in maker_list" :key="maker" class="timeTable" style="margin-left: -1px">
          <tr>
            <th>{{ maker }}</th>
          </tr>
          <tr v-for="time in time_list" :key="time">
            <td :style="reserved_list.filter((e) => e.maker == maker && e.time == time).length ? 'background:#f1f1f1;' : ''">
              <v-sheet
                v-if="reserved_list.filter((e) => e.maker == maker && e.time == time).length"
                class="rounded-10 d-flex justify-center align-center primary lighten-1 white--text"
                style="cursor: pointer"
                height="48"
                @click="viewDetail(maker, time)"
              >
                {{ reserved_list.filter((e) => e.maker == maker && e.time == time)[0].name }}
              </v-sheet>
            </td>
          </tr>
        </table>
      </main>
    </v-sheet>

    <!-- 예약 상세보기 (dialog) -->
    <v-dialog v-model="dialog.detail" width="460" content-class="rounded-xl">
      <v-sheet class="pt-8 pb-6">
        <!-- 제목 -->
        <p class="mb-6 font-weight-bold text-center" style="font-size: 24px">예약 상세보기</p>

        <table class="table_detail mx-auto">
          <tr>
            <td>예약자명</td>
            <td>{{ selected_for_detail.name }}</td>
          </tr>
          <tr>
            <td>전화번호</td>
            <td>{{ selected_for_detail.phone }}</td>
          </tr>
          <tr>
            <td>이메일</td>
            <td>{{ selected_for_detail.email }}</td>
          </tr>
          <tr style="height: 16px"></tr>
          <tr>
            <td>메이커</td>
            <td>{{ selected_for_detail.maker }}</td>
          </tr>
          <tr>
            <td>컨셉</td>
            <td>{{ selected_for_detail.concept }}</td>
          </tr>
          <tr>
            <td>결제일</td>
            <td>{{ new Date(selected_for_detail.created).toLocaleDateString() }}</td>
          </tr>
          <tr>
            <td>이용일</td>
            <td>{{ selected_for_detail.year }}.{{ selected_for_detail.month }}.{{ selected_for_detail.day }} / {{ selected_for_detail.time }}</td>
          </tr>
          <tr style="height: 16px"></tr>
          <tr>
            <td>결제상태</td>
            <td>{{ selected_for_detail.pay_status }}</td>
          </tr>
          <tr>
            <td>결제수단</td>
            <td>신용카드</td>
          </tr>
          <tr>
            <td>결제금액</td>
            <td v-if="selected_for_detail.pay_amount">{{ $toComma(selected_for_detail.pay_amount) }}원</td>
          </tr>
          <!-- <tr style="height:16px;"></tr>
                    <tr>
                        <td>이용이력</td>
                        <td>없음</td>
                    </tr> -->
        </table>

        <!-- 버튼 -->
        <div class="d-flex justify-center mt-8">
          <!-- 취소 -->
          <v-btn width="100" depressed color="grey lighten-3" class="font-weight-bold rounded-10 mr-3" @click="dialog.detail = false"> 닫기 </v-btn>

          <!-- 제출 -->
          <v-btn width="100" dark depressed class="font-weight-bold rounded-10" @click="reservation_cancel(selected_for_detail.order_id)"> 예약취소 </v-btn>
        </div>
      </v-sheet>
    </v-dialog>
  </div>
</template>
<script>
import DateSelector from "@/components/DateSelector"

export default {
  components: {
    DateSelector,
  },

  data: () => ({
    maker_list: ["노아", "멜라", "강이"],

    time_list: ["10:30", "12:00", "12:30", "13:30", "14:00", "15:00", "15:30", "16:30", "17:00", "18:00", "18:30", "19:30", "20:00", "21:00"],

    date: null,

    selected_for_detail: {},

    reserved_list: [],

    dialog: {
      detail: false,
    },
  }),

  watch: {
    date() {
      // 라피아캐슬 예약 불러오기
      this.loadReservationList()
    },
  },

  methods: {
    // DateSelector에서 date 가져오기
    dateUpdated_handler(date) {
      this.date = date
    },

    // 솔로프리미엄 예약 불러오기
    loadReservationList() {
      this.$http
        .post("/api/reservation/select/specific/day/category/all", {
          params: {
            category: "솔로프리미엄",
            year: this.date.year(),
            month: this.date.month() + 1,
            day: this.date.date(),
          },
        })
        .then((res) => {
          console.log(res)
          this.reserved_list = res.data
        })
    },

    viewDetail(maker, time) {
      this.selected_for_detail = this.reserved_list.filter((e) => e.maker == maker && e.time == time)[0]
      this.dialog.detail = true
    },

    // 예약 취소하기
    reservation_cancel() {
      if (confirm("해당 예약을 정말로 취소하시겠습니까?")) {
        this.$http
          .post("/api/reservation/update/cancel", {
            params: {
              order_id: this.selected_for_detail.order_id,
            },
          })
          .then((res) => {
            if (res.data.affectedRows) {
              alert("해당 예약이 정상적으로 취소되었습니다.")
              this.dialog.detail = false
              this.loadReservationList()
            }
          })
      }
    },
  },
}
</script>
<style scoped>
/* 타임테이블 */
.timeTable {
  width: 220px;
  border: 1px solid #ccc;
  border-collapse: collapse;
  text-align: center;
  font-weight: 500;
}

.timeTable tr th {
  padding: 4px 8px;
  height: 64px;
  border: 1px solid #ccc;
  background: rgba(195, 126, 120, 0.15);
}

.timeTable tr th.corner {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"><line x1="100%" y1="100%" x2="0" y2="0" stroke="%23ccc" stroke-width="1"/></svg>');
  background-repeat: no-repeat;
}

.timeTable tr td {
  padding: 4px 8px;
  height: 64px;
  border: 1px solid #ccc;
}

/* 상세보기 */
.table_detail {
  background: white;
}

.table_detail tr td {
  padding: 4px;
}

.table_detail tr td:first-child {
  width: 120px;
  padding: 4px;
}
</style>
