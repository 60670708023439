<template>
  <v-dialog max-width="440" :value="visible" content-class="rounded-10" style="z-index: 15" @input="$emit('input', $event)">
    <v-card>
      <v-card-title> {{ $t("page.couponselect.title") }} </v-card-title>
      <v-card-text>
        <v-list v-if="coupons.length" two-line outlined style="min-height: 200px; max-height: 300px; overflow-y: auto; background-color: #fafafa">
          <v-list-item-group v-model="selectedCouponIdx" color="primary" mandatory>
            <v-list-item v-show="showNoCoupon">
              <v-list-item-content>
                <v-list-item-title>{{ $t("page.couponselect.not-apply-title") }}</v-list-item-title>
                <v-list-item-subtitle></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="coupon in coupons" :key="coupon.issuedId">
              <v-list-item-content>
                <v-list-item-title>{{ getCouponTitle(coupon) }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  $t("page.couponselect.coupon-subtitle", { expireAt: dayjs(coupon.expireAt).format("YYYY.MM.DD"), amount: getDiscountPriceStr(coupon) })
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-list-item-group>
        </v-list>
        <v-sheet v-else>
          <span class="grey--text text-body-1"> {{ $t("page.couponselect.usable-coupon-empy") }} </span>
        </v-sheet>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn color="black" outlined class="dialog_btn rounded-2" style="font-size: 20px" width="40%" depressed @click="confirmClick" large> {{ $t("button.cancel") }} </v-btn>

        <v-btn color="#d9bddd" class="dialog_btn rounded-2" style="font-size: 20px" width="40%" depressed @click="applyClick" large :disabled="selectedCouponIdx === null">
          {{ $t("button.apply") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapState } from "vuex"
export default {
  props: {
    showNoCoupon: {
      type: Boolean,
      default: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    conceptId: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...mapState(["lafia_user", "lang"]),
  },
  data: () => ({
    selectedCouponIdx: 0,
    coupons: [],
  }),
  methods: {
    confirmClick() {
      this.$emit("close")
    },
    applyClick() {
      console.log("applyClick", this.selectedCouponIdx)
      if (this.selectedCouponIdx > 0) {
        this.$emit("apply", this.coupons[this.selectedCouponIdx - 1])
      } else {
        this.$emit("apply", null)
      }
      this.$emit("close")
    },
    getDiscountPriceStr(item) {
      return `${this.$toComma(item.discount_KRW)}${this.$t("common.krw")}`
    },

    getCouponTitle(coupon) {
      switch (this.lang) {
        case "en":
          return coupon.title_en || coupon.title
        case "jp":
          return coupon.title_jp || coupon.title
        default:
          return coupon.title
      }
    },
  },
  mounted() {
    console.log("MyCouponSelectList mounted")
    if (!["드레스대여", "키오스크컨셉구매", "인쇄", "이용권"].includes(this.type)) {
      return
    }

    this.$http
      .get("/api/coupon/getUserCoupons", {
        params: {
          user_id: this.lafia_user.user_id,
          type: this.type,
        },
      })
      .then((res) => {
        if (this.type === "키오스크컨셉구매") {
          this.coupons = res.data.filter((e) => {
            return e.type === "모든컨셉할인" || (e.type === "특정컨셉할인" && e.conceptId === this.conceptId)
          })
        } else {
          this.coupons = res.data
        }

        if (this.coupons && this.coupons.length > 0 && !this.showNoCoupon) {
          this.selectedCouponIdx = 1
        }
      })
      .catch((err) => {
        alert(this.$t("alert.error_failed_get_coupons"))
      })
  },
}
</script>
<style scoped>
::v-deep .v-card {
  border: 3px solid #dadada !important;
  border-radius: 10px;
}
.dialog_btn {
  border: 2px solid #dadada !important;
  font-size: 16px;
}
</style>
