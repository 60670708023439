<template>
  <div class="pb-10 px-4">
    <v-sheet class="mt-4 text-body-1 font-weight-medium d-flex justify-space-between">
      <span>{{ mission.title }}</span>
      <span class="text-caption">{{ new Date(mission.created).toLocaleString() }}</span>
    </v-sheet>
    <v-divider class="my-4"></v-divider>
    <v-sheet class="mt-4 mb-2">
      <TipTapReader v-if="options.content" style="min-height: 300px" class="tiptap_style" :options="options" />
    </v-sheet>
    <v-divider></v-divider>
    <!-- 댓글 등록 -->
    <div>
      <v-textarea v-model="value" :maxlength="maxLength" :counter="maxLength" :rules="rules" clear-icon outlined rounded-2 label="댓글등록" @focus="onFocus" />

      <div class="d-flex justify-end mb-4 mt-1">
        <v-btn outlined @click="submit" color="primary">등록</v-btn>
      </div>
    </div>

    <v-divider></v-divider>
    <div class="my-2">
      <div class="text-body-2 font-weight-light">댓글 목록</div>
      <div v-if="list.length > 0" class="text-center my-auto mx-auto grey--text mt-4">등록된 댓글이 없습니다.</div>
      <div class="mt-1" v-else>
        <v-list v-for="item in comments" :key="item.id">
          <v-list-item>
            <v-list-item-content class="pb-0 pt-1">
              <v-list-item-title style="white-space: pre-line; word-wrap: break-word">
                {{ item.comment }}
              </v-list-item-title>
              <v-list-item-subtitle class="mt-1 mt-md-2 d-flex justify-space-between">
                <span>{{ new Date(item.created).toLocaleDateString() }}&nbsp;&nbsp;{{ item.name }}</span>
                <v-btn v-if="item.user_id === lafia_user.user_id" x-small outlined color="accent" @click="deleteItem(item)">삭제</v-btn>
              </v-list-item-subtitle>

              <v-divider class="mt-2"></v-divider>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </div>
  </div>
</template>
<script>
import TipTapReader from "@/components/tiptap/Reader"
import { mapState } from "vuex"
const kMaxLength = 256
export default {
  components: {
    TipTapReader,
  },
  data: () => ({
    mission: {
      title: "",
      content: "",
      admin_id: "",
      created: "",
    },
    list: [],
    comments: [],
    //TipTap
    options: {
      content: "",
      editable: true,
      supportImage: true,
      supportVideo: true,
    },

    maxLength: kMaxLength,
    rules: [(v) => (v || "").length <= kMaxLength || `최대 ${kMaxLength}자까지 입력 가능합니다.`],
    value: "",
  }),
  computed: {
    ...mapState(["lafia_user"]),
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      const result = await this.$http.post("/api/missions/select/id", {
        params: { id: this.$route.params.missionId },
      })
      if (result && result.data) {
        this.mission = result.data[0]
        console.log("mission", this.mission)
        this.options.content = this.mission.content
      }

      this.commentsLoad()
    },
    async commentsLoad() {
      const commentRes = await this.$http.get("/api/missions/comment", {
        params: { mission_id: this.$route.params.missionId },
      })
      if (commentRes && commentRes.data) {
        this.comments = commentRes.data
      }
    },

    submit: _.debounce(async function () {
      console.log(this.mission)
      if (!this.mission.title) {
        alert("미션이 존재하지 않습니다.")
        return
      }
      try {
        await this.$http.post("/api/missions/comment/insert", {
          mission_id: this.$route.params.missionId,
          comment: this.value,
          user_id: this.lafia_user.user_id,
          name: this.lafia_user.name,
        })
        this.value = ""
        this.commentsLoad()
      } catch (e) {
        console.log(e)
        alert("댓글 등록에 실패하였습니다.")
      }
    }, 500),

    deleteItem: _.debounce(async function (item) {
      console.log("deleteItem", item, this.lafia_user.user_id)
      if (item.user_id !== this.lafia_user.user_id) {
        return
      }
      try {
        const result = await this.$http.post("/api/missions/comment/delete", {
          user_id: this.lafia_user.user_id,
          id: item.id,
        })
        if (result.data.affectedRows) {
          this.commentsLoad()
          alert("삭제가 되었습니다.")
        } else {
          alert("삭제가 되지 않았습니다. 권한을 확인하세요.")
        }
      } catch (e) {
        console.log(e)
      }
    }, 500),

    onFocus() {
      console.log("onFocus")
      if (!this.lafia_user.is_logined) {
        const callbackUrl = `/traveler/missions/${this.$route.params.missionId}`
        this.$router.push(`/auth/login?callbackUrl=${callbackUrl}`)
        return
      }
    },
  },
}
</script>
<style></style>
