<template>
  <div>
    <!-- 대문 -->
    <v-sheet class="pt-4 mx-auto d-flex flex-column align-center justify-center" color="transparent">
      <TitleLogo :title="$t('page.location.title')" />

      <!-- <v-img width="180" height="32" contain class="mx-auto mt-2" src="@/assets/deco/line_0.png"></v-img>
      <p class="text-center font-danjunghae mb-2" :style="selected_site === 'lafia' ? 'font-size:30px; color: #5b4a62;' : 'font-size:30px; color: #872451;'">오시는 길</p> -->

      <div class="text-center category" @click="openSelectModal()">
        {{ getLocatoinName() }}
      </div>

      <div style="width: 100%" class="mt-4">
        <carousel :per-page="1" :mouse-drag="true" :autoplay="false" paginationColor="grey" paginationActiveColor="#6d62b5" :paginationSize="16" style="width: 100%">
          <slide v-for="item in getLocationImages()" :key="item.id">
            <v-img width="100%" :src="getImageResource(item.src)" aspect-ratio="1.77" contain></v-img>
          </slide>
        </carousel>
      </div>
      <div class="mt-1" v-show="isSelectedSite()">
        <span class="primary--text text-body-1" style="letter-spacing: -0.6px !important"> {{ $t("page.location.slide-guide") }} </span>
      </div>
    </v-sheet>
    <v-sheet class="mt-10 mb-2 px-4" v-show="isSelectedSite()">
      <v-img :src="getAddressImageResource()" contain></v-img>
    </v-sheet>
    <v-dialog v-model="dialog.site_select" content-class="rounded-10" width="100%" max-width="500px">
      <v-sheet class="rounded-10 pa-4 pb-2" :key="componentKey">
        <v-btn v-for="site in site_list" :key="site.id" depressed color="#f1b2ba" width="100%" block class="rounded-10 mb-2" @click="routeSitePage(site)">
          {{ site.name }}
        </v-btn>
      </v-sheet>
    </v-dialog>
  </div>
</template>
<script>
import TipTapReader from "@/components/tiptap/Reader"
import TitleLogo from "@/views/components/TitleLogo"
import { mapState } from "vuex"

export default {
  components: {
    TipTapReader,
    TitleLogo,
  },
  computed: {
    ...mapState(["lang"]),
  },

  data: () => ({
    selected_site: "lafia",
    componentKey: 0,
    dialog: {
      site_select: false,
    },
    site_list: [
      {
        id: "lafia",
        name: "세라 캐슬 신촌점",
        name_en: "SELA CASTLE SINCHON",
        name_jp: "セラキャッスル新村店",
      },
      {
        id: "maker",
        name: "메이커 촬영 신촌점",
      },
    ],

    locationNames: {
      lafia: {
        ko: "세라 캐슬 신촌점",
        en: "SELA CASTLE SINCHON",
        jp: "セラキャッスル新村店",
      },
      maker: {
        ko: "메이커 촬영 신촌점",
        en: "메이커 촬영 신촌점",
        jp: "메이커 촬영 신촌점",
      },
    },

    locationImages: {
      lafia: [
        {
          id: "lafia-1",
          src: "ic_map01.png",
        },
        {
          id: "lafia-2",
          src: "ic_map02.png",
        },
        {
          id: "lafia-3",
          src: "ic_map03.png",
        },
        {
          id: "lafia-4",
          src: "ic_map04.png",
        },
        {
          id: "lafia-5",
          src: "ic_map05.png",
        },
        {
          id: "lafia-6",
          src: "ic_map06.png",
        },
        {
          id: "lafia-7",
          src: "ic_map07.png",
        },
      ],
      maker: [
        {
          id: "maker-1",
          src: require("@/assets/location/maker/p_map01.jpg"),
        },
        {
          id: "maker-2",
          src: require("@/assets/location/maker/p_map02.png"),
        },
        {
          id: "maker-3",
          src: require("@/assets/location/maker/p_map03.png"),
        },
        {
          id: "maker-4",
          src: require("@/assets/location/maker/p_map04.png"),
        },
      ],
    },
    addressImages: {
      lafia: "address.png",
      maker: "address.png",
    },

    //TipTap
    options: {
      content: "",
      editable: false,
      supportImage: true,
      supportVideo: true,
    },
  }),

  methods: {
    getLocatoinName() {
      return this.selected_site ? this.locationNames[this.selected_site][this.lang] : "지점 선택"
    },
    getLocationImages() {
      return this.locationImages[this.selected_site]
    },
    getAddressImage() {
      return this.addressImages[this.selected_site]
    },
    openSelectModal() {
      //this.dialog.site_select = true
      //this.componentKey++
    },
    routeSitePage(site) {
      this.dialog.site_select = false
      this.selected_site = site.id
    },
    isSelectedSite() {
      return !!this.selected_site
    },
    getLangPath() {
      //const supportLang = ["ko", "en", "jp"]
      switch (this.lang) {
        case "ko":
          return ""
        case "jp":
          return "jp/"
        default:
          return "en/"
      }
    },
    getImageResource(fileName) {
      let path = this.getLangPath()
      return require(`@/assets/location/lafia/${path}${fileName}`)
    },

    getAddressImageResource() {
      let path = this.getLangPath()

      return require(`@/assets/location/lafia/${path}address.png`)
    },
  },
}
</script>
<style scoped>
.category {
  border: 2px solid #ccc;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 22px;
  cursor: pointer;
  min-width: 220px;
}

.VueCarousel-slide {
  visibility: visible;
  flex-basis: 100%;
  width: 100%;
}
</style>
